<template>
  <div class="sid-select">
    <div
      v-if="square"
      class="border-b mt-[-1px] gap-y-[12px]"
    >
      <div
        class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter"
        :class="active === 'square' ? 'border-b rounded-b-0' : null"
        @click="active === 'square' ? active = null : active = 'square'"
      >
        <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">
          Square Field
        </span>

        <button
          title="Column Settings"
          class="flex h-[38px] items-center justify-center w-[38px] ml-auto p-0 bg-transparent hover:bg-[#E2E5EC]"
          @click.stop="$emit('open-settings')"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 7 10"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
              stroke="#6E747A"
              stroke-width="1.5"
            />
          </svg>
        </button>
      </div>

      <SquareFieldSettings
        v-if="active === 'square'"
        :sid-class="`.--${element.sid}--square`"
      />
    </div>
    
    <div
      v-for="(item, index) in control"
      :key="index"
      class="border-b mt-[-1px] gap-y-[12px]"
    >
      <div
        class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter"
        :class="active === index ? 'border-b rounded-b-0' : null"
        @click="active === index ? active = null : active = index"
      >
        <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">
          {{ item.label }}
        </span>

        <button
          title="Column Settings"
          class="flex h-[38px] items-center justify-center w-[38px] ml-auto p-0 bg-transparent hover:bg-[#E2E5EC]"
          @click.stop="$emit('open-settings')"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 7 10"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
              stroke="#6E747A"
              stroke-width="1.5"
            />
          </svg>
        </button>
      </div>

      <FormControlsFieldSettingsV2
        v-if="active === index"
        :element="element"
        :control="item"
        :sid-class="`.--${element.sid}--form-field-${index}`"
        static-type
        hide-placeholder
      />
    </div>
    
    <SidbearSettingsGroup
      title="Secure Payment"
      group
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-font :sid="`.--${element.sid}--secure-payment`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${element.sid}--secure-payment`"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Icon Size"
        :sid="`.--${element.sid}--secure-payment-icon.secure-payment-icon`"
        rule-name="height"
      />

      <sid-range-slider
        label="Vertical Gap"
        :sid="`.--${element.sid}--secure-payment`"
        rule-name="gap"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.--${element.sid}--secure-payment`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Icon Color</label>

          <sid-color
            :sid="`.--${element.sid}--secure-payment-icon`"
            rule-name="color"
          />
        </div>
      </b-form-group>
      
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${element.sid}--secure-payment`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[
              {
                label: 'Solid',
                value: 'solid'
              },
              {
                label: 'Dashed',
                value: 'dashed'
              },
              {
                label: 'Dotted',
                value: 'dotted'
              },
              {
                label: 'Double',
                value: 'double'
              },
              {
                label: 'Outset',
                value: 'outset'
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${element.sid}--secure-payment`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${element.sid}--secure-payment`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <hr>

      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--secure-payment`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--secure-payment`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding, Margins & Gaps"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--form-fields`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-range-slider
        label="Horizontal Gap"
        :sid="`.--${element.sid}--margin .--${element.sid}--form-field, .--${element.sid}--margin .--${element.sid}--card-details`"
        rule-name="column-gap"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Vertical Gap"
        :sid="`.--${element.sid}--margin .--${element.sid}--form-field, .--${element.sid}--margin .--${element.sid}--card-details`"
        rule-name="row-gap"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>

    <div class="px-[16px] py-[14px]">
      <b-form-group class="!mb-[20px]">
        <b-checkbox v-model="element.options.content.showPaymentMethods">
          Show Card Icons
        </b-checkbox>
      </b-form-group>

      <div
        v-show="element.options.content.showPaymentMethods"
        class="grid grid-cols-3 gap-[8px]"
      >
        <b-form-group>
          <div class="inline-flex items-center gap-[8px]">
            <img
              src="@/assets/icons/payment-systems/visa.svg"
              alt="Mastercard"
              class="max-h-[24px]"
            >
            <b-checkbox v-model="element.options.content.paymentMethods.visa" />
          </div>
        </b-form-group>
        <b-form-group class="w-full">
          <div class="inline-flex items-center gap-[8px]">
            <img
              src="@/assets/icons/payment-systems/diners-club.svg"
              class="max-h-[24px]"
            >
            <b-checkbox v-model="element.options.content.paymentMethods.dinersClub" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="inline-flex items-center gap-[8px]">
            <img
              src="@/assets/icons/payment-systems/amex.svg"
              class="max-h-[24px]"
            >
            <b-checkbox v-model="element.options.content.paymentMethods.amex" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="inline-flex items-center gap-[8px]">
            <img
              src="@/assets/icons/payment-systems/discover.svg"
              class="max-h-[24px]"
            >
            <b-checkbox v-model="element.options.content.paymentMethods.discover" />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="inline-flex items-center gap-[8px]">
            <img
              src="@/assets/icons/payment-systems/master-card.svg"
              class="max-h-[24px]"
            >
            <b-checkbox v-model="element.options.content.paymentMethods.mastercard" />
          </div>
        </b-form-group>
        <!--        <b-form-group>-->
        <!--          <div class="inline-flex items-center gap-[8px]">-->
        <!--            <img-->
        <!--              src="@/assets/icons/payment-systems/paypal.svg"-->
        <!--              class="h-[24px]"-->
        <!--            >-->
        <!--            <b-checkbox v-model="element.options.content.paymentMethods.paypal" />-->
        <!--          </div>-->
        <!--        </b-form-group>-->
      </div>
    </div>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import FormControlsFieldSettingsV2 from '@/components/editor/components/form/FormControlsFieldSettingsV2.vue'
import {mapState} from 'vuex'
import SquareFieldSettings from '@/components/editor/components/form/SquareFieldSettings.vue'

export default {
  name: 'CreditCardSettings',
  components: {
    SquareFieldSettings,
    FormControlsFieldSettingsV2,
    SidLinkedGroup,
    SidSelect,
    SidRangeSlider,
    SidColor,
    SidFont,
    SidbearSettingsGroup
  },
  props: {
    control: {
      type: Array,
      required: true
    },
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('orderForm', {
      providers: (state) => state.providers
    }),
    square () {
      return this.providers.find(item => item.code === 'square')
    }
  },
  data () {
    return {
      active: null,
      cardholderField: {
        label: 'Cardholder',
        placeholder: null,
        field_name: 'card_holder',
        field_type: 'text',
        values: null,
        validators: '[]',
        is_required: true,
        mapping: []
      }
    }
  },
  mounted () {
    const cardholder = this.control.find(item => item.field_name === 'card_holder')
    if (!cardholder) {
      this.control.splice(1, 0, this.cardholderField)
    }
  }
}
</script>