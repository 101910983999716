<template>
  <highliter
    v-if="visability('images')"
    v-model="section.name"
    :section="section"
    :dublicate="section"
    :uid="section.uid"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('images')"
  >
    <div class="images-group-v3">
      <div class="d-inline-block position-relative">
        <el-image-v3
          v-for="(image, index) in images"
          :key="index"
          class="img-transform"
          :section="image"
        />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'ElThreeImages',

  components: {Highliter},

  mixins: [SectionMixin],

  computed: {
    images () {
      return this.section.options.content.images.subjects
    }
  }
}
</script>

<style lang="scss">
.images-group-v3 {

  .img-transform {
    display: flex;
  }

  .img-transform:nth-child(1) {
    width: 188px !important;
    min-height: 242px !important;

    img {
      width: 188px !important;
      min-height: 242px !important;
    }
  }

  .img-transform:nth-child(3){
    position: absolute;
    top: -10%;
    left: 90%;

    width: 188px !important;
    min-height: 242px !important;

    img {
      width: 155px !important;
      min-height: 182px !important;
    }
  }

  .img-transform:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 70%;

    width: 188px !important;
    min-height: 242px !important;

    img {
      width: 155px !important;
      min-height: 182px !important;
    }
  }
}
</style>