<template>
  <div class="flex flex-item-center justify-between gap-x-[32px]">
    <div class="flex flex-col gap-[20px]">
      <div class="flex flex-row items-center gap-x-[16px]">
        <p class="text-[27px] leading-[32px] font-[700] text-[#000000] mb-0">
          {{ planName }}
        </p>

        <span
          v-if="!isSpecialPlan"
          class="flex items-center justify-center h-[24px] px-[8px] py-[4px] rounded-[4px] text-[12px] leading-[14px] font-[400] capitalize bg-[#EBF2FF] text-[#4F83E3]"
          :class="{'bg-[#D6EDD2] text-[#3B9033]': trial, 'bg-red-100 text-red-700': !['succeeded', 'succeeeded'].includes(userSubscription.status)}"
        >
          {{ planStatus }}
        </span>
      </div>

      <div
        v-if="!isSpecialPlan"
        class="flex flex-row gap-x-[12px]"
      >
        <p class="text-[16px] leading-[20px] font-[400] text-[#A9A9A9] mb-0">
          Your next invoice is scheduled on
          <span class="font-[600] text-[#000000]">
            {{ moment(billingDate).format('MMM DD, YYYY') }}
          </span>
        </p>
        <div
          class="text-[16px] leading-[20px] font-[400] text-[#4F83E3] cursor-pinter no-underline hover:underline"
          @click="modal = true"
        >
          Change Billing Date
        </div>
      </div>

      <change-billing-date-modal
        v-model="modal"
        :billing-date="billingDate"
      />
    </div>

    <template v-if="!isSpecialPlan">
      <p
        v-if="!trial"
        class="relative text-[52px] leading-[64px] font-[700] text-[#000000] mb-0"
      >
        <span class="absolute top-[8px] left-[-20px] text-[24px] leading-[32px]">$</span>{{ parseInt(userSubscription.paid_amount) }}
        <span class="text-[16px] font-[400] -ml-[8px]">
          per {{ interval }}
        </span>
      </p>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import ChangeBillingDateModal
  from '@/layouts/dashboard/compontens/account/billing/components/ChangeBillingDateModal.vue'
export default {
  name: 'AccountOverview',
  components: {
    ChangeBillingDateModal
  },
  props: {
    interval: {
      type: String,
      required: true
    },
    userSubscription: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    trial () {
      return _.includes(['ESTAGE_SUB', 'BUSINESS_Y', 'BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], this.userSubscription.plan.code) && parseInt(this.userSubscription.paid_amount) === 0
    },
    isSpecialPlan () {
      return !_.includes(['ESTAGE_SUB', 'BUSINESS_Y', 'BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], this.userSubscription.plan.code)
    },
    planStatus () {
      if (this.trial) return 'Trial'
      if (!['succeeded', 'succeeeded'].includes(this.userSubscription.status)) return this.userSubscription.status
      return this.interval
    },
    billingDate () {
      return this.userSubscription.plan_period_end
    },
    planName () {
      switch (this.userSubscription.plan.code) {
      case 'ESTAGE_SUB':
        return 'ESTAGE Business Account'
      case 'BUSINESS_Y':
        return 'ESTAGE Business Account'
      case 'BUSINESS_PLUS_M':
        return 'ESTAGE Business Plus Account'
      case 'BUSINESS_PLUS_Y':
        return 'ESTAGE Business Plus Account'
      default:
        return _.get(this.userSubscription, 'plan.description', 'special custom Four Percent insider\'s plan')
      }
    }
  }
}
</script>