<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Autoresponders
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Connect with your favorite email management system simply and easy! <br>
        ESTAGE seamlessly integrates with all of the autoresponders listed below, as we also continue to add more!
      </p>
    </div>
    <div>
      <integration-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="{ 'mb-[16px]': index + 1 !== items.length }"
        hide-status-toggler
        @submit="val => handleSubmit(val, item)"
        @toggle-status="val => handleToggleStatus(val, item)"
        @remove="val => handleDelete(val, item)"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem'
import Cookie from '@builder/components/mixins/Cookie'

export default {
  components: {IntegrationItem},
  mixins: [Cookie],

  data () {
    return {
      items: [
        {
          title: 'AWeber',
          active: false,
          image: require('@/assets/images/integrations/aweber.svg'),
          icon: require('@/assets/images/integrations/aweber-icon.svg'),
          img_styles:'height:200px;',
          alpha_code: 'AWeber',
          data:[]
        },
        {
          title: 'ActiveCampaign',
          active: false,
          image: require('@/assets/images/integrations/activecampaign.svg'),
          icon: require('@/assets/images/integrations/activecampaign-icon.png'),
          img_styles:'height:40px;',
          alpha_code: 'activecampaign',
          data: [
            {
              label: 'URL',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_id',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid URL'
                }
              }
            },
            {
              label: 'Key',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Key'
                }
              }
            }
          ]
        },
        {
          title: 'Brevo',
          active: false,
          image: require('@/assets/images/integrations/brevo.svg'),
          icon: require('@/assets/images/integrations/brevo-icon.svg'),
          alpha_code: 'brevo',

          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        // {
        //   title: 'Constant Contact',
        //   active: false,
        //   image: require('@/assets/images/integrations/constantcontact.svg'),
        //   icon: require('@/assets/images/integrations/constantcontact-icon.jpg'),
        //   img_styles:'height:40px;',
        //   alpha_code: 'ConstantContact',
        //   data:[]
        // },
        {
          title: 'ConvertKit',
          active: false,
          image: require('@/assets/images/integrations/convertkit.svg'),
          icon: require('@/assets/images/integrations/convertkit-icon.png'),
          img_styles:'height:40px;',
          alpha_code: 'ConvertKit',
          data: [
            {
              label: 'API Key',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_id',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid API Key'
                }
              }
            },
            {
              label: 'App Secret',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid App ID'
                }
              }
            }
          ]
        },
        {
          title: 'GetResponse',
          active: false,
          image: require('@/assets/images/integrations/getresponse.svg'),
          icon: require('@/assets/images/integrations/getresponse-icon.svg'),
          alpha_code: 'getResponse',
          data: [
            {
              label: 'API Key',
              value: '',
              type: 'text',
              key:'client_secret',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid API Key'
                }
              }
            }
          ]
        },
        {
          title: 'HubSpot',
          active: false,
          image: require('@/assets/images/integrations/hubspot.svg'),
          icon: require('@/assets/images/integrations/hubspot-icon.svg'),
          alpha_code: 'hubspot',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Keap',
          active: false,
          image: require('@/assets/images/integrations/keap.svg'),
          icon: require('@/assets/images/integrations/keap-icon.png'),
          alpha_code: 'keap',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Mailchimp',
          active: false,
          image: require('@/assets/images/integrations/mailchimp.png'),
          icon: require('@/assets/images/integrations/mailchimp-icon.svg'),
          img_con_styles:'background-color:#FED602;',
          alpha_code: 'mailChimp',
          data: [
            {
              label: 'API Key',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_secret',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid API Key'
                }
              }
            }
          ]
        },
        {
          title: 'MailerLite',
          active: false,
          image: require('@/assets/images/integrations/mailerlite.png'),
          icon: require('@/assets/images/integrations/mailerlite-icon.png'),
          alpha_code: 'mailer_lite',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Moosend',
          active: false,
          image: require('@/assets/images/integrations/moosend.svg'),
          icon: require('@/assets/images/integrations/moosend-icon.svg'),
          alpha_code: 'moosend',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',

              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Ontraport',
          active: false,
          image: require('@/assets/images/integrations/ontraport.svg'),
          icon: require('@/assets/images/integrations/ontraport-icon.svg'),
          img_styles:'height:40px;',
          alpha_code: 'Ontraport',
          data: [
            {
              label: 'App ID',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_id',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid App ID'
                }
              }
            },
            {
              label: 'API Key',
              value: '',
              type: 'text',
              dirty:false,
              key:'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid API Key'
                }
              }
            }
          ]
        },
        {
          title: 'Sender',
          active: false,
          image: require('@/assets/images/integrations/sender.svg'),
          icon: require('@/assets/images/integrations/sender-icon.svg'),
          alpha_code: 'sender',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'SendGrid',
          active: false,
          image: require('@/assets/images/integrations/sendgrid.svg'),
          icon: require('@/assets/images/integrations/sendgrid-icon.svg'),
          alpha_code: 'send_grid',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              dirty: false,
              key: 'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Sendlane',
          active: false,
          image: require('@/assets/images/integrations/sendlane.svg'),
          icon: require('@/assets/images/integrations/sendlane-icon.png'),
          img_styles:'height:200px;',
          alpha_code: 'sendlane',
          data: [
            {
              label: 'Access Token',
              value: '',
              type: 'textarea',
              dirty:false,
              key:'client_secret',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Access Token'
                }
              }
            }
          ]
        },
        {
          title: 'Zapier',
          active: false,
          image: require('@/assets/images/integrations/zapier.svg'),
          icon: require('@/assets/images/integrations/zapier-icon.png'),
          alpha_code: 'zapier',
          submit_text: 'Enable',
          guide_link: 'settings.zapier',
          dirty: false,
          data: [
            {
              label: '',
              value: 'fake_value_to_zapier',
              type: 'hidden',
              dirty: true,
              key: 'client_secret',
              validation: () => {
                return {
                  valid: true,
                  message: 'Please try again'
                }
              }
            }
          ]
        },
        {
          title: 'Zoho',
          active: false,
          image: require('@/assets/images/integrations/zoho.svg'),
          icon: require('@/assets/images/integrations/zoho-icon.png'),
          alpha_code: 'zoho',
          note: 'Integrating with Zoho will only add contacts email address. First name, last name, or other fields will not be added via API integration.'
        }
      ]
    }
  },

  computed: {
    ...mapState('apiIntegration', {
      autoresponders: state => state.mailAPIServices
    }),
    ...mapGetters('auth', {
      userRole: 'userRole'
    })
  },

  watch: {
    autoresponders: {
      handler:function (newValue ) {
        if (Array.isArray(newValue) && newValue.length) {
          this.initForms()
        }
      },
      deep:true,
      immediate:true
    }
  },

  destroyed () {
    if (this._oauthInterval) {
      window.clearInterval(this._oauthInterval)
    }
  },

  methods: {
    initForms () {
      this.items.forEach(item => {
        const service = this.autoresponders.find(a => a.alpha_code === item.alpha_code)
        if (service) {
          item.active = Boolean(service.is_connected)
          item.is_status_active = service.status === 'ACTIVE'
          item.autoresponder = service

          if (Array.isArray(item.data)) {
            item.data.forEach(field => {
              if (!field.value || service[field.key]) {
                field.value = service[field.key]
              }
            })
          }
        }
      })
    },

    handleSubmit ({setLoading, closeModal, formFields}, item) {
      const params = {
        project_id: this.$route.params.id,
        auto_responder_id: item.autoresponder.id
      }

      formFields.forEach(field => params[field.key] = field.value)

      if (item.alpha_code === 'zapier') {
        params.client_secret = 'fake_value_to_zapier'
      }

      if (!formFields.length) {
        this.connectOAuth(params.project_id, params.auto_responder_id, closeModal)

        return
      }

      setLoading(true)

      this.$store.dispatch(`autoresponders/${item.active ? 'update' : 'create'}`, params)
        .then(() => {
          item.data.forEach((field, index) => {
            field.value = formFields[index].value
          })

          this.autoresponders.forEach(el => {
            if (el.alpha_code === item.alpha_code) {
              formFields.forEach(field => {
                el[field.key] = field.value
              })
              if (!item.active) el.status = 'ACTIVE'
              el.is_connected = 1
            }
          })

          if (item.alpha_code === 'zapier') {
            this.$swal({
              icon: 'warning',
              iconColor: '#4F83E3',
              toast: true,
              position: 'top-right',
              title: 'Please activate the Zapier webhook in the form.',
              showConfirmButton: false,
              timer: 7500
            })
            this.$router.push({name: 'settings.zapier'})
          } else {
            this.$swal({
              icon: 'success',
              title: 'Settings saved successfully',
              showConfirmButton: false,
              timer: 1500
            })
          }


          if (!item.active) item.is_status_active = true
          item.active = true
          closeModal()
        })
        .finally(() => {
          setLoading(false)
        })
    },

    handleToggleStatus ({setLoading}, item) {
      const params = {
        project_id: this.$route.params.id,
        auto_responder_id: item.autoresponder.id,
        status: item.is_status_active ? 'DRAFT' : 'ACTIVE'
      }

      item.data.forEach(field => params[field.key] = field.value)
      setLoading(true)

      this.$store.dispatch('autoresponders/update', params)
        .then(() => {
          this.autoresponders.forEach(el => {
            if (el.alpha_code === item.alpha_code) {
              el.status = params.status
            }
          })

          item.is_status_active = params.status === 'ACTIVE'

          this.$swal({
            icon: 'success',
            title: 'Settings saved successfully',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },

    handleDelete ({setLoading, setDetails}, item) {
      setLoading(true)

      const params = {
        project_id: this.$route.params.id,
        auto_responder_id: item.autoresponder.id
      }

      this.$store.dispatch('autoresponders/delete', params)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Settings saved successfully',
            showConfirmButton: false,
            timer: 1500
          })

          item.data.forEach(field => field.value = '')
          item.active = false

          this.autoresponders.forEach(el => {
            if (el.alpha_code === item.alpha_code) {
              el.client_id = null
              el.client_secret = null
              el.is_connected = 0
            }
          })

          setDetails(false)
        })
        .finally(() => {
          setLoading(false)
        })
    },

    /* OAuth Based Integrations */
    ShowAuthWindow (options) {
      options.windowName = options.windowName || 'ConnectWithOAuth' // should not include space for IE
      options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=400'

      options.callback = options.callback || function () {
        window.location.reload()
      }

      this._oauthWindow = window.open(options.path, options.windowName, options.windowOptions)

      this._oauthInterval = window.setInterval(() => {
        if (this._oauthWindow.closed) {
          window.clearInterval(this._oauthInterval)
          options.callback()
        }
      }, 1000)
    },

    async connectOAuth (projectId, autoresponderId, closeModal) {
      await axios.get(`api/projects/${projectId}/auto-responders/${autoresponderId}/auth-url`)
        .then(({data}) => {
          this.set_cookie('mailServiceAPI', projectId + ',' + autoresponderId)
          this.ShowAuthWindow({
            path: data.data,
            callback: () => {
              VEvent.fire('loader', true)

              this.$store.dispatch('apiIntegration/getlAllMailServices')
                .finally(() => {
                  VEvent.fire('loader', false)
                  closeModal()
                })
            }
          })
        })
    }
  }
}
</script>