<template>
  <div class="player-wrapper w-full h-full aspect-video bg-black">
    <video
      ref="videoRef"
      :controls="false"
      autoplay
      muted
      playsinline
      style="width: 100%; height: auto;"
    />

    <div
      id="controls"
      :class="[{'!opacity-100': showPanel}]"
      class="video-control transition bg-gradient-to-t from-black/70 to-transparent"
      @mousemove="isPanelInFocus = true"
      @mouseleave="isPanelInFocus = false"
    >
      <div class="video-control-container">
        <div
          class="relative flex gap-2 items-center"
          @mouseenter="volumePanel = true"
          @mouseleave="volumePanel = false"
        >
          <button
            class="text-white flex items-center p-2 gap-2 font-semibold bg-transparent"
            @click="updateMute"
          >
            <svg
              v-if="mute"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="video-control-icon"
            >
              <path
                d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 0 0 1.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06ZM17.78 9.22a.75.75 0 1 0-1.06 1.06L18.44 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06l1.72-1.72 1.72 1.72a.75.75 0 1 0 1.06-1.06L20.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-1.72 1.72-1.72-1.72Z"
                stroke-linecap="evenodd"
                stroke-linejoin="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="video-control-icon"
            >
              <path
                d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 0 0 1.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06ZM18.584 5.106a.75.75 0 0 1 1.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 0 1-1.06-1.06 8.25 8.25 0 0 0 0-11.668.75.75 0 0 1 0-1.06Z"
                stroke-linecap="evenodd"
                stroke-linejoin="evenodd"
              />
              <path
                d="M15.932 7.757a.75.75 0 0 1 1.061 0 6 6 0 0 1 0 8.486.75.75 0 0 1-1.06-1.061 4.5 4.5 0 0 0 0-6.364.75.75 0 0 1 0-1.06Z"
                stroke-linecap="evenodd"
                stroke-linejoin="evenodd"
              />
            </svg>
          </button>

          <div
            class="transition overflow-hidden"
            :style="{width: volumePanel ? '60px' : '0px', display: volumePanel ? 'block' : 'none'}"
          >
            <div class="flex items-center justify-start rounded">
              <input
                v-model="volume"
                type="range"
                min="0"
                max="1"
                step="0.01"
                @input="updateVolume"
              >
            </div>
          </div>
        </div>

        <div class="live-logo ml-auto">
          <svg
            class=""
            width="70"
            height="9"
            viewBox="0 0 70 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.8926 0.576345V1.57624H28.2797V8.98575H29.7592V1.57624H33.1335V0.576345V0.0134277H24.8926V0.576345Z"
              fill="white"
            />
            <path
              d="M20.7408 4.15135C20.1888 3.65235 19.4991 3.40238 18.671 3.40238H14.8302C14.546 3.40238 14.3037 3.32319 14.1033 3.16576C13.9028 3.00834 13.8026 2.78508 13.8026 2.49599C13.8026 2.21548 13.9028 1.99222 14.1033 1.82621C14.3037 1.6602 14.546 1.57624 14.8302 1.57624H21.0925V0.56585V0.0134277H14.8302C14.086 0.0134277 13.4628 0.2281 12.9618 0.657443C12.4261 1.12209 12.1592 1.72985 12.1592 2.48358C12.1592 3.24591 12.427 3.85844 12.9618 4.32213C13.4637 4.75147 14.086 4.96615 14.8302 4.96615H18.671C19.081 4.96615 19.4317 5.05774 19.7251 5.24188C20.0758 5.46037 20.2516 5.7714 20.2516 6.17403C20.2516 6.57666 20.0804 6.89628 19.7378 7.1329C19.4363 7.32563 19.081 7.42199 18.6719 7.42199H12.335V8.43047V8.9848H18.6719C19.5 8.9848 20.1897 8.74437 20.7417 8.26255C21.3184 7.74543 21.6072 7.06325 21.6072 6.21315C21.6063 5.364 21.3175 4.67705 20.7408 4.15135Z"
              fill="white"
            />
            <path
              d="M0.0820312 8.98574H8.27286V8.42759V7.42198H1.57425V5.21515H8.27286V4.65796V3.65234H0.0820312V8.98574Z"
              fill="white"
            />
            <path
              d="M0.0820312 1.57451H8.27286V1.01636V0.0107422H0.0820312V1.57451Z"
              fill="white"
            />
            <path
              d="M60.9902 5.21515V7.42198V8.98574H69.182V8.42759V7.42198H62.4834V5.21515H69.182V4.65796V3.65234H60.9902V5.21515Z"
              fill="white"
            />
            <path
              d="M60.9902 0.0134277V1.57719H69.182V1.01905V0.0134277H60.9902Z"
              fill="white"
            />
            <path
              d="M40.3059 0.289091C40.0717 0.0963637 39.8458 0 39.6281 0C39.4277 0 39.2017 0.10018 38.9512 0.302449C38.7417 0.478003 38.5786 0.669776 38.462 0.880632L33.8086 8.98569H35.7281L39.5916 2.33849L41.0839 4.92696H39.6044L38.7016 6.47737H41.9748L43.4169 8.98664L45.3355 9L40.7951 0.881586C40.6694 0.652602 40.5063 0.456058 40.3059 0.289091Z"
              fill="white"
            />
            <path
              d="M48.7771 1.22227C47.97 2.02848 47.5664 3.08753 47.5664 4.40132C47.5664 5.7237 47.9736 6.819 48.789 7.68532C49.6043 8.5526 50.6347 8.98575 51.8809 8.98575H57.1274V5.5949V4.03304V4.03208H54.1238V5.5949H55.6352V7.42199H51.8818C51.0619 7.42199 50.3869 7.15484 49.8558 6.62055C49.3246 6.08626 49.0595 5.38976 49.0595 4.53203C49.0595 3.66476 49.3246 2.95586 49.8558 2.40344C50.3869 1.85197 51.0619 1.57624 51.8818 1.57624H57.1283V0.576345V0.0134277H51.8818C50.6192 0.0134277 49.5843 0.416057 48.7771 1.22227Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shaka from 'shaka-player'
import {mapState} from 'vuex'

export default {
  name: 'Llhls',
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mute: true,
      volumePanel: false,
      showPanel: true,
      isPanelInFocus: false,
      volume: 1,
      delay: false,
      syncCheckInterval: null,
      abr_stabilization_timeout: null
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    async loadLLHLSPreview () {
      const streamId = `stream-${this.section.options.content.liveStream.rtmp_server_stream_key}_p-${this.project.id}_t-page`
      const src = `https://hls.estage-oms.com/app/${streamId}/abr.m3u8`

      shaka.polyfill.installAll()

      if (!shaka.Player.isBrowserSupported()) {
        console.error('Browser did not support')
        return
      }

      this.player = new shaka.Player(this.$refs.videoRef)

      this.player.configure({
        'streaming': {
          'lowLatencyMode': true,
          'inaccurateManifestTolerance': 0,
          'rebufferingGoal': 1.0,
          'bufferingGoal': 5.0,
          'bufferBehind': 30,
          'evictionGoal': 1.0,
          'ignoreTextStreamFailures': false,
          'alwaysStreamText': false,
          'startAtSegmentBoundary': false,
          'gapDetectionThreshold': 0.5,
          'gapPadding': 0,
          'gapJumpTimerTime': 0.25,
          'durationBackoff': 1,
          'safeSeekOffset': 5,
          'safeSeekEndOffset': 0,
          'stallEnabled': true,
          'stallThreshold': 1,
          'stallSkip': 0.1,
          'useNativeHlsForFairPlay': true,
          'forceHTTP': false,
          'forceHTTPS': false,
          'minBytesForProgressEvents': 16000,
          'preferNativeDash': false,
          'preferNativeHls': false,
          'updateIntervalSeconds': 0.1,
          'observeQualityChanges': false,
          'maxDisabledTime': 30,
          'segmentPrefetchLimit': 2,
          'prefetchAudioLanguages': [],
          'disableAudioPrefetch': false,
          'disableTextPrefetch': false,
          'disableVideoPrefetch': false,
          'liveSync': {
            'enabled': true,
            'targetLatency': 0.5,
            'targetLatencyTolerance': 0.5,
            'maxPlaybackRate': 1.2,
            'minPlaybackRate': 1,
            'panicMode': false,
            'panicThreshold': 60,
            'dynamicTargetLatency': {
              'enabled': true
            }
          }
        },
        'abr': {
          'enabled': true,
          'useNetworkInformation': true,
          'defaultBandwidthEstimate': 4000000,
          'restrictions': {
            'minHeight': 360
          },
          'switchInterval': 15,              // Увеличено до 12 для стабильности
          'bandwidthUpgradeTarget': 0.8,     // Снижено до 0.8 для консервативности
          'bandwidthDowngradeTarget': 0.95,
          'restrictToElementSize': false,
          'restrictToScreenSize': false,
          'ignoreDevicePixelRatio': false,
          'clearBufferSwitch': false,
          'safeMarginSwitch': 0,
          'cacheLoadThreshold': 20,
          'preferNetworkInformationBandwidth': true
        }
      })
      
      try {
        await this.player.load(src)
      } catch (error) {
        this.onError(error)
      }
    },
    updateMute () {
      this.mute = !this.mute
      this.$refs.videoRef.muted = this.mute
    },
    updateVolume () {
      this.$refs.videoRef.volume = this.volume
    },
    onPause () {
      this.playing = false
    },
    onPlaying () {
      this.playing = true
    },
    seekToLive () {
      if (this.player && this.$refs.videoRef) {
        this.player.goToLive()
        this.delay = false
      }
    },
    startSyncCheck () {
      if (this.syncCheckInterval) {
        clearInterval(this.syncCheckInterval)
      }
      this.syncCheckInterval = setInterval(() => {
        if (this.player && this.$refs.videoRef) {
          this.playing = !this.$refs.videoRef.paused
          const seekRange = this.player.seekRange()
          const liveEdge = seekRange.end
          const currentTime = this.$refs.videoRef.currentTime

          if (liveEdge - currentTime > 2.5) {
            this.delay = true
          }
        }
      }, 3000)
    },
    onErrorEvent (event) {
      this.onError(event.detail)
    },
    onError (error) {
      console.error('Code Error', error.code, 'details:', error)

      if (this.player && this.$refs.videoRef) {
        setTimeout(() => {
          console.log('Recover retry after error')
          this.seekToLive()
        }, 1000)
      }
    }
  },
  mounted () {
    this.loadLLHLSPreview()
  },
  beforeDestroy () {
    if (this.syncCheckInterval) {
      clearInterval(this.syncCheckInterval)
    }

    if (this.$refs.videoRef) {
      this.$refs.videoRef.removeEventListener('pause', this.onPause)
      this.$refs.videoRef.removeEventListener('playing', this.onPlaying)
    }

    if (this.abr_stabilization_timeout) {
      clearTimeout(this.abr_stabilization_timeout)
    }

    if (this.player) {
      this.player.destroy()
      this.player = null
    }
  }
}
</script>

<style>
:root {
  --op-accent-color: #4F83E3 !important;
}
</style>