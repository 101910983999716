<script>
import {mapState} from 'vuex'
import _ from 'lodash'

export default {
  name: 'GlobalTrackingSelect',
  props: {
    variable: Object
  },
  data () {
    return {
      loading: false,
      responders: [
        'ontraport',
        'mail-chimp',
        'a-weber',
        'get-response',
        'convert-kit',
        'activecampaign',
        'send-lane'
      ],
      tags: []
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  mounted () {
    this.onResponderSelect()
  },
  methods: {
    onResponderSelect () {
      switch(this.variable.responder) {
      case 'ontraport':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/ontraport/tags?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'mail-chimp':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/mail-chimp/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'a-weber':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/a-weber/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'get-response':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/get-response/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'convert-kit':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/convert-kit/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'activecampaign':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/active-campaign/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      case 'send-lane':
        this.loading = true
        axios.get(`api/projects/${this.project.id}/auto-responders/send-lane/lists?per_page=100`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => {
            this.loading = false
          })
        break
      default:
      }
    },
    getResponderName (label) {
      return _.startCase(label).replaceAll(' ', '')
    }
  }
}
</script>

<template>
  <div class="flex gap-10">
    <div
      v-if="loading"
      class="fixed top-[120px] gap-[10px] right-[20px] flex items-center justify-center h-[50px] px-[20px] z-[1000000] bg-white rounded shadow"
    >
      <b-spinner
        variant="primary"
        small
      />
      Loading...
    </div>

    <b-form-group
      label="Autoresponder"
      class="w-auto min-w-[250px]"
    >
      <v-select
        v-model="variable.responder"
        :options="responders"
        :searchable="false"
        append-to-body
        class="custom-select !w-[250px]"
        @option:selected="onResponderSelect"
      >
        <template #selected-option="{label}">
          <div
            v-text="getResponderName(label)"
          />
        </template>

        <template #option="{label}">
          <div
            v-text="getResponderName(label)"
          />
        </template>
      </v-select>
    </b-form-group>

    <b-form-group
      v-if="variable.responder === 'ontraport'"
      label="Tags"
      class="w-[250px]"
    >
      <v-select
        v-model="variable.tags"
        :placeholder="!tags.length ? 'Please selected autoresponder' : 'Tags'"
        :disabled="!tags.length"
        :options="tags"
        :searchable="false"
        append-to-body
        multiple
        class="custom-select"
        label="tag_name"
        :reduce="val => val.tag_id"
      />
    </b-form-group>
    <b-form-group
      v-else
      label="List"
      class="w-[250px]"
    >
      <v-select
        v-model="variable.tags"
        :placeholder="!tags.length ? 'Please selected autoresponder' : 'List'"
        :disabled="!tags.length"
        :options="tags"
        :searchable="false"
        append-to-body
        class="custom-select"
        label="name"
        :reduce="val => val.id"
      />
    </b-form-group>
  </div>
</template>

<style>
.vs__selected {
  border: none !important;
  background-color: transparent !important;
}
</style>
