var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-[9px]"},[_c('sid-range-slider',{attrs:{"rule-name":"max-width","sid":`.--${_vm.section.sid}--wrapper`,"default-dimension":"%","dimensions":['%', 'vw', 'px'],"label":"Width","var":"--width","measurement":"width"}}),_c('b-form-group',[_c('sid-range-slider',{attrs:{"rule-name":"height","sid":`.--${_vm.section.sid}--wrapper`,"default-dimension":"%","dimensions":['%', 'vh', 'px'],"label":"Height","var":"--height","measurement":"height"}})],1),_c('b-form-group',{attrs:{"label":"Code"}},[_c('b-textarea',{attrs:{"rows":"7"},model:{value:(_vm.config.code),callback:function ($$v) {_vm.$set(_vm.config, "code", $$v)},expression:"config.code"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.--${_vm.section.sid}--wrapper`,"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.--${_vm.section.sid}--wrapper`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }