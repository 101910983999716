<template>
  <highliter
    v-if="visability('images')"
    v-model="section.name"
    :section="section"
    :dublicate="section"
    :uid="section.uid"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('images')"
  >
    <Template :section="section">
      <highliter
        v-for="(image, index) in images"
        :key="index"
        :disable-toolbar="true"
        :style="{opacity: image.opacity}"
        class="img-transform"
        hide-bar
        @flow="handleFlow"
        @click.stop.native="sectionSettings({item: 'images', index})"
      >
        <preview-el-image-v2
          v-if="section.options.version === 2"
          :section="image"
        />
        <image-fit
          v-else
          :customs="image"
          :position="image.position"
          :size="cols(image.size, 'contain')"
          :src="image.src || image.placeholder"
          :style="shadow"
        />
      </highliter>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import ImageFit from '@/components/utils/ImageFit'

export default {
  name: 'ElThreeImages',

  components: {ImageFit, Template, Highliter},

  mixins: [SectionMixin],

  computed: {
    images () {
      return this.section.options.version === 2 ? this.section.options.content.images.subjects : this.section.options.content.images.items
    },
    shadow () {
      const shadow = this.section.options.content.images.shadowOptions
      const color = this.section.options.content.images.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    }
  }
}
</script>
