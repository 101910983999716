<template>
  <div class="border-t-[#D7D9DC] border-t flex">
    <div
      v-if="foldersLoading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>

    <template v-else>
      <div
        v-if="activeFile"
        class="min-w-[218px] w-[218px] border-r border-[#D7D9DC] px-[33px] py-[26px]"
      >
        <div class="sticky top-[26px]">
          <div class="flex items-center justify-center w-[150px] h-[150px] rounded-[7px] overflow-hidden">
            <div v-if="activeFile.url.indexOf('json') > -1">
              <lottie-render :url="activeFile.url" />
            </div>

            <img
              v-else-if="isImg"
              :src="activeFile.url"
              class="w-full h-full object-cover"
            >

            <img
              v-else
              class="w-1/2 h-1/2"
              src="@/assets/icons/pdf-icon.svg"
            >
          </div>
          <div class="mt-[27px] flex flex-column gap-y-[12px]">
            <span
              v-if="activeFile.original_name"
              class="text-[14px] leading-[17px] text-black break-words"
            >{{ activeFile.original_name }}</span>

            <span
              v-if="activeFile.size"
              class="text-[14px] leading-[14px] text-[#6E747A]"
            >Size: {{ formatBytes(activeFile.size) }}</span>

            <span
              v-if="activeFile.created_at"
              class="text-[14px] leading-[14px] text-[#6E747A]"
            >
              Uploaded on: {{ activeFile.created_at | moment("DD/MM/YY") }}
            </span>

            <b-btn
              v-if="uploaded"
              class="!text-[15px] font-bold !py-0 px-3 mt-[9px] !inline-flex items-center !h-[38px]"
              variant="primary"
              type="submit"
              @click="uploaded(activeFile)"
            >
              Insert Asset

              <svg
                class="ml-2"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
                  fill="white"
                />

                <path
                  d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
                  fill="white"
                />
              </svg>
            </b-btn>
          </div>
        </div>
      </div>

      <div class="w-100 pb-[92px]">
        <div
          :class="[activeFile ? 'pl-[40px]':'pl-[47px]']"
          class="pr-[37px] py-[14px] bg-[#F9F9F9] mb-[42px]"
        >
          <div class="flex items-center gap-x-[6px] text-[15px] leading-[18px]">
            <template
              v-for="(breadcrumb, index) in breadcrumbs"
            >
              <span
                :key="index"
                :class="[index + 1 != breadcrumbs.length ? 'cursor-pointer text-[#6E747A]' : 'text-[#A5AEBE]']"
                @click="openFolder(breadcrumb.folder)"
              >
                {{ breadcrumb.title }}
              </span>

              <icon
                v-if="index +1 != breadcrumbs.length"
                :key="index + '_>'"
                icon="chevron-left.svg"
              />
            </template>
          </div>
        </div>

        <div
          class="pr-[37px]"
          :class="[activeFile ? 'pl-[40px]':'pl-[47px]']"
        >
          <div class="flex justify-between items-center mb-[38px]">
            <div
              v-if="activeFolder"
              class="inline-flex items-center"
            >
              <img src="@/assets/icons/folder.svg">
              <span class="ml-[23px] text-[27px] leading-[33px] font-bold text-[#44474A]">{{ activeFolder.name }}</span>
            </div>

            <a-l-sort
              v-if="activeFolder ? !activeFolder.is_my_saved_images : true"
              v-model="filters.sorting"
              class="ml-auto"
            />
          </div>

          <div
            v-if="openFolderLoading"
            class="w-full h-[250px] flex items-center justify-center "
          >
            <b-spinner
              class="!w-12 !h-12"
              variant="primary"
            />
          </div>

          <template v-else>
            <div class="assets-lib-folders">
              <draggable
                group="folders"
                :list="folders"
                :delay="200"
                filter=".static"
                @end="endFolderDrag"
              >
                <transition-group
                  tag="b-row"
                  name="shufle"
                >
                  <b-col
                    v-for="(folder, fIndex) in folders"
                    :key="`${folder.id}_${fIndex}`"
                    sm="12"
                    md="6"
                  >
                    <div
                      :class="{'static':folder.is_my_saved_images}"
                      class="folder cursor-pinter hover:bg-blue-50 px-0 mb-[34px]"
                      :data-draggable="folder.is_my_saved_images ? '-' : 'folder'"
                      :data-draggable-id="folder.id"
                      :data-draggable-name="folder.name"
                    >
                      <a-l-folder
                        :folder="folder"
                        @click="openFolder"
                        @move="openFolderModal(folder,'move')"
                        @rename="openFolderModal(folder,'rename')"
                        @edit="openFolderModal(folder,'edit')"
                        @delete="openFolderModal(folder,'delete')"
                      />
                    </div>
                  </b-col>
                </transition-group>
              </draggable>
            </div>

            <div
              v-if="filesLoading"
              class="w-full h-[250px] flex items-center justify-center "
            >
              <b-spinner
                class="!w-12 !h-12"
                variant="primary"
              />
            </div>

            <div
              v-else-if="activeFolder && activeFolder.is_my_saved_images"
              class="flex flex-wrap gap-x-[44px] gap-y-[40px]"
              :class="{'mt-[42px]':folders.length}"
            >
              <template v-for="(file,index) in favorites">
                <a-l-file
                  :key="index"
                  :file="file"
                  url-type
                  @click="$emit('upload-from-saved-ones',file)"
                  @delete="$emit('delete-from-saved-ones',index)"
                />
              </template>

              <div
                v-if="!favorites.length"
                class="w-full mt-[42px] text-center"
              >
                No Files Found
              </div>
            </div>

            <div
              v-else-if="filesCount"
              class="flex flex-wrap gap-x-[44px] gap-y-[40px] al-files"
              :class="{'mt-[42px]':folders.length}"
            >
              <template v-for="(file,index) in getFilteredFiles">
                <a-l-file
                  :key="`${file.id}_${index}`"
                  data-draggable="file"
                  :data-draggable-id="file.id"
                  :data-draggable-name="file.original_name"
                  :file="file"
                  :active="activeFile && activeFile.id == file.id"
                  @click="openFile"
                  @move="openFileModal(file, 'move')"
                  @rename="openFileModal(file, 'rename')"
                  @edit="openFileModal(file, 'edit')"
                  @delete="openFileModal(file, 'delete')"
                />
              </template>
            </div>

            <div
              v-else-if="activeFolder || filters.q"
              class="mt-[42px] text-center"
            >
              No Files Found
            </div>

            <div
              v-if="moreFilesLoading"
              class="w-full h-[50px] mt-[50px] flex items-center justify-center "
            >
              <b-spinner
                class="!w-12 !h-12"
                variant="primary"
              />
            </div>
          </template>
        </div>
      </div>
    </template>

    <a-l-upload-asset
      :modal.sync="fileModal"
      :item="fileForm"
      :action="fileAction"
      @submited="assetSubmited"
    />

    <a-l-folder-form
      :modal.sync="folderModal"
      :item="folderForm"
      :action="folderAction"
      @submited="folderModal = false"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ALFolder from './els/ALFolder.vue'
import ALFile from './els/ALFile.vue'
import ALSort from './els/ALSort.vue'
import ALUploadAsset from './els/ALUploadAsset.vue'
import ALFolderForm from './els/ALFolderForm.vue'
import draggable from 'vuedraggable'
import {mapState,mapActions, mapGetters, mapMutations} from 'vuex'
import FileMixin from '@/mixins/FileMixin'
import LottieRender from '@/components/editor/components/LottieRender'

export default {
  components: {
    LottieRender,
    ALFolder,
    ALFile,
    ALSort,
    ALUploadAsset,
    ALFolderForm,
    draggable
  },

  mixins: [FileMixin],

  props: {
    type: {
      type: String,
      default: null
    },

    modalId: {
      type: String,
      default: ''
    },

    uploaded: Function
  },

  data () {
    return {
      openFolderLoading:false,
      foldersLoading:false,
      filesLoading:false,
      moreFilesLoading:false,
      fileModal:false,
      fileForm:{},
      fileAction:'',
      folderModal:false,
      folderForm:{},
      folderAction:'',
      hasListeners: 0
    }
  },

  computed: {
    getFilteredFiles () {
      if (this.type) {
        return (this.files.data || []).filter(f => new RegExp(this.type, 'i').test(f.original_name))
      }

      return this.files.data || []
    },

    ...mapState('assetsLibrary', {
      filters: state => state.filters,
      files: state => state.files,
      activeFolder: state => state.activeFolder,
      activeFile: state => state.activeFile
    }),

    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),

    ...mapGetters({
      folders:'assetsLibrary/folders',
      breadcrumbs:'assetsLibrary/breadcrumbs'
    }),

    filesCount () {
      return _.get(this.files, 'data', []).length
    },

    isImg (){
      if(this.activeFolder){
        return this.activeFolder.type === 'image'
      }

      return false
    },

    favorites () {
      return this.globalStyles.libraryImages || []
    }
  },

  watch: {
    'filters.sorting' () {
      if(this.activeFolder) {
        this.loadFiles()
      }
    },

    'filters.q' () {
      this.loadFiles()
    }
  },

  async mounted () {
    // this.foldersLoading = true
    // await this.getFolders()
    // this.foldersLoading = false
    const modal = document.getElementById(this.modalId)
    modal.addEventListener('scroll', this.handleDocumentScroll)
  },

  beforeDestroy () {
    const modal = document.getElementById(this.modalId)
    modal.removeEventListener('scroll', this.handleDocumentScroll)
  },

  methods: {
    ...mapActions({
      getFolders:'assetsLibrary/getFolders',
      getFiles:'assetsLibrary/getFiles',
      getTotals:'assetsLibrary/getTotals',
      reorderFolder:'assetsLibrary/reorderFolder'
    }),

    ...mapMutations({
      setActiveFolder:'assetsLibrary/SET_ACTIVE_FOLDER',
      setActiveFile:'assetsLibrary/SET_ACTIVE_FILE'
    }),

    isInViewport (el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },

    assetSubmited () {
      this.fileModal = false
    },

    handleDocumentScroll () {
      const files = document.querySelector('.al-files')

      if(files){
        const isInViewport = this.isInViewport(files.lastChild)

        if(isInViewport && !this.get(this.activeFolder,'is_my_saved_images') && this.files.current_page != this.files.last_page && !this.filesLoading && !this.moreFilesLoading){
          this.loadMoreFiles()
        }
      }
    },

    async openFolder (folder){
      const id = folder?.id||null
      if((!this.activeFolder && !id)  || (this.activeFolder && this.activeFolder.id == id)) return
      this.openFolderLoading = true

      setTimeout(() => {
        this.setActiveFile(null)
        this.setActiveFolder(folder)
        this.loadFiles()
        this.openFolderLoading = false
      }, 500)
    },

    async loadFiles (){
      this.setActiveFile(null)
      this.filesLoading = true
      await this.getFiles()
      this.filesLoading = false
    },

    openFile (file){
      const id = file?.src||null

      if(this.activeFile && this.activeFile.id === id) {
        this.setActiveFile(null)
      } else {
        this.setActiveFile(file)
      }
    },

    async loadMoreFiles (){
      if (this.moreFilesLoading) return false
      if(this.moreFilesLoading || this.files.current_page == this.files.last_page) return
      const page = this.files.current_page + 1
      this.moreFilesLoading = true
      await this.getFiles({page})
      this.moreFilesLoading = false
    },

    openFileModal (file,action){
      this.fileForm = file
      this.fileAction = action
      this.fileModal = true
    },

    openFolderModal (folder,action){
      this.folderForm = folder
      this.folderAction = action
      this.folderModal = true
    },

    endFolderDrag () {
      const folders = this.folders.map(f=>f.id)
      this.reorderFolder({folders})
    }
  }
}
</script>

<style lang="scss">
.dropdown.show .btn-unstyled:not(.no-focus){
  background-color: unset!important;;
  border-color: unset!important;;
  color: unset!important;;
}
.sorting-menu{
  .dropdown-item{
    padding-top: 1px!important;
    padding-bottom: 1px!important;
  }
}

.assets-lib-folders {
  .folder {
    display: flex;
    align-items: center;
    position: relative;
    height: 90px;
    background-color: #fff;
    border: 1px solid #e2e5ec;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
    padding-left: 26px;
    padding-right: 26px;

    & {
      .btn-folder-dropdown {
        background-color: unset !important;
        border: unset !important;
      }

      .folder-details {
        margin-left: 27px;
      }

      .folder-name {
        font-weight: 600;
        font-size: 17px;
        color: #44474a;
        margin-bottom: 3px;
        max-width: 200px;
      }

      .folder-sites-count {
        font-size: 15px;
        color: #9fa9ba;
        display: flex;
        align-items: center;
      }

      .separate {
        position: relative;

        svg {
          margin-right: 30px;
        }

        &:after {
          content: "";
          position: absolute;
          right: 2px;
          top: 50%;
          transform: translateY(-50%);
          height: 90px;
          width: 1px;
          background-color: #dddfe2;
        }
      }
    }
  }
  .sortable-chosen {
    .folder {
      border: 3px solid #007bff;
    }
  }
}
.dragging-dropzone-highlighted {
  border: 3px solid #007bff!important;
  background-color: #fff!important;
  &:hover{
    background-color: #fff!important;
  }
}
</style>
