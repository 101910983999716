import axios from 'axios'
import store from './../store'
import Cookie from 'js-cookie'

axios.defaults.withCredentials = false
axios.defaults.baseURL = `${process.env.VUE_APP_API_HOST}`

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers['Authorization'] = config.headers['Custom-Token'] ||
    `Bearer ${process.env.NODE_ENV === 'development' ? localStorage.getItem('sanctum_token') : Cookie.get('cross_estage_token')}`
  config.headers['Cache-Control'] = 'no-cache'
  config.headers['Pragma'] = 'no-cache'
  config.headers['Expires'] = '0'
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error && error.response) {
    const status = error.response.status

    if (status == 422 || status == 500 || status == 404 || status == 400) {
      let title = 'Error occurred.'
      let text = 'Error occurred.'
      if(status == 422){
        title = error.response.data.message
        const errors = error.response.data.errors
        let errText = ''
        for (const error in errors) {
          errText += `<li>${errors[error]}</li>`
        }
        text = `<ul class="list-disc text-start mb-0 ml-3">${errText}</ul>`
      }
      if(status == 500){
        title = 'Server error'
        text = error.response.data.message
      }
      if(status == 404){
        title = 'Not Found'
        text = error.response.data.message
      }
      if(status == 400){
        title = 'Bad Request'
        text = error.response.data.message
      }
      store.commit('notifications/PUSH_ERROR', {title, text })
    }
  }
  return Promise.reject(error)
})


window.axios = axios
