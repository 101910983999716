import Vue from 'vue'

const state = () => ({
  page: 'home',
  mailAPIServices: [],
  contactForm: {
    id: null,
    name: 'ContactForm'
  }
})

const getters = {
  hasMailAPIService (state) {
    const service = state.mailAPIServices.filter(service => !!service.status)

    if (service.length) {
      return service[0]
    } else {
      return false
    }
  },

  hasLocalAPIService: (state, _, rootState) => (service) => {
    const settings = rootState.editor.globalStyles

    if (!settings.hasOwnProperty('api')) return false

    if (settings.api.hasOwnProperty(service) || settings.api[service] !== null) {
      return true
    }

    return false
  }
}

const actions = {
  getlAllMailServices ({state, rootState}) {
    if (!rootState.projects.project) return false
    axios.get(`api/projects/${rootState.projects.project.id}/auto-responders`)
      .then(({data}) => {
        state.mailAPIServices = data.data.filter(ser => [
          'AWeber',
          'ActiveCampaign',
          'Brevo',
          'ConvertKit',
          'GetResponse',
          'HubSpot',
          'Keap',
          'MailChimp',
          'MailerLite',
          'Moosend',
          'Ontraport',
          'Sender',
          'SendGrid',
          'Sendlane',
          'Zapier',
          'Zoho'
        ].includes(ser.name))
      })
  },
  async integrateMailService ({rootState}, payload) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/auto-responders`, {
      ...payload,
      project_id: rootState.projects.project.id
    })
  },
  async deleteMailService ({rootState}, payload) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/auto-responders/${payload.auto_responder_id}`)
  },
  async unsplashIntegrate (_, payload) {
    return await axios.post('api/user/settings', {
      'settings': [
        {
          'name': 'unsplash_api',
          'val': payload
        }
      ]
    })
  },
  integrate ({rootState, dispatch}, payload) {
    VEvent.fire('loader', true)

    const settings = rootState.editor.globalStyles

    if (!settings.hasOwnProperty('api')) {
      Vue.set(settings, 'api', {})
    }

    Vue.set(settings.api, payload.service, payload)

    dispatch('projects/save', {}, {root: true})
      .then(() => {
        VEvent.fire('loader', false)
      })
  },
  async addField ({rootState}, payload) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/form-fields`, payload)
  },
  async sync ({rootState}, ids) {
    return await axios.get(`api/site/${rootState.projects.project.subdomain}/fields?${ids.map(d => `id[]=${d}`).join('&')}`)
  },
  async massUpdate ({rootState, getters}, ids) {
    return await axios.put(`api/projects/${rootState.projects.project.id}/form-fields/mass-update`, {
      ids: ids
    })
      .then(() => {
        axios.put(`api/projects/${rootState.projects.project.id}/form-fields/sync`, {
          autoResponder: getters.hasMailAPIService.name,
          list_id: rootState.editor.globalStyles.autoresponder_list_id || null
        })
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
