<template>
  <div>
    <settings-page-card title="Global Variables">
      <div class="px-4">
        <div
          v-for="(variable, index) in variables"
          :key="index"
          class="flex gap-[30px] w-full items-center"
        >
          <b-form-group
            label="Name"
            class="w-100 min-w-[250px]"
          >
            <b-input v-model="variable.name" />
          </b-form-group>
          <b-form-group
            label="Key"
            class="w-100 min-w-[250px]"
          >
            <b-input
              v-model="variable.key"
              @change="variable.key = cleanKeyString(variable.key)"
            />
          </b-form-group>
          <b-form-group
            label="Value"
            class="w-100 min-w-[250px]"
          >
            <b-input
              v-model="variable.value"
              @change="variable.value = ensureLink(variable.value)"
            />
          </b-form-group>
          <div class="mt-2">
            <delete-confirm
              title="Are you sure?"
              description="This Global Variable will be deleted."
              @confirm="() => removeOption(index)"
            >
              <template v-slot:activator="{on}">
                <svg
                  class="cursor-pointer hover:opacity-50"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-on="on"
                >
                  <path
                    d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
                    fill="#6E747A"
                  />
                </svg>
              </template>
            </delete-confirm>
          </div>
        </div>

        <div class="flex gap-[10px]">
          <div>
            <b-btn
              variant="primary"
              @click="handleSave"
            >
              Save
            </b-btn>

            <b-modal
              v-model="confirmSaveDialog"
              body-class="text-center py-5"
              size="lg"
              hide-footer
              hide-header
              centered
            >
              <h4
                class="h2 font-weight-bold mb-4"
                v-html="'Warning!'"
              />

              <p
                class="my-4"
                v-html="'You are about to change global variables; <br>' +
                  'all existing connections using it will be disconnected.'"
              />


              <div class="d-flex align-items-center justify-content-center">
                <b-btn
                  variant="danger"
                  class="btn-wide mr-2"
                  @click="save"
                >
                  Yes
                </b-btn>
                <b-btn
                  class="btn-wide"
                  @click="confirmSaveDialog = false"
                >
                  No
                </b-btn>
              </div>
            </b-modal>
          </div>
          <b-btn
            @click="addOption"
          >
            Add New
          </b-btn>
        </div>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard.vue'
import {toJSON} from '@/utils/helpers'
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm.vue'
// import {toJSON, toStringify} from '../../../utils/helpers'

export default {
  name: 'GlobalVariables',
  components: {DeleteConfirm, SettingsPageCard},
  data () {
    return {
      loading: false,
      initVariablesKeys: [],
      confirmSaveDialog: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    variables () {
      if (!this.project.variables || this.project.variables === '{}') {
        this.$set(this.project, 'variables', [
          {
            name: '',
            key: '',
            value: ''
          }
        ])
      } else {
        this.$set(this.project, 'variables', toJSON(this.project.variables))
      }

      return toJSON(this.project.variables)
    },
    variablesKeys () {
      return this.variables.map((variable, index) => {
        return {
          id: index,
          key: variable.key
        }
      })
    }
  },

  methods: {
    cleanKeyString (key) {
      key = key.trim()

      if (key.endsWith('.')) {
        key = key.slice(0, -1)
      }

      return key
    },
    validateDomain (value) {
      const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,})$/
      return domainRegex.test(value)
    },
    ensureLink (value) {
      if (this.validateDomain(value) && !/^https?:\/\//i.test(value)) {
        return `https://${value.trim()}`
      }
      return value.trim()
    },
    hasDuplicateKeys () {
      const seenKeys = new Set()
      for (const item of this.variablesKeys) {
        if (seenKeys.has(item.key)) {
          return true
        }
        seenKeys.add(item.key)
      }
      return false
    },
    checkChanges () {
      const init = new Map(this.initVariablesKeys.map(item => [item.id, item.key]))
      const current = new Map(this.variablesKeys.map(item => [item.id, item.key]))
      for (let [id, key] of init) {
        if (current.has(id) && current.get(id) !== key) {
          return true
        } else {
          return false
        }
      }
    },
    handleSave () {
      const hasDuplicateKeys = this.hasDuplicateKeys()
      const isChanged = this.checkChanges()
      if (hasDuplicateKeys) {
        return this.$swal({
          icon: 'error',
          title: 'Error!',
          text: 'Duplicate keys are not allowed.',
          showConfirmButton: false,
          timer: 5000
        })
      }
      if (isChanged) {
        this.confirmSaveDialog = true
      } else {
        this.save()
      }
    },
    save () {
      this.confirmSaveDialog = false
      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save', true)
        .then(() => {
          this.initVariables()
          this.$swal({
            icon: 'success',
            title: 'Saved!',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    removeOption (index) {
      this.project.variables.splice(index, 1)
      this.save()
    },
    addOption () {
      this.project.variables.push({
        name: '',
        key: '',
        value: ''
      })
    },
    initVariables () {
      this.initVariablesKeys = this.variablesKeys
    }
  },
  mounted () {
    this.initVariables()
  }
}
</script>
