<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    :class="{'element-hidden': !visability('star')}"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('star')"
  >
    <Template :section="section">
      <div
        class="flex items-center flex-wrap"
        :class="`justify-content-${star.align[resolutioner]}`"
        :style="wrapperStyle"
      >
        <i
          v-for="index in parseInt(star.max)"
          :key="index"
          :class="[index - 1 === parseInt(star.stars) && isFloat(star.stars) ? 'fa fa-star-half-stroke' : index > star.stars ? 'fa fa-star-o' : 'fa fa-star']"
          :style="styles"
        />
      </div>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin],

  computed: {
    star () {
      return this.section.options.content.star
    },
    styles () {
      return {
        color: this.star.color,
        fontSize: `${this.star.size[this.resolutioner]}px`
      }
    },
    wrapperStyle () {
      return {
        gap: `${this.star.gap[this.resolutioner]}px`
      }
    }
  },

  methods: {
    isFloat (value) {
      if (
        typeof value === 'number' &&
          !Number.isNaN(value) &&
          !Number.isInteger(value)
      ) {
        return true
      }

      return false
    }
  }
}
</script>

<style>
.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "\f5c0";
}
</style>
