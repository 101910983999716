<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('playlist')"
  >
    <div
      v-if="!reload"
      :data-sid="section.sid"
    >
      <div
        v-show="false"
        :class="[`--${section.sid}--playlist-item`]"
        class="video-playlist-item flex items-center transitioner overflow-hidden"
      >
        <div
          class="video-playlist-preview rounded-[inherit] relative overflow-hidden flex align-items-center justify-content-center"
          :class="`--${section.sid}--preview`"
        >
          <div class="block-bg" />
          <i class="fa fa-play z-index-1" />
        </div>
        <div class="w-100">
          <div
            class="playlist-item-title"
            :class="`--${section.sid}--title`"
          />
          <div
            class="playlist-item-description"
            :class="`--${section.sid}--description`"
          />
        </div>
      </div>

      <div
        class="video-playlist flex"
        :class="`--${section.sid}--playlist`"
      >
        <div
          v-for="(item, index) in section.options.content.playlist.items"
          :key="index"
          :class="[`--${section.sid}--playlist-item`, {'active': index === 0}]"
          class="video-playlist-item flex items-center transitioner overflow-hidden"
          @click="() => onClick(item, index)"
        >
          <div
            class="video-playlist-preview rounded-[inherit] relative overflow-hidden flex align-items-center justify-content-center"
            :class="`--${section.sid}--preview`"
            :style="{backgroundImage: `url(${item.src})`}"
          >
            <div class="block-bg" />
            <i class="fa fa-play z-index-1" />
          </div>
          <div class="w-100">
            <div
              class="playlist-item-title"
              :class="`--${section.sid}--title`"
              v-text="item.title"
            />
            <div
              class="playlist-item-description"
              :class="`--${section.sid}--description`"
              v-html="item.description"
            />
          </div>
        </div>
      </div>

      <div
        v-show="false"
        :class="`video-playlist-item --${section.sid}--playlist-item active`"
      />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'

export default {
  name: 'ElCollapsableMenuLinks',
  components: {Highliter},
  mixins: [SectionMixin],
  data () {
    return {
      activeVideo: 0,
      items: 10,
      reload: false
    }
  },
  computed: {
    videos () {
      return this.section.options.content.playlist.items
    },
    previews () {
      return this.videos.map(video => {
        return video.src
      })
    },
    active: {
      get () {
        if (this.$route.query['active-tab']) {
          const activeIndex = this.section.options.content.playlist.items.findIndex(item => item.custom_url === this.$route.query['active-tab'])
          if (activeIndex !== -1) return activeIndex
        }

        return this.activeVideo
      },
      set () {
        this.activeVideo
      }
    }
  },
  methods: {
    playVideo (item, data = {}) {
      const $elVideo = document.querySelector(`[data-uid='${this.section.pickedUid}']`)
      if (!$elVideo) return
      $elVideo.dispatchEvent(new CustomEvent('playlistPlay', {
        detail: {
          url: item.url,
          placeholder: item.src,
          disablePreview: !item.src,
          start: item.start,
          ...data
        }
      }))
    },
    onClick (item, index) {
      this.active = index
      this.playVideo(item, {
        play: true
      })
      let query = {}

      if (item.custom_url) {
        query = {
          'active-tab': item.custom_url
        }
      }

      if (this.$route.query['active-tab'] !== query['active-tab']) {
        this.$router.replace({ query })
      }
    }
  },
  watch: {
    previews (val) {
      if (val) {
        this.reload = true
        setTimeout(() => {
          this.reload = false
        }, 100)
      }
    }
  },
  mounted () {
    if (!this.section.options.content.playlist.items.length) return
    setTimeout(() => this.playVideo(this.section.options.content.playlist.items[this.active]), 200)
  }
}
</script>

<style lang="scss">
.video-playlist {
  --width: 100%;
  padding: 0 20px;
  gap: 10px;
  flex-direction: column;
}

.video-playlist-preview {
  --width: 200px;
  --height: 100px;
  width: 200px;
  height: 100px;
  background-size: cover;

  .block-bg {
    background-color: rgba(131, 131, 131, 0.10);
  }
}

.video-playlist-item {
  --width: 100%;
  --height: 100%;
  width: 100%;
  border: 1px solid #DEE2E6;
  border-radius: 7px;
  gap: 30px;

  i {
    transition: .4s;
  }

  &:hover {
    i {
      transform: scale(1.5);
    }
  }
}

.playlist-item-title {
  font-size: 20px;
}

.playlist-item-description {

}
</style>
