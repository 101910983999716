import { render, staticRenderFns } from "./VideoPlayV2.vue?vue&type=template&id=71683fd0"
import script from "./VideoPlayV2.vue?vue&type=script&lang=js"
export * from "./VideoPlayV2.vue?vue&type=script&lang=js"
import style0 from "./VideoPlayV2.vue?vue&type=style&index=0&id=71683fd0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports