var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Icon Style","active":""}},[(_vm.linked)?_c('b-form-group',[_c('button',{staticClass:"flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]",on:{"click":() => _vm.unlinkSid()}},[_vm._v(" Unlink Styles "),_c('i',{staticClass:"fa fa-link text-primary"})])]):_vm._e(),_c('b-form-group',[_c('icon-select',{attrs:{"section":_vm.section},model:{value:(_vm.config.icon),callback:function ($$v) {_vm.$set(_vm.config, "icon", $$v)},expression:"config.icon"}})],1),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Width Setting")]),_c('sid-select',{staticClass:"w-[138px]",attrs:{"sid":`.--${_vm.section.sid}--wrapper`,"rule-name":"display","searchable":false,"options":[{label: 'Default', value: ''}, {label: 'Inline', value: 'inline-flex'}]}})],1)]),_c('sid-range-slider',{attrs:{"label":"Icon Size","sid":`.--${_vm.section.sid}--1`,"rule-name":"font-size","var":"--size"}}),_c('sid-align',{attrs:{"label":"Icon Alignment","sid":`.--${_vm.section.sid}--1`,"rule-name":"text-align"}}),_c('sid-flip',{attrs:{"sid":`.--${_vm.section.sid}--1`,"rule-name":"scale"}}),_c('sid-color',{attrs:{"label":"Color","sid":`.--${_vm.section.sid}--1`,"rule-name":"color","allow-reset":"","default-color":"rgb(117, 147, 255)"}}),_c('hr'),_c('sid-lottie-icon',{attrs:{"config":_vm.config,"sid":_vm.section.sid}}),_c('b-form-group',[_c('ButtonAction',{staticClass:"mb-0",attrs:{"config":_vm.config}})],1)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.--${_vm.section.sid}--1`,"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.--${_vm.section.sid}--1`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }