import { render, staticRenderFns } from "./UploadAsset.vue?vue&type=template&id=aaa4e32c"
import script from "./UploadAsset.vue?vue&type=script&lang=js"
export * from "./UploadAsset.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports