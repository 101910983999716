var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sid-range-slider',{attrs:{"label":"Font Size","sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"font-size"}}),_c('sid-font',{attrs:{"prefix":"Input","sid":`.--${_vm.config.sid}--form .phone-code-select`}}),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Font Color")]),_c('sid-color',{attrs:{"sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"color"}})],1)]),_c('hr'),_c('sid-background',{attrs:{"sid":`.--${_vm.config.sid}--form .phone-code-select`}}),_c('sid-range-slider',{attrs:{"label":"Input Height","sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"height"}}),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"border-style","searchable":false,"options":[{label: 'None', value: 'none'}, {label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":`.--${_vm.config.sid}--form .phone-code-select`,"label":"Border Width","rule-name":"border","angles":[
        {
          text: '',
          value: 'top-width'
        },
        {
          text: '',
          value: 'right-width'
        },
        {
          text: '',
          value: 'bottom-width'
        },
        {
          text: '',
          value: 'left-width'
        }
      ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{attrs:{"label":"Radius","sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"border","angles":[
      {
        text: 'Top Left',
        value: 'top-left-radius'
      },
      {
        text: 'Top Right',
        value: 'top-right-radius'
      },
      {
        text: 'Bottom Left',
        value: 'bottom-left-radius'
      },
      {
        text: 'Bottom Right',
        value: 'bottom-right-radius'
      }
    ]}}),_c('hr'),_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"padding","min":0,"angles":[
      {
        text: '',
        value: 'top'
      },
      {
        text: '',
        value: 'right'
      },
      {
        text: '',
        value: 'bottom'
      },
      {
        text: '',
        value: 'left'
      }
    ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.--${_vm.config.sid}--form .phone-code-select`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
      {
        text: '',
        value: 'top'
      },
      {
        text: '',
        value: 'right'
      },
      {
        text: '',
        value: 'bottom'
      },
      {
        text: '',
        value: 'left'
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }