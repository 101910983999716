<template>
  <div
    class="position-absolute w-100 h-100 overflow-hidden top-0 left-0 video-bg-container"
    :class="{'vimeo-wrapper': options.autoRatio}"
  >
    <div
      v-if="isVimeo"
      ref="video"
      class="hs-video-bg-video"
    />
    <div
      v-else-if="isEstage"
      ref="video"
      class="hs-video-bg-video"
    >
      <iframe
        ref="videoElement"
        frameborder="0"
        loading="lazy"
        class="embed-responsive-item"
        allow="accelerometer; autoplay; mute; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; screen-wake-lock"
        allowfullscreen
      />
    </div>
    <div
      v-else
      ref="video"
      class="hs-video-bg-video"
    >
      <div />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

const YouTubeIframeLoader = require('youtube-iframe')
import VimeoPlayer from '@vimeo/player'
import getEmbedUrl from 'better-video-embeds'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'BackgroundVideo',

  mixins: [ResolutionMixin],

  props: {
    options: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      ratioBlank: null,
      vimeoPlayer: null
    }
  },

  computed: {
    videoId () {
      return getEmbedUrl(this.options.url).split('/').slice(-1)[0]
    },
    videoRatio () {
      return this.options.ratio
    },
    isVimeo () {
      return this.options.url.split('/').includes('vimeo.com')
    },
    isEstage () {
      return this.options.url.split('/').includes('player.estage.com')
    },
    ratio: {
      get () {
        return _.get(this.options, ['ratio', this.resolutioner], _.get(this.options, 'ratio.lg', this.options.ratio))
      },
      set (val) {
        this.$set(this.options, 'ratio', {..._.set(this.options.ratio, [this.resolutioner], val)})
      }
    }
  },

  watch: {
    videoId () {
      this.createVideoAPI()
    },
    videoRatio () {
      this.ratioCalc()
    },
    resolutioner () {
      this.ratioCalc()
    }
  },

  mounted () {
    this.ratioBlank = this.$parent.$el
    this.createVideoAPI()

    window.addEventListener('resize', () => {
      this.ratioCalc()
    })
  },

  methods: {
    getTimeCode (timeString) {
      if (!timeString) return
      const timePices = timeString.split(':')
      return parseInt(timePices[0] * 3600) + parseInt(timePices[1] * 60) + parseInt(timePices[2])
    },
    ratioCalc () {
      let ratio = this.ratioBlank.clientWidth / this.ratioBlank.clientHeight
      if (!this.$refs.video) {return}
      this.$refs.video.style.width = (ratio * this.ratioBlank.clientWidth) * this.ratio + 100 + 'px'
      this.$refs.video.style.height = (ratio * this.ratioBlank.clientHeight) * this.ratio + 'px'
    },
    createVideoAPI () {
      if (!this.videoId) {
        return
      }

      if (this.vimeoPlayer) {
        this.vimeoPlayer.destroy()
      }

      if (this.isVimeo) {
        this.vimeoPlayer = new VimeoPlayer(this.$refs.video, {
          start: this.getTimeCode(this.options.start),
          id: this.videoId,
          title: false,
          portrait: false,
          byline: false,
          autoplay: true,
          autopause: false,
          muted: true,
          loop: true
        })

        this.vimeoPlayer.on('loaded', () => this.ratioCalc())
      } else if (this.isEstage) {
        this.$refs.videoElement.src = `${this.options.url}?autoplay=true`
      } else {
        YouTubeIframeLoader.load(YT => {
          new YT.Player(this.$refs.video.firstChild, {
            videoId: this.videoId,
            playerVars: {
              start: this.getTimeCode(this.options.start),
              autoplay: true,
              controls: 0,
              showinfo: 0,
              enablejsapi: 1,
              modestbranding: 1,
              iv_load_policy: 3,
              loop: true,
              origin: window.location.origin,
              mute: true,
              playlist: this.videoId
            },
            events: {
              onReady: (e) => {
                this.ratioCalc()
                e.target.playVideo()
              }
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
hs-video-bg-preview, .hs-video-bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.hs-video-bg-video iframe, .hs-video-bg-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video-bg-container {
  clip: rect(0, auto, auto, 0);
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
