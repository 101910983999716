var render = function render(){var _vm=this,_c=_vm._self._c;return _c('highliter',{staticClass:"d-flex justify-center w-100 post-middle",attrs:{"value":"Content Wrapper","hide-add":"","hide-move":"","hide-right":""},nativeOn:{"click":function($event){$event.stopPropagation();return (() => _vm.$store.dispatch('editor/sectionSettings', {
    section: {
      options: {
        content: {}
      }
    },
    openedItem: 'blog-wrapper-mod'
  })).apply(null, arguments)}}},[_c('div',{staticClass:"block-bg post-middle-bg"}),_vm._t("default",null,{"flow":_vm.flow})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }