var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._m(0),_c('b-link',{staticClass:"flex items-center !text-[#949697] hover:no-underline hover:bg-gray-50 text-[14px] h-[53px] px-[20px] border-b border-[#E2E5EC]",on:{"click":function($event){return _vm.$store.commit('editor/SET_PAGE', 'sections')}}},[_c('icon',{staticClass:"mr-[11px]",attrs:{"icon":"left-arrow.svg"}}),_vm._v(" Go Back ")],1),_c('div',{staticClass:"flex flex-column relative max-h-full h-[calc(100vh-232px)] simple-scrollbar"},[_c('div',{staticClass:"overflow-auto pb-[1px]"},[_c('b-card',{staticClass:"border-0",attrs:{"no-body":""}},[_c('div',{staticClass:"section-settings"},[_c('b-card',{staticClass:"border-0 noselect",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"border-top-0 pr-3 pt-0 pb-0"},[_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 0,"content-class":"py-0 pr-[15px]","title":"Text Styling"},on:{"opened":() => _vm.activeTab = 0}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Text Style ")])])]),_c('text-settings')],2),_c('SidbearSettingsGroup',{attrs:{"title":"Blocks","content-class":"border-bottom pb-0"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.el-block`,"rule-name":"padding","min":0,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.el-container`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Flex Wrapper","content-class":"border-bottom pb-0"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.flex-wrapper`,"rule-name":"padding","min":0,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.el-flex-wrapper`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Columns","content-class":"border-bottom pb-0"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.flex-col-inner`,"rule-name":"padding","min":0,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.col-hover-v2`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
                      {
                        text: '',
                        value: 'top'
                      },
                      {
                        text: '',
                        value: 'right'
                      },
                      {
                        text: '',
                        value: 'bottom'
                      },
                      {
                        text: '',
                        value: 'left'
                      }
                    ]}})],1),_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 2},on:{"opened":() => _vm.activeTab = 2}},[_c('template',{slot:"title"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Menu Links ")])]),_c('menu-links-global-style')],2),_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 3},on:{"opened":() => _vm.activeTab = 3}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Color Palette ")])])]),_c('color-pollite',{attrs:{"palette":_vm.getPalette(_vm.globalStyles.colors, 'palette')},model:{value:(_vm.globalStyles.colors.primary),callback:function ($$v) {_vm.$set(_vm.globalStyles.colors, "primary", $$v)},expression:"globalStyles.colors.primary"}})],2),_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 4,"content-class":"pb-0"},on:{"opened":() => _vm.activeTab = 4}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Buttons ")])])]),_c('button-global-style-v2')],2),_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 5},on:{"opened":() => _vm.activeTab = 5}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Background Settings ")])])]),_c('background-customs',{attrs:{"config":_vm.siteBackgroundColor}}),_c('background-image-customs',{attrs:{"config":_vm.siteBackgroundImage}})],2),_c('SidbearSettingsGroup',{attrs:{"active":_vm.activeTab === 6},on:{"opened":() => _vm.activeTab = 6}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center"},[_c('h4',{staticClass:"font-[700] text-[#44474A] text-[14px] mb-0"},[_vm._v(" Loading Indicator ")])])]),_c('loading-indicator')],2)],1)],1)],1)])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"},[_c('span',{staticClass:"text-[#44474A] font-[700] text-[15px] mb-0"},[_vm._v(" Global Style ")])])
}]

export { render, staticRenderFns }