<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @add-subject="$emit('add-subject')"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('card')"
  >
    <div
      :style="customHeight ? {height: height} : null"
      class="position-relative"
    >
      <Template :section="section">
        <elements
          v-model="section.options.content.card.elements"
          drag-height="100px"
          @flow="handleFlow"
        />
      </Template>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import Elements from '../../utils/Elements/Elements'
import _ from 'lodash'

export default {
  name: 'ElThreeImages',

  components: {Elements, Template, Highliter},

  mixins: [SectionMixin],

  computed: {
    badge () {
      return _.get(this.section.options.customize.style, 'addons', {
        enabled: false
      })
    },
    customHeight () {
      return _.get(this.section.options.content.card, 'custom_height', false)
    },
    height () {
      return `${_.get(this.section.options.content.card, `height_${this.resolution}`, 500)}${_.get(this.section.options.content.card, 'height_type', 'px')}`
    }
  },

  methods: {
    debug () {
      console.log(this.section.options)
    }
  }
}
</script>
