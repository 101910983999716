<template>
  <div
    v-if="showTitle"
    :data-sid="`product-title-${section.sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}-margin`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <p
        :class="`--${section.sid}-text`"
        class="product-title m-0"
        style="overflow-wrap: anywhere;"
      >
        {{ product.name }}
      </p>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElProductTitle',
  mixins: [SectionMixin],
  components: {Highliter},
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    products () {
      return this.form?.products || []
    },
    isSingleProduct () {
      if (!this.products.length) return false
      const singleProduct = this.products.length === 1
      const hasPrices = this.products[0]?.prices?.length > 0
      return singleProduct && !hasPrices
    },
    showTitle () {
      if (!this.products.length || !this.products[0]) return false
      return this.isSingleProduct && this.products[0].name
    },
    product () {
      if (!this.products.length || !this.products[0]) {
        return {
          name: ''
        }
      }
      return this.products[0]
    }
  }
}
</script>