<template>
  <div
    v-if="!hideFormButton"
    :data-uid="section.uid"
    :class="[`--${sid}--container`, {'element-hidden': !visability('button')}]"
  >
    <component
      :is="highlither ? 'highliter': 'btn-wrapper'"
      v-model="section.name"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element w-auto"
      section-type="element"
      :hide-left="section.hideLeft"
      :hide-right="section.hideRight"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="() => !section.hideMod ? sectionSettings('button') : null"
    >
      <slot name="before" />

      <b-form-group
        v-if="gdpr && gdpr !== ''"
      >
        <div>
          <b-checkbox v-model="gdprCheck">
            <div
              class="custom-control-legend"
              style="white-space: break-spaces;"
              v-text="gdpr"
            />
          </b-checkbox>
        </div>
      </b-form-group>

      <div
        class="d-flex justify-center"
        :class="[`--${sid}--2`]"
      >
        <div
          :data-sid="sid"
          :class="[`--${sid}--1`, section.options.content.button.hoverAnimation]"
          class="estage-btn flex-column overflow-hidden"
        >
          <div class="main-text d-flex items-center justify-center w-full">
            <template v-if="iconPosition === 'left'">
              <i
                v-show="!section.options.content.button.icon.customIcon"
                v-if="section.options.content.button.icon.icon"
                class="transition"
                :class="[section.options.content.button.icon.icon, `--${sid}--3`]"
              />

              <div
                v-show="section.options.content.button.icon.customIcon"
                :class="`--lottie-container-${section.sid}`"
              >
                <button-lottie-icon
                  :config="section.options.content.button.icon"
                />
              </div>
            </template>

            {{ section.options.content.button.text }}

            <template v-if="iconPosition === 'right'">
              <i
                v-show="!section.options.content.button.icon.customIcon"
                v-if="section.options.content.button.icon.icon"
                class="transition"
                :class="[section.options.content.button.icon.icon, `--${sid}--3`]"
              />

              <div
                v-show="section.options.content.button.icon.customIcon"
                :class="`--lottie-container-${section.sid}`"
              >
                <button-lottie-icon
                  :config="section.options.content.button.icon"
                />
              </div>
            </template>
          </div>

          <div
            v-show="section.options.content.button.subtext"
            :class="`--${sid}--subtext`"
            class="flex justify-center sub-text w-full"
            v-text="section.options.content.button.subtext"
          />

          <el-tooltip
            :enable="section.tooltip"
            :class="`--${section.sid}--tooltip`"
            :placement="section.tooltipPlacement"
            :text="section.tooltipText"
          />
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon'
import BtnWrapper from '@/components/builder/elements/9.icon/BtnWrapper'
import ElTooltip from '@/components/builder/ElTooltip'
import {mapState} from 'vuex'
import {width} from '@tailwindcss/postcss7-compat/lib/plugins'

export default {
  name: 'FeaturedItems1',
  methods: {
    width () {
      return width
    }
  },

  components: {ElTooltip, BtnWrapper, ButtonLottieIcon, Highliter},

  mixins: [SectionMixin],

  props: {
    gdpr: {
      type: String,
      require: false
    },
    ignoreHide: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: false
    },
    steps: {
      type: Number,
      required: false
    }
  },

  data () {
    return {
      gdprCheck: false
    }
  },

  computed: {
    ...mapState('orderForm', {
      selectedProvider: (state) => state.selectedProvider
    }),
    ...mapState('forms', {
      currentStep: state => state.wizard.currentStep,
      stepsCount: state => state.wizard.count
    }),
    provider () {
      const form = this.selectedProvider.find(item => item.id === this.form.id)
      if (form) {
        return form.provider
      } else {
        return null
      }
    },
    realTimeVisibility () {
      const arr = (this.project.variables === '{}' || !this.project.variables) ? [] : JSON.parse(this.project.variables)

      const foundKey = arr.find(v => v.key === 'button')

      if (foundKey) return foundKey.value

      return 0
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    iconPosition () {
      return _.get(this.section.options.content.button, ['iconPosition', this.resolutioner], _.get(this.section.options.content.button, ['iconPosition', 'lg'], 'right'))
    },
    formButton () {
      return this.section.class === 'form-button'
    },
    hideFormButton () {
      const lastStep = this.steps - 1
      const isLastStep = this.form?.step === lastStep
      return !this.ignoreHide && this.formButton && isLastStep && ['razorpay', 'paypal'].includes(this.provider)
    }
  }
}
</script>

<style lang="scss">
.estage-btn {
  --height: auto;
  --width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  background: #77abff;
  padding: 10px 30px;
  justify-content: center;
  border-style: solid;
  border-radius: 4px;
  transition: .4s all, .2s border cubic-bezier(0.2, 0, 1, 1), 0s width, 0s height, 0s font-size;
  white-space: break-spaces;
  text-align: center;
  gap: 8px;
}

.sub-text {
  font-size: 16px;
}

.main-text {
  gap: 8px;
  white-space: var(--text-wrap, break-spaces);
  transition: .2s;
}

.form-button {
  width: 100%;
  --align: stretch;
}
</style>
