<template>
  <div>
    <SidbearSettingsGroup
      v-if="!hide.includes('settings')"
      active
      title="Image Settings"
      content-class="border-0"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <sid-upload-image
        :sid="`.--${section.sid}--image`"
        title=""
        rule-name="content"
      />

      <b-form-group label="Alt Text (Alternative Text)">
        <b-input v-model="config.alt" />
      </b-form-group>

      <b-form-group>
        <template #label>
          Alt Text Type

          <icon
            id="popover-info-no-index"
            tabindex="0"
            icon="info-green.svg"
          >
            Button
          </icon>

          <b-popover
            target="popover-info-no-index"
            triggers="hover"
            placement="bottomleft"
          >
            <div>Descriptive is used by screen readers and helps with SEO</div>
            <div>Decorative is ignored by screen readers and not used for SEO</div>
          </b-popover>
        </template>

        <b-form-radio-group v-model="config.noIndex">
          <b-form-radio
            value="0"
            required
            class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
            title="Decorative"
          >
            Descriptive
          </b-form-radio>

          <b-form-radio
            value="1"
            required
            class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
            title="Descriptive"
          >
            Decorative
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <sid-range-slider
        rule-name="width"
        :sid="`.--${section.sid}--wrapper`"
        default-dimension="%"
        :dimensions="['%', 'vw', 'px']"
        label="Image Size"
        var="--width"
        measurement="width"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Display Setting</label>

          <sid-select
            :sid="`.--${section.sid}--wrapper`"
            rule-name="display"
            :searchable="false"
            class="w-[100px]"
            :options="[
              {
                label: 'Block',
                value: ''
              },
              {
                label: 'Inline',
                value: 'inline-flex',
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Position</label>

          <sid-select
            :sid="`.--${section.sid}--wrapper`"
            rule-name="position"
            :searchable="false"
            class="w-[138px]"
            :options="[
              {
                label: 'Static',
                value: ''
              },
              {
                label: 'Fixed',
                value: 'fixed',
              },
              {
                label: 'Absolute',
                value: 'absolute',
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="`.--${section.sid}--image`"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <sid-flip
        :sid="`.--${section.sid}--image`"
        rule-name="scale"
      />

      <sid-align
        :sid="`.--${section.sid}--wrapper`"
        rule-name="justify-self"
        label="Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Filters">
      <rule-tabs>
        <template #Normal>
          <sid-background-filters
            :sid="`.--${section.sid}--image`"
            rule-name="filter"
          />

          <sid-range-slider
            label="Opacity"
            :sid="`.--${section.sid}--image`"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Hover>
          <sid-background-filters
            :sid="`.--${section.sid}--image`"
            rule-name="filter"
            presudo=":hover"
          />

          <sid-range-slider
            label="Opacity"
            :sid="`.--${section.sid}--image`"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            presudo=":hover"
            :max="1"
            :step="0.1"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Mask">
      <sid-background-mask :sid="`.--${section.sid}--image`" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Transform">
      <rule-tabs>
        <template #Normal>
          <sid-transform
            :sid="`.--${section.sid}--image`"
            rule-name="transform"
            :section="section"
          />

          <sid-translate
            :sid="`.--${section.sid}--wrapper`"
            rule-name="translate"
          />
        </template>

        <template #Hover>
          <sid-transform
            :sid="`.--${section.sid}--image`"
            rule-name="transform"
            presudo=":hover"
            :section="section"
          />

          <sid-translate
            :sid="`.--${section.sid}--wrapper`"
            presudo=":hover"
            rule-name="translate"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group>
        <animations
          :sid="section.uid"
          :animations="config.animations"
        />
      </b-form-group>

      <hover-animations v-model="hoverAnimation" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <rule-tabs>
        <template slot="Normal">
          <sid-box-shadow
            :sid="`.--${section.sid}--image`"
            rule-name="box-shadow"
          />
        </template>

        <template slot="Hover">
          <sid-box-shadow
            :sid="`.--${section.sid}--image`"
            presudo=":hover"
            rule-name="box-shadow"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--image`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--image`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template slot="Hover">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--image`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--image`"
            rule-name="border"
            presudo=":hover"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      v-if="!hide.includes('action')"
      title="Click Action"
    >
      <ButtonAction
        :config="config"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <tooltip-mod
      v-if="!hide.includes('tooltip')"
      :config="section"
      component-name="tooltip"
    />

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import Animations from '@/components/editor/controls/Animations'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import SidStyles from '@/components/mixins/SidStyles'
import SidInput from '@/components/editor/components/sid-controls/SidInput'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask'
import TooltipMod from '@/components/editor/mods/TooltipMod'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageModV4',

  components: {
    TooltipMod,
    SidBackgroundMask,
    SidTranslate,
    SidInput,
    HoverAnimations,
    Animations,
    SidRangeSlider,
    SidBoxShadow,
    ButtonAction,
    SidColor,
    SidSelect,
    SidLinkedGroup,
    SidAlign,
    SidFlip,
    SidTransform,
    SidUploadImage,
    SidBackgroundFilters,
    RuleTabs,
    SidbearSettingsGroup
  },

  mixins: [ ModMixin, SidStyles ],

  computed: {
    hoverAnimation: {
      get () {
        return this.config.hoverAnimation || null
      },

      set (val) {
        this.$set(this.config, 'hoverAnimation', val)
      }
    }
  },

  methods: {
    ...mapGetters({
      all: 'editor'
    })
  }
}
</script>
