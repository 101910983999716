<template>
  <div
    :data-sid="`form-field-${sid}`"
    :class="`--${section.sid}--margin`"
    class="form-margin"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      section-type="element"
      class="highlight-element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="estage-form-element-group mb-0"
        :class="`--${sid}--form-field`"
      >
        <b-form-checkbox
          v-model="control.value"
          :placeholder="control.placeholder"
          :required="!!control.is_required"
          v-bind="$attrs"
          class="input"
          :class="`--${sid}--input`"
        >
          <legend
            v-if="control.label"
            class="flex items-center gap-x-[4px] text-wrap break-words"
            :class="`--${sid}--label`"
          >
            {{ control.label }}
            <span
              v-if="control.is_required"
              class="text-danger"
            >
              *
            </span>
          </legend>
          <p
            v-if="control.placeholder"
            class="font-normal secondary-text text-wrap break-words"
          >
            {{ control.placeholder }}
          </p>
        </b-form-checkbox>
      </b-form-group>
    </highliter>
  </div>
</template>

<script>
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldCheckbox',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  computed: {
    control () {
      return this.section.options.content.control
    }
  }
}
</script>