import {mapGetters, mapState} from 'vuex'
import CustomStylesMixin from './CustomStylesMixin'
import deepmerge from 'deepmerge'
import {clonePart, convertStyles, generateSID, insertSectionStyle, isChangedPreset} from '@/utils/useStylesheet'

export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    parentUID: {
      type: String,
      default: null
    },
    preview: {
      type: Boolean,
      default: false
    },
    customAttrs: {
      type: Object,
      default: () => {}
    },
    hideMod: {
      type: Boolean,
      default: false
    },
    hideBar: {
      type: Boolean,
      default: false
    },
    hideAdd: {
      type: Boolean,
      default: false
    },
    hideLeft: {
      type: Boolean,
      default: false
    },
    hideRight: {
      type: Boolean,
      default: false
    },
    highlither: {
      type: Boolean,
      default: true
    },
    highliterOptions: {
      type: Object,
      default: () => {}
    }
  },

  mixins: [CustomStylesMixin],

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles,
      tablet: state => state.tablet,
      currentSubjectConfig: state => state.config,
      pickSubject: state => state.pickSubject,
      grabAll: state => state.grabAll
    }),
    ...mapState('pages', {
      page: state => state.page
    }),
    ...mapGetters('editor', {
      fullResolution: 'resolution'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    style () {
      return this.section.options.customize.style
    },
    tempID () {
      return this.section.temp_id
    },
    sid () {
      return this.section.sid
    }
  },

  mounted () {
    if (!this.section.sid) {
      this.$set(this.section, 'sid', generateSID(this.$el, this.page.css_hash))
    }

    if (this.section.css) {
      this.insertStyles()
      this.$delete(this.section, 'css')
      this.$delete(this.section, 'sids')
    }
  },

  watch: {
    tempID () {
      if (!(this.$el instanceof HTMLElement)) return
      if (isChangedPreset(this.$el, this.section.sid)) {
        const sid = generateSID(this.$el, this.page.css_hash)
        convertStyles(this.section.sid, sid)
        this.$set(this.section, 'sid', sid)
      }
    }
  },

  methods: {
    debug () {
      console.log(clonePart(this.section, this.$el))
    },
    insertStyles () {
      let section = JSON.stringify(this.section)
      const sids = (this.section.sids || [])

      sids.forEach(sid => {
        section = section.replaceAll(sid, generateSID(this.$el, this.page.css_hash))
      })

      section = JSON.parse(section)

      Object.keys(this.section).forEach(name => {
        this.$set(this.section, name, section[name])
      })

      insertSectionStyle(section.css)
    },
    subjectVisability (displays) {
      if (typeof displays === 'object' && !Array.isArray(displays)) {
        displays = true
      }

      if (displays === true || displays === undefined) {
        return true
      }

      if (Array.isArray(displays)) {
        return !displays.length || displays.includes(this.resolutioner)
      }

      return displays
    },
    offset (obj) {
      if (!obj.hasOwnProperty('offset')) {
        this.$set(obj, 'offset', {
          lg: 0,
          sm: 0
        })
      }

      return obj.offset[this.resolution]
    },
    order (obj) {
      if (obj.hasOwnProperty('order')) {
        return obj.order[this.resolution]
      }
    },
    cols (lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    },
    sectionSettings (openedItem = null, options = {}) {
      if (!this.pickSubject) {
        this.$store.dispatch('editor/sectionSettings', {
          section: this.section,
          display: this.section.display,
          openedItem,
          el: this.$el,
          options
        })
        VEvent.fire('minimize', false)
      } else {
        if (Array.isArray(this.pickSubject) && !this.pickSubject.includes(this.section.component)) {
          this.$swal({
            icon: 'warning',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Please select a Block or Row container.',
            showConfirmButton: false,
            timer: 3000
          })
          this.$set(this.value, 'pickedUid', null)
          if (this.grabAll) {
            this.$set(this.value, 'subject', null)
          }
          return this.$store.commit('editor/PICK_SUBJECT', {pick: false, config: {}, grabAll: false})
        }

        this.$set(this.currentSubjectConfig, 'pickedUid', this.section._uid || this.section.uid)

        if (this.grabAll) {
          this.$set(this.currentSubjectConfig, 'subject', this.section.options.content)
        }

        this.$store.commit('editor/PICK_SUBJECT', {pick: false, config: {}, grabAll: false})
      }
    },
    handleFlow () {
      this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
        name: this.section.name,
        $el: this.$el,
        uid: this.section.uid
      })

      this.$emit('flow')
    },
    tiptapUID (name) {
      return this.section.uid + '_' + name
    },
    merger (defaultOptions) {
      this.section.options = deepmerge(defaultOptions, this.section.options)
    },
    visability (el = null) {
      if (!el) {
        // if (!this.section.display) return true
        if (!this.section.hasOwnProperty('display')) return
        const display = this.section.display

        if (typeof display === 'object') {
          return display.includes(this.fullResolution)
        }

        return display
      }

      if (!this.section.options.content[el]) return true
      if (!this.section.options.content[el].hasOwnProperty('display')) return
      const display = this.section.options.content[el].display

      if (typeof display === 'object') {
        return display.includes(this.fullResolution)
      }

      return display
    }
  }
}
