<template>
  <div class="pt-[15px]">
    <div class="flex items-center mb-[12px]">
      <label class="mb-0 pb-0 mr-[24px]">Veritcal Align</label>

      <div class="flex gap-[6px]">
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="verticalAlign = 'start'"
        >
          <svg
            fill="none"
            height="12"
            viewBox="0 0 14 12"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="verticalAlign === 'start' ? '#3D82EA' : '#333333'"
              d="M0.583313 0.74999C0.583313 0.427824 0.84448 0.166656 1.16665 0.166656H12.8333C13.1555 0.166656 13.4166 0.427824 13.4166 0.74999C13.4166 1.07216 13.1555 1.33332 12.8333 1.33332H1.16665C0.84448 1.33332 0.583313 1.07216 0.583313 0.74999Z"
            />
            <path
              :fill="verticalAlign === 'start' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M1.74998 3.08332C1.74998 2.76116 2.01115 2.49999 2.33331 2.49999H5.83331C6.15548 2.49999 6.41665 2.76116 6.41665 3.08332V11.25C6.41665 11.5722 6.15548 11.8333 5.83331 11.8333H2.33331C2.01115 11.8333 1.74998 11.5722 1.74998 11.25V3.08332ZM2.91665 3.66666V10.6667H5.24998V3.66666H2.91665Z"
              fill-rule="evenodd"
            />
            <path
              :fill="verticalAlign === 'start' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M8.16665 2.49999C7.84448 2.49999 7.58331 2.76116 7.58331 3.08332V7.74999C7.58331 8.07216 7.84448 8.33332 8.16665 8.33332H11.6666C11.9888 8.33332 12.25 8.07216 12.25 7.74999V3.08332C12.25 2.76116 11.9888 2.49999 11.6666 2.49999H8.16665ZM8.74998 7.16666V3.66666H11.0833V7.16666H8.74998Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="verticalAlign = 'center'"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 12 14"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="verticalAlign === 'center' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M6.58335 1.16668C6.58335 0.844511 6.32219 0.583344 6.00002 0.583344C5.67785 0.583344 5.41669 0.844511 5.41669 1.16668V1.75001H3.66669C3.34452 1.75001 3.08335 2.01118 3.08335 2.33334V5.83334C3.08335 6.15551 3.34452 6.41668 3.66669 6.41668H5.41669V7.58334H0.75002C0.427854 7.58334 0.166687 7.84451 0.166687 8.16668V11.6667C0.166687 11.9888 0.427854 12.25 0.75002 12.25H5.41669V12.8333C5.41669 13.1555 5.67785 13.4167 6.00002 13.4167C6.32219 13.4167 6.58335 13.1555 6.58335 12.8333V12.25H11.25C11.5722 12.25 11.8334 11.9888 11.8334 11.6667V8.16668C11.8334 7.84451 11.5722 7.58334 11.25 7.58334H6.58335V6.41668H8.33335C8.65552 6.41668 8.91669 6.15551 8.91669 5.83334V2.33334C8.91669 2.01118 8.65552 1.75001 8.33335 1.75001H6.58335V1.16668ZM5.41669 2.91668H4.25002V5.25001H5.41669V2.91668ZM6.58335 5.25001V2.91668H7.75002V5.25001H6.58335ZM5.41669 8.75001H1.33335V11.0833H5.41669V8.75001ZM6.58335 11.0833V8.75001H10.6667V11.0833H6.58335Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="verticalAlign = 'end'"
        >
          <svg
            fill="none"
            height="12"
            viewBox="0 0 14 12"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="verticalAlign === 'end' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M1.74998 0.74999C1.74998 0.427824 2.01115 0.166656 2.33331 0.166656H5.83331C6.15548 0.166656 6.41665 0.427824 6.41665 0.74999V8.91666C6.41665 9.23882 6.15548 9.49999 5.83331 9.49999H2.33331C2.01115 9.49999 1.74998 9.23882 1.74998 8.91666V0.74999ZM2.91665 1.33332V8.33332H5.24998V1.33332H2.91665Z"
              fill-rule="evenodd"
            />
            <path
              :fill="verticalAlign === 'end' ? '#3D82EA' : '#333333'"
              d="M0.583313 11.25C0.583313 10.9278 0.84448 10.6667 1.16665 10.6667H12.8333C13.1555 10.6667 13.4166 10.9278 13.4166 11.25C13.4166 11.5722 13.1555 11.8333 12.8333 11.8333H1.16665C0.84448 11.8333 0.583313 11.5722 0.583313 11.25Z"
            />
            <path
              :fill="verticalAlign === 'end' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M8.16665 3.08332C7.84448 3.08332 7.58331 3.34449 7.58331 3.66666V8.91666C7.58331 9.23882 7.84448 9.49999 8.16665 9.49999H11.6666C11.9888 9.49999 12.25 9.23882 12.25 8.91666V3.66666C12.25 3.34449 11.9888 3.08332 11.6666 3.08332H8.16665ZM8.74998 8.33332V4.24999H11.0833V8.33332H8.74998Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
      </div>
    </div>

    <div class="flex items-center mb-[16px]">
      <label class="mb-0 pb-0 mr-[9px]">Horizontal Align</label>

      <div class="flex gap-[6px]">
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="horizontalAlign = 'start'"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 12 14"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="horizontalAlign === 'start' ? '#3D82EA' : '#333333'"
              d="M0.6 0C0.931371 0 1.2 0.28491 1.2 0.636364V13.3636C1.2 13.7151 0.931371 14 0.6 14C0.268629 14 0 13.7151 0 13.3636V0.636364C0 0.28491 0.268629 0 0.6 0Z"
            />
            <path
              :fill="horizontalAlign === 'start' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M2.4 1.90909C2.4 1.55764 2.66863 1.27273 3 1.27273H11.4C11.7314 1.27273 12 1.55764 12 1.90909V5.72727C12 6.07873 11.7314 6.36364 11.4 6.36364H3C2.66863 6.36364 2.4 6.07873 2.4 5.72727V1.90909ZM3.6 2.54545V5.09091H10.8V2.54545H3.6Z"
              fill-rule="evenodd"
            />
            <path
              :fill="horizontalAlign === 'start' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M3 7.63636C2.66863 7.63636 2.4 7.92127 2.4 8.27273V12.0909C2.4 12.4424 2.66863 12.7273 3 12.7273H6.6C6.93137 12.7273 7.2 12.4424 7.2 12.0909V8.27273C7.2 7.92127 6.93137 7.63636 6.6 7.63636H3ZM3.6 11.4545V8.90909H6V11.4545H3.6Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="horizontalAlign = 'center'"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 12 14"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="horizontalAlign === 'center' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M1.09091 0.7C1.09091 0.313401 1.33512 0 1.63636 0H4.90909C5.21034 0 5.45455 0.313401 5.45455 0.7V6.3H6.54545V4.2C6.54545 3.8134 6.78966 3.5 7.09091 3.5H10.3636C10.6649 3.5 10.9091 3.8134 10.9091 4.2V6.3H11.4545C11.7558 6.3 12 6.6134 12 7C12 7.3866 11.7558 7.7 11.4545 7.7H10.9091V9.8C10.9091 10.1866 10.6649 10.5 10.3636 10.5H7.09091C6.78966 10.5 6.54545 10.1866 6.54545 9.8V7.7H5.45455V13.3C5.45455 13.6866 5.21034 14 4.90909 14H1.63636C1.33512 14 1.09091 13.6866 1.09091 13.3V7.7H0.545455C0.244208 7.7 0 7.3866 0 7C0 6.6134 0.244208 6.3 0.545455 6.3H1.09091V0.7ZM7.63636 7.7V9.1H9.81818V7.7H7.63636ZM9.81818 6.3V4.9H7.63636V6.3H9.81818ZM4.36364 1.4V6.3H2.18182V1.4H4.36364ZM2.18182 12.6V7.7H4.36364V12.6H2.18182Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
        <b-btn
          class="w-[38px] h-[38px] flex items-center justify-center p-0"
          variant="white"
          @click="horizontalAlign = 'end'"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 12 14"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="horizontalAlign === 'end' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M0 1.90909C0 1.55764 0.268629 1.27273 0.6 1.27273H9C9.33137 1.27273 9.6 1.55764 9.6 1.90909V5.72727C9.6 6.07873 9.33137 6.36364 9 6.36364H0.6C0.268629 6.36364 0 6.07873 0 5.72727V1.90909ZM1.2 2.54545V5.09091H8.4V2.54545H1.2Z"
              fill-rule="evenodd"
            />
            <path
              :fill="horizontalAlign === 'end' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M11.4 0C11.7314 0 12 0.28491 12 0.636364V13.3636C12 13.7151 11.7314 14 11.4 14C11.0686 14 10.8 13.7151 10.8 13.3636V0.636364C10.8 0.28491 11.0686 0 11.4 0Z"
              fill-rule="evenodd"
            />
            <path
              :fill="horizontalAlign === 'end' ? '#3D82EA' : '#333333'"
              clip-rule="evenodd"
              d="M4.8 8.27273C4.8 7.92127 5.06863 7.63636 5.4 7.63636H9C9.33137 7.63636 9.6 7.92127 9.6 8.27273V12.0909C9.6 12.4424 9.33137 12.7273 9 12.7273H5.4C5.06863 12.7273 4.8 12.4424 4.8 12.0909V8.27273ZM6 8.90909V11.4545H8.4V8.90909H6Z"
              fill-rule="evenodd"
            />
          </svg>
        </b-btn>
      </div>
    </div>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <padding-full-customs :config="padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ButtonMod',
  components: {SidbearSettingsGroup},
  mixins: [ModMixin, ThemeMixin],

  computed: {
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    padding () {
      if (!this.config.margin) {
        this.$set(this.config, 'padding', {})
      }

      return this.config.margin
    },
    horizontalAlign: {
      get () {
        const _align = this.config.horizontal_align

        if (_align[this.resolutioner] === null) return _align.lg

        return _align[this.resolutioner]
      },
      set (val) {
        this.$set(this.config.horizontal_align, this.resolutioner, val)
      }
    },
    verticalAlign: {
      get () {
        const _align = this.config.vertical_align
        if (_align[this.resolutioner] === null) return _align.lg

        return _align[this.resolutioner]
      },
      set (val) {
        this.$set(this.config.vertical_align, this.resolutioner, val)
      }
    }
  }
}
</script>
