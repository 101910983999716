<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    :class="{'element-hidden': !visability('code')}"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('code')"
  >
    <div
      :data-sid="section.sid"
    >
      <div :class="`--${section.sid}--wrapper`">
        <div
          class="bg-gray-300 h5 p-4 text-center rounded"
        >
          Custom HTML/JS code
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      timeout: null
    }
  },

  computed: {
    code () {
      return this.section.options.content.code.code
    }
  },

  methods: {
    prepare () {
      const template = document.createElement('div'),
        wrapperDep = document.createElement('div')

      document.querySelectorAll(`script[uid="${this.section.uid}"]`)
        .forEach(item => item.parentNode.removeChild(item))

      document.querySelectorAll('#wf_script')
        .forEach(item => item.parentNode.removeChild(item))
      delete window._wf

      wrapperDep.setAttribute('custom-embed-wrapper', true)
      wrapperDep.setAttribute('uid', this.section.uid)

      template.innerHTML = this.section.options.content.code.code

      template.querySelectorAll('script')
        .forEach($s => {
          var dEl = document.createElement($s.tagName),
            attrs = $s.attributes

          dEl.innerHTML = $s.innerHTML
          for (var i = attrs.length - 1; i >= 0; i--) {
            dEl.setAttribute(attrs[i].name, attrs[i].value)
          }
          wrapperDep.appendChild(dEl)
        })

      document.head.appendChild(wrapperDep)
    }
  }
}
</script>
