<template>
  <b-navbar class="editor-header !z-[1039]">
    <div class="flex basis-auto items-center w-[256px] mr-2">
      <b-navbar-brand
        class="cursor-pinter"
        @click="$router.push('/')"
      >
        <icon
          icon="logo.svg"
          size="36px"
        />
      </b-navbar-brand>

      <b-input-group class="d-flex align-items-center position-relative flex-nowrap">
        <b-spinner
          v-if="searching"
          class="position-absolute z-index-100 right-14 bg-white"
          small
          variant="primary"
        />
        <pages-select-menu
          input-class="rounded-l-[4px]"
          input-group-class="!ml-0"
          input-search-class="!w-full"
          other-title="Modules:"
          @selectPage="onSelectPage"
          @selectOther="onSelectPage"
        >
          <template slot="placeholder">
            <div class="px-3 max-w-[140px]">
              <strong>Page:</strong> {{ page.title }}
            </div>
          </template>
        </pages-select-menu>

        <template slot="append">
          <b-btn
            v-if="!isPereview"
            v-b-tooltip.hover
            variant="white"
            title="Add/Manage Pages"
            class="btn-icon !px-[8px]"
            @click="newPageModal"
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.72727 4.19318C5.26913 4.19318 4.89773 4.56458 4.89773 5.02273V8.34091C4.89773 8.79905 5.26913 9.17045 5.72727 9.17045H9.875C10.3331 9.17045 10.7045 8.79905 10.7045 8.34091V5.02273C10.7045 4.56458 10.3331 4.19318 9.875 4.19318H5.72727ZM6.55682 7.51136V5.85227H9.04545V7.51136H6.55682Z"
                fill="#6E747A"
              />
              <path
                d="M4.89773 11.6591C4.89773 11.2009 5.26913 10.8295 5.72727 10.8295H12.3636C12.8218 10.8295 13.1932 11.2009 13.1932 11.6591C13.1932 12.1172 12.8218 12.4886 12.3636 12.4886H5.72727C5.26913 12.4886 4.89773 12.1172 4.89773 11.6591Z"
                fill="#6E747A"
              />
              <path
                d="M5.72727 14.1477C5.26913 14.1477 4.89773 14.5191 4.89773 14.9773C4.89773 15.4354 5.26913 15.8068 5.72727 15.8068H12.3636C12.8218 15.8068 13.1932 15.4354 13.1932 14.9773C13.1932 14.5191 12.8218 14.1477 12.3636 14.1477H5.72727Z"
                fill="#6E747A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.23864 0.875C1.8642 0.875 0.75 1.9892 0.75 3.36364V16.6364C0.75 18.0108 1.8642 19.125 3.23864 19.125H14.8523C16.2267 19.125 17.3409 18.0108 17.3409 16.6364V3.36364C17.3409 1.9892 16.2267 0.875 14.8523 0.875H3.23864ZM2.40909 3.36364C2.40909 2.90549 2.78049 2.53409 3.23864 2.53409H14.8523C15.3104 2.53409 15.6818 2.90549 15.6818 3.36364V16.6364C15.6818 17.0945 15.3104 17.4659 14.8523 17.4659H3.23864C2.78049 17.4659 2.40909 17.0945 2.40909 16.6364V3.36364Z"
                fill="#6E747A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.72727 4.19318C5.26913 4.19318 4.89773 4.56458 4.89773 5.02273V8.34091C4.89773 8.79905 5.26913 9.17045 5.72727 9.17045H9.875C10.3331 9.17045 10.7045 8.79905 10.7045 8.34091V5.02273C10.7045 4.56458 10.3331 4.19318 9.875 4.19318H5.72727ZM6.55682 7.51136V5.85227H9.04545V7.51136H6.55682Z"
                stroke="white"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.89773 11.6591C4.89773 11.2009 5.26913 10.8295 5.72727 10.8295H12.3636C12.8218 10.8295 13.1932 11.2009 13.1932 11.6591C13.1932 12.1172 12.8218 12.4886 12.3636 12.4886H5.72727C5.26913 12.4886 4.89773 12.1172 4.89773 11.6591Z"
                stroke="white"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.72727 14.1477C5.26913 14.1477 4.89773 14.5191 4.89773 14.9773C4.89773 15.4354 5.26913 15.8068 5.72727 15.8068H12.3636C12.8218 15.8068 13.1932 15.4354 13.1932 14.9773C13.1932 14.5191 12.8218 14.1477 12.3636 14.1477H5.72727Z"
                stroke="white"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.23864 0.875C1.8642 0.875 0.75 1.9892 0.75 3.36364V16.6364C0.75 18.0108 1.8642 19.125 3.23864 19.125H14.8523C16.2267 19.125 17.3409 18.0108 17.3409 16.6364V3.36364C17.3409 1.9892 16.2267 0.875 14.8523 0.875H3.23864ZM2.40909 3.36364C2.40909 2.90549 2.78049 2.53409 3.23864 2.53409H14.8523C15.3104 2.53409 15.6818 2.90549 15.6818 3.36364V16.6364C15.6818 17.0945 15.3104 17.4659 14.8523 17.4659H3.23864C2.78049 17.4659 2.40909 17.0945 2.40909 16.6364V3.36364Z"
                stroke="white"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </b-btn>
        </template>
      </b-input-group>
    </div>

    <div class="flex basis-auto grow items-center">
      <b-navbar-nav class="align-items-center">
        <div class="on-hover position-relative">
          <div class="resolution-toggle">
            <b-btn
              v-if="resolution === 'lg'"
              variant="white"
              class="d-flex justify-content-center py-2 px-1"
            >
              <icon
                v-if="resolution === 'lg'"
                icon="desktop-active.svg"
              />
              <icon
                v-else
                icon="desktop.svg"
              />
            </b-btn>
            <b-btn
              v-else-if="resolution === 'sm' && tablet"
              variant="white"
              class="d-flex justify-content-center px-2"
            >
              <div class="position-relative">
                <svg
                  width="15"
                  height="19"
                  viewBox="0 0 15 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.7271 0H11.3994C12.8797 0 14.0798 0.884798 14.08 1.97967L14.0674 17.0143C14.0674 18.1054 12.8613 18.9931 11.3872 18.9931L2.7006 19C1.22623 19 0 18.1125 0 17.0215V1.98072C0 0.889779 1.25273 0 2.7271 0ZM8.32647 1.18756H5.8104C5.58876 1.18756 5.40933 1.32036 5.40933 1.48441C5.40933 1.64845 5.58876 1.78125 5.8104 1.78125H8.32647C8.54749 1.78125 8.72753 1.64845 8.72753 1.48441C8.72753 1.32036 8.54749 1.18756 8.32647 1.18756ZM4.29847 1.282C4.44711 1.17214 4.71767 1.17063 4.86611 1.282C4.94053 1.33693 4.98457 1.41405 4.98457 1.49283C4.98457 1.57009 4.94053 1.64706 4.86611 1.70214C4.7921 1.75722 4.6875 1.78967 4.58331 1.78967C4.47708 1.78967 4.37493 1.75722 4.29847 1.70214C4.22445 1.64721 4.18225 1.57009 4.18225 1.49283C4.18204 1.41405 4.22445 1.33693 4.29847 1.282Z"
                    :fill="tablet ? '#4f83e3' : '#d8dadd'"
                  />
                  <ellipse
                    cx="7.03991"
                    cy="15.0733"
                    rx="1.17333"
                    ry="0.886667"
                    fill="white"
                  />
                </svg>
              </div>
            </b-btn>
            <b-btn
              v-else
              variant="white"
              class="d-flex justify-content-center px-2"
            >
              <icon
                v-if="resolution === 'sm' && !tablet"
                icon="smartphone-active.svg"
              />
              <icon
                v-else
                icon="smartphone.svg"
              />
            </b-btn>
          </div>

          <b-btn-group
            class="relative overlay bg-white position-absolute"
            style="top: 0; width: 41px"
            vertical
          >
            <b-btn
              v-b-tooltip.hover.right="'Edit on Desktop'"
              variant="white"
              class="d-flex justify-content-center py-2 px-2"
              @click="setResolution('lg')"
            >
              <icon
                v-if="resolution === 'lg'"
                icon="desktop-active.svg"
              />
              <icon
                v-else
                icon="desktop.svg"
              />
            </b-btn>
            <b-btn
              v-b-tooltip.hover.right="'Edit on Tablet'"
              variant="white"
              class="d-flex justify-content-center py-2 px-2"
              @click="setResolution('md')"
            >
              <div class="position-relative">
                <svg
                  width="15"
                  height="19"
                  viewBox="0 0 15 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.7271 0H11.3994C12.8797 0 14.0798 0.884798 14.08 1.97967L14.0674 17.0143C14.0674 18.1054 12.8613 18.9931 11.3872 18.9931L2.7006 19C1.22623 19 0 18.1125 0 17.0215V1.98072C0 0.889779 1.25273 0 2.7271 0ZM8.32647 1.18756H5.8104C5.58876 1.18756 5.40933 1.32036 5.40933 1.48441C5.40933 1.64845 5.58876 1.78125 5.8104 1.78125H8.32647C8.54749 1.78125 8.72753 1.64845 8.72753 1.48441C8.72753 1.32036 8.54749 1.18756 8.32647 1.18756ZM4.29847 1.282C4.44711 1.17214 4.71767 1.17063 4.86611 1.282C4.94053 1.33693 4.98457 1.41405 4.98457 1.49283C4.98457 1.57009 4.94053 1.64706 4.86611 1.70214C4.7921 1.75722 4.6875 1.78967 4.58331 1.78967C4.47708 1.78967 4.37493 1.75722 4.29847 1.70214C4.22445 1.64721 4.18225 1.57009 4.18225 1.49283C4.18204 1.41405 4.22445 1.33693 4.29847 1.282Z"
                    :fill="tablet ? '#4f83e3' : '#d8dadd'"
                  />
                  <ellipse
                    cx="7.03991"
                    cy="15.0733"
                    rx="1.17333"
                    ry="0.886667"
                    fill="white"
                  />
                </svg>
              </div>
            </b-btn>
            <b-btn
              v-b-tooltip.hover.right="'Edit on Mobile'"
              variant="white"
              class="d-flex justify-content-center py-2 px-2"
              @click="setResolution('sm')"
            >
              <icon
                v-if="resolution === 'sm' && !tablet"
                icon="smartphone-active.svg"
              />
              <icon
                v-else
                icon="smartphone.svg"
              />
            </b-btn>
          </b-btn-group>
        </div>

        <b-nav-item>
          <b-btn-group class="resolution-toggle">
            <slot name="undo-redo" />

            <b-btn
              v-b-tooltip.hover
              title="Global Style"
              variant="white"
              class="w-[40px] h-[40px] p-0 rounded-left-0 text-[#6E747A]"
              :class="{'!bg-[#E2E2E2]': toolbarPage === 'global-styles'}"
              @click="$store.commit('editor/SET_PAGE', 'global-styles')"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M17.1011 10.9873C17.4409 10.5789 17.5034 10.0094 17.1945 9.53415L15.2033 6.44129C14.8589 5.91138 14.1325 5.66957 13.4846 5.8234C13.6226 5.27258 13.3519 4.68164 12.7733 4.38492L9.32293 2.6155C8.83016 2.36275 8.24097 2.38865 7.78785 2.63759C7.52524 2.25558 7.05147 2 6.51172 2H2.49414C1.67027 2 1 2.59536 1 3.32715C1 3.63402 1 15.4196 1 15.7729C1 16.5047 1.67027 17.1 2.49414 17.1C2.88106 17.1 14.6122 17.1 16.5059 17.1C17.3297 17.1 18 16.5047 18 15.7729V12.2043C18 11.6604 17.6296 11.1921 17.1011 10.9873ZM7.00977 15.7729C7.00977 16.0168 6.78634 16.2153 6.51172 16.2153H2.49414C2.21952 16.2153 1.99609 16.0168 1.99609 15.7729C1.99609 15.5708 1.99609 11.956 1.99609 11.7619H7.00977V15.7729ZM7.00977 10.8772H1.99609V7.3086H7.00977V10.8772ZM7.00977 6.42384H1.99609V3.32715C1.99609 3.08322 2.21952 2.88477 2.49414 2.88477H6.51172C6.78634 2.88477 7.00977 3.08322 7.00977 3.32715V6.42384ZM8.00586 3.75679L8.14435 3.54371C8.28174 3.3324 8.58701 3.25973 8.82488 3.38174L12.2753 5.15116C12.5132 5.27317 12.5949 5.54432 12.4572 5.75622L11.8817 6.64473C11.8673 6.66364 11.8544 6.68328 11.8435 6.70378L8.00586 12.6289V3.75679ZM8.00586 14.4031L12.6617 7.21476L13.6243 6.72109C13.8684 6.59595 14.2031 6.67195 14.3397 6.88209L16.3308 9.97494C16.4686 10.1868 16.3883 10.4584 16.1473 10.582C15.7916 10.7649 8.35612 14.5884 8.00586 14.7685V14.4031ZM17.0039 15.7729C17.0039 16.0168 16.7805 16.2153 16.5059 16.2153H7.9202C7.97329 16.0822 8.00307 15.9396 8.00536 15.7911L15.8408 11.7619H16.5059C16.7805 11.7619 17.0039 11.9604 17.0039 12.2043V15.7729Z"
                    :fill="toolbarPage === 'global-styles' ? '#fff' : '#6E747A'"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </b-btn>
            <b-btn
              v-b-tooltip.hover
              title="Assets Library"
              variant="white"
              class="w-[40px] h-[40px] p-0 rounded-left-0 text-[#6E747A]"
              @click="mediaModal = true"
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2894 18.9999C15.1519 18.9999 15.0112 18.9827 14.8721 18.9459L2.22059 15.5578C1.35332 15.3189 0.836229 14.4206 1.06368 13.5533L2.65996 7.60348C2.71886 7.38503 2.94305 7.25821 3.16068 7.31385C3.37914 7.37194 3.50841 7.59694 3.45032 7.81457L1.85487 13.7628C1.74114 14.1964 2.00132 14.648 2.43577 14.7683L15.0824 18.1547C15.5169 18.2693 15.9652 18.0107 16.0781 17.5787L16.7171 15.2109C16.776 14.9925 17.0002 14.8624 17.2187 14.9221C17.4371 14.981 17.5656 15.206 17.5075 15.4237L16.8693 17.7882C16.677 18.5164 16.0143 18.9999 15.2894 18.9999Z"
                  fill="#6E747A"
                  stroke="#6E747A"
                  stroke-width="0.3"
                />
                <path
                  d="M19.0097 14.0909H5.91877C5.01632 14.0909 4.28241 13.357 4.28241 12.4545V2.63636C4.28241 1.73391 5.01632 1 5.91877 1H19.0097C19.9121 1 20.646 1.73391 20.646 2.63636V12.4545C20.646 13.357 19.9121 14.0909 19.0097 14.0909ZM5.91877 1.81818C5.46795 1.81818 5.10059 2.18554 5.10059 2.63636V12.4545C5.10059 12.9054 5.46795 13.2727 5.91877 13.2727H19.0097C19.4605 13.2727 19.8279 12.9054 19.8279 12.4545V2.63636C19.8279 2.18554 19.4605 1.81818 19.0097 1.81818H5.91877Z"
                  fill="#6E747A"
                  stroke="#7E7E7E"
                  stroke-width="0.3"
                />
                <path
                  d="M8.37332 6.72731C7.47086 6.72731 6.73695 5.99341 6.73695 5.09095C6.73695 4.1885 7.47086 3.45459 8.37332 3.45459C9.27577 3.45459 10.0097 4.1885 10.0097 5.09095C10.0097 5.99341 9.27577 6.72731 8.37332 6.72731ZM8.37332 4.27277C7.9225 4.27277 7.55514 4.64013 7.55514 5.09095C7.55514 5.54177 7.9225 5.90913 8.37332 5.90913C8.82413 5.90913 9.1915 5.54177 9.1915 5.09095C9.1915 4.64013 8.82413 4.27277 8.37332 4.27277Z"
                  fill="#6E747A"
                />
                <path
                  d="M4.74876 13.2154C4.64403 13.2154 4.5393 13.1753 4.45912 13.096C4.29958 12.9364 4.29958 12.6771 4.45912 12.5175L8.32339 8.65326C8.78648 8.19016 9.59566 8.19016 10.0588 8.65326L11.2091 9.80362L14.3935 5.98271C14.625 5.70535 14.9654 5.54417 15.3278 5.54089H15.3368C15.6952 5.54089 16.0347 5.69635 16.2687 5.9688L20.5478 10.9613C20.6951 11.1323 20.6755 11.3909 20.5037 11.5382C20.3327 11.6854 20.0749 11.6666 19.9268 11.494L15.6478 6.50144C15.5684 6.4098 15.4588 6.35907 15.3368 6.35907C15.2517 6.35171 15.102 6.41062 15.0227 6.50635L11.5511 10.6717C11.4775 10.7601 11.3703 10.8133 11.2549 10.8182C11.1388 10.8263 11.0283 10.7805 10.9473 10.6987L9.4803 9.23171C9.32566 9.07789 9.05648 9.07789 8.90185 9.23171L5.03758 13.096C4.95821 13.1753 4.85348 13.2154 4.74876 13.2154Z"
                  fill="#6E747A"
                />
              </svg>
            </b-btn>
            <ModuleSelectMenu />
          </b-btn-group>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-center ml-auto">
        <b-btn-group>
          <template v-if="!isPereview">
            <b-btn
              v-b-tooltip.hover
              class="d-flex align-items-center"
              :variant="toolbarPage === 'favorites' ? 'primary' : 'white'"
              title="Favorites"
              @click="
                $store.commit(
                  'editor/SET_PAGE',
                  toolbarPage === 'favorites' ? 'sections' : 'favorites'
                )
              "
            >
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.48718 11.1111L2.47818 13.2743L3.24383 8.69271L0.000483781 5.44803L4.48268 4.77959L6.48718 0.611145L8.49167 4.77959L12.9739 5.44803L9.73052 8.69271L10.4962 13.2743L6.48718 11.1111Z"
                  :fill="toolbarPage === 'favorites' ? '#fff' : '#6E747A'"
                />
              </svg>
            </b-btn>
            <b-btn
              v-b-tooltip="{customClass: 'on-small-screen', title: 'Blocks'}"
              :variant="toolbarPage === 'blocks' ? 'primary' : 'white'"
              class="btn-with-icon font-weight-bold px-3"
              @click="$store.commit('editor/SET_PAGE', 'blocks')"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.1 8.3C16.1 12.6079 12.6079 16.1 8.3 16.1C3.99218 16.1 0.5 12.6079 0.5 8.3C0.5 3.99218 3.99218 0.5 8.3 0.5C12.6079 0.5 16.1 3.99218 16.1 8.3ZM8.3 15.8C12.4421 15.8 15.8 12.4421 15.8 8.3C15.8 4.15787 12.4421 0.8 8.3 0.8C4.15787 0.8 0.8 4.15787 0.8 8.3C0.8 12.4421 4.15787 15.8 8.3 15.8Z"
                  :stroke="toolbarPage === 'blocks' ? '#fff' : '#6E747A'"
                />
                <path
                  d="M8.53594 7.26844V7.76844H9.03594H11.3999V8.03244H9.03594H8.53594V8.53244V11.2804H8.14392V8.53244V8.03244H7.64392H5.26392V7.76844H7.64392H8.14392V7.26844V4.64844H8.53594V7.26844Z"
                  :stroke="toolbarPage === 'blocks' ? '#fff' : '#6E747A'"
                />
              </svg>

              <span class="d-none d-xxl-block ml-2">Blocks</span>
            </b-btn>
            <b-btn
              v-b-tooltip="{customClass: 'on-small-screen', title: 'Rows'}"
              :variant="toolbarPage === 'add-row' ? 'primary' : 'white'"
              class="btn-with-icon font-weight-bold px-3"
              @click="$store.commit('editor/SET_PAGE', 'add-row')"
            >
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.50001 0.699951H16.5C17.4941 0.699951 18.3 1.50584 18.3 2.49995C18.3 3.49406 17.4941 4.29995 16.5 4.29995H2.50001C1.5059 4.29995 0.700012 3.49407 0.700012 2.49995C0.700012 1.50584 1.5059 0.699951 2.50001 0.699951ZM2.50001 1.99995C2.22387 1.99995 2.00001 2.22381 2.00001 2.49995C2.00001 2.77609 2.22387 2.99995 2.50001 2.99995H16.5C16.7762 2.99995 17 2.77609 17 2.49995C17 2.22381 16.7762 1.99995 16.5 1.99995H2.50001Z"
                  :fill="toolbarPage === 'add-row' ? '#fff' : '#6E747A'"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.50001 5.69995H16.5C17.4941 5.69995 18.3 6.50584 18.3 7.49995C18.3 8.49406 17.4941 9.29995 16.5 9.29995H2.50001C1.5059 9.29995 0.700012 8.49407 0.700012 7.49995C0.700012 6.50584 1.5059 5.69995 2.50001 5.69995ZM2.50001 6.99995C2.22387 6.99995 2.00001 7.22381 2.00001 7.49995C2.00001 7.77609 2.22387 7.99995 2.50001 7.99995H16.5C16.7762 7.99995 17 7.77609 17 7.49995C17 7.22381 16.7762 6.99995 16.5 6.99995H2.50001Z"
                  :fill="toolbarPage === 'add-row' ? '#fff' : '#6E747A'"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.50001 10.7H16.5C17.4941 10.7 18.3 11.5058 18.3 12.5C18.3 13.4941 17.4941 14.3 16.5 14.3H2.50001C1.5059 14.3 0.700012 13.4941 0.700012 12.5C0.700012 11.5058 1.5059 10.7 2.50001 10.7ZM2.50001 12C2.22387 12 2.00001 12.2238 2.00001 12.5C2.00001 12.7761 2.22387 13 2.50001 13H16.5C16.7762 13 17 12.7761 17 12.5C17 12.2238 16.7762 12 16.5 12H2.50001Z"
                  :fill="toolbarPage === 'add-row' ? '#fff' : '#6E747A'"
                />
              </svg>

              <span class="d-none d-xxl-block ml-2">Rows</span>
            </b-btn>
            <b-btn
              v-b-tooltip="{customClass: 'on-small-screen', title: 'Elements'}"
              :variant="toolbarPage === 'add-element' ? 'primary' : 'white'"
              class="btn-with-icon font-weight-bold px-3"
              @click="$store.commit('editor/SET_PAGE', 'add-element')"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 1H2.5C1.67156 1 1 1.67156 1 2.5V15.5C1 16.3284 1.67156 17 2.5 17H15.5C16.3284 17 17 16.3284 17 15.5V2.5C17 1.67156 16.3284 1 15.5 1ZM16 15.5C16 15.7762 15.7762 16 15.5 16H2.5C2.22384 16 2 15.7762 2 15.5V2.5C2 2.22384 2.22384 2 2.5 2H15.5C15.7762 2 16 2.22384 16 2.5V15.5Z"
                  :fill="toolbarPage === 'add-element' ? '#fff' : '#6E747A'"
                  :stroke="toolbarPage === 'add-element' ? '#fff' : '#6E747A'"
                  stroke-width="0.4"
                />
                <path
                  d="M11.8551 9.64505C11.66 9.45117 11.3451 9.45117 11.1501 9.64505L10.0001 10.795L7.85506 8.65004C7.66003 8.45617 7.34509 8.45617 7.15006 8.65004L3.65006 12.15C3.45675 12.3472 3.45984 12.6638 3.65703 12.8571C3.74874 12.947 3.87159 12.9982 4.00006 13H14.0001C14.2762 13.0016 14.5013 12.7791 14.503 12.503C14.5037 12.3686 14.4504 12.2396 14.3551 12.145L11.8551 9.64505Z"
                  :fill="toolbarPage === 'add-element' ? '#fff' : '#6E747A'"
                />
                <path
                  d="M10.5 8C11.3284 8 12 7.32843 12 6.5C12 5.67157 11.3284 5 10.5 5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8Z"
                  :fill="toolbarPage === 'add-element' ? '#fff' : '#6E747A'"
                />
              </svg>

              <span class="d-none d-xxl-block ml-2">Elements</span>
            </b-btn>
            <b-btn
              v-b-tooltip="{title: 'Save'}"
              variant="white"
              class="btn-with-icon no-focus border-right-0 on-save px-3"
              @click="savePage"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.8404 0H1.75781C0.788555 0 0 0.788555 0 1.75781V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V2.15965L12.8404 0ZM3.51562 1.17188H10.2539V4.62891H3.51562V1.17188ZM11.4844 13.8281H3.51562V9.19922H11.4844V13.8281ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H12.6562V8.02734H2.34375V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H2.34375V5.80078H11.4258V1.17188H12.355L13.8281 2.64504V13.2422Z"
                  fill="#6E747A"
                />
                <path
                  d="M9.19922 1.72852H8.02734V4.04297H9.19922V1.72852Z"
                  fill="#6E747A"
                />
              </svg>
              <template v-if="getSections.length">
                <i
                  v-if="saved"
                  class="fa fa-check-circle text-primary font-size-16 ml-2"
                />
              </template>
            </b-btn>
            <b-btn
              v-b-tooltip="{title: 'Preview'}"
              variant="white"
              class="btn-with-icon font-weight-bold button-divider px-3"
              @click="preview"
            >
              <icon icon="eye-3.svg" />
            </b-btn>
          </template>
          <b-btn
            v-else
            variant="secondary"
            class="btn-with-icon font-weight-bold px-3"
            @click="inspect"
          >
            Edit
          </b-btn>
          <b-btn
            v-b-tooltip="{customClass: 'on-small-screen', title: 'Publish'}"
            v-b-modal.modal-publishNow
            variant="primary"
            class="btn-with-icon font-weight-bold px-3"
          >
            <svg
              class="mr-2"
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49998 1.83333C6.54397 1.83333 4.95831 3.41899 4.95831 5.375C4.95831 5.7662 4.64118 6.08333 4.24998 6.08333C2.98457 6.08333 2.12498 6.96205 2.12498 7.85417C2.12498 8.74628 2.98457 9.625 4.24998 9.625C4.64118 9.625 4.95831 9.94213 4.95831 10.3333C4.95831 10.7245 4.64118 11.0417 4.24998 11.0417C2.38578 11.0417 0.708313 9.70046 0.708313 7.85417C0.708313 6.23132 2.00432 4.9987 3.58398 4.72412C3.90271 2.2933 5.98219 0.416668 8.49998 0.416668C11.0178 0.416668 13.0972 2.2933 13.416 4.72412C14.9956 4.9987 16.2916 6.23132 16.2916 7.85417C16.2916 9.70046 14.6142 11.0417 12.75 11.0417C12.3588 11.0417 12.0416 10.7245 12.0416 10.3333C12.0416 9.94213 12.3588 9.625 12.75 9.625C14.0154 9.625 14.875 8.74628 14.875 7.85417C14.875 6.96205 14.0154 6.08333 12.75 6.08333C12.3588 6.08333 12.0416 5.7662 12.0416 5.375C12.0416 3.41899 10.456 1.83333 8.49998 1.83333Z"
                fill="white"
              />
              <path
                d="M11.1258 8.7092C10.8492 8.98582 10.4007 8.98582 10.1241 8.7092L9.20831 7.7934V13.875C9.20831 14.2662 8.89118 14.5833 8.49998 14.5833C8.10878 14.5833 7.79165 14.2662 7.79165 13.875V7.7934L6.87585 8.7092C6.59923 8.98582 6.15073 8.98582 5.87411 8.7092C5.59749 8.43258 5.59749 7.98409 5.87411 7.70747L7.99911 5.58247C8.13195 5.44963 8.31212 5.375 8.49998 5.375C8.68784 5.375 8.86801 5.44963 9.00085 5.58247L11.1258 7.70747C11.4025 7.98409 11.4025 8.43258 11.1258 8.7092Z"
                fill="white"
              />
            </svg>

            <span class="d-none d-xxl-block mr-2">Publish</span>
          </b-btn>
        </b-btn-group>
      </b-navbar-nav>
    </div>

    <publish-modal />
    <assets-lib-modal :modal.sync="mediaModal" />
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RouterMixin from '../../mixins/RouterMixin'
import PublishModal from '../modals/PublishModal'
import Icon from '@/components/editor/utils/Icon'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import AssetsLibModal from '@/components/assets-library/AssetsLibModal.vue'
import pageAudit from '../../../utils/page-audit'
import ModuleSelectMenu from '@/components/editor/components/module-select-menu/ModuleSelectMenu.vue'
// import Cookie from 'js-cookie'

export default {
  name: 'EditorHeader',
  components: {ModuleSelectMenu, PagesSelectMenu, PublishModal, AssetsLibModal, Icon},
  mixins: [RouterMixin, PagesPaginationMixin],

  data () {
    return {
      url: window.location.origin + '/',
      mediaModal: false,
      searching: false,
      searchedPages: [],
      saved: false,
      modal:false
    }
  },

  computed: {
    ...mapState('integrationExclusions', {
      integrationsData: state => state.list
    }),

    selectPages: {
      get () {
        return this.searchedPages.length
          ? this.searchedPages
          : [...this.pages.data]
      },

      set (val) {
        this.searchedPages = val
      }
    },

    ...mapState('projects', {
      project: state => state.project
    }),

    ...mapState('editor', {
      resolution: (state) => state.resolution,
      tablet: (state) => state.tablet,
      toolbarPage: (state) => state.page,
      element: (state) => state.element,
      section: (state) => state.section
    }),

    ...mapGetters('pages', {
      pages: 'getPages'
    }),

    ...mapState('pages', {
      currentPage: state => state.page
    }),

    ...mapState('historyChanges', {
      undo: (state) => state.undo,
      redo: (state) => state.redo
    }),

    ...mapGetters('sections', {
      getSections: 'getSections'
    }),

    page () {
      return this.$store.state.pages.page
    },

    isPereview () {
      return this.$route.name === 'preview'
    }
  },

  watch: {
    pageIsLoading (val) {
      this.searching = val
    }
  },

  mounted () {
    document.addEventListener('scroll', this.onScroll)
  },

  destroyed () {
    document.removeEventListener('scroll', this.onScroll)
  },

  beforeCreate () {
    this.selectPages = []
  },

  methods: {
    ...mapActions({
      updateIntegrationExclusions:'integrationExclusions/update'
    }),
    loadUndo () {
      this.$store.dispatch('historyChanges/loadUndo')
      this.$emit('undo')
    },
    loadRedo () {
      this.$store.dispatch('historyChanges/loadRedo')
      this.$emit('redo')
    },
    onSelectPage (page) {
      if (page.href) {
        return window.open(page.href)
      }

      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save').then(() => {
        setTimeout(() => {
          this.$store.dispatch('pages/loadPage', page.id).finally(() => {
            this.$store.commit('editor/SET_PAGE', 'sections')

            setTimeout(() => {
              VEvent.fire('loader', false)
            }, 400)
          })
        }, 100)
      })
    },
    onScroll () {},
    savePage () {
      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save', true)
        .then(async () => {
          pageAudit(this.getSections)

          await this.savePageIntegrationExclusions()
          this.saved = true
          setTimeout(() => {
            this.saved = false
          }, 1000)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Server error',
            text: 'Please try again later or let us know about your problem.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    publishNow () {
      VEvent.fire('loader', true)

      this.$store.dispatch('projects/publish').then(() => {
        VEvent.fire('loader', false)
        this.published = true
      })
    },
    newPageModal () {
      VEvent.fire('newPageModal')
    },
    async onOpen () {
      if (this.pages.data.length / 25 <= 1 && this.currentPaginationPage > 1) {
        this.currentPaginationPage = 0
      }

      setTimeout(() => {
        document
          .querySelector('.vs__dropdown-menu')
          .addEventListener('scroll', this.scrollLoadingPages)
      })
    },
    onClose () {
      document
        .querySelector('.vs__dropdown-menu')
        .removeEventListener('scroll', this.scrollLoadingPages)
    },
    async preview () {
      window.open(`https://${this.project.subdomain}.estage.site/${this.currentPage.slug}?preview=${this.currentPage.hash}`)
      // VEvent.fire('loader', true)
      //
      // document
      //   .querySelectorAll('#wf_script')
      //   .forEach((item) => item.parentNode.removeChild(item))
      // delete window._wf
      //
      // this.$store
      //   .dispatch('projects/save')
      //   .then(() => {
      //     this.$store.commit('editor/SET_OPENED_MENU', null)
      //     this.$store.commit('editor/SET_TIPTAP', null)
      //     this.$router.push('/builder/' + 'preview/' + this.$route.params.id)
      //   })
      //   .finally(() => {
      //     VEvent.fire('loader', false)
      //   })
    },
    async onSearch (search) {
      if (search.length) {
        this.searching = true
        const pages = await axios.get(
          `api/projects/${this.project.id}/pages?q=${search}`
        )
        this.selectPages = pages.data.data.data
        this.searching = false
      } else {
        this.selectPages = JSON.parse(JSON.stringify(this.pages.data))
      }
    },
    async inspect () {
      VEvent.fire('loader', true)

      this.$store
        .dispatch('projects/save')
        .then(() => {
          this.$store.commit('editor/SET_OPENED_MENU', null)
          this.$store.commit('editor/SET_TIPTAP', null)
          this.$router.push('/builder/' + this.$route.params.id)
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    setResolution (resolution) {
      document.querySelector('.es-root').style.width = null

      if (resolution === 'md') {
        this.$store.commit('editor/SET_TABLET', true)
        this.$store.commit('editor/SET_RESOLUTION', 'sm')
      } else {
        this.$store.commit('editor/SET_TABLET', false)
        this.$store.commit('editor/SET_RESOLUTION', resolution)
      }

      if (!this.section) return

      setTimeout(() => {
        const element =
          this.element ||
          document.querySelector(`[data-uid="${this.section.uid}"]`)
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }, 100)
    },
    async savePageIntegrationExclusions (){
      const {project_id,page_id,loading,data} = this.integrationsData
      if(project_id == this.project.id && page_id == this.currentPage.id && !loading && data.length){
        const params = {
          project_id,
          page_id,
          data:data.map((d)=> {
            return {
              integration_connection_id:d.integration_connection_id,
              is_excluded:Boolean(d.is_excluded)
            }
          })
        }
        try {
          await this.updateIntegrationExclusions(params)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .editor-header {
    .v-select.custom-select:not(.select-tags) {
      height: 36px !important;
    }
  }

  .on-hover {
    .overlay {
      display: none;
    }

    &:hover {
      .overlay {
        display: block;
      }
    }
  }

  .on-save {
    .progress {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      transform: rotate(45deg);
      background: white;
    }

    .progress::before {
      content: "";
      position: absolute;
      inset: -14px;
      border-radius: 50%;
      border: 16px solid #4f83e3;
      animation: progress-1tucza 20s infinite linear;
    }

    @keyframes progress-1tucza {
      0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
      }

      25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
      }

      50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
      }

      75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }

      100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
      }
    }
  }

  @media only screen and (min-width: 1450px) {
    .tooltip.b-tooltip.on-small-screen {
      display: none !important;
    }
  }
</style>
