<template>
  <div
    :data-sid="`form-field-${sid}`"
    :class="`--${section.sid}--margin`"
    class="form-margin"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      section-type="element"
      class="highlight-element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="estage-form-element-group mb-0"
        :class="`--${sid}--form-field`"
      >
        <template
          v-if="control.label"
          #label
          class="flex items-center gap-x-[4px]"
          :class="`--${sid}--label`"
        >
          {{ control.label }}
          <span
            v-if="control.is_required"
            class="text-danger"
          >
            *
          </span>
        </template>
  
        <div class="flex items-center">
          <div class="flex items-center justify-center phone-code-select cursor-pinter text-nowrap">
            <div class="flex items-center">
              <img
                width="18"
                height="13"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                alt="US"
              >
              <i class="fa fa-angle-down ml-2" />
            </div>
  
            <div>+1</div>
          </div>
  
          <legend class="d-none active" />
    
          <b-form-input
            v-model="control.value"
            :placeholder="control.placeholder"
            :required="!!control.is_required"
            type="tel"
            v-bind="$attrs"
            class="input"
            :class="`--${sid}--input`"
          />
        </div>
      </b-form-group>
    </highliter>
  </div>
</template>

<script>
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldPhone',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  computed: {
    control () {
      return this.section.options.content.control
    },
    code: {
      get () {
        return this.control.code ? this.control.code : { 'name': 'United States', 'dial_code': '+1', 'code': 'US' }
      },
      set (val) {
        this.$set(this.control, 'code', val)
      }
    }
  }
}
</script>