var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Header"}},[_c('b-form-group',{staticClass:"mb-5"},[_c('b-checkbox',{model:{value:(_vm.bringToFront),callback:function ($$v) {_vm.bringToFront=$$v},expression:"bringToFront"}},[_vm._v(" bring to front / overlay page content ")])],1),_c('b-form-group',{staticClass:"mb-0"},[_c('sid-background',{attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`}})],1),_c('sidbear-settings-group',{attrs:{"title":"Border & Radius"}},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"label":"Border Width","rule-name":"border","angles":[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Radius","sid":`.--${_vm.section.sid}--es-navbar-bg`,"rule-name":"border","angles":[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]}})],1),_c('sidbear-settings-group',{attrs:{"title":"Drop Shadow"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Shadow Color"}},[_c('sid-box-shadow',{attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"rule-name":"box-shadow"}})],1)],1),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Z-Index")]),_c('sid-input',{staticClass:"!w-[59px]",attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"rule-name":"z-index","default-dimension":""}})],1)]),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Position")]),_c('sid-select',{staticClass:"w-[138px]",attrs:{"sid":`.--${_vm.section.sid}--es-navbar-bg`,"priority":true,"rule-name":"position","searchable":false,"options":[
            {
              label: 'Static',
              value: ''
            },
            {
              label: 'Relative',
              value: 'relative'
            },
            {
              label: 'Fixed',
              value: 'fixed',
            },
            {
              label: 'Absolute',
              value: 'absolute',
            }
          ]}})],1)])],1),(!_vm.section.localNavbar)?_c('SidbearSettingsGroup',{attrs:{"content-class":"pb-0","title":"Scroll Effect"}},[_c('navbar-scroller-customs',{attrs:{"config":_vm.section.options.customize.style.navbarScroller}})],1):_vm._e(),_c('SidbearSettingsGroup',{attrs:{"title":"Navbar Layout","content-class":"pt-0 border-0 pb-0","active":""}},[_c('flex-mod-v2',{attrs:{"component-name":"Header Layout","config":_vm.section.options.content.subject,"hide":['visibility'],"local-section":_vm.section.options.content.subject,"custom-back-options":{openedItem: 'MenuItemsV2Mod', section: _vm.section}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }