var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Post List","active":""}},[_c('b-form-group',_vm._l((_vm.displayItems),function(_,index){return _c('b-checkbox',{key:index,attrs:{"switch":""},model:{value:(_vm.displayItems[index]),callback:function ($$v) {_vm.$set(_vm.displayItems, index, $$v)},expression:"displayItems[index]"}},[_vm._v(" "+_vm._s(_vm.startCase(index))+" ")])}),1),_c('b-form-group',[_c('sid-flex-direction',{attrs:{"sid":_vm.className(),"rule-name":"flex-direction"},scopedSlots:_vm._u([{key:"column",fn:function(){return [_c('sid-buttons-group',{attrs:{"label":"Horizontal Alignment","options":[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ],"sid":_vm.className(),"rule-name":"align-items"}})]},proxy:true},{key:"row",fn:function(){return [_c('sid-buttons-group',{staticClass:"mb-0",attrs:{"label":"Horizontal Alignment","options":[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'space-between',
                tooltip: 'Between'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'space-around',
                tooltip: 'Around'
              }
            ],"sid":_vm.className(),"rule-name":"justify-content"}})]},proxy:true}])})],1),_c('sid-range-slider',{attrs:{"label":"Gap","sid":_vm.className(),"rule-name":"gap","default-dimension":"px","dimensions":[]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Title Text"}},[_c('rule-tabs',{attrs:{"tabs":['Normal', 'Active', 'Hover']},scopedSlots:_vm._u([{key:"Normal",fn:function(){return [_c('b-form-group',{attrs:{"label":"Color"}},[_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a .title')}})],1),_c('sid-font',{attrs:{"sid":_vm.className(' a .title')}}),_c('sid-range-slider',{attrs:{"label":"Font Size","sid":_vm.className(' a .title'),"rule-name":"font-size","default-dimension":"px","dimensions":[]}}),_c('sid-linked-group',{attrs:{"label":"Padding","sid":_vm.className(' a .title'),"rule-name":"padding","min":0,"angles":[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]}}),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Margin","sid":_vm.className(' a .title'),"rule-name":"margin","min":-2000,"max":2000,"angles":[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]}})]},proxy:true},{key:"Hover",fn:function(){return [_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a:hover .title'),"presudo":":hover"}})]},proxy:true},{key:"Active",fn:function(){return [_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a.active .title')}})]},proxy:true}])})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Secondary Text"}},[_c('rule-tabs',{attrs:{"tabs":['Normal', 'Active', 'Hover']},scopedSlots:_vm._u([{key:"Normal",fn:function(){return [_c('b-form-group',{attrs:{"label":"Color"}},[_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a .secondary')}})],1),_c('sid-font',{attrs:{"sid":_vm.className(' a .secondary')}}),_c('sid-range-slider',{attrs:{"label":"Font Size","sid":_vm.className(' a .secondary'),"rule-name":"font-size","default-dimension":"px","dimensions":[]}}),_c('sid-linked-group',{attrs:{"label":"Padding","sid":_vm.className(' .secondary'),"rule-name":"padding","min":0,"angles":[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]}}),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Margin","sid":_vm.className(' .secondary'),"rule-name":"margin","min":-2000,"max":2000,"angles":[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]}})]},proxy:true},{key:"Hover",fn:function(){return [_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a:hover .secondary'),"presudo":":hover"}})]},proxy:true},{key:"Active",fn:function(){return [_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' .active a .secondary')}})]},proxy:true}])})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Icon"}},[_c('b-form-group',[_c('icon-select',{model:{value:(_vm.section.options.content.data.icon),callback:function ($$v) {_vm.$set(_vm.section.options.content.data, "icon", $$v)},expression:"section.options.content.data.icon"}})],1),_c('b-form-group',{attrs:{"label":"Color"}},[_c('sid-color',{attrs:{"rule-name":"color","sid":_vm.className(' a .icon')}})],1),_c('sid-range-slider',{attrs:{"label":"Icon Size","sid":_vm.className(' a .icon'),"rule-name":"font-size","default-dimension":"px","dimensions":[]}}),_c('sid-linked-group',{attrs:{"label":"Padding","sid":_vm.className(' a .icon'),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Margin","sid":_vm.className(' a .icon'),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":_vm.className(),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Margin","sid":_vm.className(),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }