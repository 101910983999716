<template>
  <div
    class="el-flex-wrapper d-flex justify-content-center position-relative w-full p-0 gap-0"
    :class="[`--${section.sid}--container`, `--${section.sid}--flex`, {'element-hidden': !subjectVisability(section.display)}]"
    :data-uid="section.uid"
  >
    <div
      class="block-bg inherit-radius"
      :class="`--${section.sid}--bg`"
      :aria-label="section.options.bg_alt || undefined"
    />

    <div class="el-overlay inherit-radius" />

    <shape-divider
      :config="section.options"
    />

    <div class="mx-auto w-100">
      <highliter
        v-model="section.name"
        :dublicate="section"
        :uid="section.uid"
        class="el-row h-100"
        delay
        section-type="row"
        v-bind="highliterOptions"
        @active="state => active = state"
        @delete="$emit('delete')"
        @dublicate="$emit('dublicate')"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @add-subject="$emit('add-subject')"
        @click.stop.native="sectionSettings('flex-mod-v2')"
      >
        <div
          :data-sid="section.sid"
          class="position-relative scrollbar-hide h-100"
          :class="[{'element-hidden': !subjectVisability(section.options.content.display)}]"
        >
          <div
            class="block-bg flex overflow-hidden"
            :class="`--lottie-container-${section.sid}`"
          >
            <button-lottie-icon
              v-if="section.options.content.lottie"
              :config="section.options.content"
            />
          </div>
          
          <slot />

          <div
            v-if="!getColumns.length"
            class="empty-row-drag h-[200px] p-[20px]"
            :class="`--${section.sid}--flex`"
            @click="addNewColumn"
          />

          <div
            v-else
            :key="`getColumns--${getColumns.length}`"
            class="flex flex-wrapper p-[20px] !max-w-full w-full"
            :class="`--${section.sid}--flex`"
          >
            <flex-wrapper-col-v2
              v-for="(col, index) in getColumns"
              :key="`${col._uid}-${index}`"
              :col="col"
              :index="index"
              :section="section"
              :class="{'element-hidden': !subjectVisability(col.display)}"
              :sub-el-props="subElProps"
              @flow="handleFlow"
              @delete="deleteColumn(index, col)"
              @copy="copyColumn(col)"
            />
          </div>
        </div>
      </highliter>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@/components/mixins/SectionMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import Highliter from '@/components/builder/utils/Highliter'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import FlexScrollMixin from '@/components/builder/sections/utils/el-inline/flex-mixins/FlexScrollMixin'
import FlexWrapperColV2 from '@/components/builder/sections/utils/el-inline/FlexWrapperColV2'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import SidStyles from '@/components/mixins/SidStyles'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon.vue'

export default {
  components: {ButtonLottieIcon, ShapeDivider, FlexWrapperColV2, Highliter},
  mixins: [SectionMixin,
    SidStyles,
    CustomStylesMixin,
    ResolutionMixin,
    BorderMixin,
    FlexScrollMixin],

  props: {
    highliterOptions: {
      type: Object,
      default: () => {}
    },
    subElProps: {
      type: Object,
      required: false
    }
  },

  computed: {
    getColumns () {
      return _.sortBy(this.section.options.content.cols, [(o) => { return _.get(o, ['sortIndex', this.resolutioner], 0) }])
    }
  },

  methods: {
    deleteColumn (index, column) {
      const _index = this.section.options.content.cols.findIndex(col => _.get(col, ['sortIndex', this.resolutioner], null) === _.get(column, ['sortIndex', this.resolutioner], undefined))
      if (_index !== -1) {
        this.section.options.content.cols.splice(_index, 1)
      }

      if (column) {
        this.clearStyles(document.querySelector(`[data-sid="${column.sid}"]`))
      }
    },
    copyColumn (col) {
      const maxSortIndexLg = Math.max(...this.section.options.content.cols.map(item => item.sortIndex.lg)) + 1
      const maxSortIndexMd = Math.max(...this.section.options.content.cols.map(item => item.sortIndex.md)) + 1
      const maxSortIndexSm = Math.max(...this.section.options.content.cols.map(item => item.sortIndex.sm)) + 1
      this.section.options.content.cols.push(_.cloneDeep({
        ...col,
        sortIndex: {
          lg: maxSortIndexLg,
          md: maxSortIndexMd,
          sm: maxSortIndexSm
        }
      }))
    },
    addNewColumn () {
      this.section.options.content.cols.push(col)
    }
  }
}
</script>

<style lang="scss">
.flex-col-placeholder .empty-row-drag {
  height: 100% !important;
}

.el-flex-wrapper {
  --height:auto;
  --width:auto;

  max-width: var(--width);
}

.flex-wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.prevent-select {
  &:before {
    content: '';
    width: 200px;
    height: 200px;
    background: transparent;
    position: absolute;
    top: -100px;
    left: -100px;
    z-index: 1;
  }
}
</style>
