<template>
  <settings-page-card title="Business Details">
    <div class="px-4 max-w-[900px] mb-[48px]">
      <p class="text-dark font-size-16">
        Your business details are required to sell products or accept payments for this project. This information will
        be public and might be displayed on customer receipts after they make a purchase.
      </p>
    </div>

    <b-form @submit.prevent="onSubmit">
      <div class="px-4">
        <div class="max-w-[600px]">
          <div class="mb-4">
            <label class="font-weight-sami-bold !text-[14px] text-dark">
              Business Logo
            </label>
            <div class="w-[370px]">
              <image-upload
                :image="image"
                class="w-100 mb-3"
                :upload-button-class="{'form-control is-invalid !border-[#dc3545]': !init && !businessDetails.logo}"
                @uploaded="uploaded"
                @imageDeleted="imageDeleted"
              />
              <small class="text-muted">Upload a 400 x 400 pixel <br> PNG, SVG, or JPEG</small>
            </div>
          </div>

          <b-row>
            <b-col md="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Business Name
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.name"
                  v-validate="'required'"
                  name="name"
                  class="!h-[37px]"
                  :state="errors.has('name') ? false : null"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="10">
              <b-form-group class="mb-4">
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Address
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.address"
                  v-validate="'required'"
                  name="address"
                  class="!h-[37px]"
                  :state="errors.has('name') ? false : null"
                />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-row>
                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        Country
                      </label>
                    </slot>
                    <country-select
                      v-model="businessDetails.country"
                      v-validate="'required'"
                      class="!h-[37px]"
                      :class="{'form-control is-invalid': !init && !businessDetails.country}"
                      name="country"
                      :state="errors.has('country') ? false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        City
                      </label>
                    </slot>
                    <b-input
                      v-model="businessDetails.city"
                      v-validate="'required'"
                      class="!h-[37px]"
                      name="city"
                      :state="errors.has('city') ? false : null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="10">
              <b-row>
                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        State
                      </label>
                    </slot>
                    <state-select
                      v-model="businessDetails.state"
                      v-validate="'required'"
                      :country="businessDetails.country"
                      class="!h-[37px]"
                      :class="{'form-control is-invalid': !init && !businessDetails.state}"
                      name="state"
                      :state="errors.has('state') ? false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group class="mb-4">
                    <slot name="label">
                      <label class="font-weight-sami-bold !text-[14px] text-dark">
                        Zip Code
                      </label>
                    </slot>
                    <b-input
                      v-model="businessDetails.zip_code"
                      v-validate="'required'"
                      class="!h-[37px]"
                      name="zip_code"
                      :state="errors.has('zip_code') ? false : null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Support email
                  </label>
                </slot>
                <b-input
                  v-model="businessDetails.support_email"
                  type="email"
                  name="email"
                  class="!h-[37px]"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="phone-number-control align-items-center">
            <b-col cols="10">
              <b-form-group>
                <slot name="label">
                  <label class="font-weight-sami-bold !text-[14px] text-dark">
                    Support Number
                  </label>
                </slot>
                <b-input-group class="align-items-center">
                  <template #prepend>
                    <div style="width: 60px;">
                      <phone-code-select
                        v-model="businessDetails.support_phone_code"
                        class="!h-[37px]"
                      />
                    </div>
                  </template>
                  <div class="dial-code !h-[37px]">
                    {{ businessDetails.support_phone_code.dial_code || '' }}
                  </div>
                  <b-form-input
                    v-model="businessDetails.support_phone"
                    :maxlength="15"
                    class="!h-[37px]"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-btn
          variant="primary"
          type="submit"
          class="mt-[12px]"
        >
          Save Changes
        </b-btn>
      </div>
    </b-form>
  </settings-page-card>
</template>
<script>
import { mapState } from 'vuex'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard.vue'
import CountrySelect from '@/layouts/dashboard/compontens/account/general/components/CountrySelect.vue'
import StateSelect from '@/layouts/dashboard/compontens/account/general/components/StateSelect.vue'
import PhoneCodeSelect from '@/layouts/dashboard/compontens/account/general/components/PhoneCodeSelect.vue'
import ImageUpload from '@/components/editor/utils/ImageUpload.vue'

export default {
  name: 'BusinessDetails',
  components: {
    ImageUpload,
    PhoneCodeSelect,
    StateSelect,
    CountrySelect,
    SettingsPageCard
  },

  data () {
    return {
      init: true,
      image: { src: '' }
    }
  },

  computed: {
    ...mapState('projects', {
      businessDetails: state => state.businessDetails
    }),

    isBusinessDetailsComplete () {
      if (!this.businessDetails) return false

      const details = this.businessDetails
      return Boolean(
        details.address &&
          details.city &&
          details.state &&
          details.country &&
          details.zip_code &&
          details.support_email &&
          details.support_phone_code &&
          details.support_phone
      )
    }
  },

  methods: {
    uploaded (image) {
      this.toggleLoader(true)
      this.businessDetails.logo = image.src
      this.toggleLoader(false)
    },

    imageDeleted () {
      this.uploaded({ src: '' })
    },

    toggleLoader (show) {
      VEvent.fire('loader', show)
    },

    showNotification (config) {
      this.$swal({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        iconColor: '#4F83E3',
        ...config
      })
    },

    getBusinessInfoPayload () {
      const {
        support_email,
        support_phone,
        support_phone_code,
        logo,
        name,
        address,
        country,
        city,
        state,
        zip_code
      } = this.businessDetails

      return {
        business_info: {
          support_email,
          support_phone,
          support_phone_code,
          logo,
          name,
          address,
          country,
          city,
          state,
          zip_code
        }
      }
    },

    async submitBusinessInfo () {
      try {
        this.toggleLoader(true)
        await axios.post(
          `/api/projects/${this.$route.params.id}/business-info`,
          this.getBusinessInfoPayload()
        )

        this.showNotification({
          icon: 'success',
          title: 'Success'
        })
      } catch (error) {
        const errors = error.response?.data?.errors || {}
        const errorMsg = Object.values(errors)[0] || error.response?.data?.message

        this.showNotification({
          icon: 'error',
          title: errorMsg
        })
      } finally {
        this.toggleLoader(false)
      }
    },

    async onSubmit () {
      this.init = false
      const isValid = await this.$validator.validateAll()

      if (isValid && this.businessDetails.logo) {
        await this.submitBusinessInfo()
      }
    },

    async fetchUserProfile () {
      try {
        const { data } = await axios.get('/api/user/profile')
        const profile = data.data
        const shipping = profile.shipping_information || {}

        if (!this.isBusinessDetailsComplete) {
          this.updateBusinessDetailsFromProfile(profile, shipping)
        }
      } catch (error) {
        console.error('Error fetching profile:', error)
      }
    },

    updateBusinessDetailsFromProfile (profile, shipping) {
      const details = this.businessDetails

      details.address = details.address || shipping.address || ''
      details.city = details.city || shipping.city || ''
      details.state = details.state || (shipping.state_id ? Number(shipping.state_id) : '')
      details.country = details.country || (shipping.country_id ? Number(shipping.country_id) : '')
      details.zip_code = details.zip_code || shipping.zip_code || ''

      details.support_email = profile.email
      details.support_phone_code = JSON.parse(profile.phone_code) || {
        name: 'United States',
        dial_code: '+1',
        code: 'US'
      }
      details.support_phone = profile.phone
    }
  },

  async mounted () {
    this.image.src = this.businessDetails.logo
    if (!this.isBusinessDetailsComplete) {
      await this.fetchUserProfile()
    }
  }
}
</script>