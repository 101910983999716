var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{staticClass:"w-full flex el-form-wrapper",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.confirmation)?_c('elements',{staticClass:"h-100",attrs:{"product":_vm.product,"classes":`--${_vm.section.sid}--form flex flex-col`,"config":_vm.section.options.content.orderForm,"form":_vm.form,"drag-height":"150px","sub-el-props":{
      form: _vm.form,
      products: _vm.products
    }},on:{"flow":_vm.handleFlow},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('editor/SET_PAGE', 'add-element')}},model:{value:(_vm.section.options.content.orderForm.confirmation[0].content),callback:function ($$v) {_vm.$set(_vm.section.options.content.orderForm.confirmation[0], "content", $$v)},expression:"section.options.content.orderForm.confirmation[0].content"}}):_c('elements',{staticClass:"h-100",attrs:{"product":_vm.product,"classes":`--${_vm.section.sid}--form flex flex-col`,"config":_vm.section.options.content.orderForm,"form":_vm.form,"submit-section":_vm.submitSection,"drag-height":"150px","gdpr":_vm.gdpr,"sub-el-props":{
      form: _vm.form,
      steps: _vm.section.options.content.orderForm.steps.length,
      products: _vm.products,
      'submit-section': _vm.submitSection,
      gdpr: _vm.gdpr
    }},on:{"flow":_vm.handleFlow},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('editor/SET_PAGE', 'add-element')}},model:{value:(_vm.step.content),callback:function ($$v) {_vm.$set(_vm.step, "content", $$v)},expression:"step.content"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }