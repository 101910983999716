<template>
  <div
    :style="styles"
    class="w-100"
  >
    <draggable
      v-model="elements"
      :class="[{'empty-row-drag': !elements.length}, classes]"
      :group="{ name: group, pull: true, put }"
      :style="{'min-height': !elements.length ? dragHeight: null, 'min-width': !elements.length ? dragHeight: null}"
      handle=".move"
      :ghost-class="`${group}-sortable-ghost`"
      :scroll="true"
      :force-fallback="true"
      :fallback-class="`${group}-sortable-fallback`"
      :scroll-sensitivity="60"
      :force-autoscroll-fallback="true"
      :bubble-scroll="true"
      :fallback-on-body="true"
      @click.native.stop="() => addSubject(-1, -1)"
      @end="onDragEnd"
      @start="onDragStart"
      @choose="onChoose"
      @add="index => $emit('add', index)"
    >
      <component
        :is="section.component"
        v-for="(section, elIndex) in elements"
        :key="section.uid"
        :section="section"
        :sub-el-props="subElProps"
        v-bind="{...$attrs, ...subElProps}"
        @delete="() => deleteElement(elIndex)"
        @dublicate="() => dublicateElement(elIndex)"
        @favorite="el => onAddToFavorite(el, elIndex)"
        @flow="() => handleFlow(section)"
        @move-to="to => moveTo(elIndex, to)"
        @add-subject="() => addSubject(elIndex, elIndex)"
      />
    </draggable>

    <add-to-favorite-modal
      :favorite="favoriteBlock"
      @submit="favorite"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {insert} from '../../../../sections/utils/helpers'
import shortid from 'shortid'
import DraggableElementsHandler from '../../../mixins/DraggableElementsHandler'
import AddToFavoriteModal from '../../components/modals/AddToFavoriteModal'
import {clonePart} from '@/utils/useStylesheet'

export default {
  name: 'Elements',
  components: {AddToFavoriteModal, draggable},
  mixins: [DraggableElementsHandler],
  props: {
    value: {
      type: Array,
      required: true
    },
    customAddSubject: {
      default: null
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    dragHeight: {
      type: String,
      default: '40px'
    },
    groupName: {
      type: String,
      default: 'elements'
    },
    classes: {
      type: [String, Object],
      default: ''
    },
    put: {
      type: Array,
      default: () => ['elements', 'rows', 'blocks']
    },
    subElProps: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      favoriteBlock: {},
      group: 'elements'
    }
  },
  computed: {
    elements: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onAddToFavorite (node, elIndex) {
      this.favoriteBlock = {
        section: JSON.parse(clonePart(this.elements[elIndex], node)),
        origSection: this.elements[elIndex],
        node
      }
    },
    handleFlow () {
      this.$emit('flow')
    },
    addSubject (index, elIndex) {
      if (this.customAddSubject) return this.customAddSubject(index, elIndex)

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: this,
          options: {index, elIndex, originKey: 'elements'}
        }
      })
    },
    deleteElement (element) {
      this.elements.splice(element, 1)
    },
    dublicateElement (element) {
      this.elements = insert(this.elements, element, JSON.parse(JSON.stringify({
        ...this.elements[element],
        uid: shortid.generate()
      })))
    },
    moveTo (element, to) {
      const copySection = JSON.parse(JSON.stringify(this.elements[element]))

      if (to === 'up') {
        if (element === 0) return
        this.elements.splice(element, 1)
        this.elements = insert(this.elements, element - 1, copySection)
      } else if (to === 'down') {
        if (element + 1 === this.elements.length) return
        this.elements.splice(element, 1)
        this.elements = insert(this.elements, element + 1, copySection)
      }
    },
    favorite (payload) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: payload.favorite,
          group: this.groupName
        },
        origSection: payload.origSection,
        inGlobal: payload.globally,
        inSharable: payload.sharable,
        node: payload.node
      })
        .finally(() => {
          this.favoriteBlock = {}
        })
    },
    onChoose (item) {
      if (item.item.querySelector('.el-row')) {
        return this.group = 'rows'
      }
      this.group = 'elements'
    }
  }
}
</script>
