var render = function render(){var _vm=this,_c=_vm._self._c;return _c('highliter',{staticClass:"highlight-element",attrs:{"section-type":"element","dublicate-deep":_vm.parentUID,"dublicate":_vm.section,"hide-bar":_vm.hideBar,"hide-add":_vm.hideAdd},on:{"favorite":function($event){return _vm.$emit('favorite', _vm.$el)},"flow":_vm.handleFlow,"move-to":to => _vm.$emit('move-to', to),"dublicate":function($event){return _vm.$emit('dublicate')},"delete":function($event){return _vm.$emit('delete')},"add-subject":function($event){return _vm.$emit('add-subject')}},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.sectionSettings('carousel')}},model:{value:(_vm.section.name),callback:function ($$v) {_vm.$set(_vm.section, "name", $$v)},expression:"section.name"}},[_c('div',{domProps:{"innerHTML":_vm._s(`
      <style>
.carousel-card-container {
  scrollbar-color: auto;
  scrollbar-width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem;
  scroll-timeline-name: --scroller;
  scroll-timeline-axis: inline;
  --carousel-scrollbar-height: 7px;
  --carouse-card-width: 300px;
}
.carousel-card-container::-webkit-scrollbar {
  height: var(--carousel-scrollbar-height);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.carousel-card-container::-webkit-scrollbar-track {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 8px;
  background-color: #ebebeb;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.carousel-card-container::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 8px;
  background-color: #a0a0a0;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.carousel-card-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.carousel-card-container .carousel-card-wrapper {
  display: flex;
  gap: 20px;
  perspective: 9000px;
  transform-style: preserve-3d;
  perspective-origin: 50% 50%;
}
.carousel-card-container .carousel-card-wrapper.animation-type-1 > .carousel-card {
  transition: 0.3s;
  min-width: var(--carouse-card-width, 300px);
  height: var(--carouse-card-height);
  animation: auto ease-in-out carousel-card-intro-1 forwards, auto ease-in-out carousel-card-outro-1 forwards;
  animation-timeline: view(inline);
  animation-range: entry, exit;
  transform-origin: bottom center;
}
.carousel-card-container .carousel-card-wrapper.animation-type-2 > .carousel-card {
  transition: 0.3s;
  min-width: var(--carouse-card-width, 300px);
  height: var(--carouse-card-height);
  animation: auto ease-in-out carousel-card-intro-2 forwards, auto ease-in-out carousel-card-outro-2 forwards;
  animation-timeline: view(inline);
  animation-range: entry, exit;
  transform-origin: bottom center;
}
.carousel-card-container .carousel-card-wrapper.animation-type-3 > .carousel-card {
  transition: 0.3s;
  min-width: var(--carouse-card-width, 300px);
  height: var(--carouse-card-height);
  animation: auto ease-in-out carousel-card-intro-3 forwards, auto ease-in-out carousel-card-outro-3 forwards;
  animation-timeline: view(inline);
  animation-range: entry, exit;
  transform-origin: bottom center;
}
@keyframes carousel-card-intro-1 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
  }
}
@keyframes carousel-card-outro-1 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
  }
}
@keyframes carousel-card-intro-2 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
    rotate: z -90deg;
    translate: 0 -100%;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
    rotate: z 0deg;
    translate: 0 0;
  }
}
@keyframes carousel-card-outro-2 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
  }
}
@keyframes carousel-card-intro-3 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
    translate: 0 -100%;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
    translate: 0 0;
  }
}
@keyframes carousel-card-outro-3 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
    translate: 0 0;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
    translate: 0 100%;
  }
}
</style>
    `)}}),_c('div',{class:[`--${_vm.section.sid}--`, {'element-hidden': !_vm.visability()}],attrs:{"data-sid":_vm.section.sid}},[_c('div',{staticClass:"carousel-card-container"},[_c('div',{staticClass:"carousel-card-wrapper",class:`animation-type-${_vm.section.options.content.carousel.animationType}`},_vm._l((_vm.cards),function(card,index){return _c('div',{key:card.sid + index,staticClass:"carousel-card flex flex-col",class:card.sid,style:({boxShadow: card.highlight ? '0 0 0 2px #3f51b5' : null}),attrs:{"data-sid":card.sid}},[_c('div',{staticClass:"el-overlay"}),_c('elements',{staticClass:"contents",attrs:{"classes":"contents","drag-height":"200px","group-name":"rows","put":['elements']},model:{value:(card.elements),callback:function ($$v) {_vm.$set(card, "elements", $$v)},expression:"card.elements"}})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }