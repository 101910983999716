<template>
  <div>
    <b-dropdown-item
      class="d-flex align-items-center"
      link-class="dashboard-menu-link !flex items-center text-[15px]"
      @click.stop="modal = true"
    >
      <svg
        v-if="uploaded"
        class="mr-2 w-4 h-4"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.25 1.75H1.75M10.5 7.58333L7 4.08333M7 4.08333L3.5 7.58333M7 4.08333V12.25"
          stroke="black"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      
      <svg
        v-else
        class="mr-2 w-4 h-4"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6673 14.25L15.0423 11.875M15.0423 11.875L17.4173 14.25M15.0423 11.875V16.625M17.4173 7.91665H1.58398M17.4173 9.49998V6.49165C17.4173 5.6049 17.4173 5.16152 17.2447 4.82283C17.0929 4.52491 16.8507 4.28269 16.5528 4.13089C16.2141 3.95831 15.7707 3.95831 14.884 3.95831H4.11732C3.23057 3.95831 2.78719 3.95831 2.4485 4.13089C2.15058 4.28269 1.90836 4.5249 1.75656 4.82283C1.58398 5.16152 1.58398 5.6049 1.58398 6.49165V12.5083C1.58398 13.3951 1.58398 13.8384 1.75656 14.1771C1.90836 14.4751 2.15058 14.7173 2.4485 14.8691C2.78719 15.0416 3.23057 15.0416 4.11732 15.0416H9.50065"
          stroke="black"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {{ uploaded ? 'Edit Template' : 'Upload as Template' }}
    </b-dropdown-item>

    <b-modal
      v-model="modal"
      dialog-class="!max-w-[880px] rounded-[5px]"
      body-class="!py-8 !px-16"
      hide-footer
      hide-header
      centered
      lazy
    >
      <i
        class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
        @click="modal = false"
      />
      
      <template>
        <b-form class="w-full flex flex-col items-center">
          <h4 class="text-center text-[27px] font-semibold">
            New Template
          </h4>

          <b-row class="w-full mt-14">
            <b-col cols="4">
              <div
                v-if="!(form.thumbnail.default ? form.thumbnail.screenshot : form.thumbnail.source)"
                class="w-full bg-[#D9D9D9] border border-[#E2E5EC] rounded-[5px] bg-cover aspect-square"
              />
              <img
                v-else
                :src="form.thumbnail.default ? form.thumbnail.screenshot : form.thumbnail.source"
                alt="Template Thumbnail"
                class="w-full bg-[#D9D9D9] border border-[#E2E5EC] rounded-[5px] object-cover aspect-square"
              >

              <b-form-checkbox
                v-model="form.thumbnail.default"
                name="thumbnail"
                class=""
                switch
              >
                <div class="text-wrap text-[#44474A] translate-y-[-.4rem] mb-0">
                  Default Thumbnail
                </div>
              </b-form-checkbox>

              <upload-asset
                type="jpg|png|jpeg|blob|webp"
                @uploaded="e => insertImage(e.url)"
              >
                <template #trigger>
                  <div class="inline-flex items-center mt-[12px]">
                    <label class="cursor-pointer inline-flex items-center">
                      <svg
                        class="mr-2 w-4 h-4"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.16667 8.47463C1.46317 8.00373 1 7.20179 1 6.29167C1 4.92458 2.04505 3.80159 3.37985 3.67797C3.65289 2.01708 5.09514 0.75 6.83333 0.75C8.57152 0.75 10.0138 2.01708 10.2868 3.67797C11.6216 3.80159 12.6667 4.92458 12.6667 6.29167C12.6667 7.20179 12.2035 8.00373 11.5 8.47463M4.5 8.33333L6.83333 6M6.83333 6L9.16667 8.33333M6.83333 6V11.25"
                          stroke="black"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span class="text-[#44474A]">
                        Upload Thumbnail
                      </span>
                    </label>
                  </div>
                </template>
              </upload-asset>

              <!--              <div class="inline-flex items-center mt-[12px]">-->
              <!--                <label class="cursor-pointer inline-flex items-center">-->
              <!--                  <svg-->
              <!--                    class="mr-2 w-4 h-4"-->
              <!--                    width="14"-->
              <!--                    height="12"-->
              <!--                    viewBox="0 0 14 12"-->
              <!--                    fill="none"-->
              <!--                    xmlns="http://www.w3.org/2000/svg"-->
              <!--                  >-->
              <!--                    <path-->
              <!--                      d="M2.16667 8.47463C1.46317 8.00373 1 7.20179 1 6.29167C1 4.92458 2.04505 3.80159 3.37985 3.67797C3.65289 2.01708 5.09514 0.75 6.83333 0.75C8.57152 0.75 10.0138 2.01708 10.2868 3.67797C11.6216 3.80159 12.6667 4.92458 12.6667 6.29167C12.6667 7.20179 12.2035 8.00373 11.5 8.47463M4.5 8.33333L6.83333 6M6.83333 6L9.16667 8.33333M6.83333 6V11.25"-->
              <!--                      stroke="black"-->
              <!--                      stroke-width="1.3"-->
              <!--                      stroke-linecap="round"-->
              <!--                      stroke-linejoin="round"-->
              <!--                    />-->
              <!--                  </svg>-->

              <!--                  <span class="text-[#44474A]">-->
              <!--                    Upload Thumbnail-->
              <!--                  </span>-->

              <!--                  <input-->
              <!--                    type="file"-->
              <!--                    accept="image/*"-->
              <!--                    class="hidden"-->
              <!--                    @change="uploadImage($event, -1)"-->
              <!--                  >-->
              <!--                </label>-->
              <!--              </div>-->
            </b-col>
            <b-col cols="8">
              <b-form-group label="Template Name">
                <b-input
                  v-model="form.name"
                  name="name"
                />
              </b-form-group>

              <b-form-group label="Description">
                <b-textarea
                  v-model="form.description"
                  name="description"
                  class=""
                  rows="5"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="w-full mt-10">
            <b-col cols="4">
              <label class="text-[15px] text-[#3E3E3E] mb-[12px]">
                Categories
              </label>

              <div class="relative flex items-center gap-[10px]">
                <v-select
                  v-model="form.categories"
                  :options="categories"
                  class="multi-select !rounded-[4px] border border-[#E3E3E3]"
                  placeholder="Select Categories"
                  :searchable="false"
                  :indicator="false"
                  label="name"
                  :reduce="item => item.id"
                  multiple
                />

                <button 
                  class="absolute right-[10px] top-1/2 -translate-y-1/2 !w-[20px] !h-[20px] hover:opacity-80 p-0"
                  type="button"
                  @click="categoryModal = true"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2606 2.8514L11.0411 5.0709L9.09961 4.37733L8.40608 2.43587L10.6256 0.216373C9.16938 -0.270789 7.49841 0.0644958 6.33886 1.22409C5.20071 2.36224 4.85681 3.99293 5.30499 5.42977C5.24463 5.47728 5.18615 5.52844 5.13046 5.58413L0.572138 10.1424C-0.190713 10.9052 -0.190713 12.142 0.572138 12.9048C1.33499 13.6677 2.57181 13.6677 3.33466 12.9048L7.89298 8.34652C7.94867 8.29083 7.99974 8.23239 8.04731 8.17204C9.48415 8.62021 11.1148 8.27627 12.2529 7.13812C13.4124 5.97852 13.7478 4.30765 13.2606 2.8514Z"
                      fill="#6E747A"
                    />
                  </svg>
                </button>
              </div>
            </b-col>
            <b-col cols="4">
              <label class="text-[15px] text-[#3E3E3E] mb-[12px]">
                Themes
              </label>

              <div class="relative flex items-center gap-[10px]">
                <v-select
                  v-model="form.themes"
                  :options="themes"
                  class="multi-select !rounded-[4px] border border-[#E3E3E3] flex-nowrap"
                  placeholder="Select Themes"
                  :searchable="false"
                  :indicator="false"
                  label="name"
                  :reduce="item => item.id"
                  multiple
                />

                <button
                  class="absolute right-[10px] top-1/2 -translate-y-1/2 !w-[20px] !h-[20px] hover:opacity-80 p-0"
                  type="button"
                  @click="themeModal = true"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2606 2.8514L11.0411 5.0709L9.09961 4.37733L8.40608 2.43587L10.6256 0.216373C9.16938 -0.270789 7.49841 0.0644958 6.33886 1.22409C5.20071 2.36224 4.85681 3.99293 5.30499 5.42977C5.24463 5.47728 5.18615 5.52844 5.13046 5.58413L0.572138 10.1424C-0.190713 10.9052 -0.190713 12.142 0.572138 12.9048C1.33499 13.6677 2.57181 13.6677 3.33466 12.9048L7.89298 8.34652C7.94867 8.29083 7.99974 8.23239 8.04731 8.17204C9.48415 8.62021 11.1148 8.27627 12.2529 7.13812C13.4124 5.97852 13.7478 4.30765 13.2606 2.8514Z"
                      fill="#6E747A"
                    />
                  </svg>
                </button>
              </div>
            </b-col>
            <b-col cols="4">
              <label class="text-[15px] text-[#3E3E3E] mb-[12px]">
                Price
              </label>

              <div class="border border-[#E2E5EC] rounded-[5px] p-3">
                <b-radio-group
                  v-model="form.price.type"
                  :options="priceTypes"
                />
                
                <b-input-group
                  v-if="form.price.type === 'paid'"
                  label="Price"
                  class="mt-4"
                >
                  <template #prepend>
                    <b-input-group-text class="bg-white !border-[#ced4da]">
                      $USD
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="form.price.amount"
                    name="price"
                    type="number"
                    class=""
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>

          <div class="flex flex-col gap-y-6 w-full mt-16">
            <p class="text-[15px] text-[#3E3E3E] mb-0">
              Pages <span class="text-[#ADADAD]">({{ form.pages.length }})</span>
            </p>

            <div class="flex flex-row gap-x-5 p-4 bg-[#FBFBFB] border border-[#E2E5EC] rounded-[5px] w-full overflow-x-scroll">
              <div 
                v-for="(page, index) in form.pages"
                :key="index"
                class="flex flex-col min-w-[256px] w-[256px] p-4 bg-white rounded-[10px] shadow-[0_2px_7px_rgba(0,0,0,0.07)]"
              >
                <div 
                  v-if="!page.thumbnail.source"
                  class="w-1/2 aspect-square object-cover bg-[#D9D9D9] border-[0.41px] border-[#E2E5EC] rounded-[5px] shadow-[0_0.82px_1.64px_rgba(0,0,0,0.05)]"
                />
                <img
                  v-else
                  class="w-1/2 aspect-square object-cover bg-[#D9D9D9] border-[0.41px] border-[#E2E5EC] rounded-[5px] shadow-[0_0.82px_1.64px_rgba(0,0,0,0.05)]"
                  alt="Page Thumbnail"
                  :src="page.thumbnail.source"
                >

                <p class="text-[14px] text-[#44474A] font-semibold mt-2 mb-0">
                  {{ page.name }}
                </p>

                <upload-asset
                  type="jpg|png|jpeg|blob|webp"
                  @uploaded="e => insertImage(e.url, index)"
                >
                  <template #trigger>
                    <div class="inline-flex items-center mt-[12px]">
                      <label class="cursor-pointer inline-flex items-center">
                        <svg
                          class="mr-2 w-4 h-4"
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.16667 8.47463C1.46317 8.00373 1 7.20179 1 6.29167C1 4.92458 2.04505 3.80159 3.37985 3.67797C3.65289 2.01708 5.09514 0.75 6.83333 0.75C8.57152 0.75 10.0138 2.01708 10.2868 3.67797C11.6216 3.80159 12.6667 4.92458 12.6667 6.29167C12.6667 7.20179 12.2035 8.00373 11.5 8.47463M4.5 8.33333L6.83333 6M6.83333 6L9.16667 8.33333M6.83333 6V11.25"
                            stroke="black"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <span class="text-[#44474A]">
                          Upload Thumbnail
                        </span>
                      </label>
                    </div>
                  </template>
                </upload-asset>

                <b-form-checkbox
                  v-model="page.visible"
                  name="visible"
                  class=""
                  switch
                >
                  <div class="text-wrap text-[#44474A] translate-y-[-.4rem] mb-0">
                    Visible
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row gap-x-3 justify-start mt-10">
            <template v-if="currentTemplate">
              <b-btn
                variant="primary"
                class="!bg-[#4F83E3] !inline-flex !items-center !justify-center hover:!opacity-80"
                :disabled="loading"
                @click="updateTemplate"
              >
                <svg
                  class="mr-1 w-4 h-4"
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16667 8.47463C1.46317 8.00373 1 7.20179 1 6.29167C1 4.92458 2.04505 3.80159 3.37985 3.67797C3.65289 2.01708 5.09514 0.75 6.83333 0.75C8.57152 0.75 10.0138 2.01708 10.2868 3.67797C11.6216 3.80159 12.6667 4.92458 12.6667 6.29167C12.6667 7.20179 12.2035 8.00373 11.5 8.47463M4.5 8.33333L6.83333 6M6.83333 6L9.16667 8.33333M6.83333 6V11.25"
                    stroke="currentColor"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Update Template

                <b-spinner
                  v-if="loading"
                  class="ml-3"
                  variant="light"
                  small
                />
              </b-btn>

              <b-btn
                variant="white"
                class="!bg-white !border !border-[#CACACA] !text-[#4B4B4B] !inline-flex !items-center !justify-center hover:!opacity-60"
                :disabled="loading"
                @click="updateTemplateStatus(!currentTemplate.status)"
              >
                {{ !currentTemplate.status ? 'Publish Template' : 'Unpublish Template' }}
              </b-btn>
            </template>

            <b-btn
              v-else
              variant="primary"
              class="!bg-[#4F83E3] !inline-flex !items-center !justify-center hover:!opacity-80"
              :disabled="loading"
              @click="uploadTemplate"
            >
              <svg
                class="mr-1 w-4 h-4"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.16667 8.47463C1.46317 8.00373 1 7.20179 1 6.29167C1 4.92458 2.04505 3.80159 3.37985 3.67797C3.65289 2.01708 5.09514 0.75 6.83333 0.75C8.57152 0.75 10.0138 2.01708 10.2868 3.67797C11.6216 3.80159 12.6667 4.92458 12.6667 6.29167C12.6667 7.20179 12.2035 8.00373 11.5 8.47463M4.5 8.33333L6.83333 6M6.83333 6L9.16667 8.33333M6.83333 6V11.25"
                  stroke="currentColor"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              Upload Template

              <b-spinner
                v-if="loading"
                class="ml-3"
                variant="light"
                small
              />
            </b-btn>

            <b-btn
              variant="white"
              class="!bg-white !border !border-[#CACACA] !text-[#4B4B4B] !inline-flex !items-center !justify-center hover:!opacity-60"
              @click="modal = false"
            >
              Cancel
            </b-btn>
          </div>
        </b-form>
      </template>
    </b-modal>
    
    <b-modal
      v-model="categoryModal"
      dialog-class="!max-w-[640px] rounded-[5px]"
      body-class="!py-8 !px-16"
      hide-footer
      hide-header
      centered
      lazy
    >
      <i
        class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
        @click="categoryModal = false"
      />

      <template>
        <div class="w-full flex flex-col items-center">
          <h4 class="text-center text-[27px] font-semibold">
            Manage Categories
          </h4>
          
          <div class="w-[400px] mx-auto mt-[24px]">
            <div class="w-full flex justify-center items-baseline gap-[10px]">
              <b-form-group
                label="New Category"
                class="w-full"
              >
                <div class="flex items-center gap-[10px]">
                  <b-input
                    v-model="newCategory"
                    name="name"
                    class="w-full"
                  />
                  
                  <b-button 
                    variant="primary"
                    @click="addTemplateCategory"
                  >
                    <svg
                      fill="none"
                      height="12"
                      viewBox="0 0 11 12"
                      width="11"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path
                      d="M6.808 11.815H4.807V7.146H0.667V5.329H4.807V0.843999H6.808V5.329H10.925V7.146H6.808V11.815Z"
                      fill="white"
                    /></svg>
                  </b-button>
                </div>
              </b-form-group>
            </div>

            <div class="w-full mt-[16px]">
              <ul class="list-none">
                <li
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  <b-form-group class="w-full">
                    <div class="flex items-center gap-[10px]">
                      <b-input
                        v-model="category.name"
                        type="text"
                        class="w-full border h-[32px] px-[8px]"
                      />
                      <button
                        class="w-[32px] h-[32px] p-0"
                        @click="updateTemplateCategory(category)"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2"
                        ><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.8048 0.528575C10.5444 0.268226 10.1223 0.268226 9.86197 0.528575L0.528636 9.86191C0.403612 9.98693 0.333374 10.1565 0.333374 10.3333V13C0.333374 13.3682 0.631851 13.6666 1.00004 13.6666H3.66671C3.84352 13.6666 4.01309 13.5964 4.13811 13.4714L13.4714 4.13805C13.7318 3.8777 13.7318 3.45559 13.4714 3.19524L10.8048 0.528575ZM11 4.72384L9.27619 2.99998L10.3334 1.94279L12.0572 3.66665L11 4.72384ZM8.33338 3.94279L10.0572 5.66665L4.33337 11.3905L2.60952 9.66665L8.33338 3.94279ZM1.66671 10.6095L3.39057 12.3333H1.66671V10.6095Z"
                          fill="#44474A"
                        /><path
                          d="M7.66671 12.3333C7.29852 12.3333 7.00004 12.6318 7.00004 13C7.00004 13.3682 7.29852 13.6666 7.66671 13.6666H13C13.3682 13.6666 13.6667 13.3682 13.6667 13C13.6667 12.6318 13.3682 12.3333 13 12.3333H7.66671Z"
                          fill="#44474A"
                        /></svg>
                      </button>
                      <button
                        class="w-[32px] h-[32px] p-0"
                        @click="removeTemplateCategory(category)"
                      >
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2 w-4 h-4"
                        ><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
                          fill="#44474A"
                        /><path
                          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
                          fill="#44474A"
                        /><path
                          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
                          fill="#44474A"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
                          fill="#44474A"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /></svg>
                      </button>
                    </div>
                  </b-form-group>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="themeModal"
      dialog-class="!max-w-[640px] rounded-[5px]"
      body-class="!py-8 !px-16"
      hide-footer
      hide-header
      centered
      lazy
    >
      <i
        class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
        @click="themeModal = false"
      />

      <template>
        <div class="w-full flex flex-col items-center">
          <h4 class="text-center text-[27px] font-semibold">
            Manage Themes
          </h4>

          <div class="w-[400px] mx-auto mt-[24px]">
            <div class="w-full flex justify-center items-baseline gap-[10px]">
              <b-form-group
                label="New Theme"
                class="w-full"
              >
                <div class="flex items-center gap-[10px]">
                  <b-input
                    v-model="newTheme"
                    name="name"
                    class="w-full"
                  />

                  <b-button
                    variant="primary"
                    @click="addTemplateTheme"
                  >
                    <svg
                      fill="none"
                      height="12"
                      viewBox="0 0 11 12"
                      width="11"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path
                      d="M6.808 11.815H4.807V7.146H0.667V5.329H4.807V0.843999H6.808V5.329H10.925V7.146H6.808V11.815Z"
                      fill="white"
                    /></svg>
                  </b-button>
                </div>
              </b-form-group>
            </div>

            <div class="w-full mt-[16px]">
              <ul class="list-none">
                <li
                  v-for="(theme, index) in themes"
                  :key="index"
                >
                  <b-form-group class="w-full">
                    <div class="flex items-center gap-[10px]">
                      <b-input
                        v-model="theme.name"
                        type="text"
                        class="w-full border h-[32px] px-[8px]"
                      />
                      <button
                        class="w-[32px] h-[32px] p-0"
                        @click="updateTemplateTheme(theme)"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2"
                        ><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.8048 0.528575C10.5444 0.268226 10.1223 0.268226 9.86197 0.528575L0.528636 9.86191C0.403612 9.98693 0.333374 10.1565 0.333374 10.3333V13C0.333374 13.3682 0.631851 13.6666 1.00004 13.6666H3.66671C3.84352 13.6666 4.01309 13.5964 4.13811 13.4714L13.4714 4.13805C13.7318 3.8777 13.7318 3.45559 13.4714 3.19524L10.8048 0.528575ZM11 4.72384L9.27619 2.99998L10.3334 1.94279L12.0572 3.66665L11 4.72384ZM8.33338 3.94279L10.0572 5.66665L4.33337 11.3905L2.60952 9.66665L8.33338 3.94279ZM1.66671 10.6095L3.39057 12.3333H1.66671V10.6095Z"
                          fill="#44474A"
                        /><path
                          d="M7.66671 12.3333C7.29852 12.3333 7.00004 12.6318 7.00004 13C7.00004 13.3682 7.29852 13.6666 7.66671 13.6666H13C13.3682 13.6666 13.6667 13.3682 13.6667 13C13.6667 12.6318 13.3682 12.3333 13 12.3333H7.66671Z"
                          fill="#44474A"
                        /></svg>
                      </button>
                      <button
                        class="w-[32px] h-[32px] p-0"
                        @click="removeTemplateTheme(theme)"
                      >
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2 w-4 h-4"
                        ><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
                          fill="#44474A"
                        /><path
                          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
                          fill="#44474A"
                        /><path
                          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
                          fill="#44474A"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
                          fill="#44474A"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /><path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
                          stroke="white"
                          stroke-width="0.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        /></svg>
                      </button>
                    </div>
                  </b-form-group>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UploadAsset
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/UploadAsset.vue'

export default {
  name: 'UploadAsTemplate',
  components: {UploadAsset},
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      modal: false,
      categoryModal: false,
      themeModal: false,
      newCategory: '',
      newTheme: '',
      uploaded: false,
      currentTemplate: null,
      priceTypes: [
        {
          text: 'Paid',
          value: 'paid'
        },
        {
          text: 'Free',
          value: 'free'
        }
      ],
      themes: [],
      categories: [],
      form: {
        thumbnail: {
          default: true,
          screenshot: null,
          source: null,
          file: null
        },
        name: '',
        description: '',
        categories: [],
        themes: [],
        price: {
          type: 'free',
          amount: 0
        },
        pages: []
      }
    }
  },
  methods: {
    async setForm () {
      this.loading = true

      const {data: templates} = await axios.get(`api/admin/templates?project_id=${this.project.id}`)
      let pagesTMP = []

      if (templates.data.data.length) {
        const {data: template} = await axios.get(`api/admin/templates/${templates.data.data[0].id}`)
        this.currentTemplate = template.data
        this.form.name = template.data.name
        this.form.description = template.data.description
        this.form.thumbnail.default = false
        this.form.thumbnail.source = template.data.cover_url || template.data.cover
        this.form.price.type = template.data.is_free ? 'free' : 'paid'
        this.form.price.amount = template.data.amount
        this.form.categories = template.data.categories_rel.map(category => category.category_id)
        this.form.themes = template.data.themes.map(theme => theme.theme_id)
        pagesTMP = template.data.pages.map(page => {
          return {
            id: page.project_id,
            name: page.name,
            visible: true,
            thumbnail: {
              default: false,
              source: page.cover_url || page.cover,
              file: null
            }
          }
        })
      } else {
        this.form.name = this.project.name
        this.form.description = this.project.description
      }

      const {data: project} = await axios.get(`api/projects/${this.project.id}`)
      this.form.content = project.data.content
      this.form.global_settings = project.data.global_settings
      this.form.setup = project.data.setup
      this.form.view_link = `https://${project.data.subdomain}.estage.site`
      this.form.status = true

      this.form.thumbnail.screenshot = this.project.screenshot
      const pagesTMPIds = pagesTMP.map(page => page.id)

      const {data: pages} = await axios.get(`api/projects/${this.project.id}/pages?per_page=100`)
      this.form.pages = [
        ...pagesTMP,
        ...pages.data.data
          .filter(page => !pagesTMPIds.includes(page.id))
          .map(page => {
            return {
              id: page.id,
              name: page.title,
              visible: true,
              thumbnail: {
                default: false,
                source: '',
                file: null
              }
            }
          })
      ]

      this.loading = false
    },
    async urlToFile (url, filename, mimeType) {
      const response = await fetch(url)
      const blob = await response.blob()
      return new File([blob], filename, { type: mimeType })
    },
    convertFileToBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async uploadImage (file, page) {
      const image = file.target.files[0]
      if (image) {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (page >= 0) {
            this.form.pages[page].thumbnail.file = image
            this.form.pages[page].thumbnail.source = e.target.result
          } else {
            this.form.thumbnail.file = image
            this.form.thumbnail.source = e.target.result
            this.form.thumbnail.default = false
          }
        }
        reader.readAsDataURL(image)
      }
    },
    insertImage (file, page) {
      if (!file) return

      if (page >= 0) {
        this.form.pages[page].thumbnail.source = file
      } else {
        this.form.thumbnail.source = file
      }
    },
    async uploadTemplate () {
      this.loading = true
      const formData = new FormData()

      let coverFile
      if (this.form.thumbnail.default) {
        coverFile = this.form.thumbnail.screenshot
      } else {
        coverFile = this.form.thumbnail.source
      }

      formData.append('name', this.form.name)
      formData.append('cover', coverFile)
      formData.append('is_free', this.form.price.type === 'free' ? 1 : 0)
      formData.append('amount', this.form.price.amount)
      formData.append('project_id', this.project.id)
      formData.append('description', this.form.description || '')
      formData.append('content', this.form.content)
      formData.append('global_settings', JSON.stringify(this.form.global_settings))
      formData.append('setup', this.form.setup || '')
      formData.append('view_link', this.form.view_link || '')
      formData.append('status', this.form.status ? 1 : 0)

      this.form.categories.forEach(category => formData.append('categories[]', category))
      this.form.themes.forEach(theme => formData.append('themes[]', theme))
      this.form.pages
        .filter(page => page.visible)
        .forEach((page, index) => {
          formData.append(`pages[${index}][project_id]`, page.id)
          formData.append(`pages[${index}][name]`, page.name)
          formData.append(`pages[${index}][cover]`, page.thumbnail.source)
        })

      try {
        const { data } = await axios.post('api/admin/templates', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (data.status === 'success') this.currentTemplate = data.data
        this.modal = false
        this.$swal({
          icon: 'success',
          title: 'Done!',
          text: 'Post published.',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Error!',
          text: error.message || 'Template publishing failed.',
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
    async updateTemplate () {
      this.loading = true
      const formData = new FormData()

      let coverFile
      if (this.form.thumbnail.default) {
        coverFile = this.form.thumbnail.screenshot
      } else {
        coverFile = this.form.thumbnail.source
      }

      formData.append('name', this.form.name)
      formData.append('cover', coverFile)
      formData.append('is_free', this.form.price.type === 'free' ? 1 : 0)
      formData.append('amount', this.form.price.amount)
      formData.append('project_id', this.project.id)
      formData.append('description', this.form.description || '')
      formData.append('content', this.form.content)
      formData.append('global_settings', JSON.stringify(this.form.global_settings))
      formData.append('setup', this.form.setup)
      formData.append('view_link', this.form.view_link || '')
      formData.append('status', this.form.status ? 1 : 0)

      this.form.categories.forEach(category => formData.append('categories[]', category))
      this.form.themes.forEach(theme => formData.append('themes[]', theme))
      this.form.pages
        .filter(page => page.visible)
        .forEach((page, index) => {
          formData.append(`pages[${index}][project_id]`, page.id)
          formData.append(`pages[${index}][name]`, page.name)
          formData.append(`pages[${index}][cover]`, page.thumbnail.source)
        })

      try {
        const { data } = await axios.post(`api/admin/templates/${this.currentTemplate.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (data.status === 'success') this.currentTemplate = data.data
        this.modal = false
        this.$swal({
          icon: 'success',
          title: 'Done!',
          text: 'Post updated.',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Error!',
          text: error.message || 'Template updating failed.',
          showConfirmButton: false,
          timer: 1500
        })
      } finally {
        this.loading = false
      }
    },
    async updateTemplateStatus (status) {
      await axios.put(`/api/admin/templates/status/${this.currentTemplate.id}`, {status: status})
        .then(({data}) => {
          this.currentTemplate = data.data
          this.modal = false
          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: status ? 'Post published.' : 'Post unpublished.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Error!',
            text: error.message || status ? 'Template publishing failed.' : 'Template unpublishing failed.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async addTemplateCategory () {
      await axios.post('api/admin/templates/categories', {
        name: this.newCategory
      })
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Category added',
            text: 'Category added successfully',
            showConfirmButton: false,
            timer: 1500
          })
          
          await axios.get('api/templates/categories')
            .then(({data}) => {
              this.categories = data.data
            })
        })
        .finally(() => {
          this.newCategory = ''
        })
    },
    async addTemplateTheme () {
      await axios.post('api/admin/templates/themes', {
        name: this.newTheme,
        is_active: true
      })
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Theme added',
            text: 'Theme added successfully',
            showConfirmButton: false,
            timer: 1500
          })

          await axios.get('api/templates/themes')
            .then(({data}) => {
              this.themes = data.data
            })
        })
        .finally(() => {
          this.newTheme = ''
        })
    },
    async updateTemplateCategory (category) {
      await axios.put(`api/admin/templates/categories/${category.id}`, {
        name: category.name
      })
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Category updated',
            text: 'Category updated successfully',
            showConfirmButton: false,
            timer: 1500
          })
          
          await axios.get('api/templates/categories')
            .then(({data}) => {
              this.categories = data.data
            })
        })
    },
    async updateTemplateTheme (theme) {
      await axios.put(`api/admin/templates/themes/${theme.id}`, {
        name: theme.name,
        is_active: true
      })
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Theme updated',
            text: 'Theme updated successfully',
            showConfirmButton: false,
            timer: 1500
          })

          await axios.get('api/templates/themes')
            .then(({data}) => {
              this.themes = data.data
            })
        })
    },
    async removeTemplateCategory (category) {
      await axios.delete(`api/admin/templates/categories/${category.id}`)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Category removed',
            text: 'Category removed successfully',
            showConfirmButton: false,
            timer: 1500
          })

          await axios.get('api/templates/categories')
            .then(({data}) => {
              this.categories = data.data
            })
        })
    },
    async removeTemplateTheme (theme) {
      await axios.delete(`api/admin/templates/themes/${theme.id}`)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Theme removed',
            text: 'Theme removed successfully',
            showConfirmButton: false,
            timer: 1500
          })

          await axios.get('api/templates/themes')
            .then(({data}) => {
              this.themes = data.data
            })
        })
    }
  },
  watch: {
    async modal (val) {
      if (val) {
        this.loading = true
        await axios.get('api/templates/categories')
          .then(({data}) => {
            this.categories = data.data
          })

        await axios.get('api/templates/themes')
          .then(({data}) => {
            this.themes = data.data
            this.loading = false
          })

        await this.setForm()
      }
    }
  }
}
</script>