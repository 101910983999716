<template>
  <div class="sid-select">
    <div
      class="w-full mt-[14px] !py-[14px] !px-[16px]"
    >
      <b-form-group v-if="!staticType">
        <div class="flex items-center justify-between">
          <label class="pb-0">Name</label>

          <div class="w-[118px]">
            <b-input
              v-model="control.field_name"
              @input="changeFieldName"
            />
          </div>
        </div>
      </b-form-group>

      <b-form-group v-if="!staticType">
        <div class="flex items-center justify-between">
          <label class="pb-0">Type</label>

          <div class="w-[118px]">
            <v-select
              :value="type"
              :options="typeOptions"
              :reduce="item => item.value"
              class="custom-select"
              @input="selectFieldType($event)"
            />
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Label</label>

          <div class="w-[118px]">
            <b-input v-model="control.label" />
          </div>
        </div>
      </b-form-group>

      <template v-if="!hidePlaceholder">
        <b-form-group v-if="isSupportedPlaceholder">
          <div class="flex items-center justify-between">
            <label class="pb-0">Placeholder</label>

            <div class="w-[118px]">
              <b-input v-model="control.placeholder" />
            </div>
          </div>
        </b-form-group>

        <b-form-group
          v-else
          label="Text"
        >
          <b-textarea v-model="control.placeholder" />
        </b-form-group>
      </template>

      <b-row
        v-if="!staticType"
        class="align-items-center"
      >
        <b-col>
          <label for="required-mark">Required</label>
        </b-col>
        <b-col class="text-right">
          <b-checkbox
            id="required-mark"
            v-model="isRequired"
            class="mb-2"
            switch
            :value="true"
            :unchecked-value="false"
          />
        </b-col>
      </b-row>

      <sid-range-slider
        label="Width"
        :sid="`${sidClass}`"
        :dimensions="['%', 'px']"
        default-dimension="%"
        rule-name="width"
        var="--width"
        measurement="width"
        class="mt-[12px]"
      />
    </div>

    <SidbearSettingsGroup
      title="Label Styles"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-font
        prefix="Label"
        :sid="`${sidClass} legend`"
      />

      <sid-range-slider
        label="Label Font Size"
        :sid="`${sidClass} legend`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Label Text Color</label>

          <sid-color
            :sid="`${sidClass} legend`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup 
      title="Placeholder Styles"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-font
        prefix="Placeholder"
        :sid="`${sidClass} .input.form-control::placeholder`"
      />

      <sid-range-slider
        label="Placeholder Font Size"
        :sid="`${sidClass} .input.form-control::placeholder`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Placeholder Font Color</label>

          <sid-color
            :sid="`${sidClass} .input.form-control::placeholder`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Field Styles"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-font
        prefix="Field"
        :sid="`${sidClass} .input.form-control`"
      />

      <sid-range-slider
        label="Field Font Size"
        :sid="`${sidClass} .input.form-control`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Field Text Color</label>

          <sid-color
            :sid="`${sidClass} .input.form-control`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-background
              :sid="`${sidClass} .input.form-control`"
              small
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group>
            <sid-background
              :sid="`${sidClass} .input.form-control`"
              presudo=":hover"
              small
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between !gap-[20px]">
              <label class="w-full pb-0">Border Type</label>

              <sid-select
                :sid="`${sidClass} .input.form-control`"
                rule-name="border-style"
                :searchable="false"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`${sidClass} .input.form-control`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`${sidClass} .input.form-control`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`${sidClass} .input.form-control`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`${sidClass} .input.form-control`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`${sidClass} .input.form-control`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`${sidClass} .input.form-control`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`${sidClass} .input.form-control`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      :title="!staticType ? 'Padding & Margins' : 'Padding'"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`${sidClass} .input.form-control`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        v-if="!staticType"
        label="Margin"
        :sid="`.--${element.sid}--margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import {toJSON} from '@/utils/helpers'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
export default {
  name: 'FormControlFieldSettings',
  components: {
    SidFont,
    SidBackground,
    SidColor,
    SidSelect,
    RuleTabs,
    SidLinkedGroup,
    SidRangeSlider,
    SidbearSettingsGroup
  },
  mixins: [ResolutionMixin],
  props: {
    sidClass: {
      type: String,
      default: null
    },
    control: {
      type: Object,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    staticType: {
      type: Boolean,
      default: false
    },
    hidePlaceholder: {
      type: Boolean,
      default: false
    },
    formConfig: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      index: 0,
      type: 'text',
      columns: 12,
      typeOptions: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Text Area',
          value: 'textarea'
        },
        {
          label: 'Number',
          value: 'number'
        },
        {
          label: 'Phone Number',
          value: 'tel'
        },
        {
          label: 'Checkbox',
          value: 'checkbox'
        }
      ]
    }
  },
  computed: {
    isRequired: {
      get () {
        return !!this.control.is_required
      },
      set (val) {
        this.control.is_required = val
      }
    },
    isSupportedPlaceholder () {
      return !['checkbox'].includes(this.control.field_type)
    },
    columnsModel: {
      get () {
        return _.get(this.control.columns, this.resolutioner, this.control.columns)
      },
      set (val) {
        const column = Object.keys(toJSON(this.control.columns)).length
          ? this.control.columns
          : {
            lg: this.control.columns,
            md: this.control.columns,
            sm: this.control.columns
          }

        this.$set(this.control, 'columns', {
          ...column,
          [this.resolutioner]: val
        })
      }
    }
  },
  methods: {
    updateIndex () {
      this.index = this.formConfig.form.controls.findIndex(item => item.field_name === this.control.field_name)
    },
    changeFieldName (val) {
      const sanitizedValue = val.replace(/[^a-zA-Z0-9]/g, '')
      if (this.index !== -1) {
        this.formConfig.form.controls[this.index].field_name = sanitizedValue
        this.control.field_name = sanitizedValue
        this.updateIndex()
      }
    },
    selectFieldType (type) {
      switch (type) {
      case 'textarea':
        this.element.component = 'ElFieldTextarea'
        this.control.values = ''
        break
      case 'tel':
        this.element.component = 'ElFieldPhone'
        this.control.values = ''
        break
      case 'checkbox':
        this.element.component = 'ElFieldCheckbox'
        this.control.values = true
        break
      case 'number':
        this.element.component = 'ElFieldInput'
        this.control.field_type = 'number'
        this.control.values = ''
        break
      default:
        this.element.component = 'ElFieldInput'
        this.control.field_type = 'text'
        this.control.values = ''
        break
      }
      this.control.field = type
      this.type = type
    }
  },
  created () {
    if (this.control.field) {
      this.type = this.control.field
    }

    if (!this.control.validators) {
      this.$set(this.control, 'validators', {})
    }

    if (typeof this.control.validators === 'string') {
      this.$set(this.control, 'validators', JSON.parse(this.control.validators))
    }

    this.updateIndex()
  }
}
</script>