<template>
  <b-navbar class="editor-header">
    <b-navbar-brand
      class="mr-[33px]"
      @click="$router.push('/')"
    >
      <icon
        icon="logo.svg"
        size="36px"
      />
    </b-navbar-brand>

    <div class="flex basis-auto grow items-center">
      <b-navbar-nav class="align-items-center mr-auto">
        <b-nav-item>
          <div class="on-hover position-relative">
            <div class="resolution-toggle">
              <b-btn
                v-if="resolution === 'lg'"
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
              >
                <icon
                  v-if="resolution === 'lg'"
                  icon="desktop-active.svg"
                />
                <icon
                  v-else
                  icon="desktop.svg"
                />
              </b-btn>
              <b-btn
                v-else-if="resolution === 'sm' && tablet"
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
              >
                <div class="position-relative">
                  <svg
                    width="15"
                    height="19"
                    viewBox="0 0 15 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.7271 0H11.3994C12.8797 0 14.0798 0.884798 14.08 1.97967L14.0674 17.0143C14.0674 18.1054 12.8613 18.9931 11.3872 18.9931L2.7006 19C1.22623 19 0 18.1125 0 17.0215V1.98072C0 0.889779 1.25273 0 2.7271 0ZM8.32647 1.18756H5.8104C5.58876 1.18756 5.40933 1.32036 5.40933 1.48441C5.40933 1.64845 5.58876 1.78125 5.8104 1.78125H8.32647C8.54749 1.78125 8.72753 1.64845 8.72753 1.48441C8.72753 1.32036 8.54749 1.18756 8.32647 1.18756ZM4.29847 1.282C4.44711 1.17214 4.71767 1.17063 4.86611 1.282C4.94053 1.33693 4.98457 1.41405 4.98457 1.49283C4.98457 1.57009 4.94053 1.64706 4.86611 1.70214C4.7921 1.75722 4.6875 1.78967 4.58331 1.78967C4.47708 1.78967 4.37493 1.75722 4.29847 1.70214C4.22445 1.64721 4.18225 1.57009 4.18225 1.49283C4.18204 1.41405 4.22445 1.33693 4.29847 1.282Z"
                      :fill="tablet ? '#4f83e3' : '#d8dadd'"
                    />
                    <ellipse
                      cx="7.03991"
                      cy="15.0733"
                      rx="1.17333"
                      ry="0.886667"
                      fill="white"
                    />
                  </svg>
                </div>
              </b-btn>
              <b-btn
                v-else
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
              >
                <icon
                  v-if="resolution === 'sm' && !tablet"
                  icon="smartphone-active.svg"
                />
                <icon
                  v-else
                  icon="smartphone.svg"
                />
              </b-btn>
            </div>

            <b-btn-group
              class="overlay bg-white position-absolute"
              style="top: 0px; width: 41px"
              vertical
            >
              <b-btn
                v-b-tooltip.hover.right="'Edit on Desktop'"
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
                @click="setResolution('lg')"
              >
                <icon
                  v-if="resolution === 'lg'"
                  icon="desktop-active.svg"
                />
                <icon
                  v-else
                  icon="desktop.svg"
                />
              </b-btn>
              <b-btn
                v-b-tooltip.hover.right="'Edit on Tablet'"
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
                @click="setResolution('md')"
              >
                <div class="position-relative">
                  <svg
                    width="15"
                    height="19"
                    viewBox="0 0 15 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.7271 0H11.3994C12.8797 0 14.0798 0.884798 14.08 1.97967L14.0674 17.0143C14.0674 18.1054 12.8613 18.9931 11.3872 18.9931L2.7006 19C1.22623 19 0 18.1125 0 17.0215V1.98072C0 0.889779 1.25273 0 2.7271 0ZM8.32647 1.18756H5.8104C5.58876 1.18756 5.40933 1.32036 5.40933 1.48441C5.40933 1.64845 5.58876 1.78125 5.8104 1.78125H8.32647C8.54749 1.78125 8.72753 1.64845 8.72753 1.48441C8.72753 1.32036 8.54749 1.18756 8.32647 1.18756ZM4.29847 1.282C4.44711 1.17214 4.71767 1.17063 4.86611 1.282C4.94053 1.33693 4.98457 1.41405 4.98457 1.49283C4.98457 1.57009 4.94053 1.64706 4.86611 1.70214C4.7921 1.75722 4.6875 1.78967 4.58331 1.78967C4.47708 1.78967 4.37493 1.75722 4.29847 1.70214C4.22445 1.64721 4.18225 1.57009 4.18225 1.49283C4.18204 1.41405 4.22445 1.33693 4.29847 1.282Z"
                      :fill="tablet ? '#4f83e3' : '#d8dadd'"
                    />
                    <ellipse
                      cx="7.03991"
                      cy="15.0733"
                      rx="1.17333"
                      ry="0.886667"
                      fill="white"
                    />
                  </svg>
                </div>
              </b-btn>
              <b-btn
                v-b-tooltip.hover.right="'Edit on Mobile'"
                variant="white"
                class="d-flex items-center justify-content-center p-0 w-[38px] h-[38px]"
                @click="setResolution('sm')"
              >
                <icon
                  v-if="resolution === 'sm' && !tablet"
                  icon="smartphone-active.svg"
                />
                <icon
                  v-else
                  icon="smartphone.svg"
                />
              </b-btn>
            </b-btn-group>
          </div>
        </b-nav-item>

        <b-nav-item class="pr-2">
          <b-btn-group>
            <b-btn
              v-b-tooltip.hover
              title="Assets Library"
              variant="white"
              class="w-[40px] h-[40px] text-[#6E747A] p-0"
              @click="mediaModal = true"
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2894 18.9999C15.1519 18.9999 15.0112 18.9827 14.8721 18.9459L2.22059 15.5578C1.35332 15.3189 0.836229 14.4206 1.06368 13.5533L2.65996 7.60348C2.71886 7.38503 2.94305 7.25821 3.16068 7.31385C3.37914 7.37194 3.50841 7.59694 3.45032 7.81457L1.85487 13.7628C1.74114 14.1964 2.00132 14.648 2.43577 14.7683L15.0824 18.1547C15.5169 18.2693 15.9652 18.0107 16.0781 17.5787L16.7171 15.2109C16.776 14.9925 17.0002 14.8624 17.2187 14.9221C17.4371 14.981 17.5656 15.206 17.5075 15.4237L16.8693 17.7882C16.677 18.5164 16.0143 18.9999 15.2894 18.9999Z"
                  fill="#6E747A"
                  stroke="#6E747A"
                  stroke-width="0.3"
                />
                <path
                  d="M19.0097 14.0909H5.91877C5.01632 14.0909 4.28241 13.357 4.28241 12.4545V2.63636C4.28241 1.73391 5.01632 1 5.91877 1H19.0097C19.9121 1 20.646 1.73391 20.646 2.63636V12.4545C20.646 13.357 19.9121 14.0909 19.0097 14.0909ZM5.91877 1.81818C5.46795 1.81818 5.10059 2.18554 5.10059 2.63636V12.4545C5.10059 12.9054 5.46795 13.2727 5.91877 13.2727H19.0097C19.4605 13.2727 19.8279 12.9054 19.8279 12.4545V2.63636C19.8279 2.18554 19.4605 1.81818 19.0097 1.81818H5.91877Z"
                  fill="#6E747A"
                  stroke="#7E7E7E"
                  stroke-width="0.3"
                />
                <path
                  d="M8.37332 6.72731C7.47086 6.72731 6.73695 5.99341 6.73695 5.09095C6.73695 4.1885 7.47086 3.45459 8.37332 3.45459C9.27577 3.45459 10.0097 4.1885 10.0097 5.09095C10.0097 5.99341 9.27577 6.72731 8.37332 6.72731ZM8.37332 4.27277C7.9225 4.27277 7.55514 4.64013 7.55514 5.09095C7.55514 5.54177 7.9225 5.90913 8.37332 5.90913C8.82413 5.90913 9.1915 5.54177 9.1915 5.09095C9.1915 4.64013 8.82413 4.27277 8.37332 4.27277Z"
                  fill="#6E747A"
                />
                <path
                  d="M4.74876 13.2154C4.64403 13.2154 4.5393 13.1753 4.45912 13.096C4.29958 12.9364 4.29958 12.6771 4.45912 12.5175L8.32339 8.65326C8.78648 8.19016 9.59566 8.19016 10.0588 8.65326L11.2091 9.80362L14.3935 5.98271C14.625 5.70535 14.9654 5.54417 15.3278 5.54089H15.3368C15.6952 5.54089 16.0347 5.69635 16.2687 5.9688L20.5478 10.9613C20.6951 11.1323 20.6755 11.3909 20.5037 11.5382C20.3327 11.6854 20.0749 11.6666 19.9268 11.494L15.6478 6.50144C15.5684 6.4098 15.4588 6.35907 15.3368 6.35907C15.2517 6.35171 15.102 6.41062 15.0227 6.50635L11.5511 10.6717C11.4775 10.7601 11.3703 10.8133 11.2549 10.8182C11.1388 10.8263 11.0283 10.7805 10.9473 10.6987L9.4803 9.23171C9.32566 9.07789 9.05648 9.07789 8.90185 9.23171L5.03758 13.096C4.95821 13.1753 4.85348 13.2154 4.74876 13.2154Z"
                  fill="#6E747A"
                />
              </svg>
            </b-btn>
            <ModuleSelectMenu />
          </b-btn-group>
        </b-nav-item>

        <slot name="before" />
      </b-navbar-nav>

      <b-navbar-nav class="align-items-center ml-auto">
        <b-btn
          class="font-weight-bold mr-3"
          variant="white"
          @click="$router.push({name: 'editor', query: {page: page.id}})"
        >
          Return to Editor
        </b-btn>

        <b-btn
          class="d-flex align-items-center font-weight-bold"
          variant="white"
          @click="goBack"
        >
          <svg
            class="mr-2"
            fill="none"
            height="10"
            viewBox="0 0 7 10"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.55408 0.75L1.44597 5L5.55408 9.25"
              stroke="#6E747A"
              stroke-width="1.5"
            />
          </svg>
          Go Back
        </b-btn>

        <!-- Modal -->
        <div
          v-if="showSave"
          class="align-items-center ml-3"
        >
          <b-btn
            :disabled="loading"
            variant="primary"
            @click="() => $emit('save')"
          >
            <svg
              class="mr-2"
              fill="none"
              height="15"
              viewBox="0 0 15 15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8404 0H1.75781C0.788555 0 0 0.788555 0 1.75781V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V2.15965L12.8404 0ZM3.51562 1.17188H10.2539V4.62891H3.51562V1.17188ZM11.4844 13.8281H3.51562V9.19922H11.4844V13.8281ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H12.6562V8.02734H2.34375V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H2.34375V5.80078H11.4258V1.17188H12.355L13.8281 2.64504V13.2422Z"
                fill="#fff"
              />
            </svg>

            <slot name="save-text">
              Save
            </slot>

            <b-spinner
              v-if="loading"
              class="ml-2"
              small
              variant="white"
            />
          </b-btn>
        </div>
        <!-- End Modal -->

        <slot name="after" />
      </b-navbar-nav>
    </div>

    <assets-lib-modal :modal.sync="mediaModal" />
  </b-navbar>
</template>

<script>
import {mapState} from 'vuex'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import Icon from '@/components/editor/utils/Icon'
import AssetsLibModal from '@/components/assets-library/AssetsLibModal.vue'
import ModuleSelectMenu from '@/components/editor/components/module-select-menu/ModuleSelectMenu.vue'

export default {
  name: 'ModuleEditorHeader',
  components: {ModuleSelectMenu, AssetsLibModal, Icon},
  mixins: [ResolutionMixin],

  props: {
    showSave: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      loading: false,
      mediaModal: false
    }
  },

  computed: {
    ...mapState('editor', {
      backLink: state => state.backLink
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      page: state => state.page
    })
  },

  methods: {
    goBack () {
      if (this.backLink) {
        if (this.backLink.name.startsWith('http')) window.location = this.backLink.name

        return this.$router.push(this.backLink)
      }

      this.$router.back()
    },
    setResolution (resolution) {
      if (resolution === 'md') {
        this.$store.commit('editor/SET_TABLET', true)
        this.$store.commit('editor/SET_RESOLUTION', 'sm')
      } else {
        this.$store.commit('editor/SET_TABLET', false)
        this.$store.commit('editor/SET_RESOLUTION', resolution)
      }
    }
  }
}
</script>

<style lang="scss">
.resolution-toggle {
  .btn {
    width: 41px;
    border: 1px solid #F0F1F2;

    &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
