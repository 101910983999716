<template>
  <b-row
    no-gutters
  >
    <b-col md="12">
      <b-form>
        <b-form-group>
          <label class="flex items-center">
            Webhook
            <div
              v-b-tooltip.hover
              class="text-primary"
              title="How to connect Zapier"
              @click="guideLink"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="w-[14px] h-[14px] ml-[5px]"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
            </div>
          </label>
          <b-input
            v-model="urlWebhook"
            class="custect"
            label="Url Webhook"
            placeholder="Enter webhook address"
          />
        </b-form-group>

        <b-btn
          :disabled="!urlWebhook"
          title="Save or update Zapier Webhook"
          variant="white"
          @click="saveOrUpdateWebhook"
        >
          <svg
            class="mr-[7.5px]"
            fill="none"
            height="8"
            viewBox="0 0 9 8"
            width="9"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.35355 0.146447C1.15829 -0.0488155 0.841709 -0.0488155 0.646447 0.146447C0.451184 0.341709 0.451184 0.658291 0.646447 0.853553L3.79289 4L0.646447 7.14645C0.451184 7.34171 0.451184 7.65829 0.646447 7.85355C0.841709 8.04881 1.15829 8.04881 1.35355 7.85355L4.85355 4.35355C5.04882 4.15829 5.04882 3.84171 4.85355 3.64645L1.35355 0.146447Z"
              fill="#3D82EA"
            />
            <path
              d="M4.85355 0.146447C4.65829 -0.0488155 4.34171 -0.0488155 4.14645 0.146447C3.95118 0.341709 3.95118 0.658291 4.14645 0.853553L7.29289 4L4.14645 7.14645C3.95118 7.34171 3.95118 7.65829 4.14645 7.85355C4.34171 8.04881 4.65829 8.04881 4.85355 7.85355L8.35355 4.35355C8.54881 4.15829 8.54881 3.84171 8.35355 3.64645L4.85355 0.146447Z"
              fill="#3D82EA"
            />
          </svg>

          Save
        </b-btn>
      </b-form>

      <b-btn
        v-if="urlWebhook"
        :disabled="!urlWebhook"
        title="Update Zapier Mapping Fields"
        variant="white"
        class="mt-3"
        @click="updateMapping"
      >
        <i
          class="fa fa-refresh"
        />

        Update Mapping
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Zapier',

  data () {
    return {
      urlWebhook: ''
    }
  },

  mounted () {
    this.getData()
  },

  props: {
    config: {
      required: true,
      type: Object
    },
    orderForm: {
      required: false,
      type: Boolean
    }
  },

  methods: {
    guideLink () {
      this.$router.push({name: 'settings.zapier'})
    },
    getData () {
      if (this.orderForm) {
        this.urlWebhook = this.config.form?.webhook?.zapier || ''
      } else {
        axios.get(`api/projects/${this.project.id}/forms/${this.fieldsForMapping[0].form_id}`).then(({ data }) => {
          this.urlWebhook = data?.data?.webhook?.url || ''
        })
      }
    },

    saveOrUpdateWebhook () {
      if (this.orderForm) {
        if (!this.config.form.webhook) this.config.form.webhook = {zapier: ''}
        this.config.form.webhook.zapier = this.urlWebhook
        this.updateMapping()
      } else {
        axios.post(`api/projects/${this.project.id}/forms/webhook`, {
          form_id: this.orderForm ? 0 : this.fieldsForMapping[0].form_id,
          url: this.urlWebhook
        }).then(({ data }) => {
          if (data.status === 'success') {
            this.updateMapping()
          }
        })
      }
    },

    updateMapping () {
      let mappedData = []
      let formID = 0
      if (this.orderForm) {
        mappedData = this.config.form.controls.reduce((acc, field) => {
          acc[field.field_name] = field.field_type === 'email' ? 'test@estage.com' : 'estage'
          return acc
        }, {})
      } else {
        mappedData = this.fieldsForMapping.reduce((acc, field) => {
          acc[field.field_name] = field.field_type === 'email' ? 'test@estage.com' : 'estage'
          return acc
        }, {})
        formID = this.fieldsForMapping[0].form_id
      }
      const query = this.orderForm ? `?webhook_url=${this.urlWebhook}` : ''

      
      axios.post(
        `api/projects/${this.project.id}/forms/webhook/${formID}/send${query}`,
        mappedData
      ).then(({ data }) => {
        if (data.status === 'success') {
          this.$swal({
            icon: 'warning',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Please go back to Zapier, create/setup field mapping, and complete the setup.',
            showConfirmButton: false,
            timer: 7500
          })
        }
      })
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),

    fieldsForMapping () {
      return this.config.controls
    }
  }
}
</script>