<template>
  <div class="editor blog-post">
    <editor-menu-bar
      v-slot="{ commands, isActive, getNodeAttrs }"
      :editor="editor"
    >
      <b-row
        class="menubar"
        no-gutters
      >
        <b-col
          cols="2"
          class="mr-2"
        >
          <b-form-group>
            <v-select
              v-model="fontFamily"
              :options="fonts"
              class="custom-select"
              @input="event => applyFontFamily(event, commands, getNodeAttrs)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="2"
          class="mr-2"
        >
          <b-form-group>
            <v-select
              left
              :value="isActiveHeading(isActive)"
              :reduce="option => option.value"
              class="custom-select"
              :options="headings"
              @input="event => applyFontSize(event, commands, getNodeAttrs)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="2"
          class="mr-2"
        >
          <b-form-group>
            <v-select
              v-model="fontWeight"
              left
              :searchable="false"
              class="custom-select"
              :reduce="option => option.value"
              :options="fontsWeight"
              @input="event => applyFontWeight(event, commands, getNodeAttrs)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="1"
          class="mr-2"
        >
          <b-form-group>
            <b-input
              v-model="pickedColor"
              type="color"
              @change="event => applyTextColor(event, commands, getNodeAttrs)"
            />
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-btn-group class="mr-2">
            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <i class="fa fa-bold" />
            </b-btn>
            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <i class="fa fa-italic" />
            </b-btn>
            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <i class="fa fa-underline" />
            </b-btn>
          </b-btn-group>

          <b-btn-group class="mr-2">
            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.code() }"
              @click="commands.code"
            >
              <i class="fa fa-code" />
            </b-btn>
          </b-btn-group>

          <b-btn-group class="mr-2">
            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <i class="fa fa-list-ul" />
            </b-btn>

            <b-btn
              variant="white"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <i class="fa fa-list-ol" />
            </b-btn>
          </b-btn-group>

          <b-btn-group class="mr-2">
            <b-btn
              variant="white"
              @click="commands.horizontal_rule"
            >
              Horizontal line
            </b-btn>
          </b-btn-group>
        </b-col>
      </b-row>
    </editor-menu-bar>

    <editor-content
      class="editor__content py-5"
      :editor="editor"
    />
  </div>
</template>

<script>
import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  HorizontalRule
} from 'tiptap-extensions'
import FontSize from '../../../plugins/TiptapFontSize'
import FontWeight from '../../../plugins/TiptapFontWeight'
import TextColor from '../../../plugins/TiptapTextColor'
import FontFamily from '../../../plugins/TiptapFontFamily'

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },

  props: {
    value: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      fontFamily: 'Font Family',
      fontWeight: null,
      heading: null,
      pickedColor: '#000',
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new ListItem(),
          new OrderedList(),
          new TodoItem({
            nested: true
          }),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new HorizontalRule,
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new FontSize(),
          new FontWeight(),
          new TextColor(),
          new FontFamily()
        ],
        content: this.value,
        onUpdate: ({getHTML}) => {
          const content = getHTML()
          this.$emit('input', content)
        }
      }),
      headings: [
        {
          label: 'Heading',
          value: null
        },
        {
          label: 'H2',
          value: 2
        },
        {
          label: 'H3',
          value: 3
        },
        {
          label: 'H4',
          value: 4
        },
        {
          label: 'H5',
          value: 5
        },
        {
          label: 'H6',
          value: 6
        }
      ],
      fonts: [
        'Font Family',
        'Roboto',
        'Montserrat',
        'Georgia',
        'Playfair Display',
        'Raleway',
        'Spectral',
        'Rubik'
      ],
      fontsWeight: [
        {
          label: 'Font Weight',
          value: null
        },
        {
          label: 'Light',
          value: '300'
        },
        {
          label: 'Regular',
          value: '400'
        },
        {
          label: 'Medium',
          value: '500'
        },
        {
          label: 'Bold',
          value: '600'
        }
      ]
    }
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    isActiveHeading (isActive) {
      let active = null
      this.headings.forEach(h => isActive.heading({ level: h.value }) ? active = h.value : null)
      return active
    },
    applyTextColor (val, commands) {
      this.pickedColor = val
      commands.textcolor({color: val})
    },
    applyFontFamily (val, commands) {
      setTimeout(() => {
        commands.fontfamily({fontFamily: val})
      }, 100)

      this.fontFamily = 'Font Family'
    },
    applyFontWeight (val, commands) {
      setTimeout(() => {
        commands.fontweight({fontWeight: val})
      }, 100)

      this.fontWeight = null
    },
    applyFontSize (val, commands) {
      setTimeout(() => {
        commands.heading({ level: val })
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.blog-post {
  &.editor {
    min-height: 100vh;
  }

  ul[data-type="todo_list"] {
    padding-left: 0;
  }

  li[data-type="todo_item"] {
    display: flex;
    flex-direction: row;
  }

  .todo-checkbox {
    border: 2px solid #000;
    height: 0.9em;
    width: 0.9em;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 0.3rem;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    border-radius: 0.2em;
    background-color: transparent;
    transition: 0.4s background;
  }

  .todo-content {
    flex: 1;

    > p:last-of-type {
      margin-bottom: 0;
    }

    > ul[data-type="todo_list"] {
      margin: .5rem 0;
    }
  }

  li[data-done="true"] {
    > .todo-content {
      > p {
        text-decoration: line-through;
      }
    }

    > .todo-checkbox {
      background-color: #000;
    }
  }

  li[data-done="false"] {
    text-decoration: none;
  }
}
</style>