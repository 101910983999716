<template>
  <div class="pt-3">
    <b-form-group
      class="w-full"
      label="Lottie JSON"
    >
      <upload-asset
        type="json"
        @uploaded="uploadedLottie"
      />
    </b-form-group>

    <b-form-group label="JSON URL">
      <div class="flex items-center gap-[10px]">
        <b-input v-model="config.jsonPath" />

        <i
          v-b-tooltip
          title="Delete"
          class="fa fa-trash cursor-pointer hover:opacity-60"
          @click="() => config.jsonPath = ''"
        />
      </div>
    </b-form-group>

    <sid-align
      label="Alignment"
      :sid="`.--lottie-container-${sid}`"
      rule-name="justify-content"
    />

    <b-form-group class="!mb-[10px]">
      <b-checkbox v-model="config.loop">
        Loop
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.autoplay">
        Autoplay
      </b-checkbox>
    </b-form-group>

    <sid-range-slider
      label="Lottie Size"
      :sid="`.--lottie-container-${sid} .button-lottie-icon`"
      :dimensions="['px', 'rem']"
      default-dimension="px"
      rule-name="width"
      class="mb-0"
    />
  </div>
</template>

<script>
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import UploadAsset from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/UploadAsset'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
export default {
  name: 'LottieConfig',
  components: {SidAlign, UploadAsset, SidRangeSlider},
  props: {
    config: {
      type: Object,
      required: true
    },
    sid: {
      type: String,
      required: true
    }
  },
  mounted () {
    if (!this.config.hasOwnProperty('loop') && !this.config.hasOwnProperty('autoplay')) {
      this.$set(this.config, 'loop', true)
      this.$set(this.config, 'autoplay', true)
    }
  },
  methods: {
    uploadedLottie (file) {
      this.$set(this.config, 'jsonPath', file.url)
    }
  }
}
</script>
