<template>
  <b-modal
    v-model="modal"
    body-class="overflow-hidden p-0"
    hide-footer
    hide-header
    lazy
    size="lg"
  >
    <div>
      <b-link
        class="position-absolute top-[20px] right-[20px]"
        @click="modal = false"
      >
        <svg
          fill="none"
          height="11"
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>

      <div
        class="h-[118px] d-flex align-items-center justify-content-center"
        :class="[item.light_icon ? 'bg-[#1D1D1D]' : 'bg-[#EDF4FA]']"
        :style="item.img_con_styles"
      >
        <b-img
          :src="item.image"
          class="h-[58px]"
          :style="item.img_styles"
        />
        <span
          v-if="item.image_text"
          class="ml-2 text-semibold text-[25px]"
        >{{ item.image_text }}</span>
      </div>

      <b-row class="mb-[100px]">
        <b-col
          cols="10"
          offset="1"
        >
          <div class="my-[37px] flex flex-col gap-y-[16px]">
            <div class="text-[31px] text-center ">
              {{ item.full_title || `Connect to ${item.title}` }}
            </div>

            <div
              v-if="item.note"
              class="w-full flex gap-2 border border-[#E3E3E3] rounded-[4px] p-[10px] text-[14px] leading-[20px] text-[#6E747A] text-start"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="w-5 h-5"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                />
                <path d="M12 16v-4" />
                <path d="M12 8h.01" />
              </svg>
              {{ item.note }}
            </div>

            <p
              v-if="item.desc"
              class="text-[16px] leading-[26px] text-[#6E747A] text-center "
            >
              {{ item.desc }}
            </p>

            <div
              v-if="item.video"
              class="embed-responsive embed-responsive-16by9"
            >
              <iframe
                ref="iframe"
                :src="item.video"
                frameborder="0"
                loading="lazy"
                class="embed-responsive-item"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; screen-wake-lock"
                allowfullscreen
              />
              <div class="ambient ambient-t" />
              <div class="ambient ambient-l" />
              <div class="ambient ambient-r" />
              <div class="ambient ambient-b" />
            </div>
            
            <div class="flex flex-col gap-[16px]">
              <b-form-group
                v-for="(field, index) in item.integration_data"
                :key="index"
                :label="field.label"
                class="w-full relative flex flex-col"
              >
                <i
                  v-if="field.type !== 'link'"
                  class="absolute right-4 -translate-y-1/2 text-gray-400 text-lg cursor-pointer"
                  :class="[
                    field.type === 'textarea' ? 'top-5' : 'top-1/2 -translate-y-1/2',
                    copiedIndex === index ? 'fa fa-clipboard-check' : 'fa fa-clipboard'
                  ]"
                  @click="onCopy(index, field.value)"
                />

                <i
                  v-if="field.type === 'link'"
                  class="absolute right-4 -translate-y-1/2 text-gray-400 text-lg cursor-pointer fa fa-external-link"
                  :class="[field.type === 'textarea' ? 'top-5' : 'top-1/2 -translate-y-1/2']"
                  @click="onCopy(index, field.value)"
                />
                
                <b-form-textarea
                  v-if="field.type === 'textarea'"
                  v-model="field.value"
                  class="!pr-12"
                  readonly
                  rows="4"
                />
                
                <b-input
                  v-else-if="field.type === 'link'"
                  v-model="field.value"
                  type="text"
                  class="!pr-12 hover:text-blue-500 hover:underline cursor-pointer"
                  readonly
                  @click.prevent="openLink(field.value)"
                />

                <b-input
                  v-else-if="field.type !== 'hidden' && field.type !== 'textarea'"
                  v-model="field.value"
                  :type="field.type"
                  class="!pr-12"
                  readonly
                />
              </b-form-group>
            </div>
          </div>
          <b-form @submit.prevent="submit">
            <b-form-group
              v-for="(field, index) in formFields"
              :key="index"
              :label="field.label"
              :invalid-feedback="field.validation(field.value).message"
              class="!mb-[37px]"
              label-class="text-[16px] !font-normal"
            >
              <!-- Custom Logics -->
              <template v-if="item.alpha_code === 'activecampaign' && field.key === 'client_secret'">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="bg-white font-size-14">
                      https://
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-input
                    v-model="field.value"
                    :type="field.type"
                    required
                    :state="!field.dirty ? undefined : !validationErrors.includes(field.label)"
                    @input="field.dirty = true"
                  />
                  <b-input-group-append>
                    <b-input-group-text class="bg-white font-size-14">
                      .api-us1.com
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </template>
              <template v-else>
                <b-form-textarea
                  v-if="field.type === 'textarea'"
                  v-model="field.value"
                  required
                  :state="!field.dirty ? undefined : !validationErrors.includes(field.label)"
                  rows="4"
                  @input="field.dirty = true"
                />

                <b-input
                  v-if="field.type !== 'hidden' && field.type !== 'textarea'"
                  v-model="field.value"
                  :type="field.type"
                  required
                  :state="!field.dirty ? undefined : !validationErrors.includes(field.label)"
                  @input="field.dirty = true"
                />
              </template>
            </b-form-group>

            <div class="d-flex justify-center">
              <button
                :disabled="loading || validationErrors.length > 0"
                class="disabled:opacity-80 font-bold text-[15px] !bg-[#3D82EA] hover:opacity-90 rounded px-[31px] h-[52px] d-flex align-items-center text-white justify-content-center"
                type="submit"
              >
                {{ item.submit_text ||'Connect My Account' }}
                <b-spinner
                  v-if="loading"
                  class="ml-2"
                  small
                  variant="white"
                />
              </button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'IntegrationModal',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formFields:[],
      modal: false,
      loading: false,
      copiedIndex: null
    }
  },
  computed: {
    validationErrors () {
      return this.formFields.filter(field => field.validation && !field.validation(field.value).valid && field.dirty)
        .map(field => {
          return field.label
        })
    }
  },
  watch: {
    modal (newValue) {
      if (newValue) {
        this.formFields = _.cloneDeep(this.item.data || [])
      }
    }
  },
  methods: {
    openLink (link) {
      window.open(link, '_blank')
    },
    onCopy (index, value) {
      navigator.clipboard.writeText(value)
      this.copiedIndex = index

      setTimeout(() => {
        this.copiedIndex = null
      }, 500)
    },
    setLoading (val) {
      this.loading = val
    },
    closeModal () {
      this.modal = false
    },
    submit () {
      if (!this.validationErrors.length) {
        this.formFields.forEach(field => field.dirty = true)
      }

      if (this.validationErrors.length) return
      const {setLoading, closeModal, formFields} = this
      this.$emit('submit',{setLoading, closeModal, formFields})
    }
  }
}
</script>