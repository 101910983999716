<template>
  <div
    v-if="showVariant"
    :data-sid="section.sid"
    :class="`--${section.sid}--wrapper`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="w-full !mb-[0]"
        :class="`--${section.sid}--product-variant`"
      >
        <div
          class="flex justify-between items-center gap-[12px]"
          :class="`--${section.sid}--header`"
        >
          <p :class="`--${section.sid}--header-name`">
            {{ section.options.content.productOptions.plan.text }}
          </p>
          <p :class="`--${section.sid}--header-price`">
            {{ section.options.content.productOptions.price.text }}
          </p>
        </div>

        <b-form-radio-group 
          v-model="plan"
          class="flex flex-col"
          :class="`--${section.sid}--products`"
        >
          <template #default>
            <div
              v-for="(product, index) in products"
              :key="index"
              :class="[`--${section.sid}--product`, product.id === plan ? 'active' : '']"
            >
              <div 
                v-if="!product.prices || product.prices.length === 0"
                class="flex items-center justify-between gap-[12px]"
                :class="`--${section.sid}--variant`"
              >
                <b-form-radio
                  :value="product.id"
                  @change="setSelectedProduct(product)"
                >
                  <p :class="[`--${section.sid}--variant-name`, product.id === plan ? 'active' : '']">
                    {{ product.name }}
                  </p>
                </b-form-radio>
                <p :class="[`--${section.sid}--variant-price flex items-center`, product.id === plan ? 'active' : '']">
                  {{ product.trial ? `${product.trial} Day(s) FREE, then` : null }}
                  <span
                    v-if="product.price === '0.00' || product.cost === '0.00'"
                    class="ml-1"
                  >
                    Free
                  </span>
                  <Price
                    v-else
                    :amount="Number(product.price || product.cost)"
                    :currency-code="product.currency || 'USD'"
                    class="ml-1"
                  />
                  {{ (product.interval && product.interval !== 'one_time') ? `/${product.interval.substring(0, 2)}.` : null }}
                </p>
              </div>
              <div v-else>
                <p :class="`--${section.sid}--product-name`">
                  {{ product.name }}
                </p>
                <div 
                  class="flex flex-col"
                  :class="`--${section.sid}--variants`"
                >
                  <div
                    v-for="(price, index) in product.prices"
                    :key="index"
                    class="flex flex-row justify-between items-center gap-[12px]"
                    :class="`--${section.sid}--variant`"
                  >
                    <b-form-radio 
                      :value="price.id"
                      @change="setSelectedProduct(product)"
                    >
                      <p :class="[`--${section.sid}--variant-name`, price.id === plan ? 'active' : '']">
                        {{ price.name }}
                      </p>
                    </b-form-radio>

                    <p :class="[`--${section.sid}--variant-price flex items-center`, price.id === plan ? 'active' : '']">
                      {{ product.trial ? `${product.trial} Day(s) FREE, then` : null }} 
                      <span
                        v-if="price.cost === '0.00'"
                        class="ml-1"
                      >
                        Free
                      </span>
                      <Price
                        :amount="Number(price.cost)"
                        currency-code="USD"
                        class="ml-1"
                      />
                      {{ price.interval ? `/${price.interval.substring(0, 2)}.` : null }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-form-radio-group>
      </b-form-group>
    </highliter> 
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import Price from '@/components/utils/Price.vue'
export default {
  name: 'ElProductVariant',
  mixins: [SectionMixin],
  components: {Price, Highliter},
  data () {
    return {
      plan: null
    }
  },
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState('orderForm', {
      selectedProduct: state => state.selectedProduct
    }),
    products () {
      return this.form?.products || []
    },
    isSingleProduct () {
      if (!this.products.length) return false
      const singleProduct = this.products.length === 1
      const hasPrices = this.products[0]?.prices?.length > 0
      return singleProduct && !hasPrices
    },
    showVariant () {
      return !this.isSingleProduct
    }
  },
  methods: {
    ...mapMutations({
      CHANGE_SELECTED_PRODUCT: 'orderForm/CHANGE_SELECTED_PRODUCT'
    }),
    defaultVariant () {
      if (Array.isArray(this.products) && this.products.length > 0) {
        const hasPrices = this.products[0].prices && this.products[0].prices.length > 0
        this.plan = hasPrices ? this.products[0].prices[0].id : this.products[0].id
        this.CHANGE_SELECTED_PRODUCT({id: this.form.id, product: this.products[0]})
      }
    },
    setSelectedProduct (product) {
      this.CHANGE_SELECTED_PRODUCT({id: this.form.id, product: product})
    }
  },
  created () {
    this.defaultVariant()
  },
  watch: {
    products () {
      this.defaultVariant()
    }
  }
}
</script>