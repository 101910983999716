<template>
  <div
    v-if="showSummary && !reload"
    :data-sid="`product-summary-${sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}--margin`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element flex flex-col"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex flex-col m-0"
        :class="`--${section.sid}--container`"
      >
        <h4
          class="payment-summary-title text-left m-0"
          :class="`--${section.sid}--title`"
        >
          Payment Summary
        </h4>
      
        <div
          class="flex flex-row items-center justify-between"
          :class="`--${section.sid}--item`"
        >
          <p
            class="payment-summary-product text-left m-0"
            :class="`--${section.sid}--product`"
          >
            {{ product.name }}
          </p>
          <span
            class="payment-summary-price text-right m-0 flex items-center"
            :class="`--${sid}--price`"
          >
            {{ product.trial ? `${product.trial} Day(s) FREE, then` : null }}
            <span
              v-if="product.price === '0.00' || product.cost === '0.00'"
              class="ml-1"
            >
              Free
            </span>
            <Price
              v-else
              :amount="Number(product.price || product.cost)"
              :currency-code="product.currency || 'USD'"
              class="ml-1"
            />
            {{ product.interval && product.interval !== 'one_time' ? `/${product.interval.substring(0, 2)}.` : null }}
          </span>
        </div>

        <template v-if="couponAvailable && section.options.content.paymentSummary.showCoupon">
          <hr class="my-0">
          
          <div
            v-if="code.length"
            class="flex flex-row items-center justify-between"
            :class="`--${section.sid}--item`"
          >
            <p
              class="payment-summary-product text-left m-0"
              :class="`--${section.sid}--product`"
            >
              {{ code }}
            </p>
            <span
              class="payment-summary-price text-right m-0 flex items-center"
              :class="`--${sid}--price`"
            >
              <Price
                :amount="Number(product.price / 2 || product.cost / 2)"
                :currency-code="product.currency || 'USD'"
                class="ml-1"
                :negative="true"
              />
            </span>
          </div>
          <div class="flex flex-col">
            <b-form-group
              class="estage-form-element-group"
              :class="`--${sid}--form-field`"
            >
              <div class="relative">
                <b-form-input
                  id="coupon-field"
                  v-model="code"
                  placeholder="Coupon code"
                  required
                  class="input"
                  :class="`--${sid}--coupon-field`"
                />
                <span
                  type="button"
                  class="coupon-button"
                  :class="`--${section.sid}--coupon-button`"
                >
                  Apply
                </span>
              </div>
              <p
                v-if="code.length"
                class="text-xs text-[#6E747A] mt-2 mb-0"
                :class="`--${sid}--coupon-comment`"
              >
                Coupon message
              </p>
            </b-form-group>

            <div
              class="flex flex-row items-center justify-between"
              :class="`--${section.sid}--item`"
            >
              <p
                class="payment-summary-product text-left m-0"
                :class="`--${section.sid}--product`"
              >
                Total due
              </p>
              <span
                class="payment-summary-price text-right m-0 flex items-center"
                :class="`--${sid}--price`"
              >
                {{ product.trial ? `${product.trial} Day(s) FREE, then` : null }}
                <span
                  v-if="product.price === '0.00' || product.cost === '0.00'"
                  class="ml-1"
                >
                  Free
                </span>
                <Price
                  v-else
                  :amount="Number(total)"
                  :currency-code="product.currency || 'USD'"
                  class="ml-1"
                />
                {{ product.interval && product.interval !== 'one_time' ? `/${product.interval.substring(0, 2)}.` : null }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </highliter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import TextMixins from '@/components/mixins/TextMixins'
import Price from '@/components/utils/Price.vue'
export default {
  name: 'ElPaymentSummary',
  mixins: [BorderMixin, SectionMixin, TextMixins],
  components: {Price, Highliter},
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      reload: false,
      code: ''
    }
  },
  computed: {
    ...mapState('orderForm', {
      selectedProduct: state => state.selectedProduct
    }),
    product () {
      const data = this.selectedProduct.find(item => item.id === this.form?.id)
      return data.product
    },
    showSummary () {
      return this.product.prices && this.product.prices.length === 0
    },
    couponAvailable () {
      return this.showSummary && !this.product.type_plan && !this.product.interval && Number(this.product.price || this.product.cost) > 0
    },
    total () {
      if (this.code.length) {
        const originalPrice = this.product.price || this.product.cost
        return originalPrice / 2
      } else {
        return this.product.price || this.product.cost
      }
    }
  },
  mounted () {
    if (this.section.options.content.paymentSummary.showCoupon === undefined) {
      this.section.options.content.paymentSummary.showCoupon = true
      this.reload = true
      setTimeout(() => this.reload = false, 250)
    }
  }
}
</script>

<style lang="scss">
.coupon-button {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
  height: 100%;
  padding: 0 16px;
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}
</style>