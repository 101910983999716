<template>
  <b-form-group>
    <legend
      tabindex="-1"
      class="bv-no-focus-ring col-form-label pt-0"
    >
      Tags
    </legend>
    <div class="d-flex align-items-center gap-[8px] mb-2">
      <tags-select
        ref="select"
        v-model="tags"
        :reduce="t => t.tagId"
        class="w-full tag-select-popped"
        multiple
        :loading="loading"
        :prepend-options="tagsList"
        label="name"
      />
    </div>

    <b-link
      class="mb-2"
      :class="{ 'opacity-0 pointer-events-none': loading }"
      @click="openModal()"
    >
      Add new tag
    </b-link>


    <b-modal
      v-model="modal"
      hide-footer
      hide-header
      body-class="p-0"
      size="lg"
    >
      <div class="d-flex align-items-center justify-end mb-4 pr-4 pt-3">
        <b-link @click="modal = false">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
              fill="#6E747A"
            />
          </svg>
        </b-link>
      </div>

      <div class="text-center mb-5">
        <h2
          class="font-weight-bold"
          v-text="!form.id ? 'Add New Tag' : 'Edit Tag'"
        />
      </div>
    
      <b-form @submit.prevent="submit">
        <b-row>
          <b-col
            cols="8"
            offset="2"
          >
            <b-form-group label="Name">
              <b-input
                v-model="form.name"
                class="font-size-16 text-dark"
                required
                size="lg"
                placeholder="Enter Name..." 
              />
            </b-form-group>
            <div class="d-flex justify-content-center mb-4 mt-5">
              <b-form-group>
                <b-btn
                  type="submit"
                  :disabled="submiting"
                  variant="primary"
                  class="font-size-16 font-weight-bold rounded-sm btn-custom-lg"
                  size="lg"
                >
                  {{ !form.id ? 'Add New' : 'Edit' }} Tag
                  <b-spinner
                    v-if="submiting"
                    class="ml-2 !w-4 !h-4"
                    variant="white"
                  />
                </b-btn>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-form-group>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import _ from 'lodash'

export default {
  name: 'AutoresponderTagsSelect',
  components: {TagsSelect},
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    projectId: {
      type: [String, Number],
      required: false
    }
  },
  data () {
    return {
      modal: false,
      loading:false,
      submiting:false,
      tagsList:[],
      form:{}
    }
  },
  computed: {
    ...mapState('projects', {
      autoResponderTags: state => state.autoResponderTags
    }),
    tags: {
      get () {
        return this.value || []
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  async created () {
    await this.getTagList()
  },
  destroyed () {
  },
  methods: {
    ...mapActions({
      getAutoResponderTags:'projects/getAutoResponderTags',
      createAutoResponderTags:'projects/createAutoResponderTags'
    }),
    async getTagList (){
      this.loading = true
      await this.getAutoResponderTags(this.getCoreParams())
      const tags =  _.get(this.autoResponderTags,[this.projectId, this.type], [])
      this.tagsList = tags
      this.loading = false
    },
    getCoreParams (){
      return { project_id: this.projectId, alpha_code: this.type }
    },
    openModal (data = {}){
      this.form = {
        id:data.id||'',
        name:data.name||''
      }
      this.modal = true
    },
    submit (){
      this.submiting = true
      const params = {...this.getCoreParams(),...this.form}
      params.name = _.replace(params.name, / /g, '_')
      this.createAutoResponderTags(params).then((result) => {
        this.tagsList.push(result)
        this.value.push(result.tagId)
        this.$emit('input', this.value)
        this.submiting = false
        this.modal = false
      }).catch((err) => {
        console.error(err)
        this.submiting = false
      })
    }
  }
}
</script>
