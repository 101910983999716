import _ from 'lodash'

const state = () => ({
  selectedProvider: [],
  providers: [],
  products: [],
  selectedProduct: [],
  forms: [],
  api_keys: {
    square: null
  }
})

const actions = {
  setProvider ({ commit }, provider) {
    commit('SET_PROVIDER', provider)
  },
  setProvidersList ({ commit }, list) {
    commit('SET_PROVIDERS_LIST', list)
  },
  addProvidersList ({ commit }, item) {
    commit('ADD_PROVIDERS_LIST', item)
  },
  removeProvidersList ({ commit }, item) {
    commit('REMOVE_PROVIDERS_LIST', item)
  },
  setSquareApiKey ({state}, payload) {
    state.api_keys = payload
  }
}

const mutations = {
  SET_FORM (state, provider) {
    state.forms.push({
      id: provider.id,
      step: 0,
      products: []
    })
    state.selectedProduct.push({
      id: provider.id,
      product: null
    })
  },
  SET_STEP (state, provider) {
    const index = state.forms.findIndex(item => item.id === provider.id)
    if (index !== -1) {
      state.forms[index].step = provider.step
    }
  },
  SET_PRODUCT (state, payload) {
    const index = state.forms.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.forms[index].products = payload.products
    }
  },
  SPLICE_PRODUCT (state, payload) {
    const index = state.forms.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.forms[index].products.splice(payload.index, 1)
    }
  },
  PUSH_PRODUCT (state, payload) {
    const index = state.forms.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.forms[index].products.push(payload.product)
    }
  },
  CHANGE_SELECTED_PRODUCT (state, payload) {
    const index = state.selectedProduct.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.selectedProduct[index].product = payload.product
    }
  },
  SET_PROVIDER (state, provider) {
    state.selectedProvider.push(provider)
  },
  CHANGE_PROVIDER (state, payload) {
    const index = state.selectedProvider.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.selectedProvider[index].provider = payload.provider
    }
  },
  SET_PROVIDERS_LIST (state, list) {
    state.providers = list
  },
  ADD_PROVIDERS_LIST (state, item) {
    state.providers.push(item)
  },
  REMOVE_PROVIDERS_LIST (state, item) {
    const index = _.findIndex(state.providers, item)
    state.providers.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
