<template>
  <b-row no-gutters>
    <!--    <b-col cols="12">-->
    <!--      <h5>{{ service.name }} <span v-if="service.status">(<span class="text-success small">Connected</span>)</span></h5>-->
    <!--      <p class="small text-muted mb-2">-->
    <!--        {{ service.short_description }}-->
    <!--      </p>-->
    <!--    </b-col>-->

    <b-col md="12">
      <b-form>
        <template v-if="!service.status">
          <b-form-group label="URL">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="bg-white font-size-14">
                  https://
                </b-input-group-text>
              </b-input-group-prepend>
              <b-input
                v-model="service.client_id"
                :disabled="!!service.status"
              />
              <b-input-group-append>
                <b-input-group-text class="bg-white font-size-14">
                  .api-us1.com
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Key">
            <b-input
              v-model="service.client_secret"
              :disabled="!!service.status"
              type="password"
            />
          </b-form-group>
          <b-btn
            :disabled="!service.client_secret"
            variant="white"
            @click="integrateMailService"
          >
            <svg
              class="mr-[7.5px]"
              fill="none"
              height="8"
              viewBox="0 0 9 8"
              width="9"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.35355 0.146447C1.15829 -0.0488155 0.841709 -0.0488155 0.646447 0.146447C0.451184 0.341709 0.451184 0.658291 0.646447 0.853553L3.79289 4L0.646447 7.14645C0.451184 7.34171 0.451184 7.65829 0.646447 7.85355C0.841709 8.04881 1.15829 8.04881 1.35355 7.85355L4.85355 4.35355C5.04882 4.15829 5.04882 3.84171 4.85355 3.64645L1.35355 0.146447Z"
                fill="#3D82EA"
              />
              <path
                d="M4.85355 0.146447C4.65829 -0.0488155 4.34171 -0.0488155 4.14645 0.146447C3.95118 0.341709 3.95118 0.658291 4.14645 0.853553L7.29289 4L4.14645 7.14645C3.95118 7.34171 3.95118 7.65829 4.14645 7.85355C4.34171 8.04881 4.65829 8.04881 4.85355 7.85355L8.35355 4.35355C8.54881 4.15829 8.54881 3.84171 8.35355 3.64645L4.85355 0.146447Z"
                fill="#3D82EA"
              />
            </svg>

            Integrate
          </b-btn>
        </template>

        <template v-else>
          <div class="mb-[10px] flex items-center gap-x-[10px]">
            <b-btn
              v-b-tooltip
              size="sm"
              variant="white"
              class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
              title="Remove from actions"
              @click="$emit('removeAction')"
            >
              <i 
                class="delete-icon"
              />
            </b-btn>
            <b-btn
              v-b-tooltip
              :title="optionsLoading ? 'Refreshing...' : 'Refresh the list'"
              size="sm"
              variant="white"
              class="p-0 w-[32px] h-[32px] d-flex justify-center items-center" 
              @click="prepare"
            >
              <i 
                class="fa fa-refresh"
              />
            </b-btn>
          </div>

          

          <b-form-group label="List">
            <v-select
              v-model="list_id"
              :options="listOptions"
              :reduce="option => option.id"
              :loading="optionsLoading"
              searchable
              class="custom-select"
              label="name"
              placeholder="Select List"
            />
          </b-form-group>

          <template v-if="APIFieldsByList && list_id">
            <hr class="my-4">

            <h6 class="mb-4">
              Field Mapping
            </h6>

            <form-mapping
              :api-fields="APIFieldsByList"
              :autoresponder="service"
              :controls="fieldsForMapping"
            />
          </template>
          <autoresponder-tags-select
            v-model="custom_tags_id"
            :project-id="project.id"
            :type="service.alpha_code"
          />
        </template>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import AutoResponderIntergationMixin
  from '@builder/components/builder/utils/auto-responders/mixins/AutoResponderIntergationMixin'
import FormMapping from '../../../editor/components/form/FormMapping'
import AutoresponderTagsSelect from '../../utils/AutoresponderTagsSelect.vue'
export default {
  name: 'ActiveCampaign',
  components: {FormMapping, AutoresponderTagsSelect},
  mixins: [AutoResponderIntergationMixin]
}
</script>
