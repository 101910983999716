<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Form Fields"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <form-fields-v2
        :form="config"
        :config="section"
      />

      <sid-flex-direction
        :sid="`.--${section.sid}--form`"
        rule-name="flex-direction"
        :disable-reverse="true"
      >
        <template #column>
          <sid-buttons-group
            label="Horizontal Alignment"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${section.sid}--form`"
            rule-name="align-items"
          />
        </template>

        <template #row>
          <sid-buttons-group
            label="Horizontal Alignment"
            class="mb-0"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'space-between',
                tooltip: 'Between'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'space-around',
                tooltip: 'Around'
              }
            ]"
            :sid="`.--${section.sid}--form`"
            rule-name="justify-content"
          />
        </template>
      </sid-flex-direction>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Form Button Actions"
      content-class="border-bottom"
    >
      <actions-after-submit v-model="config.form.actions" />

      <div>
        <div
          v-for="(action, index) in config.form.actions"
          :key="index"
        >
          <div
            :class="{'bg-[#F9F9F9]': active === index + 4}"
            class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[60px] px-[23px] font-[700] text-[#44474A]"
            @click="active === index + 4 ? active = null : active = index + 4"
          >
            {{ action.name }}

            <svg
              :class="{'transform rotate-180': active === index + 4}"
              fill="none"
              height="7"
              viewBox="0 0 10 7"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
          </div>

          <div
            v-if="active === index + 4"
            class="pt-[16px]"
          >
            <component
              :is="camelCase(action.name)"
              :config="config"
              :controls="config.controls"
              :index="active"
              :service="action"
              class="pb-3"
              @removeAction="removeAction(index)"
              @deleteResponder="deleteResponder"
            />
          </div>
        </div>
      </div>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Form Field Styles"
    >
      <form-style-v2 :config="section" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Form Phone Field Styles"
    >
      <form-phone-style :config="section" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      group
      title="Form Button Styles"
      content-class="pt-3 pb-0"
    >
      <button-mod-v2
        v-if="config.subject"
        :config="config.subject.options.content.button"
        :local-section="config.subject"
        :hide="['align', 'actions']"
        component-name="Button"
        group-prefix="Button"
        :button-alignment="buttonAlignment"
      >
        <template #button-align>
          <sid-buttons-group
            label="Alignment"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                customProperties: [[`.--${config.subject.sid}--container`, ['justify-content', 'start']]],
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                customProperties: [[`.--${config.subject.sid}--container`, ['justify-content', 'center']]],
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                customProperties: [[`.--${config.subject.sid}--container`, ['justify-content', 'end']]],
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${config.subject.sid}--container`"
            rule-name="--align"
            @change="(value) => buttonAlignment = value"
            @mount="(value) => buttonAlignment = value"
          />
        </template>
      </button-mod-v2>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="GDPR Compliance"
    >
      <form-g-d-p-r
        :section="section"
        :config="config"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--form`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--form`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import FormControlsList from '../components/form/FormControlsList'
import FormButton from '../components/form/FormButton'
import PropertiesManager from '../../mixins/PropertiesManager'
import ActionsAfterSubmit from '../components/form/ActionsAfterSubmit'
import Panel from '../controls/Panel'
import TextMixins from '../../mixins/TextMixins'
import FormGDPR from '../components/form/FormGDPR'
import ActionModal from '../../builder/utils/auto-responders/ActionModal'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ButtonMod from '@/components/editor/mods/ButtonMod'
import FormFieldsV2 from '@/components/editor/components/form/FormFieldsV2'
import FormStyleV2 from '@/components/editor/components/form/FormStyleV2'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidStyles from '@/components/mixins/SidStyles'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import FormPhoneStyle from '@/components/editor/components/form/FormPhoneCodeStyle.vue'
import egt from '@/components/builder/utils/auto-responders/ETG.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'

export default {
  name: 'FormMod',
  components: {
    SidBackground,
    RuleTabs,
    SidFlexDirection,
    egt,
    FormPhoneStyle,
    SidButtonsGroup,
    SidLinkedGroup,
    FormStyleV2,
    FormFieldsV2,
    ButtonMod,
    SidbearSettingsGroup, ActionModal, FormGDPR, Panel, ActionsAfterSubmit, FormButton, FormControlsList
  },
  mixins: [ModMixin, ThemeMixin, PropertiesManager, TextMixins, SidStyles],

  data () {
    return {
      active: 0,
      buttonAlignment: ''
    }
  },

  computed: {
    validator () {
      return this.$store.state.validation.validator || this.$validator
    },
    uid () {
      return this.section.uid
    }
  },

  mounted () {
    VEvent.listen('page.save', this.saveForm)
    console.log(this.config)
  },

  beforeDestroy () {
    this.saveForm()
    VEvent.off('page.save', this.saveForm)
  },

  methods: {
    deleteResponder (service) {
      for (let c in this.config.controls) {
        this.$set(this.config.controls[c], 'mapping', this.config.controls[c].mapping.filter(con => con.auto_responder_id !== service.id))
      }

      this.saveForm()
    },
    removeAction (index){
      this.config.form.actions.splice(index, 1)
    },
    saveForm () {
      this.validator.validateAll().then((result) => {
        if (result) {
          this.$store.dispatch('forms/saveForm', this.config)
            .finally(() => {
              // this.$bvToast.toast('Form settings have been updated', {
              //   title: 'Form',
              //   autoHideDelay: 2000,
              //   appendToast: false,
              //   variant: 'white',
              //   solid: true
              // })
            })
          this.$store.commit('validation/SET_VALIDATOR', {
            validator: null
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@builder/assets/scss/_variables.scss";

.hover-soft-primary-bg {
  &.active {
    border-bottom: 1px solid #eee;
  }

  > .fa {
    width: 15px;
    height: 15px;
  }
}

.panel-space-top {
  margin-top: 33px;
}

.panel-settings {
  bottom: 0;
}
</style>