<template>
  <b-modal
    v-if="!pickSubject"
    v-model="modal"
    body-class="nav-modal p-0"
    hide-footer
    hide-header
    size="lg"
  >
    <icon
      class="cursor-pinter ml-3 mt-3"
      icon="close.svg"
      @click.native="() => $emit('input', false)"
    />

    <h4 class="h2 font-weight-bold text-center my-4">
      Navigation
    </h4>

    <b-row
      class="border-top"
      no-gutters
    >
      <template v-if="config.links.length">
        <b-col
          :cols="5"
          class="border-right pb-3"
        >
          <div class="nav-items-els">
            <nested-draggable :children="config.links">
              <template
                slot="item"
                slot-scope="{page, index}"
              >
                <section-item-hover
                  :class="{'active': activeItem ? page.id === activeItem.id : false}"
                  @click.native.stop="setActive(page, index, config.links)"
                >
                  <icon
                    class="move cursor-move mr-3"
                    icon="move.svg"
                  />
                  <div
                    block
                    class="text-capitalize"
                  >
                    {{ page.text }}
                  </div>
                </section-item-hover>
                <nested-draggable
                  :children="page.children"
                  :class="{'drop-placeholder': !page.children.length}"
                  class="ml-4"
                >
                  <template
                    slot="item"
                    slot-scope="subPage"
                  >
                    <section-item-hover
                      @click.native.stop="setActive(subPage.page, subPage.index, page.children)"
                    >
                      <icon
                        class="move cursor-move mr-3"
                        icon="move.svg"
                      />
                      <div
                        block
                        class="text-capitalize"
                      >
                        {{ subPage.page.text }}
                      </div>
                    </section-item-hover>
                  </template>
                </nested-draggable>
              </template>
            </nested-draggable>
          </div>

          <div class="px-3 mt-4">
            <slot />
          </div>
        </b-col>

        <b-col
          v-if="activeItem"
          :cols="7"
          class="d-flex flex-wrap py-3 px-4 editor"
        >
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h6 class="font-weight-bold text-capitalize">
                {{ activeItem.title }}
              </h6>
              <delete-confirm
                title="Are you sure?"
                description="You are about to delete this navigation link and any submenu links it may contain."
                @confirm="removeItem"
              >
                <template v-slot:activator="{on}">
                  <button
                    v-on="on"
                  >
                    <icon
                      v-b-tooltip
                      class="cursor-pinter hover:opacity-80"
                      title="Remove"
                      icon="trash.svg"
                    />
                  </button>
                </template>
              </delete-confirm>
            </div>

            <b-form-group label="Name">
              <b-input
                v-model="activeItem.text"
              />
            </b-form-group>

            <div
              v-if="activeItem.customLink !== 'scroll-to-element'"
            >
              <div
                v-if="activeItem.customLink !== null && !['es-mega-menu', 'es-scroll-to-container'].includes(activeItem.href.id)"
                class="form-group !text-[14px]"
              >
                <pages-select-menu
                  :others="[{id: 'custom-link', title: 'Custom Link'},{id: 'cookie-consent', title: 'Cookie Consent',slug: 'cookie-consent'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
                  input-class="rounded-[4px] !text-[#686e74] !w-full"
                  input-search-class="!w-full"
                  other-title="Other:"
                  input-placeholder="Type to search pages and posts"
                  title="Pages and Posts"
                  add-posts
                  @selectOther="item => activeItem.href = item"
                  @selectPage="item => activeItem.href = item"
                >
                  <template
                    v-if="typeof activeItem.href === 'object' && activeItem.href.title"
                    slot="placeholder"
                  >
                    <div class="px-[13px]">
                      {{ activeItem.href.title }}
                    </div>
                  </template>
                </pages-select-menu>

                <b-form-group v-if="activeItem.customLink !== null && !activeItem.scroll_to_container && activeItem.href.id != 'cookie-consent'">
                  <b-checkbox
                    v-model="activeItem.blank"
                    class="mt-2"
                  >
                    Open in new tab
                  </b-checkbox>
                </b-form-group>
              </div>
             
              <template v-if="activeItem.href.id != 'cookie-consent'">
                <b-form-group v-if="activeItem.href.id !== 'es-scroll-to-container'">
                  <b-checkbox
                    v-model="activeItem.href"
                    :unchecked-value="{id: null, title: ''}"
                    :value="{id: 'es-mega-menu', title: 'Mega Menu'}"
                  >
                    Mega Menu
                    <span class="bg-primary ml-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] px-2 font-normal">
                      BETA
                    </span>
                  </b-checkbox>
                </b-form-group>

                <b-form-group>
                  <b-checkbox
                    v-model="activeItem.href"
                    :unchecked-value="{id: null, title: ''}"
                    :value="{id: 'es-scroll-to-container', title: 'Scroll to Container'}"
                  >
                    Scroll to Container
                    <span class="bg-primary ml-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] px-2 font-normal">
                      BETA
                    </span>
                  </b-checkbox>
                </b-form-group>

                <div
                  v-if="activeItem.href.id === 'es-scroll-to-container'"
                  class="mt-2"
                >
                  <pick-element
                    v-model="activeItem"
                    :allow-picks="['ElFlexWrapperV2', 'BlankV2']"
                  />
                </div>

                <div v-if="activeItem.href.id === 'es-mega-menu'">
                  <select-mega-menu
                    :link="activeItem"
                  />
                </div>
              </template>
              <div>
                <b-form-group
                  v-if="activeItem.href.id === 'custom-link'"
                  label="Link"
                >
                  <b-input v-model="activeItem.customHref" />
                </b-form-group>

                <b-form-group label="Icon">
                  <div class="flex items-center w-full gap-[14px]">
                    <div class="w-[130px]">
                      <IconSelect v-model="activeItem.icon" />
                    </div>
                    <b-checkbox v-model="activeItem.iconAppend">
                      Append Icon
                    </b-checkbox>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </template>

      <template v-else>
        <b-col
          class="d-flex align-items-center justify-content-center p-5"
          cols="12"
        >
          <slot />
        </b-col>
      </template>
    </b-row>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import NestedDraggable from '@/components/editor/utils/NestedDraggable'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import IconSelect from '@/components/editor/components/IconSelect'
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm'
import SelectMegaMenu from '@/components/editor/controls/menu-links-mod/SelectMegaMenu'
import PickElement from '@/components/editor/controls/PickElement.vue'

export default {
  name: 'LinksNavigationModal',
  components: {PickElement, SelectMegaMenu, DeleteConfirm, IconSelect, PagesSelectMenu, NestedDraggable},
  props: {
    value: {
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      activeItem: null,
      activeIndex: null,
      activeGroup: null,
      titleOnly: false,
      loading: false,
      link: null,
      text: '',
      customLink: false,
      href: '',
      blank: false
    }
  },
  computed: {
    ...mapState('editor', {
      pickSubject: state => state.pickSubject
    }),
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    openInNewTab: {
      get () {
        return !!_.get(this.activeItem, 'open_in_new_tab', false)
      },
      set (val) {
        this.$set(this.activeItem, 'open_in_new_tab', val)
      }
    },
    modal: {
      get () {
        return typeof this.value === 'number' || !!this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    modal (val) {
      if (!val) {
        this.text = ''
        this.href = ''
        this.customLink = false
        this.blank = false
        this.link = null
      } else {
        if (typeof this.value === 'number') {
          this.link = _.cloneDeep(this.config.links[this.value])
        }
      }
    }
  },
  methods: {
    removeItem () {
      this.activeItem = null
      this.activeGroup.splice(this.activeIndex, 1)
    },
    setActive (page, index, group) {
      this.activeItem = page
      this.activeIndex = index
      this.activeGroup = group
    },
    updateLink () {
      this.$set(this.config.links, [this.value], this.link)
      this.modal = false
    },
    addListItem () {
      this.config.links.push({
        text: this.text,
        customLink: this.customLink,
        href: this.href,
        blank: this.blank
      })
      this.modal = false
    }
  }
}
</script>