<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('socialLinks')"
  >
    <div
      :class="[{'element-hidden': !visability('socialLinks')}, `--${section.sid}--container`]"
      class="d-flex"
      :data-sid="section.sid"
    >
      <ul
        class="list-unstyled flex list-inline mb-0"
        :class="`--${section.sid}--wrapper`"
      >
        <li
          v-for="(link, index) in section.options.content.socialLinks.links"
          :key="index"
          class="list-inline-item"
        >
          <b-link
            :class="['social-link social-link-' + link.shape, `--${section.sid}--link`]"
            :style="{color: link.color, backgroundColor: link.background}"
          >
            <img
              v-if="link.icon == 'fa fa-image'"
              :src="link.icon_img"
            >
            <i
              v-else
              :class="link.icon"
            />
          </b-link>
        </li>
      </ul>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin]
}
</script>

<style lang="scss">
:root {
  --social-size: 20px;
}

.social-link {
  font-size: var(--social-size);
  width: calc(var(--social-size) + 10px);
  height:  calc(var(--social-size) + 10px);
}
</style>
