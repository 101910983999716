<template>
  <div>
    <b-modal
      id="modal-publishNow"
      v-model="modal"
      body-class="publish-modal"
      hide-footer
      hide-header
      lazy
      size="lg"
    >
      <b-link
        class="link-muted"
        @click="modal = false"
      >
        <svg
          fill="none"
          height="11"
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>
      <div class="py-5">
        <h4 class="text-center title mb-3">
          Publish Your Project
        </h4>
        <h5 class="text-center subtitle">
          {{ project.name }}
        </h5>
      </div>

      <div class="px-5">
        <b-row class="pages-header d-flex text-uppercase border-bottom pb-1">
          <b-col>
            <div class="mr-auto">
              Page
            </div>
          </b-col>
          <b-col
            class="text-nowrap"
            md="2"
          >
            <div class="mr-4">
              Draft / Ready
            </div>
          </b-col>
          <b-col md="2">
            <div>Hide / Show</div>
          </b-col>
        </b-row>
      </div>

      <div class="border-bottom py-4">
        <div class="d-flex align-items-center pl-5 pr-[60px]">
          <b-col md="8">
            <div class="pub-all">
              Publish All
            </div>
          </b-col>
          <b-col
            class="text-center"
            md="2"
          >
            <b-checkbox
              v-model="allSelected"
              :state="allSelected || null"
              size="lg"
              switch
              @input="selectAll"
            />
          </b-col>
          <b-col
            class="text-center"
            md="2"
          >
            <b-checkbox
              v-model="allSelectedToHide"
              size="lg"
              switch
              @input="selectAllToHide"
            />
          </b-col>
        </div>
      </div>

      <div class="text-center my-4">
        <b-spinner
          v-if="!pages"
          variant="primary"
        />
      </div>

      <div
        ref="scroll"
        class="overflow-auto max-h-[400px] pr-[10px]"
      >
        <div
          v-if="pages"
          class="pages mb-4"
        >
          <div class="page-is_home border-bottom page-item cursor-pinter">
            <div class="d-flex justify-content-between align-items-center px-5">
              <b-col
                md="8"
                @click.stop="selectPage(currentPage)"
              >
                <div class="py-3 w-100">
                  <div
                    class="page-name"
                    v-text="currentPage.title"
                  />
                  <div class="page-domain">
                    {{ getSiteHome }}/{{ currentPage.slug }}
                  </div>
                </div>
              </b-col>
              <b-col
                class="text-center"
                md="2"
              >
                <b-checkbox
                  v-model="selectPages"
                  :state="selectPages.includes(currentPage.id) || null"
                  :value="currentPage.id"
                  size="lg"
                  switch
                />
              </b-col>

              <b-col
                class="text-center"
                md="2"
              >
                <b-checkbox
                  v-model="currentPage.page_status"
                  size="lg"
                  switch
                  unchecked-value="DRAFT"
                  value="ACTIVE"
                />
              </b-col>
            </div>
          </div>

          <div
            v-for="page in pages.data.filter(p => p.id !== currentPage.id)"
            :key="page.id"
            class="border-bottom page-item cursor-pinter"
          >
            <div class="d-flex justify-content-between align-items-center px-5">
              <b-col
                md="8"
                @click.stop="selectPage(page)"
              >
                <div class="py-3 w-100">
                  <div
                    class="page-name truncate"
                    v-text="page.title"
                  />
                  <div class="page-domain truncate">
                    {{ getSiteHome }}/{{ page.slug }}
                  </div>
                </div>
              </b-col>

              <b-col
                class="text-center"
                md="2"
              >
                <b-checkbox
                  v-model="selectPages"
                  :state="selectPages.includes(page.id) || null"
                  :value="page.id"
                  size="lg"
                  switch
                />
              </b-col>

              <b-col
                class="text-center"
                md="2"
              >
                <b-checkbox
                  v-model="page.page_status"
                  size="lg"
                  switch
                  unchecked-value="DRAFT"
                  value="ACTIVE"
                />
              </b-col>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mb-3">
        <b-btn
          :disabled="loading || pageIsLoading"
          class="font-size-14 py-3 px-5 font-weight-bold rounded-right-0"
          variant="primary"
          @click="publishNow"
        >
          {{ pageIsLoading ? 'Loading' : 'Update / Publish Now' }}
          <b-spinner
            v-if="pageIsLoading || loading"
            class="ml-2"
            small
          />
        </b-btn>

        <b-dropdown
          v-if="selectPages.length"
          toggle-class="font-size-14 py-3 px-3 rounded-left-0 font-weight-bold border-left"
          variant="primary"
        >
          <template slot="button-content">
            <i
              class="fa fa-ellipsis-h dot-menu fa-lg link-muted cursor-pinter"
              icon="dot-menu.svg"
            />
          </template>

          <b-dropdown-item @click="openCreateTemplate">
            Create a new template with {{ selectPages.length }} page{{ selectPages.length > 1 ? 's' : '' }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-modal>

    <b-modal
      v-model="published"
      hide-footer
      hide-header
      size="lg"
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="published = false"
      />

      <div class="text-center py-5 my-5">
        <icon
          icon="positive-vote.svg"
          size="100px"
        />
        <h4 class="h3 text-center mt-4 mb-3">
          Your Site Has Been Published
        </h4>
        <b-btn
          :href="getSiteHome + `/${currentPage.slug}`"
          class="mb-4"
          target="_blank"
          variant="outline-primary"
        >
          View Online
        </b-btn>
      </div>
    </b-modal>

    <create-own-template-modal
      v-model="createTemplateModal"
      :selected-pages="selectedPages"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import RouterMixin from '../../mixins/RouterMixin'
import _ from 'lodash'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import html2canvas from 'html2canvas'
import CreateOwnTemplateModal from './CreateOwnTemplateModal'

export default {
  name: 'PublishModal',
  components: {CreateOwnTemplateModal},
  mixins: [RouterMixin, PagesPaginationMixin],
  data () {
    return {
      published: false,
      selectPages: [],
      allSelected: false,
      allSelectedToHide: false,
      loading: false,
      modal: false,
      pages: null,
      createTemplateModal: false
    }
  },
  computed: {
    ...mapGetters('pages', {
      allPages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      currentPage: state => state.page
    }),
    selectedPages () {
      return this.selectPages.map(id => {
        return {
          id,
          ..._.find(this.pages.data, ['id', id])
        }
      })
    },
    getSiteHome () {
      return this.project.domain ? this.addhttp(this.project.domain).replace('http://', 'https://') : `https://${this.project.subdomain}.${process.env.VUE_APP_ESTAGE_SITE_HOST.replace('http://', '')}`
    }
  },
  watch: {
    async modal (val) {
      if (val) {
        this.$refs.scroll.addEventListener('scroll', this.scrollLoadingPages)
        this.selectPages.push(this.currentPage.id)
        this.pages = JSON.parse(JSON.stringify(this.allPages))
      } else {
        if (!this.createTemplateModal) {
          this.selectPages = []
        }
        this.currentPaginationPage = 1
        this.$refs.scroll.removeEventListener('scroll', this.scrollLoadingPages)
      }
    },
    async createTemplateModal (val) {
      if (!val) {
        this.selectPages = []
      }
    }
  },
  methods: {
    openCreateTemplate () {
      this.modal = false
      this.createTemplateModal = true
    },
    publishNow () {
      this.$store.commit('projects/SET_PUBLISING', true)
      this.loading = true
      this.pageIsLoading = true
      this.$store.dispatch('projects/publish', {
        all: this.allSelected,
        pages: [this.currentPage, ...this.pages.data.filter(p => p.id !== this.currentPage.id)].map(page => {
          return {
            id: page.id,
            status: page.page_status,
            updContent: this.selectPages.includes(page.id)
          }
        }),
        callback: () => {
          this.$store.commit('projects/SET_PUBLISING', false)
          this.$store.dispatch('pages/loadPages')
          this.loading = false
          this.pageIsLoading = false
          this.modal = false
          this.published = true
          this.takeScreenShot()
        }
      }, {call: true})
    },
    async takeScreenShot () {
      await html2canvas(document.querySelector('.es-root'), {
        scrollX: 0,
        scrollY: -window.scrollY,
        useCORS: true,
        logging: false
      }).then(canvas => {
        let croppedCanvas = document.createElement('canvas'),
          croppedCanvasContext = croppedCanvas.getContext('2d')

        croppedCanvas.width = canvas.width
        croppedCanvas.height = 1900

        croppedCanvasContext.drawImage(canvas, 0, 0, canvas.width, 1900, 0, 0, canvas.width, 1900)

        axios.put(`api/projects/${this.project.id}/screenshot`, {
          screenshot: croppedCanvas.toDataURL()
        })
      })
    },
    selectPage (page) {
      if (this.selectPages.includes(page.id)) {
        return this.selectPages.splice(_.findIndex(this.selectPages, i => i === page.id), 1)
      }

      this.selectPages.push(page.id)
    },
    selectAll () {
      if (this.selectPages.length && !this.allSelected) {
        this.allSelected = false
        return this.selectPages = []
      }

      this.allSelected = true
      return this.selectPages = this.pages.data.map(p => p.id)
    },
    selectAllToHide () {
      this.pages.data.forEach(p => {
        p.page_status = !this.allSelectedToHide ? 'DRAFT' : 'ACTIVE'
      })
    }
  }
}
</script>

<style lang="scss">
.publish-modal {
  .title,
  .subtitle {
    font-family: 'ProximaNova';
  }

  .title {
    font-weight: 600;
    font-size: 27px;
  }

  .subtitle {
    font-size: 19px;
    font-weight: 300;
    color: #979797;
  }

  .pages-header {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 10px;
    color: #AEB1B4;
  }

  .pub-all {
    font-style: italic;
    font-size: 15px;
    line-height: 20px;
    color: #6E747A;
  }

  .page-name {
    color: #6E747A;
    font-size: 15px;
  }

  .page-domain {
    color: #AEB1B4;
    font-size: 14px;
  }

  .page-item {
    transition: background .2s;

    &:hover {
      background: #eee;
    }
  }

  .page-is_home {
    background: #F2F3F5;

    .page-name {
      font-weight: bold;
      color: #333333;
    }
  }
}

#modal-publishNow___BV_modal_outer_ {
  z-index: 100000000000 !important;
}
</style>
