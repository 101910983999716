<template>
  <div>
    <div v-if="image.src">
      <div class="selected d-flex w-100">
        <slot name="prepend" />
        <div class="d-flex pl-2 btn-es-image-upload !h-[30px] btn-block shadow-none">
          <div
            :style="{background: `url(${image.src})`}"
            class="mini-preview mr-3"
          />
          <span
            class="text-left font-weight-normal text-truncate"
            style="width: 70%; color: #6e747a; font-size: 14px;"
          >{{ image.name }}</span>
          <b-dropdown toggle-class="p-0">
            <template slot="button-content">
              <div class="flex items-center justify-center border-left w-[52px] h-[27px]">
                <svg
                  fill="none"
                  height="5"
                  viewBox="0 0 18 5"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="2"
                    cy="2.42859"
                    fill="#3D82EA"
                    r="2"
                  />
                  <circle
                    cx="8.66675"
                    cy="2.42859"
                    fill="#3D82EA"
                    r="2"
                  />
                  <circle
                    cx="15.3333"
                    cy="2.42859"
                    fill="#3D82EA"
                    r="2"
                  />
                </svg>
              </div>
            </template>

            <template v-if="saveable">
              <b-dropdown-item
                v-if="!inFavorites"
                class="d-flex align-items-center"
                @click.native="saveToMyLibrary"
              >
                <i class="far fa-save text-muted mr-2" /> Save this image
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                class="d-flex align-items-center"
                @click.native="removeFromMyLibrary"
              >
                <i class="fa fa-save text-primary mr-2" /> Saved
              </b-dropdown-item>
              <b-dropdown-item
                v-if="image.id"
                class="d-flex align-items-center"
                @click.native="showMyLibrary"
              >
                <i class="fas fa-external-link-alt text-muted mr-2" /> Show in library
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-if="editabel"
              class="d-flex align-items-center"
              @click.native="modal = true"
            >
              <i class="fa fa-plus text-muted mr-2" /> Upload image
            </b-dropdown-item>
            <b-dropdown-item
              v-if="noDelete"
              class="d-flex align-items-center"
              @click.native="deleteImage"
            >
              <icon
                class="text-muted mr-2"
                icon="trash.svg"
              />
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div
      v-else
      class="position-relative w-100 mb-0"
      @click="modal = true"
    >
      <div class="btn-group w-100">
        <slot name="prepend" />
        <button
          class="flex w-full items-center px-[12px] !text-[#44474A] !border-[#E2E5EC] rounded-[2px] h-[29px]"
          :class="uploadButtonClass"
          style="border:1px solid;"
        >
          <slot>
            <svg
              class="mr-[10px]"
              fill="none"
              height="15"
              viewBox="0 0 15 15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.93306 4.81694C3.68898 4.57286 3.68898 4.17714 3.93306 3.93306L7.05806 0.808058C7.30214 0.563981 7.69786 0.563981 7.94194 0.808058L11.0669 3.93306C11.311 4.17714 11.311 4.57286 11.0669 4.81694C10.8229 5.06102 10.4271 5.06102 10.1831 4.81694L8.125 2.75888L8.125 10C8.125 10.3452 7.84518 10.625 7.5 10.625C7.15482 10.625 6.875 10.3452 6.875 10L6.875 2.75888L4.81694 4.81694C4.57286 5.06102 4.17714 5.06102 3.93306 4.81694Z"
                fill="#3D82EA"
              />
              <path
                d="M1.25 9.375C1.59518 9.375 1.875 9.65482 1.875 10V12.5C1.875 12.8452 2.15482 13.125 2.5 13.125H12.5C12.8452 13.125 13.125 12.8452 13.125 12.5V10C13.125 9.65482 13.4048 9.375 13.75 9.375C14.0952 9.375 14.375 9.65482 14.375 10V12.5C14.375 13.5355 13.5355 14.375 12.5 14.375H2.5C1.46447 14.375 0.625 13.5355 0.625 12.5V10C0.625 9.65482 0.904822 9.375 1.25 9.375Z"
                fill="#3D82EA"
              />
              <path
                d="M3.93306 4.81694C3.68898 4.57286 3.68898 4.17714 3.93306 3.93306L7.05806 0.808058C7.30214 0.563981 7.69786 0.563981 7.94194 0.808058L11.0669 3.93306C11.311 4.17714 11.311 4.57286 11.0669 4.81694C10.8229 5.06102 10.4271 5.06102 10.1831 4.81694L8.125 2.75888L8.125 10C8.125 10.3452 7.84518 10.625 7.5 10.625C7.15482 10.625 6.875 10.3452 6.875 10L6.875 2.75888L4.81694 4.81694C4.57286 5.06102 4.17714 5.06102 3.93306 4.81694Z"
                stroke="#3D82EA"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0.3"
              />
              <path
                d="M1.25 9.375C1.59518 9.375 1.875 9.65482 1.875 10V12.5C1.875 12.8452 2.15482 13.125 2.5 13.125H12.5C12.8452 13.125 13.125 12.8452 13.125 12.5V10C13.125 9.65482 13.4048 9.375 13.75 9.375C14.0952 9.375 14.375 9.65482 14.375 10V12.5C14.375 13.5355 13.5355 14.375 12.5 14.375H2.5C1.46447 14.375 0.625 13.5355 0.625 12.5V10C0.625 9.65482 0.904822 9.375 1.25 9.375Z"
                stroke="#3D82EA"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0.3"
              />
            </svg>
            Upload Image…
          </slot>
        </button>
      </div>
    </div>

    <images-exploerer-modal
      v-if="false"
      v-model="modal"
      :highlight="highlight"
      :image="image"
      @uploaded="image => $emit('uploaded', image)"
      @removed-favorite="deleteImage"
    />
    <assets-lib-modal
      :modal.sync="modal"
      :file="image"
      type="jpg|png|svg|gif|jpeg|blob"
      :uploaded="uploaded"
      @removed-favorite="deleteImage"
    />
  </div>
</template>

<script>
import ImagesExploererModal from '../modals/ImagesExploererModal'
import AssetsLibModal from '@/components/assets-library/AssetsLibModal.vue'

import {mapState} from 'vuex'

export default {
  name: 'ImageUpload',
  components: {ImagesExploererModal,AssetsLibModal},
  props: {
    editabel: {
      type: Boolean,
      default: true
    },
    saveable: {
      type: Boolean,
      default: true
    },
    image: {
      type: Object,
      required: true
    },
    disableSettigns: {
      type: Boolean,
      default: false
    },
    noDelete: {
      type: Boolean,
      default: true
    },
    uploadButtonClass: {
      default: ''
    }
  },

  data () {
    return {
      modal: false,
      highlight: false
    }
  },

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    }),
    size: {
      get () {
        return this.resolution === 'lg' ? this.image.size : this.image.sizeSm || 'contain'
      },
      set (val) {
        this.resolution === 'lg' ? this.image.size = val : this.$set(this.image, 'sizeSm', val)
      }
    },
    favorites () {
      return this.globalStyles.libraryImages || []
    },
    imagePath () {
      return this.image.src ? this.image.src : this.image.placeholder
    },
    inFavorites () {
      return this.favorites.includes(this.imagePath)
    }
  },

  watch: {
    modal (val) {
      if (!val) {
        this.highlight = false
      }
    }
  },

  methods: {
    uploaded (image) {
      this.$emit('uploaded', image)
      this.$emit('uploaded-src', image.src)
    },
    removeFromMyLibrary () {
      this.modal = 'favorites'
    },
    deleteImage () {
      this.$set(this.image, 'src', null)
      this.$emit('imageDeleted')
    },
    saveToMyLibrary () {
      !Object.prototype.hasOwnProperty.call(this.globalStyles, 'libraryImages') ? this.$set(this.globalStyles, 'libraryImages', []) : null
      this.globalStyles.libraryImages.push(this.imagePath)

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'Saved in Assets Library.',
        showConfirmButton: false,
        timer: 3000
      })
    },
    showMyLibrary () {
      this.modal = true
      this.highlight = true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  color: #6E747A;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5);

  .mini-preview {
    width: 100% !important;
    background-size: contain !important;
    height: 26px !important;
    object-position: bottom !important;
    background-repeat: no-repeat !important;
  }
}
</style>
