<template>
  <div class="mega-menu-settings">
    <b-form-group label="Mega Menu">
      <v-select
        v-model="megaMenu"
        :options="options"
        :reduce="value => value.id"
        class="custom-select !text-[14px] !text-[#686e74] mb-1"
        placeholder="Select Mega Menu"
      />

      <b-link
        :to="{name: 'module.mega-menu'}"
        class="!text-[#7c7c7c]"
      >
        Mega Menu Manager
      </b-link>
    </b-form-group>

    <b-form-group label="Trigger">
      <v-select
        v-model="trigger"
        :options="triggerOptions"
        :reduce="val => val.value"
      />
    </b-form-group>

    <b-form-group label="Placement">
      <v-select
        v-model="position"
        :options="positionOptions"
        :reduce="val => val.value"
      />
    </b-form-group>

    <b-form-group>
      <label>Offset-Y <span class="lowercase">(px)</span></label>
      <b-input
        v-model="link.offset_y"
        type="number"
        placeholder="0"
      />
    </b-form-group>

    <b-form-group>
      <label>Offset-X <span class="lowercase">(px)</span></label>
      <b-input
        v-model="link.offset_x"
        type="number"
        placeholder="0"
      />
    </b-form-group>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'SelectMegaMenu',
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: [],
      positionOptions: [
        {
          label: 'Top Start',
          value: 'top-start'
        },
        {
          label: 'Top',
          value: 'top'
        },
        {
          label: 'Top End',
          value: 'top-end'
        },
        {
          label: 'Bottom Start',
          value: 'bottom-start'
        },
        {
          label: 'Bottom',
          value: 'bottom'
        },
        {
          label: 'Bottom End',
          value: 'bottom-end'
        },
        {
          label: 'Left',
          value: 'left'
        },
        {
          label: 'Right',
          value: 'right'
        },
        {
          label: 'Page Center',
          value: 'horizontalPageCenter'
        }
      ],
      triggerOptions:[
        {
          label: 'On Hover',
          value: 'hover'
        },
        {
          label: 'On Click',
          value: 'click'
        }
      ]
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    megaMenu: {
      get () {
        return this.link.megaMenuId || null
      },
      set (val) {
        this.$set(this.link, 'megaMenuId', val)
      }
    },
    position: {
      get () {
        return this.link.mega_menu_position || 'bottom'
      },
      set (val) {
        this.$set(this.link, 'mega_menu_position', val)
      }
    },
    trigger: {
      get () {
        return this.link.trigger || 'hover'
      },
      set (val) {
        this.$set(this.link, 'trigger', val)
      }
    }
  },
  created () {
    axios.get(`api/projects/${this.project.id}/mega-menu`)
      .then(({data}) => {
        this.options = data.data.data.map(menu => {
          return {
            label: menu.name,
            id: menu.id
          }
        })
      })
  }
}
</script>

<style lang="scss">
.mega-menu-settings {
  .v-select {
    &.custom-select:not(.select-tags) {
      height: 36px !important;
    }

    input::placeholder {
      font-size: 14px !important;
    }
  }
}
</style>