<template>
  <div
    v-if="showPaymentProvider"
    :data-sid="section.sid"
    :class="`--${section.sid}--wrapper`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="w-full !mb-[0]"
        :class="`--${section.sid}--container`"
      >
        <div
          class="flex justify-between items-center gap-[12px]"
          :class="`--${section.sid}--header`"
        >
          <p :class="`--${section.sid}--header-name`">
            {{ section.options.content.paymentProvider.label }}
          </p>
        </div>
        
        <b-form-radio-group
          v-model="provider"
          class="flex flex-col"
          :class="`--${section.sid}--providers`"
        >
          <template #default>
            <div
              v-for="(item, index) in list"
              :key="index"
              :class="[`--${section.sid}--provider`, {'active': provider === item.code}]"
            >
              <b-form-radio
                :value="item.code"
                class="w-full flex items-center justify-between gap-[12px]"
                @change="setSelectedProvider(item.code)"
              >
                <span :class="[`--${section.sid}--provider-item`, {'active': provider === item.code}]">
                  {{ getProviderName(item, index) }}
                </span>
              </b-form-radio>
            </div>
          </template>
        </b-form-radio-group>
      </b-form-group>
    </highliter>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'
export default {
  name: 'ElPaymentProvider',
  components: { Highliter },
  mixins: [SectionMixin],
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState({
      selectedProduct: state => state.orderForm.selectedProduct,
      selectedProvider: state => state.orderForm.selectedProvider,
      providers: state => state.orderForm.providers
    }),
    product () {
      const form = this.selectedProduct.find(item => item.id === this.form?.id)
      if (form) {
        return form.product
      } else {
        return null
      }
    },
    provider: {
      get () {
        const form = this.selectedProvider.find(item => item.id === this.form?.id)
        if (form) {
          return form.provider
        } else {
          return null
        }
      },
      set (value) {
        this.CHANGE_PROVIDER({id: this.form.id, provider: value})
      }
    },
    list () {
      const form = this.providers.find(item => item.id === this.form?.id)
      if (form) {
        return form.providers
      } else {
        return []
      }
    },
    showPaymentProvider () {
      return this.list.length >= 2 && !this.product.type_plan
    }
  },
  methods: {
    ...mapActions({
      setProvider: 'orderForm/setProvider'
    }),
    ...mapMutations({
      ADD_PROVIDERS_LIST: 'orderForm/ADD_PROVIDERS_LIST',
      CHANGE_PROVIDER: 'orderForm/CHANGE_PROVIDER'
    }),
    getProviderName (item, index) {
      if (this.section?.options?.content?.paymentProvider?.providers?.[index]?.name) {
        return this.section.options.content.paymentProvider.providers[index].name
      }
      return item?.name || ''
    },
    setSelectedProvider (provider) {
      this.provider = provider || null
    },
    setDefaultProvider () {
      if (Array.isArray(this.list) && this.list.length > 0) {
        this.setProvider({id: this.form.id, provider: this.list[0].code})
      }
    },

    updateProviders (product) {
      if (!product) return
      if (product.type_plan) return

      const providers = []
      const existingCodes = new Set()

      product.available_payments.forEach(payment => {
        const integration = payment.project_to_integration.integration
        if (existingCodes.has(integration.alpha_code)) return

        existingCodes.add(integration.alpha_code)
        providers.push({
          name: integration.name,
          code: integration.alpha_code
        })
      })

      this.ADD_PROVIDERS_LIST({
        id: this.form.id,
        providers: providers
      })

      this.section.options.content.paymentProvider.providers = providers
      this.setDefaultProvider()
    }
  },
  watch: {
    providers: {
      handler (val) {
        if (val) this.setDefaultProvider()
      }
    },
    product: {
      handler (val) {
        this.updateProviders(val)
      }
    }
  },
  mounted () {
    this.updateProviders(this.product)
  }
}
</script>
