<template>
  <b-card
    body-class="border"
    class="border-0"
  >
    <b-form-group>
      <label>Links to</label>
      <div class="position-relative tiptap-link">
        <pages-select-menu
          :others="[{id: 'custom-link', title: 'Custom Link'},{id: 'cookie-consent', title: 'Cookie Consent',slug: 'cookie-consent'},{id: 'blog', title: 'ESTAGE Blog', customLink: `/${project.blog_slug}`, target: '_self', code: project.blog_slug, slug: 'blog', href: '/blog'}]"
          input-class="rounded-[4px] !h-[28px]"
          menu-class="!mt-[-15px]"
          other-title="Other:"
          title="Pages and Posts"
          add-posts
          @selectOther="onSelect"
          @selectPage="onSelect"
        >
          <template
            v-if="form.page"
            slot="placeholder"
          >
            <div class="px-3">
              {{ form.page.title || 'Select Link' }}
            </div>
          </template>
        </pages-select-menu>
      </div>
    </b-form-group>


    <b-form-group v-if="form.page.id === 'custom-link'">
      <label>Custom link</label>
      <b-input
        v-model="form.customLink"
        placeholder="https://"
        type="url"
      />
    </b-form-group>

    <b-form-group v-if="form.page.id != 'cookie-consent'">
      <b-checkbox
        v-model="form.target"
        unchecked-value="_self"
        value="_blank"
      >
        Open in new tab
      </b-checkbox>
    </b-form-group>

    <rule-tabs>
      <template #Normal>
        <div class="flex items-center justify-between mb-3">
          <label class="p-0">Link Style</label>
          <sid-font-style
            :sid="`${sid} a#${id}`"
            rule-name="font"
          />
        </div>

        <div class="flex items-center justify-between">
          <label class="p-0">Link Color</label>
          <sid-color
            :sid="`${sid} a#${id}`"
            rule-name="color"
          />
        </div>
      </template>
      <template #Hover>
        <div class="flex items-center justify-between mb-3">
          <label class="p-0">Link Style</label>
          <sid-font-style
            :sid="`${sid} a#${id}`"
            rule-name="font"
            presudo=":hover"
            :pseudo-index="1"
          />
        </div>

        <div class="flex items-center justify-between">
          <label class="p-0">Link Color</label>
          <sid-color
            :sid="`${sid} a#${id}`"
            presudo=":hover"
            rule-name="color"
            :pseudo-index="1"
          />
        </div>
      </template>
    </rule-tabs>

    <b-form-group>
      <b-checkbox
        v-model="form.fromGlobalStyle"
      >
        Global Style
      </b-checkbox>
    </b-form-group>

    <slot />
  </b-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import shortid from 'shortid'

export default {
  name: 'TiptapLink',
  components: {SidColor, SidFontStyle, RuleTabs, PagesSelectMenu},
  mixins: [PagesPaginationMixin],

  props: {
    value: {
      type: Object,
      default: () => {
      }
    },
    sid: {
      type: String
    }
  },

  data () {
    return {
      target: true,
      id: null,
      form: {
        page: this.value.href,
        customLink: this.value.href.href,
        target: this.value.target,
        fromGlobalStyle: false,
        style: {
          hover: {
            color: '',
            bold: false,
            italic: false,
            underline: false
          },
          normal: {
            color: '',
            bold: false,
            italic: false,
            underline: false
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },

  watch: {
    value: {
      deep: true,
      handler: function (val) {
        const style = val.styleeffect ? JSON.parse(val.styleeffect) : {
          hover: {
            color: '',
            bold: false,
            italic: false,
            underline: false
          },
          normal: {
            color: '',
            bold: false,
            italic: false,
            underline: false
          }
        }

        this.id = val.id || `link-${shortid.generate()}`
        this.form = {
          page: val.href,
          customLink: val.href.href,
          target: val.target,
          fromGlobalStyle: val.fromGlobalStyle,
          style
        }
      }
    },
    form: {
      deep: true,
      handler: function () {

        let link = {
          custom_link: this.form.customLink,
          target: this.form.target,
          fromGlobalStyle: this.form.fromGlobalStyle
        }

        if (this.form.page !== 'custom-link') {
          link.href = this.form.page
        }

        if (!this.form.fromGlobalStyle) {
          link.styleeffect = JSON.stringify(this.form.style)
        } else {
          this.$set(link, 'styleeffect', null)
        }

        link.href.href = this.form.customLink
        link.id = this.id

        this.$emit('change', link)
      }
    }
  },

  created () {
    this.id = `link-${shortid.generate()}`
    const style = this.value.styleeffect ? JSON.parse(this.value.styleeffect) : {
      hover: {
        color: '',
        bold: false,
        italic: false,
        underline: false
      },
      normal: {
        color: '',
        bold: false,
        italic: false,
        underline: false
      }
    }

    this.form.style = style
    this.form.fromGlobalStyle = this.value.fromGlobalStyle
  },

  methods: {
    onSelect (item) {
      this.form.page = {
        ...item,
        slug: item.slug ? `/${item.slug}` : null
      }
    },
    onClose () {
      this.searching = false
      const $menu = document.querySelector('.vs__dropdown-menu')

      if ($menu) {
        $menu.removeEventListener('scroll', this.scrollLoadingPages)
      }
    }
  }
}
</script>

<style>
.tiptap-link .pages-select-menu-dropdwon {
  width: 195px !important;
  min-width: 195px !important;
}
</style>
