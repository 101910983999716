<template>
  <div>
    <div
      class="flex items-center btn-group w-full"
      :class="{'flex-wrap': stack}"
    >
      <button
        v-for="step in allSteps.slice(0, -1)"
        :key="`step-${step.value}`"
        class="btn h-[33px] !text-[13px] border hover:!bg-[#F2F4F5] !text-[#44474A] px-3 py-0 mb-[16px]"
        :class="[{'!bg-[#F2F4F5]': currentStep === step.value}, {'w-100 mb-2': stack}]"
        @click="setStep(step.value)"
      >
        {{ step.label }}
      </button>
    </div>

    <button
      v-if="showConfirmation"
      class="btn w-full h-[33px] !text-[13px] border hover:!bg-[#F2F4F5] !text-[#44474A] px-3 py-0 mb-[16px]"
      :class="[{'!bg-[#F2F4F5]': currentStep === 99}, {'w-100 mb-2': stack}]"
      @click="setStep(confirmationStep.value)"
    >
      {{ confirmationStep.label }}
    </button>

    <div
      v-for="step in allSteps"
      v-if="currentStep === step.value"
      :key="`content-${step.value}`"
    >
      <slot :name="step.value" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardRuleTabs',
  props: {
    steps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    },
    stack: {
      default: false
    },
    showConfirmation: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    regularSteps () {
      return Array.from({ length: this.steps }, (_, i) => ({
        value: i,
        label: (i + 1).toString()
      }))
    },
    allSteps () {
      return [
        ...this.regularSteps,
        {
          value: 99,
          label: 'Confirmation'
        }
      ]
    },
    confirmationStep () {
      return this.allSteps.find(step => step.value === 99)
    }
  },
  methods: {
    setStep (step) {
      this.$emit('change', step)
    }
  }
}
</script>