<template>
  <div class="pt-3">
    <SidbearSettingsGroup
      title="Lottie"
      active
    >
      <b-form-group v-if="linked">
        <span
          class="inline cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E] gap-[10px]"
          @click="() => unlinkSid()"
        >
          <i class="fa fa-unlink mr-2" />

          Unlink styles
        </span>
      </b-form-group>

      <b-form-group
        class="w-full"
        label="Lottie JSON"
      >
        <upload-asset
          type="json"
          @uploaded="uploadedLottie"
        />
      </b-form-group>

      <b-form-group label="JSON URL">
        <div class="flex items-center gap-[10px]">
          <b-input v-model="config.jsonPath" />

          <i
            v-b-tooltip
            title="Delete"
            class="fa fa-trash cursor-pointer hover:opacity-60"
            @click="() => config.jsonPath = ''"
          />
        </div>
      </b-form-group>

      <sid-range-slider
        label="Size"
        rule-name="width"
        :sid="`.--lottie-${section.sid}`"
        :dimensions="[]"
        default-dimension="%"
        :default-value="'100'"
        reset-value="100"
        var="--width"
      />

      <sid-width-and-height
        :sid="`.--lottie-${section.sid}`"
      />

      <sid-align
        label="Alignment"
        :sid="`.--lottie-container-${section.sid}`"
        rule-name="justify-content"
      />

      <b-form-group>
        <b-checkbox v-model="config.loop">
          Loop
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="config.autoplay">
          Autoplay
        </b-checkbox>
      </b-form-group>
      
      <b-form-group>
        <b-checkbox
          v-model="config.playOnHover"
          :disabled="config.autoplay"
        >
          Play on hover
        </b-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--lottie-${section.sid}`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--lottie-${section.sid}`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import UploadAsset from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/UploadAsset'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'

export default {
  name: 'ListMod',
  components: {SidLinkedGroup, SidbearSettingsGroup, SidWidthAndHeight, SidAlign, SidRangeSlider, UploadAsset},
  mixins: [ModMixin],
  methods: {
    uploadedLottie (file) {
      this.section.options.content.lottie.jsonPath = file.url
    }
  }
}
</script>
