var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{attrs:{"label":"Page"}},[_c('pages-select-menu',{attrs:{"others":[
        ...(_vm.productUrl
          ? [
            {
              id: 'product-redirect',
              title: 'Product Redirect',
            }
          ] 
          : []
        ),
        {
          id: 'custom-link',
          title: 'Custom Link'
        },
        {
          id: 'blog',
          title: 'ESTAGE Blog',
          code: _vm.project.blog_slug
        }
      ],"input-class":"rounded-[4px]","other-title":"Other:"},on:{"selectOther":item => _vm.service.data.page = item,"selectPage":item => _vm.service.data.page = item}},[(_vm.service.data.page)?_c('template',{slot:"placeholder"},[_c('div',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.service.data.page.title)+" ")])]):_vm._e()],2)],1),(_vm.service.data.page.id === 'custom-link')?_c('b-form-group',{attrs:{"label":"Redirect URL"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({url: {require_protocol: true }}),expression:"{url: {require_protocol: true }}"}],staticClass:"mb-1",attrs:{"placeholder":"https://estage.com","type":"url","name":"url","state":_vm.errors.has('url') ? false : null},model:{value:(_vm.service.data.customLink),callback:function ($$v) {_vm.$set(_vm.service.data, "customLink", $$v)},expression:"service.data.customLink"}}),(_vm.errors.has('url'))?_c('span',{staticClass:"text-danger text-[12px]"},[_vm._v("Invalid URL or http:// is required")]):_vm._e()],1):_vm._e(),(['product-redirect', 'custom-link'].includes(_vm.service.data.page.id))?_c('b-form-group',[_c('b-checkbox',{model:{value:(_vm.service.data.open_new_tab),callback:function ($$v) {_vm.$set(_vm.service.data, "open_new_tab", $$v)},expression:"service.data.open_new_tab"}},[_vm._v(" Open in new tab ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }