<template>
  <div :class="`--${section.sid}--wrapper`">
    <div
      class="relative"
      :class="`--${section.sid}--container`"
    >
      <div
        v-if="live"
        class="relative w-full aspect-video overflow-hidden"
      >
        <members-count
          :stream-key="content.rtmp_server_stream_key"
          :show-count="content.showMembersCount"
          class="absolute top-3 left-[12px] z-[10]"
        />
        <llhls
          v-if="section.options.content.liveStream.provider === 'll-hls'"
          :section="section"
        />
        <template v-if="section.options.content.liveStream.provider === 'webrtc' || !section.options.content.liveStream.provider">
          <div class="w-full h-full">
            <div
              class="video-player flex justify-center bg-black"
              @mousemove="showPanel = true"
              @mouseleave="showPanel = false"
            >
              <div
                ref="videoContainer"
                class="h-full w-full !object-contain aspect-[16/9]"
              />

              <div
                class="video-wrapper"
              >
                <div class="video">
                  <div class="video-cr" />
                </div>

                <div
                  id="controls"
                  :class="[{'!opacity-100': showPanel}]"
                  class="video-control transition bg-gradient-to-t from-black/70 to-transparent"
                  @mousemove="isPanelInFocus = true"
                  @mouseleave="isPanelInFocus = false"
                >
                  <div class="video-control-container">
                    <div
                      class="relative flex gap-2 items-center"
                      @mouseenter="volumePanel = true"
                      @mouseleave="volumePanel = false"
                    >
                      <button
                        class="text-white flex items-center p-2 gap-2 font-semibold bg-transparent"
                        @click="updateMute"
                      >
                        <svg
                          v-if="mute"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="video-control-icon"
                        >
                          <path
                            d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 0 0 1.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06ZM17.78 9.22a.75.75 0 1 0-1.06 1.06L18.44 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06l1.72-1.72 1.72 1.72a.75.75 0 1 0 1.06-1.06L20.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-1.72 1.72-1.72-1.72Z"
                            stroke-linecap="evenodd"
                            stroke-linejoin="evenodd"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          class="video-control-icon"
                        >
                          <path
                            d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 0 0 1.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06ZM18.584 5.106a.75.75 0 0 1 1.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 0 1-1.06-1.06 8.25 8.25 0 0 0 0-11.668.75.75 0 0 1 0-1.06Z"
                            stroke-linecap="evenodd"
                            stroke-linejoin="evenodd"
                          />
                          <path
                            d="M15.932 7.757a.75.75 0 0 1 1.061 0 6 6 0 0 1 0 8.486.75.75 0 0 1-1.06-1.061 4.5 4.5 0 0 0 0-6.364.75.75 0 0 1 0-1.06Z"
                            stroke-linecap="evenodd"
                            stroke-linejoin="evenodd"
                          />
                        </svg>
                      </button>

                      <div
                        class="transition overflow-hidden"
                        :style="{width: volumePanel ? '60px' : '0px', display: volumePanel ? 'block' : 'none'}"
                      >
                        <div class="flex items-center justify-start rounded">
                          <input
                            v-model="volume"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            @input="updateVolume"
                          >
                        </div>
                      </div>
                    </div>

                    <div class="live-logo ml-auto">
                      <svg
                        class=""
                        width="70"
                        height="9"
                        viewBox="0 0 70 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.8926 0.576345V1.57624H28.2797V8.98575H29.7592V1.57624H33.1335V0.576345V0.0134277H24.8926V0.576345Z"
                          fill="white"
                        />
                        <path
                          d="M20.7408 4.15135C20.1888 3.65235 19.4991 3.40238 18.671 3.40238H14.8302C14.546 3.40238 14.3037 3.32319 14.1033 3.16576C13.9028 3.00834 13.8026 2.78508 13.8026 2.49599C13.8026 2.21548 13.9028 1.99222 14.1033 1.82621C14.3037 1.6602 14.546 1.57624 14.8302 1.57624H21.0925V0.56585V0.0134277H14.8302C14.086 0.0134277 13.4628 0.2281 12.9618 0.657443C12.4261 1.12209 12.1592 1.72985 12.1592 2.48358C12.1592 3.24591 12.427 3.85844 12.9618 4.32213C13.4637 4.75147 14.086 4.96615 14.8302 4.96615H18.671C19.081 4.96615 19.4317 5.05774 19.7251 5.24188C20.0758 5.46037 20.2516 5.7714 20.2516 6.17403C20.2516 6.57666 20.0804 6.89628 19.7378 7.1329C19.4363 7.32563 19.081 7.42199 18.6719 7.42199H12.335V8.43047V8.9848H18.6719C19.5 8.9848 20.1897 8.74437 20.7417 8.26255C21.3184 7.74543 21.6072 7.06325 21.6072 6.21315C21.6063 5.364 21.3175 4.67705 20.7408 4.15135Z"
                          fill="white"
                        />
                        <path
                          d="M0.0820312 8.98574H8.27286V8.42759V7.42198H1.57425V5.21515H8.27286V4.65796V3.65234H0.0820312V8.98574Z"
                          fill="white"
                        />
                        <path
                          d="M0.0820312 1.57451H8.27286V1.01636V0.0107422H0.0820312V1.57451Z"
                          fill="white"
                        />
                        <path
                          d="M60.9902 5.21515V7.42198V8.98574H69.182V8.42759V7.42198H62.4834V5.21515H69.182V4.65796V3.65234H60.9902V5.21515Z"
                          fill="white"
                        />
                        <path
                          d="M60.9902 0.0134277V1.57719H69.182V1.01905V0.0134277H60.9902Z"
                          fill="white"
                        />
                        <path
                          d="M40.3059 0.289091C40.0717 0.0963637 39.8458 0 39.6281 0C39.4277 0 39.2017 0.10018 38.9512 0.302449C38.7417 0.478003 38.5786 0.669776 38.462 0.880632L33.8086 8.98569H35.7281L39.5916 2.33849L41.0839 4.92696H39.6044L38.7016 6.47737H41.9748L43.4169 8.98664L45.3355 9L40.7951 0.881586C40.6694 0.652602 40.5063 0.456058 40.3059 0.289091Z"
                          fill="white"
                        />
                        <path
                          d="M48.7771 1.22227C47.97 2.02848 47.5664 3.08753 47.5664 4.40132C47.5664 5.7237 47.9736 6.819 48.789 7.68532C49.6043 8.5526 50.6347 8.98575 51.8809 8.98575H57.1274V5.5949V4.03304V4.03208H54.1238V5.5949H55.6352V7.42199H51.8818C51.0619 7.42199 50.3869 7.15484 49.8558 6.62055C49.3246 6.08626 49.0595 5.38976 49.0595 4.53203C49.0595 3.66476 49.3246 2.95586 49.8558 2.40344C50.3869 1.85197 51.0619 1.57624 51.8818 1.57624H57.1283V0.576345V0.0134277H51.8818C50.6192 0.0134277 49.5843 0.416057 48.7771 1.22227Z"
                          fill="white"
                        />
                      </svg>
                    </div>

                    <!--                  <button-->
                    <!--                    class="text-white flex items-center p-2 gap-2 font-semibold bg-transparent"-->
                    <!--                    @click="fullscreen"-->
                    <!--                  >-->
                    <!--                    <svg-->
                    <!--                      width="22"-->
                    <!--                      height="23"-->
                    <!--                      viewBox="0 0 22 23"-->
                    <!--                      fill="none"-->
                    <!--                      xmlns="http://www.w3.org/2000/svg"-->
                    <!--                      class="video-control-icon"-->
                    <!--                    >-->
                    <!--                      <path-->
                    <!--                        d="M7.33333 3.25H7.15C5.60986 3.25 4.83978 3.25 4.25153 3.54973C3.73408 3.81338 3.31338 4.23408 3.04973 4.75153C2.75 5.33978 2.75 6.10986 2.75 7.65V7.83333M7.33333 19.75H7.15C5.60986 19.75 4.83978 19.75 4.25153 19.4503C3.73408 19.1866 3.31338 18.7659 3.04973 18.2485C2.75 17.6602 2.75 16.8901 2.75 15.35V15.1667M19.25 7.83333V7.65C19.25 6.10986 19.25 5.33978 18.9503 4.75153C18.6866 4.23408 18.2659 3.81338 17.7485 3.54973C17.1602 3.25 16.3901 3.25 14.85 3.25H14.6667M19.25 15.1667V15.35C19.25 16.8901 19.25 17.6602 18.9503 18.2485C18.6866 18.7659 18.2659 19.1866 17.7485 19.4503C17.1602 19.75 16.3901 19.75 14.85 19.75H14.6667"-->
                    <!--                        stroke="white"-->
                    <!--                        stroke-width="2.5"-->
                    <!--                        stroke-linecap="round"-->
                    <!--                        stroke-linejoin="round"-->
                    <!--                      />-->
                    <!--                    </svg>-->
                    <!--                  </button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-else
        :class="`--${section.sid}--element`"
        class="placeholder-wrapper position-relative aspect-video"
      >
        <div class="placeholder-container min-h-[300px]">
          <img
            v-if="placeholder"
            ref="posterElement"
            :src="placeholder"
            class="placeholder"
          >
          <div class="placeholder-label">
            Offline
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import MembersCount from '@/components/builder/utils/MembersCount.vue'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import {db} from '@/firebase.js'
import Llhls from '@/components/builder/utils/llhls.vue'

export default {
  name: 'LiveStreamVideo',
  components: {Llhls, MembersCount},
  data () {
    return {
      unsubscribeMessages: null,
      mute: true,
      playing: true,
      volumePanel: false,
      showPanel: true,
      volume: 1,
      isPanelInFocus: false,
      streamLive: false,
      player: null,
      placeholder: null,
      previewLoading: true,
      sdk: false,
      resolutions: []
    }
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    content: {
      type: Object
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('liveStream', {
      poster: state => state.poster,
      isOpen: state => state.isOpen,
      isLive: state => state.isLive
    }),
    key () {
      return this.content.rtmp_server_stream_key
    },
    posterElement () {
      return this.$refs.posterElement
    },
    live () {
      return this.streamLive && this.isOpen || this.isLive && this.isOpen
    },
    shouldGenerateStreamKey () {
      return this.key === null || this.key === undefined
    }
  },
  methods: {
    ...mapActions({
      rotateServers: 'liveStream/rotateServers',
      postLiveStream: 'liveStream/postLiveStream'
    }),
    ...mapMutations({
      setPoster: 'liveStream/SET_POSTER',
      setOpen: 'liveStream/SET_POSTER',
      setLive: 'liveStream/SET_POSTER'
    }),
    async loadPreview () {
      const streamId = `stream-${this.key}_p-${this.project.id}_t-page`
      const WebPlayer = (await import('@antmedia/web_player')).WebPlayer
      this.sdk = new WebPlayer({
        streamId: streamId,
        httpBaseURL: 'https://estage.live:5443/WebRTCAppEE',
        playOrder: ['webrtc'],
        mute: true,
        videoHTMLContent: '<video id="video-player" class="video-js h-full w-full" muted controls playsinline></video>'
      }, this.$refs.videoContainer, null)

      function updateResolution (stats) {
        if (stats && stats.inboundRtpList) {
          const videoStats = stats.inboundRtpList.find(track => track.trackIdentifier.includes('videoTrack'))

          if (videoStats) {
            const videoResolution = `${videoStats.frameWidth}x${videoStats.frameHeight}`
            this.autoQuality = videoStats.frameHeight
            console.log(`Resolution: ${videoResolution}`)
          }
        }
      }

      this.sdk.initialize().then(() => {
        this.sdk.play()
        this.sdk.videojsPlayer.on('play', () => {
          this.ready.value = true
        })

        this.sdk.videojsPlayer.on('ready', function () {
          this.$refs.videoElement.value = this.sdk.videojsPlayer.el_.querySelector('video')
          setTimeout(async () => {
            this.resolutions = [
              {
                value: 0,
                label: 'Auto'
              },
              ...this.sdk.videojsPlayer.controlBar.player_.resolutions
            ]

            this.$refs.videoElement.value.addEventListener('webkitendfullscreen', function () {
              setTimeout(() => {
                this.playing.value = false
              }, 100)
            }, false)

            this.sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.enableStats(streamId)
            await this.sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.getStats(streamId)

            this.sdk.videojsPlayer.on('timeupdate', () => {
              const stats = this.sdk.videojsPlayer.tech().webrtc.webRTCAdaptor.remotePeerConnectionStats[streamId]
              updateResolution(stats)
            })
          }, 3000)
        })
      }).catch((error) => {
        console.error('Error initializing player: ' + error)
      })

      document.addEventListener('fullscreenchange', () => {
        this.playing.value = true
      })

      document.addEventListener('onwebkitfullscreenchange', () => {
        this.playing.value = true
      })
    },
    async loadLLHLSPreview () {
      this.llhlsClass = ''
      setTimeout(() => {
        window.OvenPlayer.create(this.$refs.videoElementLLHLS, {
          mute: true,
          autoStart: true,
          sources: [
            {
              label: 'label_for_webrtc',
              type: 'll-hls',
              file: `https://hls.estage-oms.com/app/${this.section.options.content.liveStream.rtmp_server_stream_key}/abr.m3u8`
            }
          ]
        })
      }, 1000)
    },
    updateMute () {
      if (!this.loading) {
        this.mute = !this.mute
        this.$refs.videoElement.value.muted = this.mute

        if (this.mute) {
          this.player.mute()
        } else {
          this.player.unmute()
        }
      }
    },
    updateVolume () {
      this.sdk.videojsPlayer.muted(this.volume)
    },
    fullscreen () {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      const target = isIOS ? this.$refs.videoElement : this.$refs.videoElement.closest('.video-player')

      if (!document.fullscreenElement) {
        if (target.requestFullscreen) {
          target.requestFullscreen()
        } else if (target.mozRequestFullScreen) { /* Firefox */
          target.mozRequestFullScreen()
        } else if (document.getElementsByTagName('video')[0].webkitEnterFullscreen) { /* Chrome, Safari & Opera */
          document.getElementsByTagName('video')[0].webkitEnterFullscreen()
        } else if (target.msRequestFullscreen) { /* IE/Edge */
          target.msRequestFullscreen()
        }

        this.$refs.videoElement.maxHeight = 'unset'
      } else {
        this.$refs.videoElement.maxHeight = null
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    async getStreamKey () {
      if (this.shouldGenerateStreamKey) {
        await this.postLiveStream(this.section.options.content.liveStream)
      }
    },
    async saveProject () {
      await this.$store.dispatch('projects/save')
    }
  },
  watch: {
    async live (val) {
      if (val) {
        if (this.section.options.content.liveStream.provider === 'll-hls') console.log('ll-hls')
        else await this.loadPreview()
      } else {
        if (this.section.options.content.liveStream.provider === 'll-hls') console.log('ll-hls')
        else this.$refs.videoElement.srcObject = null
      }
    },
    posterElement (val) {
      val.src = val
    },
    playing (val) {
      if (val) {
        this.sdk.videojsPlayer.play()
      } else {
        this.sdk.videojsPlayer.pause()
      }
    },
    mute (val) {
      this.sdk.videojsPlayer.muted(val)
    },
    key (val) {
      if (!this.unsubscribeMessages && val) {
        const streamDoc = doc(collection(db, 'lives'), this.key)
        this.unsubscribeMessages = onSnapshot(streamDoc, snapshot => {
          this.setPoster(snapshot.data().poster)
          this.placeholder = snapshot.data().poster
          this.streamLive = snapshot.data().streamLive
        })
      }
    }
  },
  async created () {
    this.setPoster(this.content.poster)
    if (!this.key) {
      await this.getStreamKey()
      await this.saveProject()
    }
  },
  async mounted () {
    if (!this.unsubscribeMessages && this.key) {
      const streamDoc = doc(collection(db, 'lives'), this.key)
      this.unsubscribeMessages = onSnapshot(streamDoc, snapshot => {
        this.setPoster(snapshot.data().poster)
        this.placeholder = snapshot.data().poster
        this.streamLive = snapshot.data().streamLive
      })
    }
    if (this.live) {
      if (this.section.options.content.liveStream.provider === 'll-hls') console.log('ll-hls')
      else await this.loadPreview()
    } 
  },
  beforeDestroy () {
    if (this.unsubscribeMessages) this.unsubscribeMessages()
  },
  destroyed () {
    if (this.player) {
      this.player.destroy()
      this.player = null
      this.$refs.videoElement.srcObject = null
    }
  }
}
</script>

<style lang="scss">
.vjs-control-bar {
  display: none !important;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%, 100% {
      opacity: .8;
    }
    50% {
      opacity: .5;
    }
  }
}

.aspect-video {
  aspect-ratio: 16/9;
}

.absolute {
  position: absolute;
}

.placeholder-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.placeholder-container {
  width: 100%;
  height: 0;
  overflow: hidden;
}

.placeholder-container .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-container .placeholder-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(226, 226, 226, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.3);
}

.settings-menu {
  position: absolute;
  right: 0;
  bottom: 40px;
  margin-top: 8px;
  overflow: hidden;
  transform-origin: top right;
  z-index: 10;
  transform: translateX(10%);
  min-width: 240px;
  padding: 8px 0;
  border-radius: 8px;
  background: rgba(18, 18, 18, 0.8);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
  }
}

.settings-btn {
  display: flex;
  background: transparent;
  gap: 14px;
  align-items: center;
  width: 100%;
  color: #E2E2E2;
  font-size: 13px;
  border-width: 0;
  padding: 6px 12px;

  &:hover {
    color: white;
    background: rgba(226, 226, 226, 0.1);
  }
}

.settings-btn-icon {
  width: 20px;
  height: 20px;
  color: white;
}

.settings-list {
  border: 0;
  display: flex;
  flex-direction: column;
}

.btn-play {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 50;

  &:hover {
    scale: 1.05;
  }
}

.video {
  left: 0;
  bottom: 0;
  position: absolute;
  top: 0;
  right: 0;
}

video {
  transition: opacity 0.5s ease-out;
}

.video-cr {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  transition: opacity .25s;
}

.video-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  overflow: hidden;
}

.video-player {
  max-height: 100%;
  width: 100%;
  height: 100%;
  background: #000;
  position: relative;
  overflow: visible;
  border-collapse: separate;
  user-select: none;

  input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 50px;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
  }

  /* Track: webkit browsers */

  input[type="range"]::-webkit-slider-runnable-track {
    height: 4px;
    background: #d3d2cc;
    border-radius: 16px;
  }

  /* Track: Mozilla Firefox */

  input[type="range"]::-moz-range-track {
    height: 4px;
    background: #d3d2cc;
    border-radius: 16px;
  }

  /* Thumb: webkit */

  input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 10px;
    width: 10px;
    background-color: #286DEB;
    border-radius: 50%;
    position: relative;
    top: -3px;
    box-shadow: -50vw 0 0 50vw #286DEB;
  }


  /* Thumb: Firefox */

  input[type="range"]::-moz-range-thumb {
    height: 4px;
    width: 4px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: -407px 0 0 100px #fff;
  }
}

.video-btn-play {
  width: 96px;
  height: 96px;
  color: #fff;
}

.video-control {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px 12px 24px 12px;
  z-index: 60;
}

.video-control-container {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 8px;
}

.video-live-badge {
  width: 44px;
  height: 24px;
  color: #fff;
}

.video-control-icon {
  width: 24px;
  height: 24px;
  color: white;
}

.live-logo {
  display: flex;
  height: 100%;
}
.live-logo svg {
  width: 70px;
  height: 9px;
  color: white;
}

@media screen and (max-width: 448px) {
  .settings-menu {
    min-width: 128px;
  }

  .settings-btn {
    font-size: 11px;
  }

  .settings-btn-icon {
    width: 16px;
    height: 16px;
  }

  .video-btn-play {
    width: 48px;
    height: 48px;
  }

  .video-control {
    padding: 8px 4px 12px 4px;
  }

  .video-control-container {
    gap: 4px;
  }

  .video-live-badge {
    width: 32px;
    height: 16px;
  }

  .video-control-icon {
    width: 20px;
    height: 20px;
  }

  .live-logo svg {
    width: 45px;
    height: 6px;
  }
}
</style>