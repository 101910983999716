<template>
  <div>
    <p class="font-[600] text-[16px]">
      Choose from the list
    </p>

    <div class="max-w-[476px] mb-[22px]">
      <v-select
        v-model="font.family"
        :options="getGoogleFonts"
        :reduce="value => value.family"
        placeholder="Abhata Libre..."
        @option:selected="resetOptions"
      >
        <template #selected-option="{family}">
          <!--            :style="{fontFamily: family}"-->
          <div
            v-text="family"
          />
        </template>

        <template #option="{family}">
          <!--            :style="{fontFamily: family}"-->
          <div
            v-text="family"
          />
        </template>
      </v-select>
    </div>

    <div
      v-if="options"
      class="border rounded-[3px] py-[18px] px-[22px] max-w-[659px]"
    >
      <div class="text-[13px] text-[#6B6B6B] mb-[15px]">
        Select variants to include:
      </div>


      <div class="mb-[10px]">
        <b-form-checkbox-group
          v-model="font.variants"
          stacked
          :options="variants"
        />
      </div>

      <button
        class="h-[42px] w-[146px] disabled:opacity-80 text-white bg-[#3D82EA] rounded-[3px] hover:opacity-80 p-0"
        :disabled="!font.variants.length"
        @click="addFont"
      >
        Add Font
      </button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'FontOptions',
  data () {
    return {
      font: {
        family: null,
        variants: []
      }
    }
  },
  computed: {
    ...mapState({
      fonts: state => state.font.allGoogleFonts,
      addedGoogleFonts: state => state.editor.globalStyles.font.addedGoogleFonts,
      editableGoogleFont: state => state.font.editableGoogleFont
    }),
    getGoogleFonts () {
      return this.fonts.filter(font => !this.addedGoogleFonts.map(font => font.family).includes(font.family)).map(font => {
        return {
          ...font,
          label: font.family
        }
      })
    },
    options () {
      return this.fonts.find(font => font.family === this.font.family)
    },
    variants () {
      return this.options.variants
    }
  },
  watch: {
    editableGoogleFont (val) {
      if (val !== false) {
        this.resetFont()
      }
    }
  },
  methods: {
    addFont () {
      this.$store.dispatch('font/addGoogleFont', {...this.font})
        .finally(() => {
          this.resetFont()

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'The font has been added to your library.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    resetFont () {
      this.font = {
        family: null,
        variants: []
      }
    },
    resetOptions () {
      this.$store.dispatch('font/editGoogleFont', false)
      this.font.variants = []
    }
  }
}
</script>