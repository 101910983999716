<template>
  <div class="sid-select">
    <pre>{{ elementProviders }}</pre>
    <draggable
      :list="elementProviders"
      handle=".move"
      @change="updateProviders"
    >
      <div
        v-for="(provider, index) in elementProviders"
        :key="index"
        class="m-[4px] gap-y-[4px]"
      >
        <div
          class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter"
          @click="active === index ? active = null : active = index"
        >
          <svg
            class="move cursor-move mr-[14px]"
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0H0.568359V3H3.57745V0Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0H7.58936V3H10.5984V0Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7H0.568359V10H3.57745V7Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7H7.58936V10H10.5984V7Z"
              fill="#3D82EA"
            />
          </svg>

          <span
            class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA]"
            :class="active === index ? 'text-[#3D82EA]' : 'text-[#7E7E7E]'"
          >
            {{ provider.name }}
          </span>

          <button
            title="Column Settings"
            class="flex h-[38px] items-center justify-center w-[38px] ml-auto p-0 bg-transparent hover:bg-[#E2E5EC]"
            :class="{'rotate-90': active === index}"
            @click.stop="$emit('open-settings')"
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 7 10"
              width="7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
          </button>
        </div>

        <div
          v-if="active === index"
          class="m-[4px]"
        >
          <b-form-group class="Option Name">
            <b-form-input v-model="element.options.content.paymentProvider.providers[index].name" />
          </b-form-group>
        </div>
      </div>
    </draggable>

    <SidbearSettingsGroup
      title="Headline"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <b-form-group label="Plan Text">
        <b-form-input v-model="element.options.content.paymentProvider.label" />
      </b-form-group>

      <sid-font :sid="`.--${element.sid}--header-name`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${element.sid}--header-name`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${element.sid}--header-name`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${element.sid}--header-name`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.--${element.sid}--header-name`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Options"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <div class="flex w-full items-center justify-between mb-[14px]">
            <label class="pb-0">
              Background Color:
            </label>

            <sid-color
              :sid="`.--${element.sid}--provider`"
              rule-name="background-color"
            />
          </div>
          
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--provider`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--provider`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--provider`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--provider`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: 'Top Left',
                value: 'top-left-radius'
              },
              {
                text: 'Top Right',
                value: 'top-right-radius'
              },
              {
                text: 'Bottom Left',
                value: 'bottom-left-radius'
              },
              {
                text: 'Bottom Right',
                value: 'bottom-right-radius'
              }
            ]"
          />

          <hr>

          <sid-linked-group
            label="Padding"
            :sid="`.--${element.sid}--provider`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${element.sid}--provider`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
          
          <hr>

          <sid-font :sid="`.--${element.sid}--provider-item`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--provider-item`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${element.sid}--provider-item`"
            rule-name="letter-spacing"
            :min="-2"
            :dimensions="[]"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${element.sid}--provider-item`"
            rule-name="line-height"
            :default-dimension="''"
            :min="0"
            :max="5"
            :step=".1"
            var="--line-height"
            :dimensions="[]"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--provider-item`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Active>
          <div class="flex w-full items-center justify-between mb-[14px]">
            <label class="pb-0">
              Background Color:
            </label>

            <sid-color
              :sid="`.--${element.sid}--provider.active`"
              rule-name="background-color"
            />
          </div>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--provider.active`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--provider.active`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--provider.active`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--provider.active`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: 'Top Left',
                value: 'top-left-radius'
              },
              {
                text: 'Top Right',
                value: 'top-right-radius'
              },
              {
                text: 'Bottom Left',
                value: 'bottom-left-radius'
              },
              {
                text: 'Bottom Right',
                value: 'bottom-right-radius'
              }
            ]"
          />

          <hr>

          <sid-linked-group
            label="Padding"
            :sid="`.--${element.sid}--provider.active`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${element.sid}--provider.active`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
          
          <hr>

          <sid-font :sid="`.--${element.sid}--provider-item.active`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--provider-item.active`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--provider-item.active`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <div class="flex w-full items-center justify-between mb-[14px]">
            <label class="pb-0">
              Background Color:
            </label>

            <sid-color
              :sid="`.--${element.sid}--provider`"
              presudo=":hover"
              rule-name="background-color"
            />
          </div>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--provider`"
                presudo=":hover"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--provider`"
              presudo=":hover"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--provider`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--provider`"
            presudo=":hover"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: 'Top Left',
                value: 'top-left-radius'
              },
              {
                text: 'Top Right',
                value: 'top-right-radius'
              },
              {
                text: 'Bottom Left',
                value: 'bottom-left-radius'
              },
              {
                text: 'Bottom Right',
                value: 'bottom-right-radius'
              }
            ]"
          />

          <hr>

          <sid-linked-group
            label="Padding"
            :sid="`.--${element.sid}--provider`"
            presudo=":hover"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${element.sid}--provider`"
            presudo=":hover"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
          
          <hr>

          <sid-font
            :sid="`.--${element.sid}--provider-item`"
            presudo=":hover"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--provider-item.active`"
            presudo=":hover"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--provider-item.active`"
                presudo=":hover"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding, Margins"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import draggable from 'vuedraggable'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'

export default {
  name: 'ProductVariantSettings',
  components: {
    RuleTabs,
    draggable,
    SidSelect,
    SidRangeSlider,
    SidColor,
    SidFont,
    SidLinkedGroup,
    SidbearSettingsGroup
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    formContent: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      active: null
    }
  },
  computed: {
    ...mapState('orderForm', {
      providers: state => state.providers
    }),
    providersList () {
      const form = this.providers.find(item => item.id === this.formContent?.id)
      if (form) {
        return form.providers
      } else {
        return []
      }
    },
    elementProviders: {
      get () {
        return this.element.options.content.paymentProvider.providers
      },
      set (val) {
        this.element.options.content.paymentProvider.providers.push(val)
      }
    }
  },
  methods: {
    updateProviders () {
      const tmp = this.element.options.content.paymentProvider.providers
      this.element.options.content.paymentProvider.providers = this.providers
      this.element.options.content.paymentProvider.providers.forEach(provider => {
        const item = tmp.find(item => item.code === provider.code)
        if (item) {
          provider.name = item.name
        }
      })
    }
  },
  created () {
    if (!this.element.options.content.paymentProvider.providers) {
      this.element.options.content.paymentProvider.providers = []
    }

    this.providersList.forEach(provider => {
      const exists = this.elementProviders.find(item => item.code === provider.code)

      if (!exists) {
        this.elementProviders.push({
          name: provider.name,
          code: provider.code
        })
      }
    })
  }
}
</script>