<template>
  <div>
    <div v-html="render" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import shortid from 'shortid'

export default {
  name: 'GlobalStylesRender',
  mixins: [ResolutionMixin],
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('font', {
      customFontsCSS: state => state.customFontsCSS
    }),
    defaultTextStylesSettings (){
      const data = {
        'h1': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
            md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
            sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
            md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
            sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
          },
          color: {
            lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
            md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
            sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
          },
          align: {
            lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'h2': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
            md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
            sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
            md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
            sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
          },
          color: {
            lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
            md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
            sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
          },
          align: {
            lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'h3': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
            md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
            sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '34px'),
            md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '34px'),
            sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
          },
          color: {
            lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
            md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
            sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
          },
          align: {
            lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'h4': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
            md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
            sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '24px'),
            md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '24px'),
            sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '24px')
          },
          color: {
            lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
            md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
            sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
          },
          align: {
            lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'h5': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'font', 'lg'], 'ProximaNova'),
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'font', 'md'], 'ProximaNova'),
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'font', 'sm'], 'ProximaNova')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontWeight', 'lg'], 500),
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontWeight', 'md'], 500),
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontWeight', 'sm'], 500)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontSize', 'lg'], '20px'),
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontSize', 'md'], '20px'),
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'fontSize', 'sm'], '20px')
          },
          color: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'color', 'lg'], '#212529'),
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'color', 'md'], '#212529'),
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'color', 'sm'], '#212529')
          },
          align: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'align', 'lg'], 'left'),
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'align', 'md'], 'left'),
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'align', 'sm'], 'left')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['textStyles', 'h5' ,'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['textStyles', 'h5' ,'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['textStyles', 'h5' ,'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.2,
            md: 1.2,
            sm: 1.2
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'h6': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'font', 'lg'], 'ProximaNova'),
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'font', 'md'], 'ProximaNova'),
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'font', 'sm'], 'ProximaNova')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'fontWeight', 'lg'], 500),
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'fontWeight', 'md'], 500),
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'fontWeight', 'sm'], 500)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'fontSize', 'lg'], '16px'),
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'fontSize', 'md'], '16px'),
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'fontSize', 'sm'], '16px')
          },
          color: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'color', 'lg'], '#212529'),
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'color', 'md'], '#212529'),
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'color', 'sm'], '#212529')
          },
          align: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'align', 'lg'], 'left'),
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'align', 'md'], 'left'),
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'align', 'sm'], 'left')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['textStyles', 'h6', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['textStyles', 'h6', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['textStyles', 'h6', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.2,
            md: 1.2,
            sm: 1.2
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'paragraph': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['paragpraph', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['paragpraph', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['paragpraph', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'lg'], 400),
            md: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'md'], 400),
            sm: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'sm'], 400)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'lg'], '18px'),
            md: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'md'], '18px'),
            sm: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'sm'], '18px')
          },
          color: {
            lg: _.get(this.globalStyles, ['paragpraph', 'color', 'lg'], '#383838'),
            md: _.get(this.globalStyles, ['paragpraph', 'color', 'md'], '#383838'),
            sm: _.get(this.globalStyles, ['paragpraph', 'color', 'sm'], '#383838')
          },
          align: {
            lg: _.get(this.globalStyles, ['paragpraph', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['paragpraph', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['paragpraph', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['paragpraph', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['paragpraph', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['paragpraph', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'link': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['link', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['link', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['link', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['link', 'fontWeight', 'lg'], 400),
            md: _.get(this.globalStyles, ['link', 'fontWeight', 'md'], 400),
            sm: _.get(this.globalStyles, ['link', 'fontWeight', 'sm'], 400)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['link', 'fontSize', 'lg'], '18px'),
            md: _.get(this.globalStyles, ['link', 'fontSize', 'md'], '18px'),
            sm: _.get(this.globalStyles, ['link', 'fontSize', 'sm'], '18px')
          },
          color: {
            lg: _.get(this.globalStyles, ['link', 'color', 'lg'], '#7CACFA'),
            md: _.get(this.globalStyles, ['link', 'color', 'md'], '#7CACFA'),
            sm: _.get(this.globalStyles, ['link', 'color', 'sm'], '#7CACFA')
          },
          align: {
            lg: _.get(this.globalStyles, ['link', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['link', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['link', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['link', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['link', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['link', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        },
        'link-hover': {
          fontFamily: {
            lg: _.get(this.globalStyles, ['link', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['link', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['link', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['link', 'fontWeight', 'lg'], 400),
            md: _.get(this.globalStyles, ['link', 'fontWeight', 'md'], 400),
            sm: _.get(this.globalStyles, ['link', 'fontWeight', 'sm'], 400)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['link', 'fontSize', 'lg'], '18px'),
            md: _.get(this.globalStyles, ['link', 'fontSize', 'md'], '18px'),
            sm: _.get(this.globalStyles, ['link', 'fontSize', 'sm'], '18px')
          },
          color: {
            lg: _.get(this.globalStyles, ['link', 'color', 'lg'], '#5f8edc'),
            md: _.get(this.globalStyles, ['link', 'color', 'md'], '#5f8edc'),
            sm: _.get(this.globalStyles, ['link', 'color', 'sm'], '#5f8edc')
          },
          align: {
            lg: _.get(this.globalStyles, ['link', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['link', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['link', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['link', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['link', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['link', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        }
      }
      return data
    },
    textStyles () {
      if (!this.globalStyles.textStyles) {
        this.$set(this.globalStyles, 'textStyles', this.defaultTextStylesSettings)
      }

      if (!this.globalStyles.button) {
        this.$set(this.globalStyles, 'button', {
          'uid': shortid.generate(),
          'name': 'Button',
          'component': 'ElButton',
          'group': 'Media',
          'layout': null,
          'options': {
            'customize': null,
            'content': {
              'button': {
                'pressetID': 0,
                'display': true,
                'hover': {
                  'resolutionStyle': {
                    'lg': {
                      'size': '1'
                    }
                  }
                },
                'type': 'button-mod',
                'text': 'Click Me',
                'resolutionStyle': {
                  'lg': {
                    'size': '1',
                    'align': 'center',
                    'btnStyle': '',
                    'color': 'rgba(255, 255, 255, 1)',
                    'backgroundColor': this.globalStyles.colors.buttons,
                    'shape': null,
                    'shadow': null,
                    'padding': {
                      'top': 20,
                      'bottom': 20,
                      'x': 0
                    }
                  },
                  'sm': {
                    'size': 'md',
                    'align': 'center',
                    'btnStyle': '',
                    'color': '#ffffff',
                    'backgroundColor': this.globalStyles.colors.buttons,
                    'shape': null,
                    'shadow': null,
                    'padding': {
                      'top': 20,
                      'bottom': 20,
                      'x': 0
                    }
                  }
                },
                'buttonType': 'button',
                'animations': {
                  'animation': null,
                  'once': true,
                  'offset': 200,
                  'delay': 50
                },
                'backgroundType': null,
                'backgroundGradient': [
                  {
                    'color': 'rgba(177, 211, 103, 1)',
                    'position': 0
                  },
                  {
                    'color': 'rgba(102, 176, 83, 1)',
                    'position': 100
                  }
                ],
                'border': [
                  0,
                  0,
                  0,
                  0
                ],
                'radius': [
                  4,
                  4,
                  4,
                  4
                ],
                'gradientDirection': '180',
                'padding': {
                  'lg': {
                    'top': 0,
                    'bottom': 0,
                    'x': 22
                  }
                },
                'margin': {
                  'lg': {
                    'top': '20',
                    'bottom': '20',
                    'x': 0
                  },
                  'md': {
                    'top': '20',
                    'bottom': '20',
                    'x': 0
                  },
                  'sm': {
                    'top': '20',
                    'bottom': '20',
                    'x': 0
                  }
                },
                'shadowOptions': {
                  'spread': 4,
                  'blur': 20
                }
              }
            }
          }
        })
      }

      return this.globalStyles.textStyles
    },
    render () {
      const getTextStyles = (type, important = false, key = 'textStyles') => {
        return `{
           font-size: ${type !== 'link' && type !== 'link-hover' ? this[key][type].fontSize[this.resolutioner] : ''};
           font-family: ${this[key][type].fontFamily[this.resolutioner]};
           font-weight: ${this[key][type].fontWeight[this.resolutioner]};
           color: ${this[key][type].color.lg} ${important ? '!important' : ''};
           text-align: ${this[key][type].align[this.resolutioner]};
           line-height: ${type !== 'link' && type !== 'link-hover' ? this[key][type].lineHeight[this.resolutioner] : ''};
           letter-spacing: ${type !== 'link' && type !== 'link-hover' ? this[key][type].letterSpacing[this.resolutioner] : ''}px;
           text-decoration: ${this[key][type].textDecoration[this.resolutioner] || 'unset'};
           font-style: ${this[key][type].textStyle[this.resolutioner] || 'unset'};}
        `
      }

      const raw = `
          h1 .headline ${getTextStyles('h1')}
          h2 .headline ${getTextStyles('h2')}
          h3 .headline ${getTextStyles('h3')}
          h4 .headline ${this.textStyles.h4 ? getTextStyles('h4') : getTextStyles('h3')}
          h5 .headline ${this.textStyles.h5 ? getTextStyles('h5') : getTextStyles('h5', false, 'defaultTextStylesSettings')}
          h6 .headline ${this.textStyles.h6 ? getTextStyles('h6') : getTextStyles('h6', false, 'defaultTextStylesSettings')}
         .paragraph ${getTextStyles('paragraph')}
         .editor__content a:not([fromglobalstyle=false]), p span a:not([fromglobalstyle=false]) ${getTextStyles('link')}
         .editor__content a:not([fromglobalstyle=false]):hover, .pub__content a:not([fromglobalstyle=false]):hover ${getTextStyles('link-hover', true)}
      `

      return `<style>${raw}</style>`
    }
  },
  mounted () {
    this.$store.dispatch('font/fetchCustomFontsCSS')
      .then(() => {
        const $styleEl = document.createElement('style')
        $styleEl.type = 'text/css'
        $styleEl.id = 'custom-font-loader'
        $styleEl.innerHTML = this.customFontsCSS
        document.head.appendChild($styleEl)
      })
  },
  beforeDestroy () {
    const $styleEl = document.head.querySelector('#custom-font-loader')
    if ($styleEl) {
      $styleEl.remove()
    }
  }
}
</script>