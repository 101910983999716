<template>
  <div class="flex items-center gap-2">
    <div class="animate-pulse flex items-center justify-center gap-1 h-6 rounded-[3px] bg-[#E64141] text-white text-[10px] font-[600] p-2">
      Live
    </div>

    <div class="flex items-center justify-center gap-1 h-6 rounded-[3px] bg-[#262626] text-white text-[10px] font-[600] p-2">
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.71006 6.35659C1.64197 6.24877 1.60792 6.19486 1.58886 6.11171C1.57455 6.04925 1.57455 5.95075 1.58886 5.88829C1.60792 5.80514 1.64197 5.75123 1.71006 5.64341C2.27276 4.75242 3.9477 2.5 6.5002 2.5C9.0527 2.5 10.7276 4.75242 11.2903 5.64341C11.3584 5.75123 11.3925 5.80514 11.4115 5.88829C11.4259 5.95075 11.4259 6.04925 11.4115 6.11171C11.3925 6.19486 11.3584 6.24877 11.2903 6.35659C10.7276 7.24758 9.0527 9.5 6.5002 9.5C3.9477 9.5 2.27276 7.24758 1.71006 6.35659Z"
          stroke="white"
          stroke-width="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.5002 7.5C7.32863 7.5 8.0002 6.82843 8.0002 6C8.0002 5.17157 7.32863 4.5 6.5002 4.5C5.67177 4.5 5.0002 5.17157 5.0002 6C5.0002 6.82843 5.67177 7.5 6.5002 7.5Z"
          stroke="white"
          stroke-width="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {{ onlineCount }}
    </div>

    <!--    <div-->
    <!--      v-if="isRecording"-->
    <!--      class="flex items-center justify-center gap-1 h-6 rounded-[3px] bg-[#262626] text-white text-[10px] font-[600] p-2"-->
    <!--    >-->
    <!--      REC-->
    <!--    </div>-->
  </div>
</template>

<script>
import {ref, onValue} from 'firebase/database'
import {realTimeDB} from '@/firebase.js'
import {mapState} from 'vuex'

export default {
  name: 'MembersCount',
  props: {
    streamKey: {
      type: String,
      default: ''
    },
    showCount: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      onlineCount: 0,
      isRecording: false,
      unsub: null
    }
  },
  computed: {
    ...mapState('liveStream', {
      roomID: state => state.roomID
    }),
    key () {
      return this.streamKey
    }
  },
  mounted () {
    const counterRef = ref(realTimeDB, `/online-countersV2/${this.roomID || this.key}`)

    onValue(counterRef, snapshot => {
      this.onlineCount = snapshot.size
    })
  },
  beforeCreate () {
    if (this.unsub) {
      this.unsub()
    }
  }
}
</script>