var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-[9px] sid-select"},[_c('SidbearSettingsGroup',{attrs:{"title":"Navbar"}},[_c('b-form-group',{attrs:{"label":"Navbar Collapsable"}},[_c('v-select',{attrs:{"options":_vm.breackOptions,"reduce":val => val.value,"searchable":false},model:{value:(_vm.config.specialCols.navigation.showOn),callback:function ($$v) {_vm.$set(_vm.config.specialCols.navigation, "showOn", $$v)},expression:"config.specialCols.navigation.showOn"}})],1),_c('b-form-group',{attrs:{"label":"Placement"}},[_c('v-select',{attrs:{"options":_vm.options,"reduce":val => val.value,"searchable":false},model:{value:(_vm.config.specialCols.navigation.placement),callback:function ($$v) {_vm.$set(_vm.config.specialCols.navigation, "placement", $$v)},expression:"config.specialCols.navigation.placement"}})],1),_c('sid-buttons-group',{attrs:{"label":"Horizontal Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        }
      ],"sid":`#header-collapse`,"rule-name":"justify-content"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Hamburger Icon"}},[_c('b-form-group',{attrs:{"label":"Open icon"}},[_c('icon-select',{model:{value:(_vm.iconOpen),callback:function ($$v) {_vm.iconOpen=$$v},expression:"iconOpen"}}),_c('sid-color',{attrs:{"sid":`.--${_vm.config.sid}--open-icon`,"rule-name":"color"}})],1),_c('b-form-group',{attrs:{"label":"Close icon"}},[_c('icon-select',{model:{value:(_vm.iconClose),callback:function ($$v) {_vm.iconClose=$$v},expression:"iconClose"}}),_c('sid-color',{attrs:{"sid":`.--${_vm.config.sid}--close-icon`,"reference":`.--${_vm.config.sid}--icon`,"rule-name":"color"}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Hamburger Icon Size","label-class":"!pb-[3px]"}},[_c('sid-range-slider',{attrs:{"sid":`.--${_vm.config.sid}--icon`,"rule-name":"font-size"}})],1),_c('b-form-group',[_c('sid-buttons-group',{staticClass:"mb-0",attrs:{"label":"Icon Placement","options":[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            tooltip: 'Start',
            value: '10px',
            property: 'left',
            customProperties: [[`.--${_vm.config.sid}--icon`, ['right', 'auto']], [`.--${_vm.config.sid}--icon`, ['left', '10px']]]
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            tooltip: 'End',
            value: '10px',
            property: 'right',
            customProperties: [[`.--${_vm.config.sid}--icon`, ['left', 'auto']], [`.--${_vm.config.sid}--icon`, ['right', '10px']]]
          }
        ],"sid":`.--${_vm.config.sid}--icon`,"rule-name":"rightd"}})],1),_c('b-form-group',[_c('sid-translate',{attrs:{"sid":`.--${_vm.config.sid}--icon`,"rule-name":"translate","min":-100,"max":100,"step":1}})],1)],1),_c('flex-column-mod-v2',{attrs:{"component-name":"Navigation","config":_vm.config.specialCols.navigation}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }