<template>
  <div class="page-seo-settings">
    <div class="seo-preview pb-3">
      <div class="seo-preview__header pb-3">
        <div class="seo-preview__header__title">
          Preview
        </div>

        <b-button
          v-b-modal.modal-seo-settings
          variant="link"
          size="sm"
        >
          Preview on Google
        </b-button>

        <b-modal
          id="modal-seo-settings"
          title="Preview on Google"
          size="xl"
          ok-only
        >
          <div class="modal-seo-settings">
            <div class="modal-seo-settings__header">
              <div class="modal-seo-settings__header__buttons">
                <b-btn
                  v-b-tooltip.hover.top="'Show on Desktop'"
                  variant="white"
                  class=" justify-content-center py-2 px-2"
                  @click="setSeoView('desktop')"
                >
                  <icon
                    v-if="seoView === 'desktop'"
                    icon="desktop-active.svg"
                  />
                  <icon
                    v-else
                    icon="desktop.svg"
                  />
                </b-btn>

                <b-btn
                  v-b-tooltip.hover.top="'Show on Mobile'"
                  variant="white"
                  class=" justify-content-center py-2 px-2"
                  @click="setSeoView('mobile')"
                >
                  <icon
                    v-if="seoView === 'mobile'"
                    icon="smartphone-active.svg"
                  />
                  <icon
                    v-else
                    icon="smartphone.svg"
                  />
                </b-btn>
              </div>
            </div>

            <div class="modal-seo-settings__body">
              <div :class="[`seo-search modal-seo-settings-${seoView}`]">
                <div class="seo-search__input">
                  <input
                    type="text"
                    disabled=""
                    :value="page.title"
                  >

                  <span class="seo-search__input__icon">
                    <svg
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                      />
                    </svg>
                  </span>

                  <span class="seo-search__input__icon-cam">
                    <svg
                      class="b4yCbb"
                      focusable="false"
                      viewBox="0 0 192 192"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        fill="none"
                        height="192"
                        width="192"
                      />
                      <g>
                        <circle
                          fill="#34a853"
                          cx="144.07"
                          cy="144"
                          r="16"
                        />
                        <circle
                          fill="#4285f4"
                          cx="96.07"
                          cy="104"
                          r="24"
                        />
                        <path
                          fill="#ea4335"
                          d="M24,135.2c0,18.11,14.69,32.8,32.8,32.8H96v-16l-40.1-0.1c-8.8,0-15.9-8.19-15.9-17.9v-18H24V135.2z"
                        />
                        <path
                          fill="#fbbc05"
                          d="M168,72.8c0-18.11-14.69-32.8-32.8-32.8H116l20,16c8.8,0,16,8.29,16,18v30h16V72.8z"
                        />
                        <path
                          fill="#4285f4"
                          d="M112,24l-32,0L68,40H56.8C38.69,40,24,54.69,24,72.8V92h16V74c0-9.71,7.2-18,16-18h80L112,24z"
                        />
                      </g>
                    </svg>
                  </span>

                  <span class="serp-icon-mic" />
                </div>

                <div class="serp-preview-menus">
                  <ul>
                    <li class="current">
                      All
                    </li>
                    <li>
                      Images
                    </li>

                    <li>
                      Videos
                    </li>

                    <li>
                      News
                    </li>

                    <li>
                      Maps
                    </li>

                    <li>
                      More
                    </li>
                  </ul>
                </div>

                <div class="hr" />

                <div
                  class="modal-seo-settings__body__link"
                  role="button"
                  tabindex="0"
                >
                  <div class="group">
                    <svg
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
                      />
                    </svg>

                    <div class="seo-preview__link__url">
                      <div class="seo-preview__link__url__top capitalize">
                        {{ domain.replace('-', ' ') }}
                      </div>

                      <div class="seo-preview__link__url__bottom">
                        https://{{ domain }}/{{ page.slug }}/
                      </div>
                    </div>
                  </div>

                  <div class="group">
                    <h5 class="seo-preview__link__title">
                      {{ page.meta_title }}
                    </h5>
                  </div>

                  <div class="group">
                    <div class="seo-preview__link__description">
                      {{ page.meta_description | cropLongText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>

      <div
        class="seo-preview__link"
        role="button"
        tabindex="0"
      >
        <div class="group">
          <h5 class="seo-preview__link__title">
            {{ page.meta_title }}
          </h5>
        </div>

        <div class="group">
          <div class="seo-preview__link__url">
            https://{{ domain }}/{{ page.slug }}/
          </div>
        </div>

        <div class="group">
          <div class="seo-preview__link__description">
            {{ page.meta_description | cropLongText }}
          </div>
        </div>
      </div>

      <div class="hr" />
    </div>

    <b-form-group label="Focus Keyword/Keyphrase">
      <b-textarea
        v-model="page.meta_keywords"
        class="mb-2"
      />
    </b-form-group>

    <b-form-group label="Page SEO Title">
      <b-input
        v-model="page.meta_title"
        class="mb-2"
      />
      <strong-progress
        :max="65"
        :min="5"
        :value="titleLength"
      />
    </b-form-group>

    <b-form-group label="Canonical URL">
      <div class="text-muted mb-1">
        By default, the primary website domain and current page slug are used.
        <br>
        The default Canonical URL for this page is:
      </div>
      <b-input-group class="mb-0 my-2">
        <b-input-group-prepend>
          <b-input-group-text class="h-[29px] bg-white font-size-14">
            https://
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input
          v-model="canonical_url"
        />
      </b-input-group>
      <b-link
        class="d-block"
        @click="canonical_url = ''"
      >
        Reset to default
      </b-link>
      <div class="text-muted mt-2">
        To change this URL, edit the link in the input field above. Do not edit the link to continue using the page default settings.
      </div>
    </b-form-group>
    
    <b-form-group label="URL Slug">
      <p>(the last part of the page's link)</p>

      <b-input
        v-model="slug"
        class="mb-3"
      />

      <h5 class="!text-[15px] mb-1">
        Warning note:
      </h5>
      <p>
        Changes to the URL Slug will change the page link. Any links to this page will need to be updated to reflect
        this change.
      </p>
    </b-form-group>

    <b-form-group label="Meta Description">
      <b-textarea
        v-model="page.meta_description"
        class="mb-2"
        placeholder="Add Description..."
        rows="5"
      />
      <strong-progress
        :max="150"
        :min="120"
        :value="descriptionLength"
      />
    </b-form-group>

    <template>
      <!--      <b-form-group-->
      <!--        label="og:title"-->
      <!--        label-class="text-lowercase"-->
      <!--      >-->
      <!--        <b-input v-model="page.og_title" />-->
      <!--      </b-form-group>-->

      <!--      <b-form-group-->
      <!--        label="og:description"-->
      <!--        label-class="text-lowercase"-->
      <!--      >-->
      <!--        <b-textarea v-model="page.og_description" />-->
      <!--      </b-form-group>-->

      <b-form-group
        label="Featured Image"
      >
        <image-upload
          :image="ogImage"
          disable-settigns
          @uploaded="uploadedOgImage"
          @imageDeleted="() => uploadedOgImage({url: ''})"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import StrongProgress from '../components/StrongProgress'
import {mapState} from 'vuex'
import _ from 'lodash'
import ImageUpload from '@/components/editor/utils/ImageUpload.vue'

export default {
  name: 'PageSeoSettings',
  components: { ImageUpload, StrongProgress },

  filters: {
    cropLongText: string => string?.length > 160 ? string.slice(0, 160) + '..' : string
  },

  data () {
    return {
      seoView: 'desktop'
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),

    ogImage () {
      return {
        src: this.page.settings.open_graph_image
      }
    },

    domain () {
      return this.project.domain || `${this.project.subdomain}.estage.site`
    },

    ...mapState('pages', {
      page: state => state.page
    }),

    titleLength () {
      return this.page.meta_title ? this.page.meta_title.length : 0
    },

    descriptionLength () {
      return this.page.meta_description ? this.page.meta_description.length : 0
    },

    slug: {
      get () {
        return _.kebabCase(this.page.slug)
      },

      set (val) {
        this.page.slug = val
      }
    },

    canonical_url: {
      get () {
        let url = _.get(this.page, 'canonical_url') 
        if(!url) {
          url = this.getFullDomain(this.project) +'/' + this.page.slug
        }
        return url
      },

      set (val) {
        this.page.canonical_url = val
      }
    }
  },

  methods: {
    uploadedOgImage (img) {
      this.$set(this.page.settings, 'open_graph_image', img.url)
    },

    setSeoView (view) {
      this.seoView = view
    }
  }
}
</script>
