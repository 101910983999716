import {mapActions, mapState} from 'vuex'

export default {
  computed: {
    ...mapState('integrations', {
      integrationsData: state => state.list
    }),
    integrations (){
      return this.integrationsData.data
    },
    integrationsLoading (){
      return this.integrationsData.loading
    },
    projectId (){
      return this.$route.params.id
    }
  },
  async created () {
    const {project_id} = this.integrationsData
    if((project_id != this.projectId || (project_id == this.projectId && !this.integrations.length)) && !this.integrationsLoading){
      await this.getIntegrations(this.projectId)
    }
    this.initForms()
  },
  methods: {
    ...mapActions({
      getIntegrations:'integrations/getList'
    }),
    initForms () {
      this.items.forEach(item => {
        const service = this.integrations.find(a=> a.alpha_code === item.alpha_code)
        if(service){
          item.active = Boolean(service.is_connected)
          item.integration_id = service.id
          item.is_status_active = service.status === 'ACTIVE'
          if(Array.isArray(item.data) && service.data){
            item.data.forEach(field => {
              field.value = service.data[field.key]
            })
          }
        }
      })
    },
    handleSubmit ({setLoading, closeModal, formFields},item) {
      const params = {
        project_id:this.projectId,
        integration_id:item.integration_id,
        data:{}
      }
      formFields.forEach(field => {
        params.data[field.key] = field.value
      })

      setLoading(true)

      this.$store.dispatch(`integrations/${item.active ? 'update':'create'}`,params)
        .then((data) => {
          if (data.data.status === 'success') {
            item.data.forEach((field,index) => {
              field.value = formFields[index].value
            })
            this.integrations.forEach(el => {
              if (el.alpha_code === item.alpha_code) {
                if (el.data == null) el.data = {}
                formFields.forEach(field => {
                  el.data[field.key] = field.value
                })
                if(!item.active) el.status = 'ACTIVE'
                el.is_connected = 1
              }
            })
            this.$swal({
              icon: 'success',
              title: 'Settings saved successfully',
              showConfirmButton: false,
              timer: 1500
            })

            if(!item.active) item.is_status_active = true
            item.active = true
            closeModal()
          }
        })
        .catch((error) => {
          console.error(error)
          this.$swal({
            icon: 'error',
            title: 'Error!',
            text: 'API key is invalid.',
            showConfirmButton: false,
            timer: 5000
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    handleToggleStatus ({setLoading},item){
      setLoading(true)
      const params = {
        project_id:this.projectId,
        integration_id:item.integration_id,
        status:item.is_status_active ? 'DRAFT' : 'ACTIVE' 
      }
    
      this.$store.dispatch('integrations/update',params)
        .then(() => {
          this.integrations.forEach(el => {
            if(el.alpha_code == item.alpha_code){
              el.status = params.status
            }
          })
          item.is_status_active = params.status === 'ACTIVE'
          this.$swal({
            icon: 'success',
            title: 'Settings saved successfully',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    handleDelete ({setLoading,setDetails},item){
      setLoading(true)
      const params = {
        project_id:this.projectId,
        integration_id:item.integration_id
      }
      this.$store.dispatch('integrations/delete',params)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Settings saved successfully',
            showConfirmButton: false,
            timer: 1500
          })
          item.data.forEach(field => field.value = '')
          item.active = false
          this.integrations.forEach(el => {
            if(el.alpha_code == item.alpha_code){
              el.data = null
              el.is_connected = 0
            }
          })
          setDetails(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
         
}