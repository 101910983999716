import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Payment Method'
export const group = ''

export default class ElPaymentProvider extends SectionProvider {
  constructor (options = {}) {
    super('ElPaymentProvider', options)

    this.name = name
    this.group = group
  }
}