<template>
  <div
    v-click-outside="close"
    class="relative on-hover select-none"
  >
    <b-btn
      v-b-tooltip.hover.right
      title="Admin & Modules"
      variant="white"
      class="w-[40px] h-[40px] text-[#6E747A] p-0 border-left-0 rounded-left-0"
      :class="{'!bg-[#E2E2E2]': menu}"
      @click="$router.push({name: 'settings.general', query: null})"
    >
      <icon icon="modules 1.svg" />
    </b-btn>

    <div
      class="overlay absolute top-0 left-0 translate-y-[40px] -translate-x-[50%] z-[100]"
    >
      <div class="w-[256px] h-auto mt-[6px] bg-[#FFFFFF] rounded-[8px] p-[10px] shadow-[0_1px_12px_rgba(0,0,0,0.03)]">
        <button
          v-if="list.title"
          class="inline-flex items-center justify-start w-full h-[36px] mb-[8px] p-[10px] text-[14px] text-[#525253] leading-none font-[400] transition-all !duration-[0ms] cursor-pointer hover:font-[600] hover:text-[#303030]"
          @click.prevent="mainMenu"
        >
          <i class="fa fa-chevron-left mr-[8px]" />
          {{ list.title }}
        </button>

        <div class="flex flex-col gap-y-[6px]">
          <ModuleSelectMenuItem
            v-for="(item, index) in list.items"
            v-if="item.hasOwnProperty('condition') ? item.condition : true"
            :key="`${index}-item`"
            :item="item"
            @submenu="submenu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import ClickOutside from '@/directives/click-outside'
import Icon from '@/components/editor/utils/Icon.vue'
import ModuleSelectMenuItem from '@/components/editor/components/module-select-menu/ModuleSelectMenuItem.vue'

export default {
  name: 'ModuleSelectMenu',
  components: {
    ModuleSelectMenuItem,
    Icon
  },
  directives: { ClickOutside },
  data () {
    return {
      reload: false,
      menu: false,
      list: []
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    routes () {
      return [
        {
          title: 'Admin & Modules',
          route: 'settings.general',
          query: null
        },
        {
          title: 'Project Settings',
          children: [
            {
              title: 'General',
              route: 'settings.general',
              query: this.$route.query
            },
            {
              title: 'Business Details',
              route: 'settings.business-details',
              query: this.$route.query
            },
            {
              title: 'Custom Domain',
              route: 'settings.domain',
              query: this.$route.query
            },
            {
              title: 'Cloudflare Enterprise',
              route: 'settings.cloudflare-enterprise',
              statement: this.user.cf_enterprise_status && this.project.cf_token,
              query: this.$route.query
            },
            {
              title: 'Code Injection',
              route: 'settings.injection',
              query: this.$route.query
            },
            {
              title: 'Share',
              route: 'settings.share',
              query: this.$route.query
            },
            {
              title: 'Social Share',
              route: 'settings.social_share',
              query: this.$route.query
            },
            {
              title: 'SEO Settings',
              route: 'settings.seo',
              query: this.$route.query
            },
            {
              title: 'Cookie Consent',
              route: 'settings.cookie',
              query: this.$route.query
            },
            {
              title: 'Restrictions',
              route: 'settings.page_restrictions',
              query: this.$route.query
            },
            {
              title: 'Integrations',
              route: 'settings.integrations',
              actives: [
                'settings.integrations.autoresponders',
                'settings.integrations.payment-processing',
                'settings.integrations.marketing',
                'settings.integrations.analytics',
                'settings.integrations.chat'
              ],
              query: this.$route.query
            },
            {
              title: 'Fonts',
              route: 'settings.fonts',
              query: this.$route.query
            },
            {
              title: 'Recaptcha',
              route: 'settings.recaptcha',
              query: this.$route.query
            },
            {
              title: 'Global Variables',
              route: 'settings.variables',
              query: this.$route.query
            },
            {
              title: 'EGT Event Actions',
              route: 'settings.global-tracking',
              query: this.$route.query
            }
          ].filter(link => link.hasOwnProperty('statement') ? link.statement : true)
        },
        {
          title: 'Blog',
          group: 'settings.blog',
          children: [
            {
              title: 'Overview',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: ''
              }
            },
            {
              title: 'Categories',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'categories'
              }
            },
            {
              title: 'Authors',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'authors'
              }
            },
            {
              title: 'Posts',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'posts'
              }
            },
            {
              title: 'Blog Home Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'blog-layouts'
              }
            },
            {
              title: 'Post Page Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'post-templates'
              }
            },
            {
              title: 'Post Content Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'post-content-templates'
              }
            }
          ]
        },
        {
          title: 'Comments',
          route: 'project.comments'
        },
        {
          title: 'Community',
          route: 'project.community'
        },
        {
          title: 'CRM',
          href: `https://crm.estage.com/${this.project.id}`,
          beta: true
        },
        {
          title: 'Modal Builder',
          route: 'module.modal'
        },
        {
          title: 'Mega Menu Builder',
          route: 'module.mega-menu'
        },
        {
          title: 'Marketing Funnels',
          href: `https://marketing-funnel.estage.com?project=${this.project.id}`
        },
        {
          title: 'Video Hosting',
          href: 'https://video.estage.com'
        }
      ].filter(link => link.hasOwnProperty('statement') ? link.statement : true)
    }
  },
  methods: {
    close () {
      this.menu = false
      this.list = {
        title: null,
        items: [
          ...this.routes
        ]
      }
    },
    toggle () {
      this.menu = !this.menu
      this.list = {
        title: null,
        items: [
          ...this.routes
        ]
      }
    },
    mainMenu () {
      this.reload = true
      this.list = {
        title: null,
        items: [
          ...this.routes
        ]
      }
      this.reload = false
    },
    async submenu (item) {
      this.reload = true
      try {
        if (!item) {
          this.list = {
            title: null,
            items: [
              ...this.routes
            ]
          }
        } else {
          const active = _.findIndex(this.routes, { title: item })
          this.list = {
            title: item || null,
            items: [
              ...this.routes[active].children
            ]
          }
        }
      } finally {
        this.reload = false
      }
    },
    async handleNavigation (item) {
      if (item.route) {
        const currentRoute = this.$route.name === item.route && _.isEqual(this.$route.query, item.query)

        if (!currentRoute) {
          await this.$router.push({ name: item.route, query: item.query })
        }
      }

      if (item.href) {
        window.open(item.href)
      }
    }
  },
  mounted () {
    this.submenu()
  }
}
</script>
