<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Chat Integrations
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Meet and chat with your visitors right where they're at - live on your pages! <br>
        ESTAGE gives you seamless integrations with some of the best live chat platform available!
      </p>
    </div>

    <div
      v-if="integrationsLoading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>

    <div v-else>
      <integration-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="{'mb-[16px]':index + 1 != items.length }"
        @submit="val => handleSubmit(val,item)"
        @toggle-status="val => handleToggleStatus(val,item)"
        @remove="val => handleDelete(val,item)"
      />
    </div>
  </div>
</template>

<script>
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem'
import IntegrationsMixin from '@/mixins/IntegrationsMixin'
export default {
  components: {IntegrationItem},
  mixins: [IntegrationsMixin],
  data () {
    return {
      items: [
        {
          title: 'Crisp Chat',
          active: false,
          image: require('@/assets/images/integrations/cripschat.svg'),
          icon: require('@/assets/images/integrations/cripschat-icon.svg'),
          alpha_code: 'CrispChat',
          data: [
            {
              label: 'Embed Code',
              value: '',
              type: 'textarea',
              dirty:false,
              key:'code',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Embed Code'
                }
              }
            }
          ]
        },
        {
          title: 'Intercom',
          active: false,
          image: require('@/assets/images/integrations/intercom.svg'),
          icon: require('@/assets/images/integrations/intercom-icon.svg'),
          alpha_code: 'Intercom',
          data: [
            {
              label: 'Embed Code',
              value: '',
              type: 'textarea',
              key:'code',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Embed Code'
                }
              }
            }
          ]
        }
      ]
    }
  }       
}
</script>