var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"QR","active":""}},[_c('b-form-group',{attrs:{"label":"Content / URL"}},[_c('b-input',{model:{value:(_vm.section.options.content.qr.content),callback:function ($$v) {_vm.$set(_vm.section.options.content.qr, "content", $$v)},expression:"section.options.content.qr.content"}})],1),_c('b-form-group',[_c('div',{staticClass:"flex items-center gap-[12px]"},[_c('label',{staticClass:"pb-0"},[_vm._v("QR Color")]),_c('color-picker',{attrs:{"color":_vm.section.options.content.qr.color},on:{"input":val => _vm.section.options.content.qr.color = val.hex}})],1)]),_c('b-form-group',[_c('div',{staticClass:"flex items-center gap-[12px]"},[_c('label',{staticClass:"pb-0"},[_vm._v("QR Background Color")]),_c('color-picker',{attrs:{"color":_vm.section.options.content.qr.background},on:{"input":val => _vm.section.options.content.qr.background = val.hex}})],1)]),_c('b-form-group',[_c('sid-range-slider',{attrs:{"label":"Size","rule-name":"--size","sid":`.--${_vm.section.sid}--`,"var":"--size","px-range":[100, 1000]}})],1),_c('sid-align',{attrs:{"sid":`.--${_vm.section.sid}--`,"rule-name":"justify-content","label":"Alignment"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Border & Radius"}},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":`.--${_vm.section.sid}-- div`,"rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":`.--${_vm.section.sid}-- div`,"label":"Border Width","rule-name":"border","angles":[
          {
            text: '',
            value: 'top-width'
          },
          {
            text: '',
            value: 'right-width'
          },
          {
            text: '',
            value: 'bottom-width'
          },
          {
            text: '',
            value: 'left-width'
          }
        ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":`.--${_vm.section.sid}-- div`,"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{attrs:{"label":"Radius","sid":`.--${_vm.section.sid}-- div`,"rule-name":"border","angles":[
        {
          text: '',
          value: 'top-left-radius'
        },
        {
          text: '',
          value: 'top-right-radius'
        },
        {
          text: '',
          value: 'bottom-left-radius'
        },
        {
          text: '',
          value: 'bottom-right-radius'
        }
      ]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.--${_vm.section.sid}--`,"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":`.--${_vm.section.sid}--`,"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }