<template>
  <highliter
    v-model="section.name"
    :class="align"
    :section="section"
    :dublicate="section"
    class="highlight-element custom-carousel-editor"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings({item: 'carousel', $slick: $refs.slick})"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <div class="max-w-[1000px]">
        <VueSlickCarousel
          v-if="showSlider"
          ref="slick"
          class="dots-top"
          v-bind="settings"
        >
          <div
            v-for="slide in slides"
            :key="slide.uid"
            :style="gap"
            class="d-block py-5"
          >
            <div :style="{boxShadow: slide.highlight ? '0 0 0 4px #7cacfa' : null}">
              <elements
                v-model="slide.content"
                drag-height="300px"
                @flow="handleFlow"
                @click.stop.native="$store.commit('editor/SET_PAGE', 'add-element')"
              />
            </div>
          </div>

          <template
            v-if="(position !== 'right' && position !== 'left') || insideDots"
            #prevArrow
          >
            <div>
              <i
                :class="section.options.content.carousel.arrows.leftIcon"
                :style="arrowsStyle"
              />
            </div>
          </template>

          <template
            v-if="(position !== 'right' && position !== 'left') || insideDots"
            #nextArrow
          >
            <div class="next-icon">
              <i
                :class="section.options.content.carousel.arrows.leftIcon"
                :style="arrowsStyle"
              />
            </div>
          </template>

          <template #customPaging>
            <div class="custom-dot">
              <i
                :class="dotIcon"
                :style="dotsStyle"
              />
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Template from './Template'
import _ from 'lodash'

export default {
  name: 'ElThreeImages',

  components: {Template},

  mixins: [SectionMixin],

  data () {
    return {
      showSlider: true
    }
  },

  computed: {
    insideDots () {
      return this.section.options.content.carousel.insideDots || false
    },
    dotIcon () {
      return `${this.section.options.content.carousel.dotOptions.icon}`
    },
    dotsStyle () {
      return {
        fontSize: `${this.section.options.content.carousel.dotOptions.size}px`,
        color: this.section.options.content.carousel.dotOptions.color
      }
    },
    position () {
      return this.section.options.content.carousel.dotOptions.position
    },
    slides () {
      return this.section.options.content.carousel.slides
    },
    align () {
      return `carouse-align-${this.section.options.content.carousel.align}`
    },
    settings () {
      return {
        speed: this.speed,
        'accessibility': false,
        'centerMode': !this.section.options.content.carousel.centerMode,
        'focusOnSelect': true,
        'autoplay': false,
        'dots': this.section.options.content.carousel.dots === 'true',
        'dotsClass': `slick-dots custom-dot-class ${this.position} ${this.insideDots ? 'inside' : null}`,
        'infinite': false,
        'slidesToShow': this.slidesToShow,
        'slidesToScroll': parseInt(this.section.options.content.carousel.slidesToScroll),
        'arrows': this.section.options.content.carousel.arrows.display
      }
    },
    slidesToShow () {
      const _slides = _.get(this.section.options.content.carousel.slidesToShow, this.resolutioner, _.get(this.section.options.content.carousel.slidesToShow, 'lg', this.section.options.content.carousel.slidesToShow))
      if (_slides === null) return _.get(this.section.options.content.carousel.slidesToShow, 'lg', this.section.options.content.carousel.slidesToShow)

      return _slides
    },
    arrowsStyle () {
      return {
        fontSize: `${this.section.options.content.carousel.arrows.arrowSize}px`,
        color: this.section.options.content.carousel.arrows.color
      }
    },
    gap () {
      return {
        padding: `0 ${this.section.options.content.carousel.gap || 0}px`
      }
    },
    speed () {
      return this.section.options.content.carousel.speed || 300
    }
  },

  watch: {
    slides () {
      this.updateSlider()
    }
  },

  methods: {
    updateSlider () {
      this.showSlider = false

      setTimeout(() => {
        this.showSlider = true
      }, 100)
    }
  }
}
</script>
