<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    :class="{'element-hidden': !visability('star')}"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('star')"
  >
    <div
      :data-sid="section.sid"
      :class="`--${section.sid}--wrapper`"
      class="flex items-center flex-wrap star-rating-el"
    >
      <i
        v-for="index in parseInt(star.max)"
        :key="index"
        :class="[`--${section.sid}--star`, index - 1 === parseInt(star.stars) && isFloat(star.stars) ? 'fa fa-star-half-stroke' : index > star.stars ? 'fa fa-star-o' : 'fa fa-star']"
      />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'


export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin],

  computed: {
    star () {
      return this.section.options.content.star
    }
  },
  
  methods: {
    isFloat (value) {
      if (
        typeof value === 'number' &&
          !Number.isNaN(value) &&
          !Number.isInteger(value)
      ) {
        return true
      }

      return false
    }
  }
}
</script>

<style>
.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "\f5c0";
}

.star-rating-el {
  font-size: 27px;
  justify-content: center;
}
</style>
