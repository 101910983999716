<template>
  <div>
    <b-form-group
      v-for="control in controls"
      :key="control.key"
      :label="control.label"
    >
      <div class="flex items-center justify-between gap-[10px]">
        <vue-slider
          v-model="transformParts[control.key]"
          class="editor-slider-1 !w-[160px]"
          :interval="control.step"
          :dot-size="17"
          tooltip="none"
          :min="control.min"
          :max="control.max"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="transformParts[control.key]"
          :min="control.min"
          :max="control.max"
          :step="control.step"
          class="d-block !w-[60px]"
          @input="onChange"
        />
      </div>
    </b-form-group>

    <div class="flex justify-end pt-2 pr-1">
      <span
        class="text-[13px] text-[#44474A] hover:opacity-80 cursor-pointer underline"
        @click="reset"
      >Reset Transforms</span>
    </div>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import { parse } from 'transform-parser'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidTransform',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  props: {
    legacySid: {
      type: String,
      default: null
    },
    var: {
      type: String,
      default: '--transform'
    },
    section: {
      type: Object,
      required: true
    },
    translateDimensional: {
      type: String,
      default: 'px'
    },
    controls: {
      type: Array,
      default: () => [
        {
          label: 'Skew',
          key: 'skew',
          min: -50,
          max: 50,
          step: 1,
          dimension: 'deg'
        },
        {
          label: 'Scale',
          key: 'scale',
          min: 0,
          max: 2,
          step: 0.1,
          dimension: ''
        },
        {
          label: 'Rotate-Y',
          key: 'rotateY',
          min: -360,
          max: 360,
          step: 1,
          dimension: 'deg'
        },
        {
          label: 'Rotate-X',
          key: 'rotateX',
          min: -360,
          max: 360,
          step: 1,
          dimension: 'deg'
        },
        {
          label: 'Rotate-Z',
          key: 'rotateZ',
          min: -360,
          max: 360,
          step: 1,
          dimension: 'deg'
        },
        {
          label: 'Translate-Y',
          key: 'translateY',
          min: -4000,
          max: 4000,
          step: 1,
          dimension: 'px'
        },
        {
          label: 'Translate-X',
          key: 'translateX',
          min: -4000,
          max: 4000,
          step: 1,
          dimension: 'px'
        },
        {
          label: 'Translate-Z',
          key: 'translateZ',
          min: -4000,
          max: 4000,
          step: 1,
          dimension: 'px'
        },
        {
          label: 'Perspective',
          key: 'perspective',
          min: -4000,
          max: 4000,
          step: 100,
          dimension: 'px'
        }
      ]
    }
  },
  data () {
    return {
      transformParts: {
        'perspective': 0,
        'skew': 0,
        'scale': 1,
        'rotateY': 0,
        'rotateX': 0,
        'rotateZ': 0,
        'translateY': 0,
        'translateX': 0,
        'translateZ': 0
      }
    }
  },
  watch: {
    resolutioner () {
      this.parseTransform()
    }
  },
  created () {
    this.parseTransform()
  },
  methods: {
    parseTransformToString () {
      let str = ''

      Object.keys(this.transformParts).forEach(prop => {
        let orig = this.controls.find(p => p.key === prop)
        let defaultValue = 0

        if (prop === 'perspective' && this.transformParts[prop] === 0) {
          defaultValue = 'none'
          orig = ''
        }

        if (orig) {
          str += `${prop}(${this.transformParts[prop] || defaultValue}${orig.dimension}) `
        }
      })

      return str
    },
    onChange () {
      const transform = this.parseTransformToString()

      this.addSidRules([[this.getSid(), [this.ruleName, transform]]])
      this.addSidRules([[this.getSid(), [this.var, transform]]])
    },
    parseTransform () {
      if (this.sidValueMutation !== 'none') {
        const transformString = this.getSidStyle(this.getSid(), this.var)

        if (transformString) {
          const parsedTransform = parse(transformString)
          this.transformParts = {
            'perspective': parseFloat(parsedTransform.perspective),
            'skew': parseFloat(parsedTransform.skew),
            'scale': parseFloat(parsedTransform.scale),
            'rotateY': parseFloat(parsedTransform.rotateY),
            'rotateX': parseFloat(parsedTransform.rotateX),
            'rotateZ': parseFloat(parsedTransform.rotateZ),
            'translateY': parseFloat(parsedTransform.translateY),
            'translateX': parseFloat(parsedTransform.translateX),
            'translateZ': parseFloat(parsedTransform.translateZ)
          }
        }
      }
    },
    reset () {
      this.transformParts = {
        'perspective': 0,
        'skew': 0,
        'scale': 1,
        'rotate': 0,
        'translateY': 0,
        'translateX': 0,
        'translateZ': 0
      }

      if (this.legacySid !== null) {
        this.clearSidRuleByName(this.legacySid, this.ruleName)
      }

      this.addSidRules([[this.getSid(), [this.ruleName, '']]])
      this.addSidRules([[this.getSid(), [`--${this.section.sid}--transform`, '']]])
    }
  }

  //   function matrixToTransform(matrix) {
  //   const match = matrix.match(/matrix\((.+),(.+),(.+),(.+),(.+),(.+)\)/);
  //   if (!match) {
  //     return '';
  //   }
  //   const a = match[1];
  //   const b = match[2];
  //   const c = match[3];
  //   const d = match[4];
  //   const e = match[5];
  //   const f = match[6];
  //   return `translate(${e}px, ${f}px) rotate(${Math.atan2(b, a)}rad) scale(${Math.sqrt(a * a + b * b)})`;
  // }
  //
  // const matrix = 'matrix(0.7071067811865475, 0.7071067811865475, -0.7071067811865475, 0.7071067811865475, 0, 0)';
  // const transform = matrixToTransform(matrix);
  // console.log(transform); // Outputs 'translate(0px, 0px) rotate(0.7853981633974483rad) scale(1)'

}
</script>