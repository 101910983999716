import { render, staticRenderFns } from "./ElPostCommentsAndLikes.vue?vue&type=template&id=4f70e659"
import script from "./ElPostCommentsAndLikes.vue?vue&type=script&lang=js"
export * from "./ElPostCommentsAndLikes.vue?vue&type=script&lang=js"
import style0 from "./ElPostCommentsAndLikes.vue?vue&type=style&index=0&id=4f70e659&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports