<template>
  <div class="sid-select">
    <SidbearSettingsGroup
      title="Message"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs :tabs="['Normal', 'Error']">
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Icon Color</label>

              <sid-color
                :sid="`${sidClass} .sq-card-wrapper .sq-card-message-no-error::before`"
                rule-name="background-color"
              />
            </div>
          </b-form-group>
          
          <sid-font
            :sid="`${sidClass} .sq-card-wrapper .sq-card-message`"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`${sidClass} .sq-card-wrapper .sq-card-message`"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`${sidClass} .sq-card-wrapper .sq-card-message`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Error>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Icon Color</label>

              <sid-color
                :sid="`${sidClass} .sq-card-wrapper .sq-card-message-error::before`"
                rule-name="background-color"
              />
            </div>
          </b-form-group>
          
          <sid-font
            :sid="`${sidClass} .sq-card-wrapper .sq-card-message-error`"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`${sidClass} .sq-card-wrapper .sq-card-message-error`"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`${sidClass} .sq-card-wrapper .sq-card-message-error`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Border & Radius"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs :tabs="['Normal', 'Focus', 'Error']">
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between !gap-[20px]">
              <label class="w-full pb-0">Border Type</label>

              
              <sid-select
                :sid="`${sidClass} .sq-card-wrapper .sq-card-iframe-container`"
                rule-name="border-style"
                :searchable="false"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`${sidClass} .sq-card-wrapper .sq-card-iframe-container`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`${sidClass} .sq-card-wrapper .sq-card-iframe-container`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`${sidClass} .sq-card-wrapper .sq-card-iframe-container`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Focus>
          <b-form-group>
            <div class="flex items-center justify-between !gap-[20px]">
              <label class="w-full pb-0">Border Type</label>

              <sid-select
                :sid="`${sidClass} .sq-card-wrapper.sq-focus .sq-card-iframe-container::before`"
                rule-name="border-style"
                :searchable="false"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`${sidClass} .sq-card-wrapper.sq-focus .sq-card-iframe-container::before`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`${sidClass} .sq-card-wrapper.sq-focus .sq-card-iframe-container::before`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`${sidClass} .sq-card-wrapper.sq-focus .sq-card-iframe-container::before`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Error>
          <b-form-group>
            <div class="flex items-center justify-between !gap-[20px]">
              <label class="w-full pb-0">Border Type</label>

              <sid-select
                :sid="`${sidClass} .sq-card-wrapper.sq-error .sq-card-iframe-container::before`"
                rule-name="border-style"
                :searchable="false"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`${sidClass} .sq-card-wrapper.sq-error .sq-card-iframe-container::before`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`${sidClass} .sq-card-wrapper.sq-error .sq-card-iframe-container::before`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`${sidClass} .sq-card-wrapper.sq-error .sq-card-iframe-container::before`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'

export default {
  name: 'SquareFieldSettings',
  components: {SidSelect, RuleTabs, SidLinkedGroup, SidColor, SidFont, SidbearSettingsGroup, SidRangeSlider},
  props: {
    sidClass: {
      type: String,
      required: true
    }
  }
}
</script>
