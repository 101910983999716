<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    :class="{'element-hidden': !visability('counter')}"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('counter')"
  >
    <Template :section="section">
      <template v-if="shapeEnabled && fromVal < toVal">
        <div
          v-for="(digit, index) in getLength - count.length"
          :key="`key-${index}`"
          :style="{...size}"
          class="flex items-center justify-center"
        >
          <span>0</span>
        </div>
      </template>
      <div
        v-for="(digit, index) in getCount"
        :key="index"
      >
        <div
          v-if="digit !== ','"
          :style="{...size}"
          class="flex items-center justify-center"
        >
          <span
            v-text="digit"
          />
        </div>
        <div
          v-else
          class="drop-shadow"
          :style="decimalStyles"
          v-text="section.options.content.counter.decimal.char"
        />
      </div>
    </Template>
  </highliter>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import BorderMixin from '@/components/mixins/BorderMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, BorderMixin, CustomStylesMixin],

  data () {
    return {
      $scrollContainer: null,
      from: 0,
      to: 0,
      play: false,
      counterValue: 0
    }
  },

  computed: {
    getLength () {
      return this.to.toString().length
    },
    getCount () {
      return this.section.options.content.counter.decimal.enabled.lg ? this.formatedCount : this.count
    },
    formatedCount () {
      return new Intl.NumberFormat().format(this.counterValue).split('')
    },
    count () {
      if (this.counterValue.toString() === 'NaN') return [0]
      return this.counterValue.toString().split('')
    },
    size () {
      if (!this.shapeEnabled) return {}
      let _subject = this.section.options.content.counter.size[this.resolutioner]
      if (_subject === null) {
        _subject = this.section.options.content.counter.size.lg
      }

      return {
        width: `${_subject + 20}px`,
        height: `${_subject + 20}px`,
        ...this.borderSubject(this.section.options.content.counter, [0, 0, 0, 0]),
        ...this.radiusSubject(this.section.options.content.counter, [0, 0, 0, 0]),
        ...this.shadowSubject(this.section.options.content.counter),
        ...this.backgroundColor
      }
    },
    shapeEnabled () {
      let _subject = this.section.options.content.counter.shapeEnabled[this.resolutioner]
      if (_subject === null) {
        _subject = this.section.options.content.counter.shapeEnabled.lg
      }

      return _subject
    },
    speed () {
      return (this.section.options.content.counter.speed || 0.1) * 1000
    },
    decimalStyles () {
      return {
        color: this.section.options.content.counter.decimal.color
      }
    },
    fromVal () {
      return this.section.options.content.counter.from
    },
    toVal () {
      return this.section.options.content.counter.to
    }
  },

  watch: {
    play () {
      this.animate()
    },
    fromVal () {
      this.animate()
    },
    toVal () {
      this.animate()
    }
  },

  mounted () {
    this.isInViewPort()

    document.querySelector('.scrollbale-container').addEventListener('scroll', this.isInViewPort)
  },

  beforeDestroy () {
    const $scrollContainer = document.querySelector('.scrollbale-container')
    if (!$scrollContainer) return

    $scrollContainer.removeEventListener('scroll', this.isInViewPort)
  },

  methods: {
    isInViewPort () {
      if (this.play || !this.$el) return
      const bounding = this.$el.getBoundingClientRect()

      if (
        bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.play = true
        return true
      }
    },
    animate () {
      let startTime = null
      this.from = _.cloneWith(this.section.options.content.counter.from)
      this.to = _.cloneWith(this.section.options.content.counter.to)

      const step = (currentTime) => {
        if (!startTime) {
          startTime = currentTime
        }

        const progress = Math.min((currentTime - startTime) / this.speed, 1)

        this.counterValue = Math.floor(progress * (this.to - this.from) + this.from)

        if (progress < 1) {
          window.requestAnimationFrame(step)
        } else {
          window.cancelAnimationFrame(window.requestAnimationFrame(step))
        }
      }

      window.requestAnimationFrame(step)
    }
  }
}
</script>
