import Vue from 'vue'
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor'

export function useWebRTC (token, streamName, project, type) {
  let vm = new Vue()
  const rating = 5
  let webRTCAdaptor = null
  const streamId = `stream-${streamName}_p-${project}_t-${type}`

  vm.$on('hook:beforeDestroy', async () => {
    await hangup()
  })

  // eslint-disable-next-line no-unused-vars
  const call = async (localStream, bitrate = 3000) => {
    const videoSettings = localStream.getVideoTracks()[0].getSettings()
    console.log('Video Settings:', videoSettings)

    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: 'wss://estage.live:443/WebRTCAppEE/websocket',
      mediaConstraints: {
        video: {
          height: 1080,
          width: 1920,
          frameRate: 30
        },
        audio: true
      },
      peerconnection_config: {
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }]
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
      },
      localStream,
      bandwidth: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 1200 : 1800 // Dynamic bandwidth
    })

    webRTCAdaptor.publish(streamId)
  }

  const hangup = async () => {
    webRTCAdaptor.stop(streamId)
    webRTCAdaptor.closeStream(streamId)
    webRTCAdaptor.closePeerConnection(streamId)
  }

  const replaceTrack = async (newTrack) => {
    if (webRTCAdaptor) {
      webRTCAdaptor.updateVideoTrack(newTrack, streamId)
    }
  }

  const replaceTrackByDevice = async (newTrack, type) => {
    if (webRTCAdaptor) {
      if (type === 'video') {
        webRTCAdaptor.updateVideoTrack(newTrack, streamId)
      } else if (type === 'audio') {
        webRTCAdaptor.updateAudioTrack(newTrack, streamId)
      }
    }
  }

  return {
    call,
    hangup,
    replaceTrack,
    replaceVideoTrack: track => replaceTrackByDevice(track, 'video'),
    replaceAudioTrack: track => replaceTrackByDevice(track, 'audio'),
    rating
  }
}