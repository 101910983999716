var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Collapsable Menu","active":""}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Expand Menu Display"}},[_c('b-checkbox-group',{attrs:{"stacked":"","options":[{text: 'Desktop', value: 'lg'}, {text: 'Tablet', value: 'md'}, {text: 'Mobile', value: 'sm'}]},model:{value:(_vm.section.options.content.menu.visability),callback:function ($$v) {_vm.$set(_vm.section.options.content.menu, "visability", $$v)},expression:"section.options.content.menu.visability"}})],1)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Hamburger Icon"}},[_c('b-form-group',{attrs:{"label":"Open icon"}},[_c('icon-select',{model:{value:(_vm.section.options.content.menu.openIcon),callback:function ($$v) {_vm.$set(_vm.section.options.content.menu, "openIcon", $$v)},expression:"section.options.content.menu.openIcon"}}),_c('sid-color',{attrs:{"sid":`.--${_vm.section.sid}--open-icon`,"rule-name":"color"}})],1),_c('b-form-group',{attrs:{"label":"Close icon"}},[_c('icon-select',{model:{value:(_vm.section.options.content.menu.closeIcon),callback:function ($$v) {_vm.$set(_vm.section.options.content.menu, "closeIcon", $$v)},expression:"section.options.content.menu.closeIcon"}}),_c('sid-color',{attrs:{"sid":`.--${_vm.section.sid}--close-icon`,"reference":`.--${_vm.section.sid}--icon`,"rule-name":"color"}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Hamburger Icon Size","label-class":"!pb-[3px]"}},[_c('sid-range-slider',{attrs:{"sid":`.--${_vm.section.sid}--icon`,"rule-name":"font-size"}})],1),_c('b-form-group',[_c('sid-buttons-group',{staticClass:"mb-0",attrs:{"label":"Icon Placement","options":[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            tooltip: 'Start',
            value: 'left'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            tooltip: 'End',
            value: 'right'
          }
        ],"sid":`.--${_vm.section.sid}--icon`,"rule-name":"text-align"}})],1),_c('b-form-group',[_c('sid-translate',{attrs:{"sid":`.--${_vm.section.sid}--icon`,"rule-name":"translate","min":-100,"max":100,"step":1}})],1)],1),_c('menu-links-mod-v4',{attrs:{"config":_vm.section.options.content.menu.subject,"local-section":_vm.section.options.content.menu.subject,"component-name":"Menu Links","show-active-group":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }