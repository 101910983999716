import {Mark} from 'tiptap'
import {toggleMark} from 'tiptap-commands'

export default class VerticalAlign extends Mark {
  get name () {
    return 'verticalAlign'
  }
  get defaultOptions () {
    return {
      verticalAlign: null
    }
  }
  get schema () {
    return {
      attrs: {
        verticalAlign: {
          default: null
        }
      },
      parseDOM: [{
        style: 'vertical-align',
        getAttrs: value => ({
          verticalAlign: value
        })
      }],
      toDOM: mark => {
        return ['span', {
          style: `vertical-align:${mark.attrs.verticalAlign};`
        }, 0]
      }
    }
  }
  commands ({ type }) {
    return (attrs) => toggleMark(type, attrs)
  }
}