<template>
  <div class="flex flex-col">
    <color-picker
      :color="val || d"
      @input="event => val = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
    />
    <!--    <span @click="pickColor">Pick color</span>-->
    <span
      v-if="allowReset"
      class="mt-2 block text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
      @click="() => {
        val = defaultColor
        // eslint-disable-next-line vue/this-in-template
        this.reset()
      }"
    >
      Reset Color
    </span>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidColor',
  mixins: [SidConcept],
  props: {
    allowReset: {
      type: Boolean,
      default: false
    },
    defaultColor: {
      type: String,
      default: ''
    },
    legacySid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      d: '',
      pciked: {}
    }
  },
  computed: {
    val: {
      get () {
        return this.d || this.sidValueMutation
      },
      set (val) {
        this.d = val
        this.addSidRules([[this.getSid(), [this.ruleName, val]]])
        this.$emit('change')
      }
    }
  },
  watch: {
    sid () {
      this.d = null
      this.$emit('mount', this.sidValueMutation)
    }
  },
  mounted () {
    this.$emit('mount', this.sidValueMutation)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.pick, true)
  },
  methods: {
    setValue (val) {
      this.d = val
      this.addSidRules([[this.getSid(), [this.ruleName, val]]])
    },
    reset () {
      this.val = this.defaultColor || 'rgba(0,0,0,0)'
      this.d = this.defaultColor || 'rgba(0,0,0,0)'

      if (this.legacySid !== null) {
        this.clearSidRuleByName(this.legacySid, this.ruleName)
      }

      setTimeout(() => {
        this.d = this.getSidStyle(this.sid, this.ruleName)
      }, 400)
    },
    pick (e) {
      e.preventDefault()
      document.body.classList.remove('pick-color')
      window.removeEventListener('click', this.pick, true)

      this.val = window.getComputedStyle(e.target).getPropertyValue(this.ruleName)
    },
    pickColor () {
      this.$store.commit('editor/PICK_SUBJECT', {pick: true, config: this.pciked})
      document.body.classList.add('pick-color')
      setTimeout(() => {
        window.addEventListener('click', this.pick, true)
      })
    }
  }
}
</script>