var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sidbear-settings-group',{attrs:{"title":"Image","active":""}},[_c('sid-range-slider',{attrs:{"rule-name":"width","sid":_vm.className(' img'),"default-dimension":"%","dimensions":['%', 'vw', 'px'],"label":"Image Size","var":"--width","measurement":"width"}}),_c('sid-align',{attrs:{"sid":_vm.className(' .image-wrapper'),"rule-name":"justify-content","label":"Alignment"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":_vm.className(),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Margin","sid":_vm.className(),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }