<template>
  <div
    v-if="options"
    class="bg-slides"
  >
    <div
      v-for="(image, index) in getImages"
      :key="index"
      :class="`--${section.sid}--bg bg-cover`"
      :style="getSlideStyle[index]"
    />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    section: {
      type: Object,
      required: false
    },
    options: {
      required: true
    }
  },
  computed: {
    getImages () {
      const images = _.cloneWith(this.options.images)

      if (!this.options.images.length) return []

      if (this.options.images.length < 5) {
        for (let i = 0; i < 5 - this.options.images.length; i++) {
          images.push(images[i])
        }
      }

      return images
    },
    getSlideStyle () {
      const styles = []
      this.getImages.forEach((image, index) => {
        styles.push({
          animation: `bg-slide-fade ${this.options.duration * 5}s infinite`,
          backgroundImage: `url(${image.src})`,
          animationDelay: `${this.options.duration * index}s`
        })
      })

      return styles
    }
  }
}
</script>

<style>
.bg-slides {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg-slides > div {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0;
}

@keyframes bg-slide-fade {
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    transform: scale(1.2);
  }
}
</style>