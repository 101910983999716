<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('inline')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <elements
        v-model="section.options.content.inline.elements"
        :classes="`d-flex flex-wrap ${horizontalAlign} ${verticalAlign}`"
        @flow="handleFlow"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import Elements from '../../utils/Elements/Elements'

export default {
  name: 'ElThreeImages',

  components: {Elements, Template, Highliter},

  mixins: [SectionMixin],

  computed: {
    horizontalAlign () {
      const _align = this.section.options.content.inline.horizontal_align
      if (_align[this.resolutioner] === null) return _align.lg

      return `justify-content-${_align[this.resolutioner]}`
    },
    verticalAlign () {
      const _align = this.section.options.content.inline.vertical_align
      if (_align[this.resolutioner] === null) return _align.lg

      return `align-items-${_align[this.resolutioner]}`
    }
  }
}
</script>
