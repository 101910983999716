<template>
  <settings-page-card title="SEO Settings">
    <div class="px-4">
      <div class="max-w-[600px]">
        <div class="mb-5">
          <h5 class="font-weight-bold mb-4">
            SEO Verification Tools
          </h5>

          <b-form-group
            label-class="custom-label"
            label="Google Verification Meta Tag"
          >
            <b-input
              v-model="verificationForm.google_verification_code"
              :class="{'is-invalid': verificationErrors.google_verification_code}"
              placeholder="<meta name=&quot;google-site-verification&quot; content=“xxxxx#####” />"
            />

            <div class="text-muted mt-2">
              Get your Google verification meta tag in <a
                class="text-primary"
                target="_blank"
                href="https://search.google.com/search-console/welcome"
              >Google
                Search Console</a>
            </div>
          </b-form-group>

          <b-form-group
            label-class="custom-label"
            label="Bing Verification Meta Tag"
          >
            <b-input
              v-model="verificationForm.bing_verification_code"
              :class="{'is-invalid': verificationErrors.bing_verification_code}"
              placeholder="<meta name=&quot;msvalidate.01&quot; content=&quot;xxxxx#####&quot; />"
            />

            <div class="text-muted mt-2">
              Get your Bing verification meta tag in <a
                class="text-primary"
                target="_blank"
                href="https://www.bing.com/webmasters"
              >Bing
                Webmaster Tools</a>
            </div>
          </b-form-group>

          <b-form-group
            label-class="custom-label"
            label="Yandex Verification Meta Tag"
          >
            <b-input
              v-model="verificationForm.ya_verification_code"
              :class="{'is-invalid': verificationErrors.ya_verification_code}"
              placeholder="<meta name=“yandex-verification” content=“xxxxx#####” />"
            />

            <div class="text-muted mt-2">
              Get your Yandex verification meta tag in <a
                class="text-primary"
                target="_blank"
                href="http://webmaster.yandex.com"
              >Yandex
                Webmaster Tools</a>
            </div>
          </b-form-group>

          <b-form-group
            label-class="custom-label"
            label="Pinterest Verification Meta Tag"
          >
            <b-input
              v-model="verificationForm.pinterest_confirmation"
              :class="{'is-invalid': verificationErrors.pinterest_confirmation}"
              placeholder="<meta name=&quot;p:domain_verify&quot; content=&quot;xxxxx#####&quot;/>"
            />

            <div class="text-muted mt-2">
              To <a
                href="https://www.pinterest.com/settings/claim"
                target="_blank"
                class="text-primary"
              >confirm your site with Pinterest</a>
            </div>
          </b-form-group>

          <b-form-group
            label-class="custom-label"
            label="Global canonical tag URL"
          >
            <div class="text-muted mb-1">
              By default, the primary website domain is used.
              <br>
              The default Canonical URL for this project is:
            </div>
            <b-form-group class="mb-0 my-1">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text class="bg-white font-size-14">
                    https://
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-input
                  v-model="canonical_url"
                />
              </b-input-group>
              <b-link
                class="mt-1 d-block"
                @click="canonical_url = ''"
              >
                Reset to default
              </b-link>
            </b-form-group>
            <div class="text-muted">
              To change this URL, edit the link in the input field above. Do not edit the link to continue using the project default settings.
            </div>
          </b-form-group>
        </div>

        <div class="mb-5">
          <h5 class="font-weight-bold mb-4">
            Basic Site Settings
          </h5>
          <project-seo-organization :form="organizationForm" />
        </div>

        <div class="mb-5">
          <h5 class="font-weight-bold mb-4">
            Open Graph MetaData
          </h5>

          <project-seo-meta-data
            v-if="formMetaData"
            :form="formMetaData"
          />
        </div>

        <b-btn
          variant="primary"
          size="lg"
          class="mr-2"
          :disabled="loading"
          @click="save"
        >
          Save SEO Settings
          <svg
            v-if="!loading"
            class="ml-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8404 0H1.75781C0.788555 0 0 0.788555 0 1.75781V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V2.15965L12.8404 0ZM3.51562 1.17188H10.2539V4.62891H3.51562V1.17188ZM11.4844 13.8281H3.51562V9.19922H11.4844V13.8281ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H12.6562V8.02734H2.34375V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H2.34375V5.80078H11.4258V1.17188H12.355L13.8281 2.64504V13.2422Z"
              fill="white"
            />
          </svg>
          <b-spinner
            v-else
            small
            variant="light"
            class="ml-1"
          />
        </b-btn>
      </div>
    </div>
  </settings-page-card>
</template>

<script>
import _ from 'lodash'
import SettingsPageCard from '../../../layouts/dashboard/SettingsPageCard'
import {mapState} from 'vuex'
import ProjectSeoOrganization from './components/ProjectSeoOrganization'
import {toJSON, toStringify} from '../../../utils/helpers'
import ProjectSeoMetaData from './components/graph-meta-data'

export default {
  name: 'ProjectSeo',

  components: {ProjectSeoMetaData, ProjectSeoOrganization, SettingsPageCard},

  data () {
    return {
      loading: false,
      verificationForm: {
        google_verification_code: '',
        bing_verification_code: '',
        ya_verification_code: '',
        pinterest_confirmation: ''
      },
      organizationForm: {
        org_or_person: 'organization',
        org_name: '',
        org_logo: {}
      },
      formMetaData: null,
      verificationErrors: {}
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),

    canonical_url: {
      get () {
        let url = _.get(this.formMetaData,'canonical_url') 
        if(!url) url = this.getFullDomain(this.project)
        return url
      },

      set (val) {
        this.formMetaData.canonical_url = val
      }
    }
  },

  mounted () {
    const {
      google_verification_code,
      bing_verification_code,
      ya_verification_code,
      pinterest_confirmation,
      org_or_person,
      org_name,
      org_logo
    } = this.project
    const metaObj = _.clone({google_verification_code, bing_verification_code, ya_verification_code, pinterest_confirmation})
    for (const metaKey in metaObj) {
      if (metaObj[metaKey]) {
        try {
          const meta = JSON.parse(metaObj[metaKey])
          this.verificationForm[metaKey] = `<meta name="${meta.name}" content="${meta.content}" />`
        } catch (error) {
          console.error(metaObj, error)
        }

      }
    }

    this.organizationForm = _.clone({
      org_or_person: org_or_person || 'organization',
      org_name,
      org_logo: toJSON(org_logo)
    })

    this.formMetaData = _.clone({
      facebook: {
        fb_open_graph_enabled: this.project.fb_open_graph_enabled,
        fb_open_graph_image: this.project.fb_open_graph_image ? JSON.parse(this.project.fb_open_graph_image) : {}
      },
      twitter: {
        tw_open_graph_enabled: this.project.tw_open_graph_enabled
      },
      pinterest: {
        pinterest_confirmation: this.project.pinterest_confirmation ? `<meta name="p:domain_verify" content="${this.project.pinterest_confirmation}" />` : ''
      },
      canonical_url: this.project.canonical_url
    })
  },

  methods: {
    save () {
      this.verificationErrors = {}

      for (const prop in this.verificationForm) {
        if (this.verificationForm[prop]) {
          const name = this.verificationForm[prop].match(/name="(.*?)"/g)
          const content = this.verificationForm[prop].match(/content="(.*?)"/g)
          if (name && content) {
            this.project[prop] = JSON.stringify({
              name: name[0].replace(/name="(.*?)"/g, '$1'),
              content: content[0].replace(/content="(.*?)"/g, '$1')
            })
          } else {
            this.project[prop] = null
            this.verificationErrors[prop] = true
          }
        } else {
          this.project[prop] = null
        }
      }

      this.project.org_or_person = _.clone(this.organizationForm.org_or_person)
      this.project.org_name = _.clone(this.organizationForm.org_name)
      this.project.org_logo = toStringify(this.organizationForm.org_logo)

      this.project.fb_open_graph_enabled = _.get(this.formMetaData,'facebook.fb_open_graph_enabled')
      this.project.fb_open_graph_image = JSON.stringify(this.formMetaData.facebook.fb_open_graph_image)
      this.project.tw_open_graph_enabled = this.formMetaData.twitter.tw_open_graph_enabled
      this.project.canonical_url = this.formMetaData.canonical_url

      
      // const pinterestConfirmationContent = this.formMetaData.pinterest.pinterest_confirmation.match(/content="(.*?)"/g)
      // if (pinterestConfirmationContent) {
      //   this.project.pinterest_confirmation = pinterestConfirmationContent[0].replace(/content="(.*?)"/g, '$1')
      // } else {
      //   this.project.pinterest_confirmation = ''
      // }


      this.loading = true

      this.$store.dispatch('projects/saveProject')
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Settings saved successfully',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
