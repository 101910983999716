var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Tooltip","button-class":_vm.buttonClass}},[_c('b-form-group',[_c('b-checkbox',{model:{value:(_vm.section.tooltip),callback:function ($$v) {_vm.$set(_vm.section, "tooltip", $$v)},expression:"section.tooltip"}},[_vm._v(" Enable ")])],1),_c('b-form-group',{attrs:{"label":"Text"}},[_c('b-input',{attrs:{"size":"sm","placeholder":"My Tooltip"},model:{value:(_vm.config.tooltipText),callback:function ($$v) {_vm.$set(_vm.config, "tooltipText", $$v)},expression:"config.tooltipText"}})],1),_c('b-form-group',{attrs:{"label":"Placement"}},[_c('v-select',{attrs:{"searchable":false,"reduce":val => val.value,"options":[{value: 'top', label: 'Top'}, {value: 'bottom', label: 'Bottom'}, {value: 'left', label: 'Left'}, {value: 'right', label: 'Right'}]},model:{value:(_vm.tooltipPlacement),callback:function ($$v) {_vm.tooltipPlacement=$$v},expression:"tooltipPlacement"}})],1),_c('sid-range-slider',{attrs:{"label":"Text Size","sid":`.--${_vm.config.sid}--tooltip`,"rule-name":"font-size"}}),_c('b-form-group',{attrs:{"label":"Text Color"}},[_c('sid-color',{attrs:{"sid":`.--${_vm.config.sid}--tooltip`,"rule-name":"color"}})],1),_c('sid-background',{attrs:{"sid":`.--${_vm.config.sid}--tooltip`}}),_c('sid-linked-group',{attrs:{"label":"Padding","sid":`.--${_vm.config.sid}--tooltip`,"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }