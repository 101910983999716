<template>
  <div
    :data-sid="section.sid"
    class="d-content"
  >
    <div
      class="w-full"
      :class="`--${section.sid}--wrapper`"
    >
      <highliter
        v-model="section.name"
        section-type="element"
        class="highlight-element"
        :dublicate-deep="parentUID"
        :dublicate="section"
        :hide-bar="hideBar"
        :hide-add="hideAdd"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @add-subject="$emit('add-subject')"
        @click.stop.native="sectionSettings('icon')"
      >
        <div
          v-show="!section.options.content.icon.customIcon"
          class="el-icon"
          :class="[`--${section.sid}--1`, {'element-hidden': !visability('icon')}]"
        >
          <div
            v-if="section.options.content.icon.svg"
            class="svg-icon"
            v-html="section.options.content.icon.svg"
          />
          <icon-editable
            v-else
            :customs="section.options.content.icon"
          />
        </div>
        <div
          v-show="section.options.content.icon.customIcon"
          class="flex w-full"
          :class="`--lottie-container-${section.sid}`"
        >
          <button-lottie-icon
            :config="section.options.content.icon"
          />
        </div>
      </highliter>
    </div>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon'

export default {
  name: 'FeaturedItems1',

  components: {ButtonLottieIcon, Highliter},

  mixins: [SectionMixin]
}
</script>

<style>
.el-icon {
  font-size: 46px;
  text-align: center;
}

.svg-icon > svg {
  width: var(--size);
  height: var(--size);
}
</style>