<template>
  <div
    v-if="showBillingTerms"
    :data-sid="`product-billing-terms-${section.sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}-margin`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <p
        :class="`--${section.sid}-billing-terms-text`"
        class="product-billing-terms m-0"
        style="overflow-wrap: anywhere;"
      >
        {{ product.term }}
      </p>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElBillingTerms',
  mixins: [SectionMixin],
  components: {Highliter},
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    products () {
      return this.form?.products || []
    },
    showBillingTerms () {
      if (!this.products.length || !this.products[0]) return false
      return this.products[0].term
    },
    product () {
      if (!this.products.length || !this.products[0]) {
        return {
          cover: null,
          name: '',
          description: ''
        }
      }
      return this.products[0]
    }
  }
}
</script>

<style lang="scss">
.product-billing-terms {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #9B9B9B;
  text-align: center;
}
</style>