<template>
  <div
    class="post-button-container"
    :class="`--${sid}--post-button`"
  >
    <highliter
      class="el-dynamic"
      value="Read More"
      hide-add
      hide-right
    >
      <div class="el-post-button d-flex">
        <div class="post-button d-flex align-items-center">
          {{ settings.btnText || 'Read More...' }}
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'PostButton',
  components: {Highliter},
  props: {
    settings: {
      type: Object
    },
    sid: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.post-button-container {
  margin: 0 20px 20px;
  display: flex;
}

.post-button {
  background: #3D82EA;
  padding: 10px 0;
  width: 100%;
  display: block;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 16px;
  justify-content: center;
}
</style>