<template>
  <div>
    <div class="d-flex align-items-end">
      <b-form-group
        class="mr-2 mb-0"
        label="Color"
      >
        <color-picker
          :color="styles.color"
          @input="event => styles.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>

      <b-form-group
        class="mb-0"
        label="Hover color"
      >
        <color-picker
          :color="styles.hoverColor"
          @input="event => styles.hoverColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>

      <b-btn-group>
        <b-btn
          :class="{ 'is-active': styles.bold }"
          variant="white"
          @click="styles.bold = !styles.bold"
        >
          <i class="fa fa-bold" />
        </b-btn>
        <b-btn
          :class="{ 'is-active': styles.italic }"
          variant="white"
          @click="styles.italic = !styles.italic"
        >
          <i class="fa fa-italic" />
        </b-btn>
        <b-btn
          :class="{ 'is-active': styles.underline }"
          variant="white"
          @click="styles.underline = !styles.underline"
        >
          <i class="fa fa-underline" />
        </b-btn>
      </b-btn-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextLink',

  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          color: '',
          hoverColor: '',
          bold: false,
          italic: false,
          underline: false
        }
      }
    }
  },

  data () {
    return {
      timeout: null
    }
  },

  watch: {
    styles: {
      deep: true,
      handler: function () {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.appendStyles()
        }, 300)
      }
    }
  },

  methods: {
    appendStyles () {
      let style =  null
      if (document.querySelector('[data-global-link-style]')) {
        style = document.querySelector('[data-global-link-style]')
        style.parentElement.removeChild(style)
      } else {
        style = document.createElement('style')
        style.setAttribute('data-global-link-style', '')
      }

      document.head.appendChild(style)
    }
  }
}
</script>
