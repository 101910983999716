<template>
  <b-form-group :label="label">
    <b-input v-model="val" />
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidTransition',
  mixins: [SidConcept],
  props: {
    label: {
      type: String
    }
  },
  computed: {
    val: {
      get () {
        return this.sidValueMutation
      },
      set (val) {
        this.addSidRules([[this.getSid(), [this.ruleName, val]]])
      }
    }
  }
}
</script>