<template>
  <p class="p-0 m-0">
    {{ negative ? '-' : '' }}{{ new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    }).format(amount) }}
    <span
      v-if="!hideCurrencyCode"
      :class="cn('ml-1 inline', currencyCodeClass)"
    >
      {{ currencyCode }}
    </span>
  </p>
</template>

<script>
export default {
  name: 'Price',
  props: {
    amount: {
      type: Number,
      required: true
    },
    currencyCode: {
      type: String,
      default: 'USD'
    },
    currencyCodeClass: {
      type: String,
      default: ''
    },
    hideCurrencyCode: {
      type: Boolean,
      default: true
    },
    negative: {
      type: Boolean,
      default: false
    }
  }
}
</script>