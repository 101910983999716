<template>
  <div class="pt-[9px]">
    <b-form-group v-if="linked">
      <button
        class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
        @click="() => unlinkSid()"
      >
        Unlink Styles
        <i class="fa fa-link text-primary" />
      </button>
    </b-form-group>

    <draggable
      v-model="menu.links"
      handle=".move"
    >
      <div
        v-for="(item, index) in menu.links"
        :key="index"
        class="border group rounded-[2px] h-[38px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
      >
        <div class="mr-[11px] move cursor-move">
          <svg
            fill="none"
            height="11"
            viewBox="0 0 11 11"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
              fill="#3D82EA"
            />
          </svg>
        </div>


        <span
          class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
          v-text="item.text"
        />

        <button
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0 ml-auto"
          @click="newListItemModal = index"
        >
          <svg
            fill="none"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M8.34918 0.18252C8.57698 -0.0452861 8.94633 -0.0452861 9.17414 0.18252L11.5075 2.51585C11.7353 2.74366 11.7353 3.11301 11.5075 3.34081L3.3408 11.5075C3.23141 11.6169 3.08303 11.6783 2.92832 11.6783H0.594991C0.272825 11.6783 0.0116577 11.4172 0.0116577 11.095V8.76167C0.0116577 8.60696 0.0731159 8.45858 0.182512 8.34919L8.34918 0.18252ZM7.83662 2.345L9.34499 3.85337L10.27 2.92833L8.76166 1.41996L7.83662 2.345ZM8.52003 4.67833L7.01166 3.16996L2.00328 8.17833L3.51166 9.68671L8.52003 4.67833ZM2.6867 10.5117L1.17832 9.00329V10.5117H2.6867Z"
              fill="#6E747A"
              fill-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0"
          @click="menu.links.splice(index, 1)"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </div>
    </draggable>

    <button
      class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
      @click="newListItemModal = true"
    >
      <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
      Add Menu Item
    </button>

    <StateTabs
      v-model="isHover"
      :states-text="['Normal State', 'Hover State']"
    >
      <b-form-group>
        <b-checkbox v-model="column">
          Column
        </b-checkbox>
      </b-form-group>

      <font-family-and-weight-control
        text-type="paragraph"
        :family="fontFamily"
        :weight="fontWeight"
        @family="family => fontFamily = family"
        @weight="weight => fontWeight = weight"
      />

      <b-form-group label="Size">
        <div class="flex items-center gap-[10px]">
          <div class="flex items-center w-[80x] h-[38px] border rounded-[2px]">
            <input
              v-model="fontSize"
              :max="100"
              :min="6"
              class="h-full px-[8px]"
              type="number"
            >
            <div class="border-left flex items-center justify-center h-full text-[15px] w-[38px] text-[#333333]">
              px
            </div>
          </div>

          <color-picker
            :color="color"
            @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <b-btn-group>
          <b-btn
            :class="{ 'is-active': fontStyle }"
            variant="white"
            @click="fontStyle = !fontStyle"
          >
            <i class="fa fa-italic" />
          </b-btn>
          <b-btn
            :class="{ 'is-active': textDecoration }"
            variant="white"
            @click="textDecoration = !textDecoration"
          >
            <i class="fa fa-underline" />
          </b-btn>
        </b-btn-group>
      </b-form-group>

      <b-form-group label="Alignment">
        <b-btn-group>
          <b-btn
            variant="white"
            @click="align = 'start'"
          >
            <i
              :class="{'text-primary': align === 'start'}"
              class="fa fa-outdent"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'center'"
          >
            <i
              :class="{'text-primary': align === 'center'}"
              class="fa fa-align-center"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'end'"
          >
            <i
              :class="{'text-primary': align === 'end'}"
              class="fa fa-indent"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'between'"
          >
            <i
              :class="{'text-primary': align === 'between'}"
              class="fa fa-align-justify"
            />
          </b-btn>
        </b-btn-group>
      </b-form-group>

      <b-form-group
        label="Text Transform"
        class="mb-4"
      >
        <b-btn-group class="mb-2">
          <b-btn
            variant="white"
            @click="textTransform = 'uppercase'"
          >
            <svg
              width="21"
              height="12"
              viewBox="0 0 21 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.28906 8.22656L4.75781 4.52344H4.625L3.17969 8.22656H6.28906ZM0.289062 11.5938C0.674479 11.5677 0.984375 11.3932 1.21875 11.0703C1.36979 10.8672 1.58594 10.4193 1.86719 9.72656L5.42188 0.96875H5.86719L9.4375 9.375C9.83854 10.3177 10.138 10.9271 10.3359 11.2031C10.5339 11.474 10.8151 11.6042 11.1797 11.5938V12H6V11.5938C6.52083 11.5729 6.86198 11.5286 7.02344 11.4609C7.1901 11.3932 7.27344 11.2214 7.27344 10.9453C7.27344 10.8203 7.23177 10.6302 7.14844 10.375C7.09635 10.224 7.02604 10.0417 6.9375 9.82812L6.53125 8.85938H2.9375C2.70312 9.49479 2.55208 9.90885 2.48438 10.1016C2.34375 10.513 2.27344 10.8125 2.27344 11C2.27344 11.2292 2.42708 11.3958 2.73438 11.5C2.91667 11.5573 3.1901 11.5885 3.55469 11.5938V12H0.289062V11.5938Z"
                :fill="textTransform === 'uppercase' ? '#000' : '#AEB1B4'"
              />
              <path
                d="M11.2578 11.5938C11.6901 11.5781 12 11.5208 12.1875 11.4219C12.5052 11.2552 12.6641 10.9375 12.6641 10.4688V2.72656C12.6641 2.25781 12.5156 1.94271 12.2188 1.78125C12.0417 1.68229 11.7214 1.61979 11.2578 1.59375V1.1875H16.2031C17.1562 1.1875 17.9479 1.29688 18.5781 1.51562C19.7656 1.92708 20.3594 2.67708 20.3594 3.76562C20.3594 4.42708 20.1172 4.95312 19.6328 5.34375C19.1536 5.72917 18.6328 5.97396 18.0703 6.07812V6.22656C18.6641 6.34115 19.1797 6.53385 19.6172 6.80469C20.4714 7.33594 20.8984 8.07552 20.8984 9.02344C20.8984 9.90885 20.4896 10.6276 19.6719 11.1797C18.8542 11.7266 17.7656 12 16.4062 12H11.2578V11.5938ZM15.2188 6.03906C16.2083 6.03906 16.8724 5.8776 17.2109 5.55469C17.5495 5.23177 17.7188 4.65885 17.7188 3.83594C17.7188 3.22656 17.6068 2.71354 17.3828 2.29688C17.1641 1.88021 16.7396 1.67188 16.1094 1.67188C15.776 1.67188 15.5443 1.72917 15.4141 1.84375C15.2839 1.95312 15.2188 2.15365 15.2188 2.44531V6.03906ZM15.2188 10.5234C15.224 10.8047 15.2656 11.0104 15.3438 11.1406C15.474 11.3698 15.724 11.4844 16.0938 11.4844C16.849 11.4844 17.3776 11.2786 17.6797 10.8672C17.987 10.4557 18.1406 9.86979 18.1406 9.10938C18.1406 7.97396 17.8047 7.21875 17.1328 6.84375C16.7266 6.61458 16.0885 6.50781 15.2188 6.52344V10.5234Z"
                :fill="textTransform === 'uppercase' ? '#000' : '#AEB1B4'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            @click="textTransform = 'capitalize'"
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.28906 10.2266L4.75781 6.52344H4.625L3.17969 10.2266H6.28906ZM0.289062 13.5938C0.674479 13.5677 0.984375 13.3932 1.21875 13.0703C1.36979 12.8672 1.58594 12.4193 1.86719 11.7266L5.42188 2.96875H5.86719L9.4375 11.375C9.83854 12.3177 10.138 12.9271 10.3359 13.2031C10.5339 13.474 10.8151 13.6042 11.1797 13.5938V14H6V13.5938C6.52083 13.5729 6.86198 13.5286 7.02344 13.4609C7.1901 13.3932 7.27344 13.2214 7.27344 12.9453C7.27344 12.8203 7.23177 12.6302 7.14844 12.375C7.09635 12.224 7.02604 12.0417 6.9375 11.8281L6.53125 10.8594H2.9375C2.70312 11.4948 2.55208 11.9089 2.48438 12.1016C2.34375 12.513 2.27344 12.8125 2.27344 13C2.27344 13.2292 2.42708 13.3958 2.73438 13.5C2.91667 13.5573 3.1901 13.5885 3.55469 13.5938V14H0.289062V13.5938Z"
                :fill="textTransform === 'capitalize' ? '#000' : '#AEB1B4'"
              />
              <path
                d="M14.3672 12.4453C14.388 12.737 14.4453 12.9766 14.5391 13.1641C14.7266 13.5286 15.0391 13.7109 15.4766 13.7109C16.0234 13.7109 16.4089 13.4219 16.6328 12.8438C16.862 12.2656 16.9766 11.4479 16.9766 10.3906C16.9766 9.55208 16.875 8.83073 16.6719 8.22656C16.474 7.6224 16.0833 7.32031 15.5 7.32031C15.1562 7.32031 14.8802 7.44792 14.6719 7.70312C14.4688 7.95312 14.3672 8.13281 14.3672 8.24219V12.4453ZM14.3672 3.1875V7.33594C14.6172 7.09115 14.849 6.90625 15.0625 6.78125C15.4531 6.55729 15.875 6.44531 16.3281 6.44531C17.2292 6.44531 17.9531 6.79688 18.5 7.5C19.0521 8.20312 19.3281 9.06771 19.3281 10.0938C19.3281 11.4167 18.9766 12.4375 18.2734 13.1562C17.5755 13.8698 16.7057 14.2266 15.6641 14.2266C15.237 14.2266 14.8594 14.1432 14.5312 13.9766C14.2031 13.8099 13.8958 13.5911 13.6094 13.3203L12.3516 14.2266H12.125V4.5C12.125 4.16667 12.0651 3.94271 11.9453 3.82812C11.8255 3.70833 11.6016 3.625 11.2734 3.57812V3.1875H14.3672Z"
                :fill="textTransform === 'capitalize' ? '#000' : '#AEB1B4'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            @click="textTransform = 'lowercase'"
          >
            <svg
              width="17"
              height="12"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.50781 10.0859C3.76823 10.0859 4.01042 10.0078 4.23438 9.85156C4.35938 9.75781 4.50781 9.61198 4.67969 9.41406V7.07812C4.2526 7.18229 3.8776 7.34635 3.55469 7.57031C3.00781 7.95052 2.73438 8.4349 2.73438 9.02344C2.73438 9.3724 2.8125 9.63802 2.96875 9.82031C3.13021 9.9974 3.3099 10.0859 3.50781 10.0859ZM0.40625 9.50781C0.40625 8.78906 0.752604 8.21875 1.44531 7.79688C2.13802 7.375 3.22396 6.95052 4.70312 6.52344V5.35938C4.70312 4.88542 4.60156 4.53646 4.39844 4.3125C4.20052 4.08333 3.84635 3.96875 3.33594 3.96875C3.04948 3.96875 2.8125 4.02344 2.625 4.13281C2.44271 4.23698 2.35156 4.36979 2.35156 4.53125C2.35156 4.59375 2.36198 4.64583 2.38281 4.6875C2.40365 4.72917 2.4349 4.77344 2.47656 4.82031L2.57031 4.92969C2.64323 5.01823 2.70052 5.11719 2.74219 5.22656C2.78906 5.33073 2.8125 5.44271 2.8125 5.5625C2.8125 5.86458 2.71094 6.09896 2.50781 6.26562C2.3099 6.42708 2.07812 6.50781 1.8125 6.50781C1.50521 6.50781 1.24219 6.41667 1.02344 6.23438C0.809896 6.05208 0.703125 5.78906 0.703125 5.44531C0.703125 4.83073 0.992188 4.34375 1.57031 3.98438C2.14844 3.625 2.85938 3.44531 3.70312 3.44531C4.60938 3.44531 5.36719 3.61979 5.97656 3.96875C6.58073 4.32292 6.88281 4.97396 6.88281 5.92188V9.79688C6.88281 9.91146 6.91146 10.0104 6.96875 10.0938C7.03125 10.1719 7.09896 10.2109 7.17188 10.2109C7.23438 10.2109 7.28906 10.2005 7.33594 10.1797C7.38802 10.1536 7.46615 10.0885 7.57031 9.98438L7.8125 10.3203C7.5 10.6849 7.15365 10.9401 6.77344 11.0859C6.53385 11.1797 6.28125 11.2266 6.01562 11.2266C5.54167 11.2266 5.19531 11.0885 4.97656 10.8125C4.85156 10.6562 4.76042 10.4245 4.70312 10.1172C4.26042 10.5599 3.76042 10.8724 3.20312 11.0547C2.86458 11.1693 2.53125 11.2266 2.20312 11.2266C1.76562 11.2266 1.35677 11.0885 0.976562 10.8125C0.596354 10.5312 0.40625 10.0964 0.40625 9.50781Z"
                :fill="textTransform === 'lowercase' ? '#000' : '#AEB1B4'"
              />
              <path
                d="M11.3672 9.44531C11.388 9.73698 11.4453 9.97656 11.5391 10.1641C11.7266 10.5286 12.0391 10.7109 12.4766 10.7109C13.0234 10.7109 13.4089 10.4219 13.6328 9.84375C13.862 9.26562 13.9766 8.44792 13.9766 7.39062C13.9766 6.55208 13.875 5.83073 13.6719 5.22656C13.474 4.6224 13.0833 4.32031 12.5 4.32031C12.1562 4.32031 11.8802 4.44792 11.6719 4.70312C11.4688 4.95312 11.3672 5.13281 11.3672 5.24219V9.44531ZM11.3672 0.1875V4.33594C11.6172 4.09115 11.849 3.90625 12.0625 3.78125C12.4531 3.55729 12.875 3.44531 13.3281 3.44531C14.2292 3.44531 14.9531 3.79688 15.5 4.5C16.0521 5.20312 16.3281 6.06771 16.3281 7.09375C16.3281 8.41667 15.9766 9.4375 15.2734 10.1562C14.5755 10.8698 13.7057 11.2266 12.6641 11.2266C12.237 11.2266 11.8594 11.1432 11.5312 10.9766C11.2031 10.8099 10.8958 10.5911 10.6094 10.3203L9.35156 11.2266H9.125V1.5C9.125 1.16667 9.0651 0.942708 8.94531 0.828125C8.82552 0.708333 8.60156 0.625 8.27344 0.578125V0.1875H11.3672Z"
                :fill="textTransform === 'lowercase' ? '#000' : '#AEB1B4'"
              />
            </svg>
          </b-btn>
        </b-btn-group>

        <div>
          <span
            class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
            @click="textTransform = null"
          >
            Set to Default
          </span>
        </div>
      </b-form-group>

      <b-form-group
        label="Line Height"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="lineHeight"
              :interval=".1"
              :max="5"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <LinkedControlItem
              v-model="lineHeight"
              :max="5"
              :min="0"
              class="d-block !w-[50px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Letter Spacing"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="letterSpacing"
              :interval="1"
              :max="20"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <LinkedControlItem
              v-model="letterSpacing"
              :max="20"
              :min="0"
              class="d-block !w-[50px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Gap"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="gap"
              :interval="1"
              :max="40"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <LinkedControlItem
              v-model="gap"
              :max="40"
              :min="0"
              class="d-block !w-[50px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <background-customs :config="section.options.customize.style.background" />

      <template slot="action-state">
        <font-family-and-weight-control
          text-type="paragraph"
          :family="fontFamily"
          :weight="fontWeight"
          @family="family => fontFamily = family"
          @weight="weight => fontWeight = weight"
        />

        <b-form-group label="Size">
          <div class="flex items-center gap-[10px]">
            <div class="flex items-center w-[80x] h-[38px] border rounded-[2px]">
              <input
                v-model="fontSize"
                :max="100"
                :min="6"
                class="h-full px-[8px]"
                type="number"
              >
              <div class="border-left flex items-center justify-center h-full text-[15px] w-[38px] text-[#333333]">
                px
              </div>
            </div>

            <color-picker
              :color="color"
              @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <b-btn-group>
            <b-btn
              :class="{ 'is-active': fontStyle }"
              variant="white"
              @click="fontStyle = !fontStyle"
            >
              <i class="fa fa-italic" />
            </b-btn>
            <b-btn
              :class="{ 'is-active': textDecoration }"
              variant="white"
              @click="textDecoration = !textDecoration"
            >
              <i class="fa fa-underline" />
            </b-btn>
          </b-btn-group>
        </b-form-group>

        <hover-animations
          v-model="menu.hoverAnimation.lg"
          class="mb-3"
        />
      </template>
    </StateTabs>

    <SidbearSettingsGroup
      title="Border & Radius"
      class="border-top"
    >
      <border-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="section.options.customize.style.margin" />
    </SidbearSettingsGroup>

    <link-modal
      v-model="newListItemModal"
      :config="menu"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ModMixin from '@builder/components/mixins/ModMixin'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'
import StateTabs from '@/components/editor/components/StateTabs'
import LinkModal from '@/components/editor/controls/menu-links-mod/LinkModal'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'ListMod',
  components: {
    HoverAnimations,
    LinkedControlItem, LinkModal, draggable, StateTabs, FontFamilyAndWeightControl, SidbearSettingsGroup
  },
  mixins: [ModMixin, PagesPaginationMixin, SidStyles],
  data () {
    return {
      isHover: false,
      newListItemModal: false
    }
  },
  computed: {
    menu () {
      return this.section.options.content.menu
    },
    textTransform: {
      get () {
        return this.menu.textTransform[this.resolutioner] || this.menu.textTransform.lg
      },
      set (val) {
        this.menu.textTransform[this.resolutioner] = val
      }
    },
    fontSize: {
      get () {
        if (this.isHover) {
          if (this.menu.fontSize.hover[this.resolutioner] === null) return this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg
          return this.menu.fontSize.hover[this.resolutioner] || this.menu.fontSize.lg
        }

        return this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg
      },
      set (val) {
        if (this.isHover) {
          return this.menu.fontSize.hover[this.resolutioner] = val
        }

        this.menu.fontSize[this.resolutioner] = val
      }
    },
    color: {
      get () {
        if (this.isHover) {
          if (this.menu.color.hover.color === null) return this.menu.color.color
          return this.menu.color.hover.color
        }

        return this.menu.color.color
      },
      set (val) {
        if (this.isHover) {
          return this.menu.color.hover.color = val
        }

        this.menu.color.color = val
      }
    },
    fontFamily: {
      get () {
        if (this.isHover) {
          if (this.menu.fontFamily.hover[this.resolutioner] === null) return this.menu.fontFamily[this.resolutioner] || this.menu.fontFamily.lg
          return this.menu.fontFamily.hover[this.resolutioner] || this.menu.fontFamily.lg
        }

        return this.menu.fontFamily[this.resolutioner] || this.menu.fontFamily.lg
      },
      set (val) {
        if (this.isHover) {
          return this.menu.fontFamily.hover[this.resolutioner] = val
        }

        this.menu.fontFamily[this.resolutioner] = val
      }
    },
    fontWeight: {
      get () {
        if (this.isHover) {
          if (this.menu.fontWeight.hover[this.resolutioner] === null) return this.menu.fontWeight[this.resolutioner] || this.menu.fontWeight.lg
          return this.menu.fontWeight.hover[this.resolutioner] || this.menu.fontWeight.lg
        }

        return this.menu.fontWeight[this.resolutioner] || this.menu.fontWeight.lg
      },
      set (val) {
        if (this.isHover) {
          return this.menu.fontWeight.hover[this.resolutioner] = val
        }

        this.menu.fontWeight[this.resolutioner] = val
      }
    },
    fontStyle: {
      get () {
        if (this.isHover) {
          if (this.menu.italic.hover.italic === null) return this.menu.italic.italic
          return this.menu.italic.hover.italic
        }
        return this.menu.italic.italic
      },
      set (val) {
        if (this.isHover) {
          return this.menu.italic.hover.italic = val
        }

        this.menu.italic.italic = val
      }
    },
    lineHeight: {
      get () {
        return this.menu.lineHeight[this.resolutioner] || this.menu.lineHeight.lg
      },
      set (val) {
        this.menu.lineHeight[this.resolutioner] = val
      }
    },
    gap: {
      get () {
        return this.menu.gap[this.resolutioner] || this.menu.gap.lg
      },
      set (val) {
        this.menu.gap[this.resolutioner] = val
      }
    },
    letterSpacing: {
      get () {
        return this.menu.letterSpacing[this.resolutioner] || this.menu.letterSpacing.lg
      },
      set (val) {
        this.menu.letterSpacing[this.resolutioner] = val
      }
    },
    textDecoration: {
      get () {
        if (this.isHover) {
          if (this.menu.underline.hover.underline === null) return this.menu.underline.underline
          return this.menu.underline.hover.underline
        }

        return this.menu.underline.underline ? 'underline' : null
      },
      set (val) {
        if (this.isHover) {
          return this.menu.underline.hover.underline = val
        }

        this.menu.underline.underline = val
      }
    },
    align: {
      get () {
        return this.menu.align[this.resolutioner] || this.menu.align.lg
      },
      set (val) {
        this.menu.align[this.resolutioner] = val
      }
    },
    column: {
      get () {
        return this.menu.column[this.resolutioner] || this.menu.column.lg
      },
      set (val) {
        this.menu.column[this.resolutioner] = val
      }
    }
  }
}
</script>
