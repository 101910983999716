<template>
  <div
    v-bind="$attrs"
    @click="() => modal = true"
  >
    <slot name="trigger">
      <div class="w-full flex items-center hover:bg-gray-100 justify-center text-dark text-[11px] font-[600] h-[29px] rounded-[4px] border gap-[11px] cursor-pointer">
        {{ title }}

        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00016 1.33335C5.38933 1.33335 4.0835 2.63919 4.0835 4.25002C4.0835 4.57219 3.82233 4.83335 3.50016 4.83335C2.45806 4.83335 1.75016 5.557 1.75016 6.29169C1.75016 7.02637 2.45806 7.75002 3.50016 7.75002C3.82233 7.75002 4.0835 8.01119 4.0835 8.33335C4.0835 8.65552 3.82233 8.91669 3.50016 8.91669C1.96494 8.91669 0.583496 7.81217 0.583496 6.29169C0.583496 4.95522 1.65079 3.94013 2.9517 3.714C3.21418 1.71215 4.92669 0.166687 7.00016 0.166687C9.07364 0.166687 10.7861 1.71215 11.0486 3.714C12.3495 3.94013 13.4168 4.95522 13.4168 6.29169C13.4168 7.81217 12.0354 8.91669 10.5002 8.91669C10.178 8.91669 9.91683 8.65552 9.91683 8.33335C9.91683 8.01119 10.178 7.75002 10.5002 7.75002C11.5423 7.75002 12.2502 7.02637 12.2502 6.29169C12.2502 5.557 11.5423 4.83335 10.5002 4.83335C10.178 4.83335 9.91683 4.57219 9.91683 4.25002C9.91683 2.63919 8.61099 1.33335 7.00016 1.33335Z"
            fill="#3D82EA"
          />
          <path
            d="M9.16264 6.99583C8.93484 7.22364 8.56549 7.22364 8.33768 6.99583L7.5835 6.24165V11.25C7.5835 11.5722 7.32233 11.8334 7.00016 11.8334C6.678 11.8334 6.41683 11.5722 6.41683 11.25V6.24164L5.66264 6.99583C5.43484 7.22364 5.06549 7.22364 4.83768 6.99583C4.60988 6.76803 4.60988 6.39868 4.83768 6.17088L6.58768 4.42088C6.69708 4.31148 6.84545 4.25002 7.00016 4.25002C7.15487 4.25002 7.30324 4.31148 7.41264 4.42088L9.16264 6.17088C9.39045 6.39868 9.39045 6.76803 9.16264 6.99583Z"
            fill="#3D82EA"
          />
        </svg>
      </div>
    </slot>

    <assets-lib-modal
      :modal.sync="modal"
      :file="{}"
      :type="type"
      :uploaded="uploaded"
    />
  </div>
</template>

<script>
import AssetsLibModal from '@/components/assets-library/AssetsLibModal'

export default {
  name: 'UploadAsset',
  components: {AssetsLibModal},
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Upload JSON'
    }
  },
  data () {
    return {
      modal: false
    }
  },
  methods: {
    save () {
      !Object.prototype.hasOwnProperty.call(this.globalStyles, 'libraryImages') ? this.$set(this.globalStyles, 'libraryImages', []) : null

      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save', true).then(async () => {
        VEvent.fire('loader', false)

        this.$swal({
          icon: 'success',
          iconColor: '#4F83E3',
          toast: true,
          position: 'top-right',
          title: 'Saved in Assets Library.',
          showConfirmButton: false,
          timer: 3000
        })
      })
    },
    removeSaved () {
      this.modal = true
    },
    remove () {
      this.$emit('remove')
    },
    uploaded (file) {
      this.$emit('uploaded', file)
    }
  }
}
</script>
