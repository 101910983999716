<template>
  <highliter
    v-model="section.name"
    :section="section"
    :dublicate="section"
    :uid="section.uid"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('gallery')"
  >
    <div
      class="flex"
      :class="[`--gallery-container-${section.sid}`, `--gallery-gap-${section.sid}`]"
    >
      <div
        v-for="(col, index) in grid"
        :key="index"
        class="flex flex-column"
        :class="`--gallery-gap-${section.sid}`"
      >
        <div
          v-for="(image, indexCol) in col"
          :key="`col-${indexCol}`"
          :class="`--${section.sid}-image-wrapper`"
          @click.stop="sectionSettings({item: 'gallery', image})"
        >
          <img
            :src="image.src"
            alt=""
            class="transition"
            :class="`--${section.sid}-image`"
          >
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
// import Highliter from '@builder/components/builder/utils/Highliter'
export default {
  name: 'ElImageGallery',

  // components: {Highliter},

  mixins: [SectionMixin],

  computed: {
    rows () {
      return this.section.options.content.gallery.rows
    },
    images () {
      if (this.section.options.content.gallery.images.length) {
        return this.section.options.content.gallery.images
      }

      function getRandomImage () {
        const randomNum = Math.floor(Math.random() * 1000) + 1
        return {
          src: `https://source.unsplash.com/random?${randomNum}`
        }
      }

      const images = Array(30).fill('')
      images.forEach((_, index) => {
        images[index] = getRandomImage()
      })

      return images
    },
    grid () {
      const perCol = this.images.length / this.rows
      const grid = Array(this.rows).fill([])
      grid.forEach((col, index) => {
        grid[index] = this.images.slice(perCol * index, perCol * (index + 1))
      })

      return grid
    }
  }
}
</script>
