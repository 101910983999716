<template>
  <div class="px-4 max-w-[900px]">
    <settings-page-card title="How to integrate forms with Zapier">
      <div
        class="w-full flex gap-2 border border-[#E3E3E3] rounded-[4px] p-[10px] text-[14px] leading-[20px] text-[#6E747A] text-start"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="w-5 h-5"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
          />
          <path d="M12 16v-4" />
          <path d="M12 8h.01" />
        </svg>
        Please note: You will need to setup Zapier integration for each individual "form" on a 'form element' level.
        Here are the steps showing you how to do it.
      </div>
      
      <div class="border rounded-[7px] overflow-hidden shadow-[0px_1px_6px_rgba(0,0,0,.07)] mt-[30px]">
        <h4
          class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
        >
          <span class="text-[#3D82EA] mr-2">STEP 1:</span> Connecting Webhook
        </h4>
        <div class="p-[38px]">
          <p
            class="mb-4"
            style="font-size: 16px;"
          >
            <b>1.</b> Go to
            <a
              class="text-primary"
              href="https://zapier.com/app/home"
              target="_blank"
            >Zapier</a> and create a new zap.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-1.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>2.</b> Select the <b>Webhooks</b> trigger.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-3.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>3.</b> In the Webhooks trigger, click on the <b>Trigger event</b> and choose <b>Catch Hook</b> option.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-4.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>4.</b> in the <b>Test</b> tab copy the Webhook URL and go to the ESTAGE form to save it.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-5.png"
          >
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-6.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>5.</b> After saving the Webhook URL, go back to Zapier and click on the <b>Test trigger</b>.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-7.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>5.</b> After successful test, chose new record and click on the <b>Continue with selected record</b>.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-8.png"
          >
        </div>
      </div>

      <div class="border rounded-[7px] overflow-hidden shadow-[0px_1px_6px_rgba(0,0,0,.07)] mt-[50px]">
        <h4
          class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
        >
          <span class="text-[#3D82EA] mr-2">STEP 2:</span> Setting up Autoresponder
        </h4>
        <div class="p-[38px]">
          <p
            class="mb-4"
            style="font-size: 16px;"
          >
            <b>1.</b> Select second trigger for example <b>MailChimp</b>.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-9.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>2.</b> In the <b>MailChimp</b> trigger, click on the <b>Actions event</b> and choose <b>Add/Update Subscriber</b> option.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-10.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>3.</b> In the <b>Configure</b> tab, chose audience and setup the fields you want to map to the ESTAGE form.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-11.png"
          >

          <p
            class="my-4"
            style="font-size: 16px;"
          >
            <b>4.</b> In the <b>Test</b> tab, click on the <b>Test step</b> and after successful test, publish the Zap.
          </p>
          <img
            class="img-fluid mb-4"
            src="@builder/assets/images/integrations/zapier/zapier-step-12.png"
          >
        </div>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard.vue'

export default {
  name: 'ZapierIntegration',
  components: {SettingsPageCard}
}
</script>