<template>
  <div>
    <b-form-group label="Focus Keyword/Keyphrase">
      <b-textarea
        v-model="post.seo_keywords"
        placeholder="animals, music"
      />
    </b-form-group>

    <b-form-group label="SEO Title">
      <b-input v-model="post.seo_title" />
    </b-form-group>

    <b-form-group label="SEO Description">
      <b-textarea v-model="post.seo_description" />
    </b-form-group>

    <b-form-group label="Canonical URL">
      <div class="text-muted mb-1">
        By default, the primary website domain and current post slug are used.
        <br>
        The default Canonical URL for this post is:
      </div>
      <b-input-group class="mb-0 my-2">
        <b-input-group-prepend>
          <b-input-group-text class="h-[29px] bg-white font-size-14">
            https://
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input
          v-model="canonical_url"
        />
      </b-input-group>
      <b-link
        class="d-block"
        @click="canonical_url = ''"
      >
        Reset to default
      </b-link>
      <div class="text-muted mt-2">
        To change this URL, edit the link in the input field above. Do not edit the link to continue using the post default settings.
      </div>
    </b-form-group>

    <b-form-group label="URL Slug">
      <b-input
        v-model="post.slug"
        placeholder="postitle"
        class="mb-[12px]"
      />

      <div class="text-[#4F4F4F]">
        <span class="block font-[600] text-[14px] mb-1">Warning note:</span>
        <i class="text-[12px]">Changes to the URL Slug will change the page link. Any links to this page will need to be updated to reflect this change.</i>
      </div>
    </b-form-group>

    <!--    <b-form-group label="Code Injection">-->
    <!--      <b-textarea v-model="post.code_injection" />-->
    <!--    </b-form-group>-->
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'PostSeo',
  props: {
    post: {
      type: Object
    },
    project: {
      type: Object
    }
  },
  computed: {
    canonical_url: {
      get () {
        let url = _.get(this.post, 'canonical_url') 
        if(!url) {
          url = this.getFullDomain(this.project) +'/blog/' + this.post.slug
        }
        return url
      },

      set (val) {
        this.post.canonical_url = val
      }
    }
  }
}
</script>
