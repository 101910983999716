<template>
  <div>
    <button-mod-v2
      v-if="element"
      :config="element.options.content.button"
      :local-section="element"
      :hide="['align', 'actions', 'realTimeVisibility', 'EGT']"
      component-name="Button"
      group-prefix="Button"
      class="!py-[14px] !pl-[20px] !pr-[16px]"
      button-class="!px-[16px]"
    >
      <template #button-align>
        <sid-buttons-group
          label="Alignment"
          :options="[
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[`.--${element.sid}--wrapper`, ['width', 'auto']], [`.--${element.sid}--wrapper`, ['margin-left', '']], [`.--${element.sid}--wrapper`, ['--align', 'start']]],
              value: 'start',
              tooltip: 'Start'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[`.--${element.sid}--wrapper`, ['width', 'auto']], [`.--${element.sid}--wrapper`, ['margin-left', 'auto']], [`.--${element.sid}--wrapper`, ['margin-right', 'auto']], [`.--${element.sid}--wrapper`, ['--align', 'center']]],
              value: 'center',
              tooltip: 'Center'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[`.--${element.sid}--wrapper`, ['width', 'auto']], [`.--${element.sid}--wrapper`, ['margin-left', 'auto']], [`.--${element.sid}--wrapper`, ['margin-right', '']], [`.--${element.sid}--wrapper`, ['--align', 'end']]],
              value: 'end',
              tooltip: 'End'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0_336_14)\'%3E%3Cpath d=\'M2.64185 6.88083L2.64185 3.76855L11.2976 3.76855L11.2976 6.88083L2.64185 6.88083Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M2.64185 10.4726L2.64185 7.36035L11.2976 7.36035L11.2976 10.4726L2.64185 10.4726Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M11.7787 12.8665L11.7787 1.375L12.5 1.375L12.5 12.8665L11.7787 12.8665Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M1.44006 12.8665L1.44006 1.375L2.16138 1.375L2.16138 12.8665L1.44006 12.8665Z\' fill=\'%237E7E7E\'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=\'clip0_336_14\'%3E%3Crect width=\'13\' height=\'12\' fill=\'white\' transform=\'translate(12.5 0.5) rotate(90)\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A',
              customProperties: [[`.--${element.sid}--wrapper`, ['width', '100%']], [`.--${element.sid}--wrapper`, ['margin-left', '']], [`.--${element.sid}--wrapper`, ['margin-right', '']], [`.--${element.sid}--wrapper`, ['--align', 'stretch']]],
              value: 'stretch',
              tooltip: 'Stretch'
            }
          ]"
          :sid="`.--${element.sid}--wrapper`"
          rule-name="--align"
        />
      </template>

      <template #actions>
        <SidbearSettingsGroup
          v-if="formContent.step !== section.options.content.orderForm.steps.length - 1"
          title="Click Action"
          button-class="!px-[16px]"
        >
          <template v-if="formContent.step === 99">
            <actions-after-submit
              v-model="section.options.content.orderForm.confirmation[0].form.actions"
              :hide-responders="true"
              :hide-action-items="['EGT', 'Email To Admin', 'Refresh']"
              :unremovable-actions="['Redirect']"
            />

            <div>
              <div
                v-for="(action, actionIndex) in section.options.content.orderForm.confirmation[0].form.actions"
                :key="actionIndex"
              >
                <div
                  :class="{'bg-[#F9F9F9]': activeAction === actionIndex + 4}"
                  class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[60px] px-[23px] font-[700] text-[#44474A]"
                  @click="activeAction === actionIndex + 4 ? activeAction = null : activeAction = actionIndex + 4"
                >
                  {{ action.name }}

                  <svg
                    :class="{'transform rotate-180': activeAction === actionIndex + 4}"
                    fill="none"
                    height="7"
                    viewBox="0 0 10 7"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                      stroke="#6E747A"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>

                <div
                  v-if="activeAction === actionIndex + 4"
                  class="pt-[16px]"
                >
                  <component
                    :is="camelCase(action.name)"
                    :config="section.options.content.orderForm.confirmation[0].form"
                    :controls="section.options.content.orderForm.confirmation[0].form.controls"
                    :product-url="true"
                    :order-form="true"
                    :index="activeAction"
                    :service="action"
                    class="pb-3"
                    @removeAction="removeAction(form.step, actionIndex)"
                    @deleteResponder="(service) => deleteResponder(form.step, service)"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <actions-after-submit
              v-model="section.options.content.orderForm.steps[formContent.step].form.actions"
              :hide-actions="true"
            />

            <div>
              <div
                v-for="(action, actionIndex) in section.options.content.orderForm.steps[formContent.step].form.actions"
                :key="actionIndex"
              >
                <div
                  :class="{'bg-[#F9F9F9]': activeAction === actionIndex + 4}"
                  class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[60px] px-[23px] font-[700] text-[#44474A]"
                  @click="activeAction === actionIndex + 4 ? activeAction = null : activeAction = actionIndex + 4"
                >
                  {{ action.name }}

                  <svg
                    :class="{'transform rotate-180': activeAction === actionIndex + 4}"
                    fill="none"
                    height="7"
                    viewBox="0 0 10 7"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                      stroke="#6E747A"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>

                <div
                  v-if="activeAction === actionIndex + 4"
                  class="pt-[16px]"
                >
                  <component
                    :is="camelCase(action.name)"
                    :config="section.options.content.orderForm.steps[formContent.step]"
                    :controls="section.options.content.orderForm.steps[formContent.step].form.controls"
                    :order-form="true"
                    :index="activeAction"
                    :service="action"
                    class="pb-3"
                    @removeAction="removeAction(formContent.step, actionIndex)"
                    @deleteResponder="(service) => deleteResponder(formContent.step, service)"
                  />
                </div>
              </div>
            </div>
          </template>
        </SidbearSettingsGroup>
      </template>

      <template #device>
        <SidbearSettingsGroup
          v-if="element.class === 'form-button-redirect'"
          title="Device Visibility"
          button-class="!px-[16px]"
        >
          <Panel :config="element.options.content.button" />
        </SidbearSettingsGroup>
      </template>
    </button-mod-v2>
  </div>
</template>

<script>
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction.vue'
import ActionsAfterSubmit from '@/components/editor/components/form/ActionsAfterSubmit.vue'
import TextMixins from '@/components/mixins/TextMixins'

export default {
  name: 'SubmitButtonSettings',
  components: {ActionsAfterSubmit, ButtonAction, SidbearSettingsGroup, SidButtonsGroup},
  mixins: [TextMixins],
  props: {
    element: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    formContent: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      activeAction: 0
    }
  },
  methods: {
    deleteResponder (step, service) {
      for (let c in this.section.options.content.orderForm.steps[step].form.controls) {
        this.$set(this.section.options.content.orderForm.steps[step].form.controls[c], 'mapping', this.section.options.content.orderForm.steps[step].form.controls[c].mapping.filter(con => con.auto_responder_id !== service.id))
      }
    },
    removeAction (step, index) {
      this.section.options.content.orderForm.steps[step].form.actions.splice(index, 1)
    }
  }
}
</script>