<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    active-class="z-100"
    class="highlight-element"
    section-type="element"
    :hide-bar="hideBar"
    :hide-add="hideAdd"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('carousel-mod-v3')"
  >
    <div
      :data-sid="section.sid"
      class="flex position-relative"
      :class="{'element-hidden': !visability('carousel')}"
    >
      <swiper-container
        v-if="showSlider"
        ref="slider"
        :controller-control="relatedSlider"
        :direction="swiperOptions.direction"
        :pagination="swiperOptions.pagination"
        :pagination-el="`#_${section.uid}`"
        :space-between="section.options.content.carousel.gap[resolutioner]"
        :speed="swiperOptions.speed"
        :loop="swiperOptions.loop"
        :autoplay-delay="swiperOptions.autoplay"
        :mousewheel="swiperOptions.mousewheel"
        :free-mode="swiperOptions.freeMode"
        :autoplay-reverse-direction="swiperOptions.reverseDirection"
        class="es-slider"
        :slides-per-view="swiperOptions.slidesPerView"
        :class="[`--${section.sid}--slider`, `--${section.uid}--slider`, `swiper-direction-${swiperOptions.direction}`]"
        simulate-touch="false"
        @slidechange="slideChange"
      >
        <swiper-slide
          v-for="(slide, index) in section.options.content.carousel.slides"
          :key="index"
          class="slide-container"
          :data-sid="`${section.sid}--${slide.uid}`"
        >
          <div
            class="slide w-100 h-100 relative"
          >
            <div
              class="block-bg inherit-radius"
              :class="`--${section.sid}--${slide.uid}--bg`"
            />

            <elements
              v-model="slide.content"
              class="h-100"
              drag-height="150px"
              @flow="handleFlow"
              @click.stop.native="$store.commit('editor/SET_PAGE', 'add-element')"
            />
          </div>
        </swiper-slide>
      </swiper-container>

      <div
        :id="`_${section.uid}`"
        class="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
        :class="`--pagination--${section.sid}`"
      />

      <button
        class="slide-btn slide-prev-btn custom"
        :class="`--${section.sid}--prev-btn`"
        @click.stop="previousSlide"
      >
        <i :class="section.options.content.carousel.prevIcon" />
      </button>

      <button
        class="slide-btn slide-next-btn custom"
        :class="`--${section.sid}--next-btn`"
        @click.stop="nextSlide"
      >
        <i :class="section.options.content.carousel.nextIcon" />
      </button>
    </div>

    <div
      v-show="false"
      :class="`--${section.sid}--slider`"
      class="swiper-direction-vertical"
    >
      <div class="swiper-slide-backward" />
      <div class="swiper-slide-forward" />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import EventsMixin from '@/components/editor/mixins/EventsMixin'

export default {
  name: 'ElCarouselV2',
  mixins: [SectionMixin, EventsMixin],
  data () {
    return {
      showSlider: true
    }
  },
  computed: {
    relatedSlider () {
      return this.section.pickedUid ? `.--${this.section.pickedUid}--slider` : null
    },
    autoplay () {
      return this.section.options.content.carousel.autoplay ? this.section.options.content.carousel.autoplayDelay : false
    },
    swiperOptions () {
      return {
        autoplay: this.autoplay,
        relatedSlider: this.relatedSlider,
        speed: this.section.options.content.carousel.speed,
        freeMode: this.section.options.content.carousel.freeMode,
        mousewheel: this.section.options.content.carousel.mousewheel,
        pagination: this.section.options.content.carousel.pagination,
        loop: this.section.options.content.carousel.loop[this.resolutioner],
        centeredSlides: this.section.options.content.carousel.centeredSlides,
        reverseDirection: this.section.options.content.carousel.reverseDirection,
        direction: this.section.options.content.carousel.direction[this.resolutioner],
        slidesPerView: this.section.options.content.carousel.slidesPerView[this.resolutioner]
      }
    }
  },
  watch: {
    swiperOptions: {
      deep: true,
      handler: function () {
        this.hardRefreshSwiper()
      }
    }
  },
  mounted () {
    this.setSlideClasses(this.$refs.slider.swiper)
    this.listen('onServiceSlideChange', e => this.onService(e.detail))
  },
  methods: {
    hardRefreshSwiper () {
      this.showSlider = false

      setTimeout(() => {
        this.showSlider = true
      })
    },
    previousSlide () {
      this.$refs.slider.swiper.slidePrev()
    },
    nextSlide () {
      this.$refs.slider.swiper.slideNext()
    },
    onService ({slide}) {
      this.$refs.slider.swiper.slideTo(slide)
    },
    setSlideClasses (swiper) {
      if (swiper.slides.length < 3) return

      const nextNextSlide = swiper.slides[swiper.activeIndex + 2]
      const prevPrevSlide = swiper.slides[swiper.activeIndex - 2]

      swiper.slides.forEach(slide => slide.classList.remove('swiper-slide-backward'))
      swiper.slides.forEach(slide => slide.classList.remove('swiper-slide-forward'))

      if (prevPrevSlide) {
        prevPrevSlide.classList.add('swiper-slide-backward')
      }

      if (nextNextSlide) {
        nextNextSlide.classList.add('swiper-slide-forward')
      }
    },
    slideChange (e) {
      const [swiper] = e.detail
      this.setSlideClasses(swiper)
    }
  }
}
</script>

<style lang="scss">
swiper-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  --height: 100vh;
  --horizontal-height: 0px;

  &.swiper-direction-vertical {
    height: 100vh;
  }
}

.slide-container {
  --transition: 1000ms;
  transition: transform var(--transition), opacity var(--transition);
}

.slide-btn {
  z-index: 1000;
}

.swiper-pagination {
  display: none;
  justify-content: center;
  left: var(--swiper-pagination-left, 0) !important;
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-inactive-opacity: 1;
}

.slide-container > .slide > .w-100.h-100 {
  display: flex;
  flex-direction: column;
  justify-content: var(--slide-vertical-alignment, start);
}

.slide-container {
  min-width: auto !important;
}
</style>