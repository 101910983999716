<template>
  <b-modal
    v-model="dialog"
    hide-footer
    hide-header
    size="650"
  >
    <icon
      class="cursor-pinter ml-3 mt-3"
      icon="close.svg"
      @click.native="dialog = false"
    />
    <h4 class="h2 text-center !mb-[26px] !text-[24px]">
      Page Management
    </h4>

    <b-tabs
      v-model="tabs"
      active
      card
      class="add-page-tabs mx-n3"
      lazy
      nav-class="mx-0"
    >
      <b-tab
        active
        class="!px-[21px] !py-[25px]"
        title-link-class="!text-[13px] text-[#AFB4B9] !font-normal"
        title="Pages"
      >
        <template v-if="!page">
          <b-form-group class="position-relative">
            <page-select-menu-search
              v-model="searchQuery"
              type="text"
              class="form-control search-fc"
              @onSearch="onSearch"
            />
            <div
              v-if="searchQueryLoading"
              class="search-fc-spinner"
            >
              <b-spinner
                small
                variant="primary"
              />
            </div>
            <div class="search-fc-icon" />
          </b-form-group>

          <div
            v-if="pageIsLoading && paginationCurrentPage == 1 && !searchQuery"
            class="h-200px d-flex align-items-center justify-content-center text-center mt-5"
          >
            <b-spinner variant="primary" />
          </div>

          <b-list-group
            v-else
            ref="scroll"
            v-h-scroll-class="'pr-[10px]'"
            class="overflow-auto text-[15px] text-[#6E747A] pr-[10px]"
            flush
            style="max-height:500px"
            @scroll="scrollLoadingPages"
          >
            <!-- Blog Page -->
            <b-list-group-item
              class="px-0 py-2"
              :class="{'!sticky top-0 z-10': project.blog_is_home_page}"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <i
                    v-if="project.blog_is_home_page"
                    class="home-icon mr-2"
                    style="margin-bottom: 2px;"
                  />
                  Blog Home Page
                </div>

                <div class="d-flex gap-2">
                  <b-btn
                    v-b-tooltip.right
                    title="Edit page"
                    class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
                    size="sm"
                    variant="white"
                    @click="page = 'blog-home'"
                  >
                    <i class="pencil-icon" />
                  </b-btn>
                </div>
              </div>
            </b-list-group-item>
            <!-- End Blog Page -->

            <b-list-group-item
              v-for="(item, index) in filteredPages"
              :key="index"
              class="px-0 py-2"
              :class="{'!sticky top-0 z-10':item.is_home}"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <i
                    v-if="item.is_home && !project.blog_is_home_page"
                    class="home-icon mr-2"
                    style="margin-bottom: 2px;"
                  />
                  {{ item.title }}
                  <span
                    v-if="item.category"
                    class="d-flex items-center text-[#AFB4B9] ml-[16px]"
                  >
                    <i class="folder-icon mr-[7px]" />
                    {{ item.category.name }}
                  </span>
                </div>

                <div class="d-flex gap-2">
                  <b-btn
                    v-b-tooltip.right
                    title="Edit page"
                    class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
                    size="sm"
                    variant="white"
                    @click="setEditablePage(item)"
                  >
                    <i class="pencil-icon" />
                  </b-btn>
                  <b-btn
                    v-b-tooltip="{ title: 'Duplicate page', placement: 'top', boundary: 'document' }"
                    class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
                    size="sm"
                    title="Duplicate page"
                    variant="white"
                    @click="duplicate(item)"
                  >
                    <i class="copy-icon" />
                  </b-btn>
                  <delete-confirm
                    v-if="!item.is_home"
                    :description="currentPage.id == item.id ? 'You can not delete this page because it is open in the builder.' : `The <b>${item.title}</b> page will be added in archive.`"
                    :title="currentPage.id == item.id ? 'Attention!':'Are you sure?'"
                    no-btn-text="Close"
                    :hide-yes-btn="currentPage.id == item.id"
                    @confirm="deletePage(item,index)"
                  >
                    <template v-slot:activator="{on}">
                      <b-btn
                        v-b-tooltip="{ title: 'Archive page', placement: 'right', boundary: 'document' }"
                        v-loading="deleteLoadingIndex == index"
                        size="sm"
                        tooltip-placement="left"
                        variant="white"
                        class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
                        v-on="on"
                      >
                        <i
                          v-if="deleteLoadingIndex != index"
                          class="archive-icon"
                        />
                      </b-btn>
                    </template>
                  </delete-confirm>
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item v-if="pageIsLoading && paginationCurrentPage > 1">
              <div
                class="bg-white d-flex justify-content-center align-items-center text-primary position-absolute bottom-2 left-0 w-100"
              >
                <b-spinner
                  class="mr-2"
                  small
                  variant="primary"
                />
                Loading
              </div>
            </b-list-group-item>
          </b-list-group>

          <p
            v-if="!filteredPages.length && !searchQueryLoading && !pageIsLoading"
            class="text-muted text-center mb-0 text-[15px]"
          >
            No pages found
          </p>
        </template>
        <template v-else-if="page === 'blog-home'">
          <b-form @submit.prevent="editBlogHomePage">
            <b-row>
              <b-col
                cols="10"
                class="mx-auto"
              >
                <div class="w-100">
                  <b-form-group
                    label="Slug Name"
                    label-class="pm-label"
                  >
                    <b-input
                      v-model="blogSlug"
                      required
                      class="pm-input"
                      :state="!validateBlogSlug"
                    />
                    <div
                      v-if="validateBlogSlug"
                      class="text-danger mt-2"
                    >
                      {{ validateBlogSlug }}
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <b-checkbox
                      v-model="project.blog_is_home_page"
                    >
                      <div class="pm-label mt-[-6px]">
                        Set as home page
                      </div>
                    </b-checkbox>
                  </b-form-group>

                  <div class="d-flex justify-content-center">
                    <b-btn
                      class="!text-[15px] btn-wide d-flex align-items-center mr-3"
                      @click="page = null"
                    >
                      Cancel
                    </b-btn>
                    <b-btn
                      class="!text-[15px] btn-wide d-flex align-items-center"
                      type="submit"
                      variant="primary"
                      :disabled="validateBlogSlug !== false"
                    >
                      Save
                    </b-btn>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </template>
        <template v-else>
          <b-form @submit.prevent="editPage">
            <b-row>
              <b-col
                cols="10"
                class="mx-auto"
              >
                <div class="w-100">
                  <b-form-group
                    label="Page Name"
                    label-class="pm-label"
                  >
                    <b-input
                      v-model="page.title"
                      required
                      class="pm-input"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Page SEO Title"
                    label-class="pm-label"
                  >
                    <b-input
                      v-model="page.meta_title"
                      required
                      class="pm-input"
                    />
                  </b-form-group>

                  <b-form-group
                    label="URL Slug"
                    label-class="pm-label"
                  >
                    <b-input
                      v-model="slug"
                      required
                      :state="!validateSlug"
                      class="pm-input"
                    />

                    <div
                      v-if="validateSlug"
                      class="text-danger mt-2"
                    >
                      {{ validateSlug }}
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <b-checkbox
                      v-model="page.is_home"
                      :disabled="!!page.disable_set_home"
                      :unchecked-value="0"
                      :value="1"
                    >
                      <div class="pm-label mt-[-6px]">
                        Set as home page
                      </div>
                    </b-checkbox>
                  </b-form-group>

                  <b-form-group
                    label="Select Folder"
                    class="pm-form-group"
                    label-class="pm-label"
                  >
                    <page-folders-tree-select
                      v-model="page.category_id"
                      show-chevron-icon
                    />
                  </b-form-group>


                  <b-form-group
                    v-if="!page.category_id"
                    label="Create New Folder"
                    label-class="pm-label"
                  >
                    <b-input
                      v-model="folderName"
                      class="pm-input"
                    />
                  </b-form-group>


                  <div class="d-flex justify-content-center">
                    <b-btn
                      class="!text-[15px] btn-wide d-flex align-items-center mr-3"
                      @click="page = null"
                    >
                      Cancel
                    </b-btn>
                    <b-btn
                      class="!text-[15px] btn-wide d-flex align-items-center"
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </b-btn>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </b-tab>

      <b-tab
        class="!px-[21px] !py-[25px]"
        title="Add Blank Page"
        title-link-class="!text-[13px] text-[#AFB4B9] !font-normal !p-[10px]"
      >
        <b-form
          @submit.prevent="addPage"
        >
          <b-row>
            <b-col
              cols="10"
              class="mx-auto"
            >
              <div class="w-100">
                <b-form-group
                  label="Page Name"
                  label-class="pm-label"
                >
                  <b-input
                    v-model="name"
                    class="pm-input"
                    :state="newPageValidateSlug ? false : null"
                    required
                  />

                  <div
                    v-if="newPageValidateSlug"
                    class="text-danger mt-2"
                  >
                    {{ newPageValidateSlug }}
                  </div>
                </b-form-group>

                <b-form-group
                  label="Select Folder"
                  label-class="pm-label"
                  class="pm-form-group"
                >
                  <page-folders-tree-select
                    v-model="folder"
                    show-chevron-icon
                  />
                </b-form-group>


                <b-form-group
                  v-if="folder == '' || folder == null"
                  label="Create New Folder"
                  label-class="pm-label"
                >
                  <b-input
                    v-model="folderName"
                    class="pm-input"
                  />
                </b-form-group>

                <div class="d-flex">
                  <b-btn
                    class="!text-[15px] font-weight-bold import-page-select py-2 px-3 d-flex align-items-center mx-auto"
                    type="submit"
                    variant="primary"
                    :disabled="newPageValidateSlug"
                  >
                    Add Page Now
                    <svg
                      class="ml-2"
                      fill="none"
                      height="17"
                      viewBox="0 0 17 17"
                      width="17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
                        fill="white"
                        fill-rule="evenodd"
                      />
                      <path
                        d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
                        fill="white"
                      />
                    </svg>
                  </b-btn>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>

      <b-tab
        class="!px-[21px] !py-[25px]"
        title="Add From Share Code"
        title-link-class="!text-[13px] text-[#AFB4B9] !font-normal !p-[10px]"
      >
        <!-- Import Page From Template -->
        <import-page-from-project @page-added="dialog = false" />
        <!-- End Import Page From Template -->
      </b-tab>

      <b-tab
        class="!px-[21px] !py-[25px]"
        title="Add From Template"
        title-link-class="!text-[13px] text-[#AFB4B9] !font-normal !p-[10px]"
      >
        <!-- Import Page From Template -->
        <import-page @page-added="dialog = false" />
        <!-- End Import Page From Template -->
      </b-tab>

      <b-tab
        class="!px-[21px] !py-[25px]"
        title="Archive"
        title-link-class="!text-[13px] text-[#AFB4B9] !font-normal !p-[10px]"
      >
        <!-- Archive -->
        <archived-pages />
        <!-- End Archive -->
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import DeleteConfirm from './DeleteConfirm'
import ImportPage from '../components/importPage/ImportPage'
import ImportPageFromProject from '../components/importPage/ImportPageFromProject'
import PageManagmentMixin from '../mixins/PageManagmentMixin'
import _ from 'lodash'
import ArchivedPages from '../../../layouts/dashboard/compontens/archive/ArchivedPages'
import PageFoldersTreeSelect from '@/layouts/dashboard/compontens/templates/PageFoldersTreeSelect'
import PageSelectMenuSearch from '@/components/editor/components/pagesSelectMenu/PageSelectMenuSearch'

const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i')


export default {
  name: 'NewPageModal',
  components: {
    PageSelectMenuSearch,
    PageFoldersTreeSelect,
    ArchivedPages, ImportPageFromProject, ImportPage, DeleteConfirm
  },
  mixins: [PageManagmentMixin],
  data () {
    return {
      dialog: false,
      folder: null,
      folderName: '',
      name: null,
      page: '',
      tabs: 0,
      blogSlug: '',
      deleteLoadingIndex: -1
    }
  },
  computed: {
    validateBlogSlug () {
      if (!this.blogSlug) {
        return 'This is field can not be empty.'
      }

      if (this.pages.findIndex(({slug}) => slug === this.blogSlug) !== -1) {
        return 'This slug is already taken by another page.'
      }

      if (urlPattern.test(this.blogSlug)) {
        return 'Invalid slug name.'
      }

      return false
    },
    validateSlug () {
      if (!this.slug) {
        return 'This is field can not be empty.'
      }

      if ([{slug: this.blogSlug}, ...this.pages].filter(({slug, id}) => (slug === this.slug && id !== this.page.id)).length >= 1) {
        return 'This slug is already taken by another page.'
      }

      return false
    },
    newPageValidateSlug () {
      if ([{slug: this.blogSlug}, ...this.pages].filter(({slug}) => slug === this.name).length >= 1) {
        return 'This slug is already taken by another page.'
      }

      return false
    },
    slug: {
      get () {
        return _.kebabCase(this.page.slug)
      },
      set (val) {
        this.page.slug = val
      }
    },
    homePage () {
      return this.initialPages.data.find(page => page.is_home)
    },
    filteredPages () {
      let data = this.pages
      const pagesCount = data.length
      data = data.filter(d => !d.is_home)
      if (this.homePage && pagesCount != 0) {
        const homeIndex = data.findIndex(d => d.id == this.homePage.id)
        if (homeIndex != -1) {
          data.splice(homeIndex, 1)
        }
        data.unshift(this.homePage)
      }
      return data
    },
    ...mapGetters('pages', {
      initialPages: 'getPages'
    }),
    ...mapState('pages', {
      currentPage: state => state.page
    })
  },
  watch: {
    async dialog (val) {
      if (!val) {
        this.page = null
      } else {
        this.paginationCurrentPage = 1
        this.searchQuery = ''
        this.blogSlug = _.clone(this.project.blog_slug) || 'blog'
        // if(!this.pages.length && !this.searchQuery) this.getUnfilteredPages(1)
        this.getUnfilteredPages(1)
      }
    }
  },
  mounted () {
    VEvent.listen('newPageModal', (page = null) => {
      this.dialog = true
      this.folder = null
      this.folderName = ''
      if (page) {
        this.setEditablePage(page)
      }
    })
    VEvent.listen('pm-modal-append-page', (page) => {
      this.pages.push(page)
    })
  },
  methods: {
    updateSlug (val) {
      this.page.slug = _.kebabCase(val)
    },
    addPage () {
      VEvent.fire('loader', true)

      this.$store.dispatch('pages/addPage', {
        title: this.name,
        meta_title: this.name,
        category_id: this.folder,
        category_name: this.folderName,
        content: '[]',
        content_draft: '[]',
        dont_dispatch: true
      })
        .then((data) => {
          this.pages.unshift(data)
          VEvent.fire('open-page', data)
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The page has been added.',
            showConfirmButton: false,
            timer: 1500
          })
          this.dialog = false
          this.name = null
          this.folder = null
          this.folderName = ''
        })
    },
    duplicate (item) {
      this.$store.dispatch('pages/duplicatePage', {id: item.id, css_hash: item.css_hash})
        .finally(() => {
          this.dialog = false
          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been duplicated.',
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    editPage () {
      this.$store.commit('projects/SET_PUBLISING', true)
      VEvent.fire('loader', true)
      this.$store.dispatch('pages/editPage', {...this.page, category_name: this.folderName})
        .then(async (data) => {

          if (this.page.id === this.currentPage.id) {
            this.currentPage.title = this.page.title
            this.currentPage.slug = this.page.slug
          }

          try {
            const item = data.data
            if (item.is_home) {
              await this.$store.dispatch('pages/loadPages')
            }

            this.pages.forEach(p => {
              if (item.is_home) p.is_home = 0
              if (p.id == item.id) {
                for (const key in item) {
                  p[key] = item[key]
                }
              }
            })
          } finally {
            this.page = null
            VEvent.fire('loader', false)
            this.$store.commit('projects/SET_PUBLISING', false)
          }

          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The page has been updated.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(() => VEvent.fire('loader', false))
    },
    deletePage (page, index) {
      // this.clearStyles(document.body)
      this.deleteLoadingIndex = index
      this.$store.dispatch('pages/deletePage', page)
        .then(() => {
          this.pages = this.pages.filter(p => p.id != page.id)
          this.$store.dispatch('pages/loadPages')
          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been added in archive.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => this.deleteLoadingIndex = -1)
    },
    setEditablePage (page) {
      this.folderName = ''
      this.page = _.cloneDeep(page)
      this.page.disable_set_home = this.page.is_home
      this.tabs = 0
    },
    editBlogHomePage () {
      VEvent.fire('loader', true)
      this.project.blog_slug = _.clone(this.blogSlug)

      this.$store.dispatch('projects/save')
        .finally(() => {
          VEvent.fire('loader', false)
        })
    }
  }
}
</script>

<style lang="scss">
.add-page-tabs {
  .card-header {
    background: #fff !important;
    border-bottom: none !important;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-item {
    border: 1px solid #E2E5EC;
    border-bottom: none !important;
    height: 40px !important;
  }

  .nav-link {
    border-radius: 0 !important;
    font-weight: bold;
    border-right: none !important;
    border-left: none !important;
    font-size: 15px;
    color: #AEB1B4;
    background: #F7F7F7;
    border-top: none !important;
    padding-left: 1.462rem !important;
    padding-right: 1.462rem !important;
    border-bottom: 1px solid #E2E5EC !important;
    height: 40px !important;

    &:hover {
      background: rgba(247, 247, 247, 0.65);
    }

    &.active {
      border-bottom: none !important;
      color: #6E747A !important;
      background: #FFF !important;
      margin-top: 2px;
    }
  }

  .nav-item:not(:last-child) {
    border-right: none !important;
  }


  .tab-content {
    border-top: 1px solid #E2E5EC;

  }
}

.h-200px {
  height: 200px;
}
</style>
