<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('media')}"
    :section="section"
    :dublicate="section"
    class="highlight-element"
    @click="debug"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('media')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <b-media :vertical-align="section.options.content.media.vertical_align[resolutioner]">
        <template slot="aside">
          <elements
            v-model="section.options.content.media.aside"
            :style="{width: !section.options.content.media.aside.length ? '40px': ''}"
            @flow="handleFlow"
          />
        </template>

        <elements
          v-model="section.options.content.media.elements"
          @flow="handleFlow"
        />
      </b-media>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import Elements from '../../utils/Elements/Elements'
import ResolutionMixin from '../../../mixins/ResolutionMixin'

export default {
  name: 'ElMediaWrapper',

  components: {Elements, Template, Highliter},

  mixins: [SectionMixin, ResolutionMixin],

  methods: {
    debug () {
      console.log(this.section.options)
    }
  }
}
</script>
