<template>
  <b-card no-body>
    <b-card-header class="pb-0">
      <editor-menu-bar
        v-slot="{ commands, isActive, getNodeAttrs }"
        :editor="editor"
      >
        <b-row
          class="menubar"
          no-gutters
        >
          <b-col
            cols="3"
            class="mr-2"
          >
            <b-form-group>
              <b-select
                v-model="config.fontFamily"
                :options="fonts"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="2"
            class="mr-2"
          >
            <b-form-group>
              <b-select
                :value="config.fontSize"
                :options="fontSizes"
                @input="event => applyFontSize(event, commands, getNodeAttrs)"
              />
            </b-form-group>
          </b-col>

          <b-col cols="1">
            <b-form-group>
              <b-input
                style="width: 40px;"
                type="color"
                @input="event => applyTextColor(event, commands, getNodeAttrs)"
              />
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-btn-group>
              <b-btn
                variant="white"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                <i class="fa fa-bold" />
              </b-btn>
              <b-btn
                variant="white"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <i class="fa fa-italic" />
              </b-btn>
              <b-btn
                variant="white"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
              >
                <i class="fa fa-underline" />
              </b-btn>
            </b-btn-group>
          </b-col>

          <b-col class="pl-3">
            <b-btn-group>
              <b-btn
                variant="white"
                :class="{ 'is-active': config.align === 'left' }"
                @click="config.align = 'left'"
              >
                <i class="fa fa-align-left" />
              </b-btn>
              <b-btn
                variant="white"
                :class="{ 'is-active': config.align === 'center' }"
                @click="config.align = 'center'"
              >
                <i class="fa fa-align-center" />
              </b-btn>
              <b-btn
                variant="white"
                :class="{ 'is-active': config.align === 'right' }"
                @click="config.align = 'right'"
              >
                <i class="fa fa-align-right" />
              </b-btn>
            </b-btn-group>
          </b-col>
        </b-row>
      </editor-menu-bar>
    </b-card-header>

    <b-card-body class="pb-0">
      <editor-content
        :style="style"
        style="overflow-wrap: anywhere;"
        class="success-message"
        :editor="editor"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'

import TextColor from '../../../plugins/TiptapTextColor'
import FontSize from '../../../plugins/TiptapFontSize'

export default {
  name: 'ContentEditable',

  components: {
    EditorContent,
    EditorMenuBar
  },

  props: {
    value: {
      required: true
    },
    config: {
      required: true
    },
    uid: String
  },

  data () {
    return {
      fontSizes: ['1px',
        '2px',
        '3px',
        '4px',
        '5px',
        '6px',
        '7px',
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '13px',
        '14px',
        '15px',
        '16px',
        '17px',
        '18px',
        '19px',
        '20px',
        '21px',
        '22px',
        '23px',
        '24px',
        '25px',
        '26px',
        '27px',
        '28px',
        '29px',
        '30px',
        '31px',
        '32px',
        '33px',
        '34px',
        '35px',
        '36px',
        '37px',
        '38px',
        '39px',
        '40px',
        '41px',
        '42px',
        '43px',
        '44px',
        '45px',
        '46px',
        '47px',
        '48px',
        '49px',
        '50px',
        '51px',
        '52px',
        '53px',
        '54px',
        '55px',
        '56px',
        '57px',
        '58px',
        '59px',
        '60px',
        '61px',
        '62px',
        '63px',
        '64px',
        '65px',
        '66px',
        '67px',
        '68px',
        '69px',
        '70px',
        '71px',
        '72px',
        '73px',
        '74px',
        '75px',
        '76px',
        '77px',
        '78px',
        '79px',
        '80px',
        '81px',
        '82px',
        '83px',
        '84px',
        '85px',
        '86px',
        '87px',
        '88px',
        '89px',
        '90px',
        '91px',
        '92px',
        '93px',
        '94px',
        '95px',
        '96px',
        '97px',
        '98px',
        '99px',
        '100px'],
      fonts: [
        'Global Style',
        'Roboto',
        'Montserrat',
        'Georgia',
        'Playfair Display',
        'Raleway',
        'Spectral',
        'Rubik'
      ],
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new TextColor(),
          new FontSize(),
          new Underline(),
          new History()
        ],
        onUpdate: ({getHTML}) => {
          const content = getHTML()
          this.$emit('input', content)
        },
        parseOptions: {
          preserveWhitespace: 'full'
        },
        content: this.value
      }),
      fontSize: '11px',
      fontFamily: 'Global Style',
      align: 'left',
      color: '#000'
    }
  },

  computed: {
    style () {
      return {
        color: this.config.color,
        textAlign: this.config.align,
        fontFamily: this.config.fontFamily,
        fontSize: this.config.fontSize
      }
    }
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    applyTextColor (val, commands) {
      const { state } = this.editor
      const empty = state.selection.empty

      if (empty) {
        this.config.color = val
      } else {
        commands.textcolor({ color: val })
      }
    },
    applyFontSize (val, commands) {
      const { state } = this.editor
      const empty = state.selection.empty

      if (empty) {
        this.config.fontSize = val
      } else {
        commands.fontsize({ fontSize: val })
      }
    }
  }
}
</script>

<style lang="scss">
.success-message {
  > .ProseMirror {
    min-height: 200px;
  }
}
</style>
