require('dotenv').config()
import Cookie from 'js-cookie'
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// Router and Store
import router from './router'
import store from './store'

// Plugins
import './plugins/bootstrap-vue'
import './plugins/vue-animate'
import './plugins/moment'
import './plugins/colorPicker'
import './plugins/axios'
import './plugins/sweetalert'
import './plugins/vEvent'
import './plugins/select'
import './plugins/unsplash'
import './plugins/countdown'
import './plugins/aos'
import './plugins/veevalidate'
import './plugins/vue-clipboard2'
import './plugins/animate-css'
import './plugins/custom-slider'
import './plugins/vuelidate'

// Styles
import '@builder/assets/css/dist/tailwind.css'
import '@builder/assets/scss/main.scss'

// Directives
import './directives/index'

// App Component
import App from './App.vue'

// ApexCharts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Utility function for component registration
function registerComponents (requireComponent, prefix = '') {
  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')))
    Vue.component(prefix + componentName, componentConfig.default || componentConfig)
  })
}

// Register components
registerComponents(require.context('./components/builder/sections', true, /[A-Z]\w+\.(vue)$/))
registerComponents(require.context('./components/builder/elements', true, /[A-Z]\w+\.(vue)$/))
registerComponents(require.context('./components/editor/mods', false, /[A-Z]\w+\.(vue)$/))
registerComponents(require.context('./components/editor/controls', false, /[A-Z]\w+\.(vue)$/))
registerComponents(require.context('./components/editor/utils', false, /[A-Z]\w+\.(vue)$/))
registerComponents(require.context('./components/builder/utils', true, /[A-Z]\w+\.(vue)$/))

// Global Components
import FormSearch from '@/components/editor/components/form/FormSearch.vue'
import InlineSvg from './components/editor/utils/InlineSvg.vue'
Vue.component('FormSearch', FormSearch)
Vue.component('inline-svg', InlineSvg)

// Router Middleware
router.beforeEach((to, from, next) => {
  // const isMaintenanceMode = true
  // if (isMaintenanceMode) {
  //   window.location.href = 'https://estage.com/system/maintenance'
  // }
  const user = store.state.auth.user
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !user) {
    Cookie.remove('cross_estage_token')
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = `${process.env.VUE_APP_AUTH_HOST}/logout`
    }
  } else {
    store.dispatch('auth/me').then(() => next())
  }
})

// Global Mixin
import GlobalMixin from '@/mixins/GlobalMixin'
Vue.mixin(GlobalMixin)

// Initialize Vue Application
Vue.config.productionTip = false

store.dispatch('auth/me').then(() => {
  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
})