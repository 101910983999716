<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element hide-copy-favorite"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('orderForm')"
  >
    <div
      :class="{'element-hidden': !visability('orderForm')}"
      class="el-order-form"
      :data-sid="section.sid"
    >
      <form-wizard
        :order-form="section.options.content.orderForm"
        :steps="steps"
        :count="steps.length"
        :section="section"
        :class="`--${section.sid}--order-form`"
        class="overflow-hidden order-form"
      />
    </div>
  </highliter>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'
import PropertiesManager from '@/components/mixins/PropertiesManager'
import FormWizard from '@/components/builder/components/form/FormWizard.vue'
export default {
  name: 'ElOrderForm',
  components: {FormWizard, Highliter},
  mixins: [SectionMixin, PropertiesManager],
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('forms', {
      wizard: state => state.wizard
    }),
    currentStep () {
      return this.wizard.currentStep
    },
    steps () {
      return this.section.options.content.orderForm.steps
    }
  },
  methods: {
    ...mapMutations({
      SET_FORM: 'orderForm/SET_FORM',
      SET_PRODUCT: 'orderForm/SET_PRODUCT'
    }),
    initPayPal (attributes) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        for (const name of Object.keys(attributes)) {
          script.setAttribute(name, attributes[name])
        }
        document.head.appendChild(script)
        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
      })
    },
    createForm (index) {
      if (!this.section.options.content.orderForm.steps[index].form) {
        this.section.options.content.orderForm.steps[index].form = {
          actions: [],
          api_list_or_tag: {},
          mappedFields: {},
          controls: index === 0
            ? [
              {
                field_name: 'first_name',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                field_name: 'last_name',
                field_type: 'text',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              },
              {
                field_name: 'email',
                field_type: 'email',
                values: null,
                validators: '[]',
                is_required: true,
                mapping: []
              }
            ]
            : []
        }
      }
      if (!this.section.options.content.orderForm.confirmation[0].form) {
        this.section.options.content.orderForm.confirmation[0].form = {
          actions: [
            {
              name: 'Redirect',
              data: {
                page: {
                  id: 'product-redirect',
                  title: 'Product Redirect'
                },
                customLink: '',
                open_new_tab: true
              }
            }
          ],
          api_list_or_tag: {},
          mappedFields: {},
          controls: []
        }
      }
    }
  },
  async created () {
    this.SET_FORM({id: this.section.uid})
    if (!this.section.options.content.orderForm.products) {
      this.section.options.content.orderForm.products = []
      this.SET_PRODUCT({id: this.section.uid, products: this.section.options.content.orderForm.products})
    } else {
      this.SET_PRODUCT({id: this.section.uid, products: this.section.options.content.orderForm.products})
    }
    await axios.get(`api/projects/${this.project.id}/integrations`)
      .then(({data}) => {
        const paypal = data.data.find((item) => item.alpha_code === 'paypal')
        const square = data.data.find((item) => item.alpha_code === 'square')
        const client_id = paypal.data?.public_key || ''
        const location_id = square.data?.location_id || ''
        if (client_id) {
          this.initPayPal({
            'src': 'https://www.sandbox.paypal.com/sdk/js' + '?client-id=' + client_id
                  + '&enable-funding=venmo&currency=USD&intent=capture&components=buttons',
            'data-namespace': 'paypal_one_time'
          })
          this.initPayPal({
            'src': 'https://www.paypal.com/sdk/js' + '?client-id=' + client_id
                  + '&enable-funding=venmo&currency=USD&intent=capture&components=buttons',
            'data-namespace': 'paypal_one_time'
          })
        }
        if (location_id) {
          document.head.appendChild(document.createElement('script')).src = 'https://sandbox.web.squarecdn.com/v1/square.js'
          this.$store.dispatch('orderForm/setSquareApiKey', {
            location_id: square.data.location_id,
            public_key: square.data.public_key
          })
        }
      })
    for (let i = 0; i < this.steps.length; i++) {
      this.createForm(i)
    }
  }
}
</script>

<style lang="scss">
.order-form {
  overflow: hidden;
  outline-width: var(--border-width);
}
</style>