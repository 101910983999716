<template>
  <div>
    <b-form-group label="Actions">
      <v-select
        v-model="action"
        :options="allActions"
        class="custom-select"
        label="name"
        placeholder="Select Button Action"
        @option:selected="selected"
      />
    </b-form-group>

    <b-form-group v-if="value.length">
      <div class="flex flex-wrap gap-[3px] border p-[9px] rounded-[2px]">
        <div
          v-for="(item, index) in value"
          :key="item.id"
          class="flex items-center bg-[#3D82EA] py-[3px] px-[7px] rounded-[3px] text-[11px] font-[700] text-white"
        >
          <span
            class="mr-[7px]"
            v-text="item.name"
          />

          <button
            v-if="!unremovableActions.includes(item.name)"
            class="hover:opacity-80 p-0 m-0"
            @click="() => removeItem(index)"
          >
            <svg
              fill="none"
              height="7"
              viewBox="0 0 6 7"
              width="6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.640159 0.609834C0.493714 0.463389 0.256279 0.463389 0.109834 0.609834C-0.0366113 0.756279 -0.0366113 0.993714 0.109834 1.14016L2.46968 3.5L0.109834 5.85984C-0.0366111 6.00629 -0.0366113 6.24372 0.109834 6.39017C0.256279 6.53661 0.493714 6.53661 0.640159 6.39017L3 4.03033L5.35978 6.39011C5.50623 6.53656 5.74366 6.53656 5.89011 6.39011C6.03656 6.24366 6.03656 6.00623 5.89011 5.85978L3.53033 3.5L5.89011 1.14022C6.03656 0.99377 6.03656 0.756336 5.89011 0.609891C5.74366 0.463445 5.50623 0.463445 5.35978 0.609891L3 2.96968L0.640159 0.609834Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ActionsAfterSubmit',

  props: {
    value: {
      type: Array,
      required: true
    },
    unremovableActions: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    hideActionItems: {
      type: Array,
      default: () => []
    },
    hideResponders: {
      type: Boolean,
      default: false
    },
    hideMailServices: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      action: ''
    }
  },

  computed: {
    ...mapState('apiIntegration', {
      mailAPIServices: state => state.mailAPIServices
    }),
    allActions () {
      return [
        ...(!this.hideActions
          ? [
            {
              name: 'Email To Admin',
              data: {
                to: '',
                subject: '',
                from_email: '',
                from_name: '',
                cc: ''
              }
            },
            {
              name: 'Redirect',
              data: {
                customLink: '',
                page: {},
                open_new_tab: false
              }
            },
            {
              name: 'Action Modal',
              data: {
                modal_id: null,
                actiion: 'on-submit'
              }
            },
            {
              name: 'Refresh',
              data: {
                actiion: 'on-submit'
              }
            },
            {
              name: 'EGT'
            },
            {
              name: 'Success Message',
              data: {
                title: 'Thank You!',
                text: '',
                showConfirmButton: false,
                confirmButtonText: 'OK',
                timer: 1500
              }
            }
          ].filter(item => !this.hideActionItems.includes(item.name))
          : []),
        ...(!this.hideResponders 
          ? this.mailAPIServices.filter(item => !this.hideMailServices.includes(item.alpha_code))
          : []
        )
      ].filter(item => {
        return this.value.findIndex(val => val.name === item.name) === -1
      })
    }
  },

  methods: {
    selected (val) {
      this.action = ''
      this.$emit('input', [val, ...this.value])
    },
    removeItem (index) {
      this.value.splice(index, 1)
    }
  }
}
</script>
