import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Opt-In / Contact Form'
export const group = 'Forms'

export const defaultOptions = {
  'customize': null,
  'content': {
    'form': {
      display: true,
      'type': 'form-mod-v2',
      'controls': [
        {
          'label': 'Email',
          'placeholder': 'Enter email address',
          'field_name': 'Email',
          'field_type': 'email',
          'values': null,
          'validators': '[]',
          'is_required': 1,
          'columns': 12,
          'form_nane': 'New Form',
          'mapping': []
        },
        {
          'label': 'Name',
          'placeholder': 'Enter your name',
          'field_name': 'Name',
          'field_type': 'text',
          'values': null,
          'validators': '[]',
          'is_required': 0,
          'columns': 12,
          'form_nane': 'New Form',
          'mapping': []
        }
      ],
      'subject': {
        'uid': 'RSfMebaiz8',
        'display': true,
        'name': 'Button',
        'css': null,
        'sid': 'cea967j--TTfZ-A7eOR',
        'sids': null,
        'hideFromSections': false,
        'component': 'ElButtonV2',
        'group': 'Media',
        'layout': null,
        'options': {
          'content': {
            'button': {
              'pressetID': 0,
              'display': true,
              'type': 'button-mod-v2',
              'text': 'Click Me',
              'buttonType': 'button',
              'icon': {'lottie': {'jsonPath': '', 'loop': true, 'autoplay': true}},
              'hoverAnimation': {},
              'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50}
            }
          }, 'customize': null
        },
        'temp_id': 'GJ3MzsHvO9'
      }
    }
  }
}

export const icon = `
<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 4H14C14.2652 4 14.5196 4.10536 14.7071 4.29289C14.8946 4.48043 15 4.73478 15 5V9C15 9.26522 14.8946 9.51957 14.7071 9.70711C14.5196 9.89464 14.2652 10 14 10H10V11H14C14.5304 11 15.0391 10.7893 15.4142 10.4142C15.7893 10.0391 16 9.53043 16 9V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10V4ZM6 4V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5L0 9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11H6V10H2C1.73478 10 1.48043 9.89464 1.29289 9.70711C1.10536 9.51957 1 9.26522 1 9V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H6Z" fill="#1D1D1D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C8.13261 0 8.25979 0.0526784 8.35355 0.146447C8.44732 0.240215 8.5 0.367392 8.5 0.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V0.5C7.5 0.367392 7.55268 0.240215 7.64645 0.146447C7.74021 0.0526784 7.86739 0 8 0V0Z" fill="#1D1D1D"/>
</svg>
`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElFormV2', options)

    this.name = name
    this.group = group
    this.css = [['.--cea967j--PLYS7XqUVU--form { gap: 20px 20px; align-items: end; flex-direction: column; padding: 20px; }', '.--cea967j--TTfZ-A7eOR--1 { margin-bottom: 0px; height: 50px; width: 100%; }', '.--cea967j--PLYS7XqUVU--form .pU9T6Auqb { width: 100%; }', '.--cea967j--PLYS7XqUVU--form .faQ0DoIri4 { width: 100%; }', '.--cea967j--PLYS7XqUVU--form legend { text-align: start; }'], [], []]
    this.sid = 'cea967j--PLYS7XqUVU'
    this.sids = ['cea967j--PLYS7XqUVU', 'cea967j--TTfZ-A7eOR']
    this.defaultOptions = defaultOptions
  }
}

